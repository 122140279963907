import React from 'react';

const Tooltip = ({ children, content,position }) => {
  return (
  <div className="tooltip-container relative">
      {children}
      <div className={`tooltip-content top-5 !border-none !bg-white text-white z-50 ${position ? position :"!ml-0"}`}>
        {content}
      </div>
    </div>
  );
};

export default Tooltip;
