import logo from './logo.svg';
import './App.css';
import AllRoutes from './allroutes/AllRoutes';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useOnlineStatus from './common/onlineStatus';
import OfflineNotification from './common/offlineNotification';
import { useEffect, useState } from 'react';
import ScrollToTop from './common/ScrollToTop';

function App() {
  const isOnline = useOnlineStatus();
  const [showOfflinePopup, setShowOfflinePopup] = useState(false);
  useEffect(() => {
    if (!isOnline) {
      setShowOfflinePopup(true);
    }
  }, [isOnline]);

  const handleClosePopup = () => {
    setShowOfflinePopup(false);
  };
  return (
    <div className="App relative">
      {showOfflinePopup && <OfflineNotification onClose={handleClosePopup} />}
      <ToastContainer />
      <ScrollToTop />
      <AllRoutes/>
    </div>
  );

}

export default App;
