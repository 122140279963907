import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Checkbox from "../../../../common/Checkbox";
import RegisterTermsConditions from "../../RegisterTermsConditions";
import SelectTagComponent from "../../../../common/SelectTagComponent";
import { commenInputDivSpace, formStyle, termAndConditionStyle } from "../../RegisterStyle";
import { AuthButtonClass, AuthButtonDisabledClass, InputFieldClass, LabelFieldClass } from "../../../../common/StyleCommon";
import InputField from "../../../../common/InputField";
import Button from "../../../../common/Button";
import { validateCompanyRegisterForm } from "../../../../common/Validations";
import { Axios, allowAlphabeticInputs, apiHost, emailRegex, handleAlphabeticInput, handleNumericInput } from "../../../../common/Config";
import usePost from "../../../../Hook/usePost";
import { onkeyDownforSpecialCharcter } from "../../../../Hook/validation";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css' // Add this line to import the necessary CSS
import useGetData from "../../../../Hook/useGetData";


const Company = () => {
  const navigate = useNavigate();
  const { data: emailData, error: emailError, loading: emailLoading, postData: postEmailData } = usePost('onboarding/verify/verify-email');
  const { data: mobileData, error: mobileError, loading: mobileLoading, postData: postMobileData } = usePost('onboarding/verify/verify-phone');
  const { data: getStateData, loading: loadingState, error: errorState } = useGetData('master-data/state/get',"token");
 
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    companyEmail: "",
    mobileNumber: "",
    turnoverGrid: "",
    vintageGrid: "",
    termsAccepted: false,
    signUpStatus : "company",
    state:0,
    city:0
  });
  const { data: getCityData, loading: loadingCity, error: errorCity } = useGetData(`master-data/city/${formData?.state}`,"token");
  const [errors, setErrors] = useState({});
  const [turnoverGridOptions, setTurnoverGridOptions] = useState([]);
  const [vintageGridOptions, setVintageGridOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true)
  const [value, setValue] = useState('');
  const [previousDialCode, setPreviousDialCode] = useState('+91');
  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };
 
  const debouncedPostEmailData = debounce(postEmailData, 300);
  const debouncedPostMobileData = debounce(postMobileData, 300);

  const handleChange = (e) => {
    let { name, value, type, checked } = e.target;


    if (name == 'companyEmail' && !emailRegex.test(value)) {
      setErrors({ ...errors, 'companyEmail': "Enter valid company email id" })
    }
    else {
      let newError = { ...errors };
      if (('companyEmail' in newError) && Object.keys(newError).length === 1) {
        delete newError.companyEmail;

      }
      if (name in newError) {
        delete newError[name];
      }
      setErrors(newError);
    }

    // Debounce API calls for email and mobile number
    if (name === 'companyEmail') {
      debouncedPostEmailData({ email: value });
    } if (name === 'mobileNumber' && value?.length>9) {
      debouncedPostMobileData({ phone: value?.replaceAll(" ","").slice(-10) });
    }
    if (name === 'mobileNumber') {
      value = value?.replace(/\D/g, '');
    }

    // Update form data
    const updatedFormData = {
      ...formData,
      [name]: type === "checkbox" ? checked : value
    };

    // Set form data
    setFormData(updatedFormData);

    // Validate form field
    if (errors[name]  && Object.keys(errors).length >=5) {
      validateCompanyRegisterForm(updatedFormData, setErrors);
    }

    // Clear error on change
    // setErrors({ ...errors, [name]: '' });
  };


  const handleSelectChange = (name, value) => {
    if (value && typeof value === 'object') {
      setFormData({ ...formData, [name]: value.id }); // Update only with value name
    } else {
      setFormData({ ...formData, [name]: value.id });
    }
    setErrors({ ...errors, [name]: '' }); // Clear error on change
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateCompanyRegisterForm({...formData,mobileNumber:formData?.mobileNumber?.replaceAll(" ","").slice(-10)}, setErrors)) {
      if (mobileData?.status === true) {
        setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Mobile number already exists" }));
      }

      else if (emailData?.status === true) {
        setErrors((prevErrors) => ({ ...prevErrors, companyEmail: "Email already exists" }));
      }
      else {
        sessionStorage.setItem("companyData", JSON.stringify({...formData,mobileNumber:formData?.mobileNumber?.replaceAll(" ","").slice(-10)}));
        let bkdata;
        
        try {
          bkdata = JSON.parse(sessionStorage.getItem("bankerData"));
          if(bkdata !== undefined) {
            sessionStorage.removeItem("bankerData");
          }
        }
        catch {
          console.error("Banker Data key does not exist in session storage");
        }
        navigate("/set-password");
      }
    }
  };
  // Fetch turnover grid options from API or static data
  const fetchTurnoverGridOptions = async () => {
    // const response = await axios.get(`${apiHost}/turnovers/index`);
    const response = await Axios("GET", `${apiHost}/master-data/turnovers/index`);
    const data = response?.data?.data;
    setTurnoverGridOptions(data.map((item) => item));
  }

  // Fetch vintage grid options from API
  const fetchVintageGridOptions = async () => {
    // const response = await axios.get(`${apiHost}/vintages/index`);
    const response = await Axios("GET", `${apiHost}/master-data/vintages/index`);
    const data = response?.data?.data;
    setVintageGridOptions(data.map((item) => item));
  }

  useEffect(() => {
    fetchTurnoverGridOptions();
    fetchVintageGridOptions();
  }, []);

  useEffect(() => {

    if (emailData?.status === true) {
      setErrors((prevErrors) => ({ ...prevErrors, companyEmail: "Email already exists" }));
    }
  }, [emailData, emailError]);

  useEffect(() => {
    if (mobileData?.status === true) {
      setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Mobile number already exists" }));
    } else {
      let prevErrors = { ...errors };

      if ("mobileNumber" in prevErrors) {
        delete prevErrors.mobileNumber;
      }
      setErrors(prevErrors);
    }
  }, [mobileData, mobileError]);

  useEffect(() => {
    if (formData?.termsAccepted) {
      setIsDisabled(false)
    } else {
      setIsDisabled(!false)
    }
  }, [formData])
 
  return (
    <div>
      <div className={formStyle}>
        <form onSubmit={handleSubmit}>
          <div>
            <div className={commenInputDivSpace}>
              <InputField
                disabled={false}
                astrix={true}
                label="Full Name"
                type="text"
                id="fullName"
                name="fullName"
                required={true}
                value={formData.fullName}
                onChange={handleChange}
                onkeyDown={handleAlphabeticInput}
                error={errors.fullName}
                labelClass={LabelFieldClass}
                className={InputFieldClass}
              />
            </div>
          </div>
          <div>
            <div className={commenInputDivSpace}>
              <InputField
                disabled={false}
                astrix={true}
                label="Company Name"
                type="text"
                id="companyName"
                name="companyName"
                required={true}
                value={formData.companyName}
                onChange={handleChange}
                error={errors.companyName}
                labelClass={LabelFieldClass}
                className={InputFieldClass}
              />
            </div>
          </div>
          <div>
            <div className={commenInputDivSpace}>
              <InputField
                disabled={false}
                astrix={true}
                label="Company email ID"
                type="email"
                id="companyEmail"
                name="companyEmail"
                // required={false}
                value={formData.companyEmail}
                onChange={handleChange}
                error={errors.companyEmail}
                labelClass={LabelFieldClass}
                className={InputFieldClass}
              />
            </div>
          </div>
          <div>
            <div className={commenInputDivSpace}>
            <PhoneInput
        country={'in'}
        disableCountryGuess={true}
        countryCodeEditable={false}
        value={formData.mobileNumber}
        
        onChange={(fullPhoneNumber,country) => {
          const newDialCode = `+${country.dialCode}`;
          if (newDialCode !== previousDialCode) {
            // Reset mobile number to just the new country code
            const newMobileNumber = newDialCode;
            setFormData({ ...formData, mobileNumber: newMobileNumber });
            setPreviousDialCode(newDialCode);
            handleChange({ target: { name: 'mobileNumber', value: newMobileNumber } });
          } else {
            // Update the phone number as usual
            setFormData({ ...formData, mobileNumber: fullPhoneNumber });
            handleChange({ target: { name: 'mobileNumber', value: fullPhoneNumber } });
          }
      
        }}
        inputStyle={{ width: '100%', height: '45px' }}
        containerClass="phone-input-container"
      dropdownClass="phone-dropdown"
      />
      <p className="text-right primary-text-red text-sm">{errors && errors?.mobileNumber}</p>
      
              {/* <InputField
                disabled={false}
                astrix={true}
                span="+91"
                label="Your mobile number"
                type="tel"
                onkeyDown={onkeyDownforSpecialCharcter}
                id="mobileNumber"
                name="mobileNumber"
                required={true}
                maxLength={10}
                value={formData.mobileNumber}
                onChange={handleChange}
                error={errors.mobileNumber}
                labelClass={LabelFieldClass}
                className={InputFieldClass}
              /> */}

             

            </div>
          </div>
          <div className="lg:flex md:flex gap-4">
            <div className={commenInputDivSpace}>
            <SelectTagComponent
              defaultVal="State"
              error={errors.state}
              options={getStateData?.data || []}
              onChange={(value) => handleSelectChange("state", value)}
            />
            </div>
            <div className={commenInputDivSpace}>
            <SelectTagComponent
              defaultVal="Current City"
              options={getCityData?.data?.map((item)=>{
                return {
                  ...item,
                  name: item.city,
                }
              }) || []}
              error={errors?.city}
              onChange={(value) => handleSelectChange("city", value)}

            />
            </div>
          </div>
          <div className="mt-4 lg:mt-0">
            <SelectTagComponent
              defaultVal="Turnover Grid"
              options={turnoverGridOptions}
              error={errors.turnoverGrid}
              onChange={(value) => handleSelectChange("turnoverGrid", value)}
            />
          </div>
          <div className="mt-5">
            <SelectTagComponent
              error={errors.vintageGrid}
              defaultVal="Vintage Grid"
              options={vintageGridOptions}
              onChange={(value) => handleSelectChange("vintageGrid", value)}
            />
          </div>
          <div className={termAndConditionStyle}>
            <Checkbox name="termsAccepted" type="checkbox" value={formData.termsAccepted} onChange={handleChange} />
            <RegisterTermsConditions />
          </div>
          <div className="text-start ml-8 mb-4">
          {errors.termsAccepted && <span className="primary-text-red text-sm">{errors.termsAccepted}</span>}

          </div>
          <Button type="submit" className={ `mb-4 md:mb-0 ${isDisabled ? AuthButtonDisabledClass : AuthButtonClass}`} disabled={isDisabled}>
            Register
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Company;