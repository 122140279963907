import React from 'react';
import { container, title, separator, value } from "./StyleCommon"; // Import styles

const TitleValueComp = ({ data }) => {
  
  return (
    <div>
       {data &&
            data?.map((item, index) => (
              <div key={index} className={container}>
                <div className={title}>
                  {item?.title}
                </div>
                <div className={separator}>
                  -
                </div>
                <div className={value}>
                  {item?.titleValue}
                </div>
              </div>
            ))}
    </div>
  );
};

export default TitleValueComp;
