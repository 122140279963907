import React, { useState } from 'react'
import Navbar from '../../../../common/Navbar'
import Referrals from '../../../../common/Referrals'
import SideNavbar from '../../../../common/SideNavbar'

const CompanyReferrals = () => {
 
  return (
    
    <div>
     
        <Navbar  hemBurgerIcon={true} CompanyNavbar={true} BackBtn={false} ProfileAndNotification={true} />
     
      <div className='flex'>
      <div className='md:w-[7%] lg:w-[7%] '>
           <SideNavbar/>
        </div>

        <div className='w-full md:w-[93%] lg:w-[93%]'>
<Referrals/>
</div>        
      </div>
    </div>
  )
}

export default CompanyReferrals
