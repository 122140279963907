import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BankerProjectIconDashboard,
  CalendarIcon,
  JobIcon,
  ReferralsIcon,
  MenuIcon,
  CloseIcon, // Ensure you have a close icon
} from "./Icons";
import {
  SideNavbarOptionActive,
  SideNavbarStyleContainer,
  SideNavbarStyleDivTwo,
  SideNavbarStyleDivPtagtwo,
  SideNavbarStyleContainerMobile,
  SideNavbarStyleDivTwoMobile,
  SideNavbarOptionActiveMobile,
  SideNavbarStyleDivPtagtwoGreen,
  BankerSideNavbarStyleContainer1,
  BankerSideNavbarStyleContainer2,
  BankerSideNavbarStyleContainer3,
} from "./StyleCommon";
import { useBankerSidebar } from "./BankerSidebarProvider";

const BankerSideNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isBankerSidebarOpen, toggleBankerSidebar } = useBankerSidebar();


  return (
    <div>
      {/* Mobile Hamburger Menu */}
      {/* <div className="lg:hidden fixed bottom-0 left-0 right-0 flex justify-between px-4 shadow-lg h-[6rem] bg-white z-50 items-center">
        <div onClick={handleMenuToggle} className="cursor-pointer">
          <MenuIcon />
        </div>
      </div> */}

      {isBankerSidebarOpen && (
        <div className={BankerSideNavbarStyleContainer1}>
          <div onClick={toggleBankerSidebar} className={BankerSideNavbarStyleContainer2}>
            <div>
                <h6 className="h6-text text-[#2C2C2C]">
                  Menu
                </h6>
            </div>
            <div>
            <CloseIcon />
            </div>
          </div>
          <div
                       className={`${location?.pathname === '/dashboard/banker' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}
           
           // className={SideNavbarStyleContainerMobile}
            onClick={() => {
              navigate("/dashboard/banker");
              toggleBankerSidebar()
            }}
          >
            <div
              className={
                location?.pathname === "/dashboard/banker"
                  ? `${SideNavbarStyleDivTwoMobile}`
                  : `${SideNavbarStyleDivTwoMobile}`
              }
            >
              <JobIcon location={location} />
            </div>
            <div>
        
              <p 
              // className={SideNavbarStyleDivPtagtwo}
              className={`${location?.pathname === '/dashboard/banker' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}

              
              >Jobs</p>
            </div>
          </div>

          <div
                       className={`${location?.pathname === '/dashboard/banker/project' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}

            onClick={() => {
              navigate("/dashboard/banker/project");
              toggleBankerSidebar()
            }}
          >
            <div
              className={
                location?.pathname === "/dashboard/banker/project"
                  ? `${SideNavbarStyleDivTwoMobile}`
                  : `${SideNavbarStyleDivTwoMobile}`
              }
            >
              <BankerProjectIconDashboard location={location} />
            </div>
            <div>
              <p 
              className={`${location?.pathname === '/dashboard/banker/project' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}
              
              >Projects</p>
            </div>
          </div>

          <div
                        // className={`${location?.pathname === '/dashboard/banker/calendar' ? {SideNavbarOptionActiveMobile} : ""} ${SideNavbarStyleContainerMobile}`}
                        className={`${location?.pathname === '/dashboard/banker/calendar' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}

            onClick={() => {
              navigate("/dashboard/banker/calendar");
              toggleBankerSidebar()
            }}
          >
            <div
              className={
                location?.pathname === "/dashboard/banker/calendar"
                  ? `${SideNavbarStyleDivTwoMobile} `
                  : `${SideNavbarStyleDivTwoMobile}`
              }
            >
              <CalendarIcon location={location} />
            </div>
            <div>
              <p 
              // className={SideNavbarStyleDivPtagtwo}
              className={`${location?.pathname === '/dashboard/banker/calendar' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}

              >Calendar</p>
            </div>
          </div>

          {/* <div
                       className={`${location?.pathname === '/dashboard/banker/referrals' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}
           
            onClick={() => {
              navigate("/dashboard/banker/referrals");
              toggleBankerSidebar()
            }}
          >
            <div
              className={
                location?.pathname === "/dashboard/banker/referrals"
                  ? `${SideNavbarStyleDivTwoMobile}`
                  : `${SideNavbarStyleDivTwoMobile}`
              }
            >
              <ReferralsIcon location={location} />
            </div>
            <div>
              <p
              className={`${location?.pathname === '/dashboard/banker/referrals' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}
              
              >Referrals</p>
            </div>
          </div>  */}
        </div>
      )}

      {/* Desktop Sidebar */}
      <div className={BankerSideNavbarStyleContainer3}>
        <div
          className={SideNavbarStyleContainer}
          onClick={() => navigate("/dashboard/banker")}
        >
          <div
            className={
              location?.pathname === "/dashboard/banker"
                ? `${SideNavbarStyleDivTwo} ${SideNavbarOptionActive}`
                : `${SideNavbarStyleDivTwo}`
            }
          >
            <JobIcon location={location} />
          </div>
          <div>
            <p className={SideNavbarStyleDivPtagtwo}>Jobs</p>
          </div>
        </div>

        <div
          className={SideNavbarStyleContainer}
          onClick={() => navigate("/dashboard/banker/project")}
        >
          <div
            className={
              location?.pathname === "/dashboard/banker/project"
                ? `${SideNavbarStyleDivTwo} ${SideNavbarOptionActive}`
                : `${SideNavbarStyleDivTwo}`
            }
          >
            <BankerProjectIconDashboard location={location} />
          </div>
          <div>
            <p className={SideNavbarStyleDivPtagtwo}>Projects</p>
          </div>
        </div>

        <div
          className={SideNavbarStyleContainer}
          onClick={() => navigate("/dashboard/banker/calendar")}
        >
          <div
            className={
              location?.pathname === "/dashboard/banker/calendar"
                ? `${SideNavbarStyleDivTwo} ${SideNavbarOptionActive}`
                : `${SideNavbarStyleDivTwo}`
            }
          >
            <CalendarIcon location={location} />
          </div>
          <div>
            <p className={SideNavbarStyleDivPtagtwo}>Calendar</p>
          </div>
        </div>

        {/* <div
          className={SideNavbarStyleContainer}
          onClick={() => navigate("/dashboard/banker/referrals")}
        >
          <div
            className={
              location?.pathname === "/dashboard/banker/referrals"
                ? `${SideNavbarStyleDivTwo} ${SideNavbarOptionActive}`
                : `${SideNavbarStyleDivTwo}`
            }
          >
            <ReferralsIcon location={location} />
          </div>
          <div>
            <p className={SideNavbarStyleDivPtagtwo}>Referrals</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BankerSideNavbar;
