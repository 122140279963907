import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../common/Navbar";
import {
  ForgotSetPasswordmainContainer,
  ForgotSetPasswordmainContainerDiv,
  SetPasswordStyleInputDiv,
  SetPasswordStyleListSVG,
  SetPasswordStyleResetDiv,
  SetPasswordStyleSVG,
  SetPasswordStyleUL,
} from "../register/RegisterStyle";
import { InputFieldClass, flexitemCentre } from "../../common/StyleCommon";
import InputField from "../../common/InputField";
import Button from "../../common/Button";
import {
  CloseEyePasswordIcon,
  LockIcon,
  OpenEyePasswordIcon,
  ResetPasswordRightTick,
} from "../../common/Icons";
import { ToastMessage, hasCapitalLetter, hasLetter, hasNumberOrSpecialChar } from "../../common/Config";
import usePost from "../../Hook/usePost";
import useGetData from "../../Hook/useGetData";
import { LogOut, comparePassword } from "../../Hook/validation";

const ResetPassword = ({ type }) => {
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const { data, error, loading, refetch } = useGetData(type === true ? "profile/banker/basic-details" : "profile/company/basic-details", token);
  const { data: ChangeData, error: ChangeDataError, loading: ChangeDataLoading, postData: postChangeData } = usePost(`onboarding/forgot-password`);
  
  const [currentPassword, setCurrentPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [passwordLength, setPasswordLength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [errors, setErrors] = useState({});

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    let errs = { ...errors };

    if ("password" in errs) delete errs["password"];

    setErrors(errs);
    setPassword(newPassword);
    setPasswordLength(newPassword.length);
  };

  const handleRePasswordChange = (e) => {
    let errs = { ...errors };

    if ("rePassword" in errs) delete errs["rePassword"];

    setErrors(errs);
    setRePassword(e.target.value);
  };

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const isContinueDisabled =
    password === "" ||
    rePassword === "" ||
    password !== rePassword ||
    password === currentPassword || // Ensure new password is not the same as the current password
    !hasCapitalLetter(password) ||
    !hasLetter(password) ||
    !hasNumberOrSpecialChar(password) ||
    passwordLength < 8;

  const handleConfirm = async() => {
    let errs = {};
    const CurrentPasswordw=sessionStorage?.getItem("Password");
const storedHashedPassword = CurrentPasswordw;
const isPasswordCorrect = await comparePassword(currentPassword, storedHashedPassword);
if (isPasswordCorrect) {
  // errs["currentPassword"]="Password is correct"
} else {
  errs["currentPassword"]="Password is incorrect"
}
    if (!password) {
      errs["password"] = "This field is required";
    } else if (password.length > 20) {
      errs["password"] = "Password should not exceed 20 characters";
    } else if (password === currentPassword) {
      errs["password"] = "New password cannot be the same as the current password";
    }

    if (!rePassword) {
      errs["rePassword"] = "This field is required";
    } else if (password && rePassword && password !== rePassword) {
      errs["rePassword"] = "Entered password should be the same";
    }

    if (Object.keys(errs).length > 0) {
      setErrors(errs);
      return;
    }
    if (isContinueDisabled) {
      return;
    }

    const obj = {
      email: data?.data?.user?.email || data?.data?.company?.user?.email || "",
      new_password: password,
      confirm_new_password: rePassword,
    };
    postChangeData(obj);
  };

  useEffect(() => {
    if (ChangeData) {
      ToastMessage("success", "Password changed successfully");
      LogOut();
    }
    if (ChangeDataError) {
      ToastMessage("error", "Something went wrong");
    }
  }, [ChangeData, ChangeDataError]);

  return (
    <div className="m-auto">
      <div>
        <div>
          <div className={ForgotSetPasswordmainContainer}>
            <div className={ForgotSetPasswordmainContainerDiv}>
              <LockIcon />
            </div>
            <h3 className="mb-2 h3-text mt-4 text-gray-900">Change Password</h3>
            <div>
              <div className={SetPasswordStyleInputDiv}>
                <InputField
                  label="Current password"
                  astrix={true}
                  id="currentPassword"
                  disabled={false}
                  required={true}
                  type={showCurrentPassword ? "text" : "password"}
                  value={currentPassword}
                  name=""
                  onChange={handleCurrentPasswordChange}
                  className={InputFieldClass}
                  error={errors.currentPassword}
                />
                {showCurrentPassword ? (
                  <CloseEyePasswordIcon
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    className={SetPasswordStyleSVG}
                  />
                ) : (
                  <OpenEyePasswordIcon
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    className={SetPasswordStyleSVG}
                  />
                )}
              </div>

              <div className="w-full border mt-6   md:w-[34.625rem] m-auto"></div>

              <div className="relative mb-2 w-full lg:w-[560px] mt-6 m-auto">
                <InputField
                  label="New password"
                  astrix={true}
                  id="newPassword"
                  disabled={false}
                  required={true}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  name=""
                  onChange={handlePasswordChange}
                  className={InputFieldClass}
                  error={errors.password}
                />
                {showPassword ? (
                  <CloseEyePasswordIcon
                    onClick={() => setShowPassword(!showPassword)}
                    className={SetPasswordStyleSVG}
                  />
                ) : (
                  <OpenEyePasswordIcon
                    onClick={() => setShowPassword(!showPassword)}
                    className={SetPasswordStyleSVG}
                  />
                )}
              </div>
            </div>
            <ul className={SetPasswordStyleUL}>
              <li className={flexitemCentre}>
                <ResetPasswordRightTick
                  className={`${SetPasswordStyleListSVG} ${
                    passwordLength >= 8
                      ? "primary-text-green"
                      : "text-[#D3D3D3]"
                  } flex-shrink-0`}
                />
                At least 8 characters
              </li>
              <li className={flexitemCentre}>
                <ResetPasswordRightTick
                  className={`${SetPasswordStyleListSVG} ${
                    hasCapitalLetter(password)
                      ? "primary-text-green"
                      : "text-[#D3D3D3]"
                  } flex-shrink-0`}
                />
                At least 1 capital letter
              </li>
              <li className={flexitemCentre}>
                <ResetPasswordRightTick
                  className={`${SetPasswordStyleListSVG} ${
                    hasNumberOrSpecialChar(password)
                      ? "primary-text-green"
                      : "text-[#D3D3D3]"
                  } flex-shrink-0`}
                />
                At least 1 number or special character
              </li>
            </ul>

            <div className={SetPasswordStyleResetDiv}>
              <InputField
                label="Re-enter password"
                astrix={true}
                id="rePassword"
                disabled={false}
                required={true}
                type={showRePassword ? "text" : "password"}
                name=""
                onChange={handleRePasswordChange}
                value={rePassword}
                error={errors.rePassword}
                className={InputFieldClass}
              />

              {showRePassword ? (
                <CloseEyePasswordIcon
                  onClick={() => setShowRePassword(!showRePassword)}
                  className={SetPasswordStyleSVG}
                />
              ) : (
                <OpenEyePasswordIcon
                  onClick={() => setShowRePassword(!showRePassword)}
                  className={SetPasswordStyleSVG}
                />
              )}
            </div>

            <Button
              onClick={handleConfirm}
              disabled={!password || isContinueDisabled}
              className={`w-full lg:w-[560px] rounded-lg py-3 mt-6 text-white ${
                password !== rePassword ||
                password === "" ||
                currentPassword===password ||
                rePassword === ""
                  ? "bg-[#8ED9B4]"
                  : "greenbg"
              }`}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
