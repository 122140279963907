export const SearchStyleMainDiv = "w-full lg:w-[55%] relative px-4 lg:px-10 py-10 overflow-auto custom-scrollbar text-start";
export const SearchStyleMainDivContainer ="";
export const SearchStyleMainDivSearchButtonDiv = "h-[4rem] py-2 shadow border-t border-r-0 bg-white flex justify-end px-[2.5rem] w-full  md:w-[55%] z-50 fixed bottom-0 left-0"
export const SearchStyleMainDivSearchButton= "font-urbanist flex items-center text-md justify-center greenbg text-center ml-auto rounded-lg text-white w-full md:w-[9.75rem] h-[48px] px-4 gap-1";
export const SearchStyleCheckboxTitle= "s2-text pt-6";
export const SearchStyleCheckboxDiv= "grid mt-4 grid-cols-3";
export const SearchStyleCheckboxDivForDashboard= " mt-4 grid-cols-3";

export const SearchStyleInputDiv="mt-4 flex";
export const SearchStyleInputDivText1 ="px-4 mt-6 text-[14px] text-[#80828D]"
export const SearchStyleInputDivText2 = "mt-6 text-[14px] pl-2 text-[#80828D]"

export const SearchStyleSecondDiv  = "w-full md:w-[45%] light-gray-background";
export const SearchStyleSecondDivContainer = "mt-7 px-4 py-4 md:mx-7 md:pr";
export const SearchStyleSecondDivtwo =" flex gap-2 items-center";
export const SearchStyleSecondDivtwoText = "body-text text-start text-[#1E1F24]";
export const SearchStyleSecondDivfillsearch= "flex justify-start items-center mt-3 gap-6"
export const JustStartItemCenter= "flex justify-start items-center mt-1 gap-6"

export const SearchStyleSecondRecentSearh = "h5-text secondary-text-gray";
export const RemoveTPgreen="remove-text primary-text-green";
export const SearchStyleSecondProfileFound = "rounded-[24.5px] text-[#62636C] px-4 py-1 bg-[#EFF0F3]"
export const SearchStyleSecondSavedSearch = "md:mt-10 px-4 md:mx-7 md:pr-";
export const SearchStyleSecondSaved ="flex items-center w-full  justify-between";
export const S2mb1  = "s2-text mb-1"


export const ProfileViewContainer = "mt-10 flex justify-between"
export const ProfileViewContainerDiv = "flex items-center gap-3 cursor-pointer"
export const ProfileViewContainerDiv2 = "px-5 pt-2 border rounded-[12px] mt-6 border-[#EFF0F3]"
export const ProfileViewHRLine = "px-5 py-5 border rounded-[12px] mt-6 border-[#EFF0F3]"
export const ProfileViewContainerDivText = "h6-text my-4 secondary-text-gray";
export const ProfileViewSimilarTag = "md:w-[38%] mt-4 md:mt-0 w-full pt-2 md:pt-0 light-gray-background";
export const ProfileViewSimilarTagContainer = "flex  justify-between mt-5 px-4 py-2 mx-2 md:mx-6 bg-white rounded-[8px]";
export const ProfileViewSimilarCard = "rounded-[8px] mx-2 md:mx-6";
