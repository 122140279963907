import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../common/Navbar';
import Timeline from './Timeline';
import ProfilePhotoUploadModel from '../ProfilePhotoUploadModal';
import BottomBar from '../../../common/BottomBar';
import useGetData from '../../../Hook/useGetData';
 
const StepThreebanker = () => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: getPaymentData,loading: loadingPayment, error: errorPayment } = useGetData('onboarding/get-payment-status', token);
  const [profilePhoto, setProfilePhoto] = useState(false);
  const navigate = useNavigate();
  
  return (
    <div>
      
      <Navbar BackBtn={true} hemBurgerIcon={false} buybtn={getPaymentData?.payment_status ? null : "Buy Package"} />
      {/* <div> */}
      {/* <div className="lg:w-[34.625rem] md:w-[34.625rem] w-full m-auto"> */}

        <Timeline
       
          stepOneStatus={true}
          stepTwoStatus={true}
          stepThreeStatus={false}
          stepFourStatus={false}
           heading="Add Profile Picture"
 
        />
      {/* </div> */}

      {/* </div> */}
      <div>
        <h5 className="h5-text my-10 text-[#2C2C2C]" >Upload profile picture</h5>
      </div>
 
      <div className="py-2 ">
        <ProfilePhotoUploadModel profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto}/>

      </div>


      <div className='h-[8rem] lg:h-[2rem] md:h-[2rem] '>

      </div>

      <div>

      <BottomBar OnCLickBackbtn={()=>navigate("/profile/banker/additional-hiring-details")} OnCLickNextBtn={()=>navigate("/profile/banker/profile-preview")}  btnName="Next"
        BackBtn="Back"
        SaveBtn="Save"
        step="3"
        />
 
      </div>
    </div>
  )
}
 
export default StepThreebanker