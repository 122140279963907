import React from 'react';
import Navbar from '../../../../common/Navbar';
import SideNavbar from '../../../../common/SideNavbar';
import { MarginForProfileCoreExpertise } from '../../../../common/StyleCommon';
import RoundedBox from '../../../../common/RoundedBox';
import TitleValueComp from '../../../../common/TitleValueComp';

const BankerProfileView = () => {
  const CoreExpertise = ["Small company" ,"SME", "Stress asset" , "International banking" , "Forex" ];
  const KeyAreaService =["Debt fund raise" , "Equity Fund Raise" , "Debt & Equity" , "Debt Resturing Advisory" , "International expansion Advisory"]
  const educationData = [
    {
      id: 1,
      title: "Level",
      titleValue: "Custom",
    },
    {
      id: 2,
      title: "Degree",
      titleValue: "XYZ certification from ABC Institute",
    },
  ];
  const experienceData = [
    {
      id: 1,
      title: "Last company",
      titleValue: "SBI Card, Gurgaon",
    },
    {
      id: 2,
      title: "Total experience",
      titleValue: "6 Years 8 Months",
    },
    {
      id: 3,
      title: "Date of retirement",
      titleValue: "12/12/2023",
    },
  ];

  const profileData = [
    {
      id: 1,
      title: "DOB",
      titleValue: "20/04/1965",
    },
    {
      id: 2,
      title: "Email ID",
      titleValue: "faisalyazdani@gmail.com",
    },
    {
      id: 3,
      title: "Phone Number",
      titleValue: "+91-80768192678",
    },
    {
      id: 4,
      title: "Current City",
      titleValue: "Delhi",
    },
  ];
  return (
    <div>
      <Navbar hemBurgerIcon={true} BackBtn={true} ProfileAndNotification={true} CompanyNavbar={true} />

      <div className="flex bg-[#F9F9FB]">
        
        <div className='grid grid-cols-2'>
        <div className="w-[150%] text-start flex-1 max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-md">
         <div className='border px-4 py-4 rounded-lg'>
         <div className="flex items-center space-x-4 mb-4">
            <img
              src="https://via.placeholder.com/150"
              alt="Profile"
              className="w-16 h-16 rounded-full"
            />
            <div>
              <h2 className="text-xl font-bold">Sumit Mathur</h2>
              <p className="text-gray-500">Bank Manager</p>
            </div>
          </div>

          <div className="flex space-x-4 mb-4">
            <span className="px-3 py-1 bg-gray-100 rounded-full">Short Term (Upto 3 months)</span>
            <span className="px-3 py-1 bg-gray-100 rounded-full">In-Person, Virtual</span>
            <span className="px-3 py-1 bg-gray-100 rounded-full">₹ 80,000/month</span>
          </div>
         </div>

          <div className="mb-4 border rounded-lg px-4 my-4 py-4">
          <div className="w-[50%]">
              <TitleValueComp data={profileData} />
            </div>
          </div>

          <div className="mb-4 border rounded-lg px-4 my-4 py-4">
          <h6 className="h6-text">Experience</h6>
 
 <div className="w-[50%]">
   <TitleValueComp data={experienceData} />
 </div>
          </div>

          <div className="mb-4 border rounded-lg px-4 my-4 py-4">
            <h6 className="h6-text">Education</h6>
            <div className="w-[50%]">
              <TitleValueComp data={educationData} />
            </div>
        
          </div>

          <div className="mb-4 border rounded-lg px-4 my-4 py-4">
            <h3 className="text-lg font-semibold">Key Area of Services</h3>
            <div className={MarginForProfileCoreExpertise}>
        { <RoundedBox data = {KeyAreaService} cross={false}/>}
      </div>
          </div>

          <div className="mb-4 border rounded-lg px-4 my-4 py-4">
            <h3 className="text-lg font-semibold">Core Expertise</h3>
           
         
      <div className={MarginForProfileCoreExpertise}>
        { <RoundedBox data = {CoreExpertise} cross={false}/>}
      </div>
          </div>
        </div>
            <div className=''></div>
        </div>
      </div>
    </div>
  );
}

export default BankerProfileView;
