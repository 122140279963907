import React, { useEffect, useState } from 'react'
import Navbar from '../../../common/Navbar'
import SideNavbar from '../../../common/SideNavbar'
import { NotificationMessageIcon, TimeIcon1 } from '../../../common/Icons';
import useGetData from '../../../Hook/useGetData';
import { getTimeDifference } from '../../../Hook/validation';
import BottomPagination from '../../../common/BottomPagination';
import DataNotFound from '../../../common/DataNotFound';
import { useNavigate } from 'react-router-dom';

const CompanyNotification = () => {
    const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
    const navigate=useNavigate()
    let itemsPerPage=5;
    const { data: getNotificationData} = useGetData('notifications/all', token);
    const [data,setData]=useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const imageData = "https://img.freepik.com/free-photo/portrait-young-man-talking-phone-walking-street_231208-2764.jpg";
    const totalPages = Math.ceil(data?.length / itemsPerPage);
    const displayData = data?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
      const handlePageChange = (page) => {
        setCurrentPage(page);
      };
useEffect(()=>{
if(getNotificationData?.data?.length>0){
    setData(getNotificationData?.data?.reverse())
}

},[getNotificationData?.data])
  return (
    <div>

        <Navbar hemBurgerIcon={true} BackBtn={true} ProfileAndNotification={true} CompanyNavbar={true}/>
        
        <div className='flex'>
            <div className='w-0 md:w-[9%]'>
                <SideNavbar/>
            </div>
            <div className='px-3 md:px-10 w-full md:w-[91%] py-10'>
                <div className='text-start my-4'>
                    <h4 className='h4-text text-[#2F3340]'>
                    Notifications
                    </h4>
                </div>
                {displayData?.length >0 ? displayData?.map((item, index) =>(
 <div className='flex border bg-[#F4FBF6] w-full py-5'>
 <div className={`w-[54px] mx-5 h-[54px] rounded-full overflow-hidden ${!imageData ? "bg-[#1EB469]" : ""}`} onClick={() => navigate(`/${item?.event_url}`)}>
  <NotificationMessageIcon />
 </div>
 <div className='text-start'>
     <p className='notification'> {item?.message}</p>
     <p className='notification-ptag my-2 flex items-center'>{getTimeDifference(item?.updated_at)}<div className='h-1 mx-2 w-1 bg-[#8B8D98]  rounded-full'></div> </p>
     {/* <Button className="redfolicbg h-[38px] rounded-lg text-start px-4">
         <p className='remove-text'>Schedule Meeting</p>
     </Button> */}
 </div>
</div>
                )) :
                <DataNotFound message="No Notification Found" />
                }
               
                           
            </div>
        </div>
        <BottomPagination 
           currentPage={currentPage}
           totalPages={totalPages}
           onPageChange={handlePageChange}
          />
    </div>
  )
}

export default CompanyNotification
