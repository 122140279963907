import React, { useState } from 'react';
import { FilterIcon } from './Icons';
import {
  DashboardCheckboxTitle,
  DashboardCheckboxTitleValue,
  DashboardClearAllDiv,
  DashboardClearAllpTag,
  DashboardNextFilterSec,
  DashboardNextFilterSecDiv,
  DashboardNextFilterTag
} from '../modules/dashboard/company/dashboard/DashboardStyle';
import { CommenHRLine, FilterCardStyleMaindiv } from './StyleCommon';
import Checkbox from './Checkbox';

const FilterCard = ({ checkedItems, setCheckedItems, fetch, setFilterState }) => {
  const handleChange = (event) => {
    const { value, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  const handleClearAll = () => {
    setCheckedItems({
      shortTerm: false,
      mediumTerm: false,
      longTerm: false,
      virtual: false,
      'In-person': false,
      both: false,
    });

    if(setFilterState) {
      setFilterState(false);
    }
    
    if(fetch){
      fetch()
    }
  };

  const checkboxTitle = [
    {
      id: 1,
      title: 'Availability for hire',
      checkbox: [
        {
          id: 1,
          label: 'Short Term',
          description: '(Up to 3 months)',
          value: 'shortTerm',
        },
        {
          id: 2,
          label: 'Medium Term',
          description: '(Up to 6 months)',
          value: 'mediumTerm',
        },
        {
          id: 3,
          label: 'Long Term',
          description: '(6 months and above)',
          value: 'longTerm',
        },
      ],
    },
    {
      id: 2,
      title: 'Mode of Consultancy',
      checkbox: [
        {
          id: 4,
          label: 'Virtual',
          description: '',
          value: 'virtual',
        },
        {
          id: 5,
          label: 'In-person',
          description: '',
          value: 'In-person',
        },
        {
          id: 6,
          label: 'Both',
          description: '',
          value: 'both',
        },
      ],
    },
  ];

  return (
    <div className={FilterCardStyleMaindiv}>
      <div className={DashboardNextFilterSec}>
        <div className={DashboardNextFilterSecDiv}>
          <div className="flex">
            <div>
              <FilterIcon />
            </div>
            <div>
              <h5 className={DashboardNextFilterTag}>Filters</h5>
            </div>
          </div>
          <div className={DashboardClearAllDiv} role='button'>
            <p className={DashboardClearAllpTag} onClick={handleClearAll}>Clear all</p>
          </div>
        </div>

        {checkboxTitle?.map((item) => (
          <div key={item.id} className='text-start'>
            <div className={CommenHRLine}></div>

            <div className={DashboardCheckboxTitle}>
              <h6 className="h6-text">{item.title}</h6>
            </div>

            <div className={DashboardCheckboxTitleValue}>
              {item?.checkbox?.map((checkboxItem) => (
                <div className="my-3" key={checkboxItem.id}>
                  <Checkbox
                    type="checkbox"
                    id={checkboxItem.id}
                    value={checkboxItem?.value}
                    label={`${checkboxItem?.label} ${checkboxItem?.description}`}
                    checked={checkedItems[checkboxItem?.value]}
                    onChange={handleChange}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterCard;
