import React from "react";
import { CrossIcon } from "./Icons";
import { RoundedBoxItemStyle1, RoundedBoxMainDiv } from "./StyleCommon";

const RoundedBox = ({ data, className, cross , mainClassName,onRemove }) => {
  
  return (
    <div className={mainClassName ? mainClassName :RoundedBoxMainDiv}>
      {data?.map((item, index) => {
        return (
          <div
            className={`${className? className : RoundedBoxItemStyle1}`}
            key={index}
          >
            <p className={`${className ? className : ""}  `}>{item}</p>
            {cross &&
            <span  role="button" className="cursor-pointer" data={index} onClick={(data)=>onRemove(item)}> <CrossIcon/>
            </span>
            }

          </div>
        );
      })}
    </div>
  );
};

export default RoundedBox;
