import React, { useEffect, useState } from "react";
import Navbar from "../../../common/Navbar";
import Timeline from "./Timeline";
import ExpertiseModal from "../../../common/ExpertiseModal";
import InputField from "../../../common/InputField";
import SelectTagComponent from "../../../common/SelectTagComponent";
import BottomBar from "../../../common/BottomBar";
import { useNavigate } from "react-router-dom";
import { validateStepTwoBanker } from "../../../common/Validations";
import { BankerStep2MainDiHRLine, BankerStep2MainDiHRLine2, BankerStep2MainDiv, BankerStep2MainDivMode, BankerStep2div, BankerStep2divCoreError, BankerStep2divKeyServ, BankerStep2divSaveIem, BankerStepTwoCustomBox, BankerTagStyle, FlexJustEnd, RelMT6mAuto } from "./BankerStyle";
import { ToastMessage, apiHost, getAvailabilityModes, getConsultancyModes, getCoreExpertise, getKeyAreaServices, handleNonNumericInput } from "../../../common/Config";
import useUploadProfilePicture from "../../../Hook/useUploadProfilePicture";
import useGetData from "../../../Hook/useGetData";
import { Loader } from "../../../common/Loader";
import { InputFieldClass, InputFieldClass1, LabelFieldClass, LabelFieldClass1 } from "../../../common/StyleCommon";
import { commenInputDivSpace } from "../../register/RegisterStyle";
import NudgeNotification from "../../../common/NudgeNotification";
import { extractFileName } from "../../../Hook/validation";

const StepTwobanker = () => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { uploading, error, success, uploadProfilePicture } = useUploadProfilePicture('onboarding/banker/salary-slip');
  const { data: getBankerData, loading: loadingServices, error: errorServices } = useGetData('onboarding/banker/get-bankers',token);
  const { data: getStateData, loading: loadingState, error: errorState } = useGetData('master-data/state/get',"token");
  const { data: getPaymentData,loading: loadingPayment, error: errorPayment } = useGetData('onboarding/get-payment-status', token);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const handleSave = (selectedItems) => {
    // ("Selected items:", selectedItems);
  };

  const [keyAreaServicesData, setKeyAreaServicesData] = useState([]);
  const [coreExpertiseData, setCoreExpertiseData] = useState([]);
  const [avalForHire, setAvalForHire] = useState([]);
  const [avalForConsultancy, setAvalForConsultancy] = useState([]);

  const [savedItems, setSavedItems] = useState([]);
  const [savedItemsCore, setSavedItemsCore] = useState([]);
  const [salarySlip, setSalarySlip] = useState(false);
  const [show, setShow] = useState(true);
  const [showCore, setShowCore] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Select");
  const [customInputVisible, setCustomInputVisible] = useState(!false);
  const [formData, setFormData] = useState({
    form_step:2,
    keyAreaServices: savedItems,
    coreExpertise: savedItemsCore,
    lastDrawnGrossConsolidatedSalary: "",
    preferredLocation: "",
    hireAvailability: "Select",
    state:0,
    city:0,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [file,setFile]=useState('ffffffffffff')
  const { data: getCityData, loading: loadingCity, error: errorCity } = useGetData(`master-data/city/${formData?.state}`,"token");
  const { data: getCityData1 } = useGetData(`master-data/city/get`,"token");
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'salarySlip') {
      const file = e.target.files[0];
      if (file) {
        setFile(file.name);
        uploadProfilePicture("salary_slip", file)
          .then(() => {
            setSalarySlip(true);
            e.target.value = ''; // Clear the file input value after upload
          })
          .catch(error => {
            console.error("File upload failed", error);
          });
      }
    } else if (name === "lastDrawnGrossConsolidatedSalary") {
      sessionStorage.setItem("bankerLastDrawnSalary", JSON.stringify(value));
      let newErrors = { ...errors };
      if (name in newErrors) delete newErrors[name];
      setErrors(newErrors);
    } else if (name !== 'salarySlip') {
      sessionStorage.setItem("bankerAdditionalInfo", JSON.stringify(value));
    } else if (name === "preferredLocation") {
      sessionStorage.setItem("bankerPreferredLocation", JSON.stringify(value));
    }
    setFormData({ ...formData, [name]: value });
  };
  
  const handleSelectChange = (name, value) => {
    if (value && typeof value === 'object') {
      setFormData({ ...formData, [name]: value.id }); // Update only with value name
    } else {
      setFormData({ ...formData, [name]: value.id });
    }
    setErrors({ ...errors, [name]: '' }); // Clear error on change
  };
  const handleNavigation = () => {
    const storedItems = JSON.parse(sessionStorage.getItem("selectedItems"))
    const storedItemsCore = JSON.parse(sessionStorage.getItem("CoreExpertise"));

    var hireMode = {};
    var consultMode = {};
    var stateCity = {};
    
    try {
      hireMode = JSON.parse(sessionStorage.getItem("bankerhireMode"));
    }
    catch {
      hireMode = {};
    }

    try {
      consultMode = JSON.parse(sessionStorage.getItem("consultancyMode"));
    }
    catch {
      consultMode = {};
    }

    try {
      stateCity = JSON.parse(sessionStorage.getItem("stateCity"));
    }
    catch {
      stateCity = {}
    }
    if (validateStepTwoBanker(formData, setErrors, customInputVisible, storedItems, storedItemsCore, consultMode, hireMode, stateCity) && consultMode && hireMode) {
      sessionStorage.setItem("stateCity", JSON.stringify({city:formData?.city,state:formData?.state}));
      sessionStorage.setItem("bankerConsultancyMode", JSON.stringify(selectedOption));

   
      if(("hireAvailability" in formData) && formData?.hireAvailability !== "Select" && formData?.hireAvailability !== undefined) {
        sessionStorage.setItem("bankerhireMode", JSON.stringify(avalForHire?.filter((item) => item?.id === formData?.hireAvailability)[0]))
      }
   
        navigate("/profile/banker/add-profile-picture");
    }
  };
  const handleNavigationSave = () => {
    const storedItems = JSON.parse(sessionStorage.getItem("selectedItems"))
    const storedItemsCore = JSON.parse(sessionStorage.getItem("CoreExpertise"));

    var hireMode = {};
    var consultMode = {};
    
    try {
      hireMode = JSON.parse(sessionStorage.getItem("bankerhireMode"));
    }
    catch {
      hireMode = {};
    }

    try {
      consultMode = JSON.parse(sessionStorage.getItem("consultancyMode"));
    }
    catch {
      consultMode = {};
    }

    if (validateStepTwoBanker(formData, setErrors, customInputVisible, storedItems, storedItemsCore, consultMode, hireMode, stateCity)) {
      sessionStorage.setItem("stateCity", JSON.stringify({city:formData?.city,state:formData?.state}));
      sessionStorage.setItem("bankerConsultancyMode", JSON.stringify(selectedOption));

      if(getBankerData?.data?.profile?.master_tenure && getBankerData?.data?.profile?.master_tenure !== null && getBankerData?.data?.profile?.master_tenure !== undefined) {
        sessionStorage.setItem("bankerhireMode", JSON.stringify(avalForHire?.filter(
          (item)=>item?.id===getBankerData?.data?.profile.master_tenure)[0]))
      }
      else if(("hireAvailability" in formData) && formData?.hireAvailability !== "Select" && formData?.hireAvailability !== undefined) {
        sessionStorage.setItem("bankerhireMode", JSON.stringify(avalForHire?.filter((item) => item?.id === formData?.hireAvailability)[0]))
      }
    }
  };
  const handleOptionChange = (option) => {
    sessionStorage.setItem("consultancyMode", JSON.stringify(option));
    setSelectedOption(option);
    if (option?.name === "In-person" || option?.name === "in-person" || option?.name === "Both" || option?.name=='Virtual') {
      setCustomInputVisible(true);
    } else {
      setCustomInputVisible(false);
      try {
        let preferredLocation = JSON.parse(sessionStorage.getItem("bankerPreferredLocation"));
        if (preferredLocation?.length > 0) {
          sessionStorage.removeItem("bankerPreferredLocation")
        }
      }
      catch {
        console.error("Banker preferred location does not exist in session Storage");
      }
    }
    let name = "modeOfConsultancy";
    let newErrors = {...errors};
    if (name in newErrors) delete newErrors[name];
    setErrors(newErrors);
  };
  const handleOptionHireChange = (option) => {
    let newData = {...formData};
    newData.hireAvailability = option?.id;
    setFormData(newData);
    if(option !== undefined) {
      sessionStorage.setItem("bankerhireMode", JSON.stringify(option));
      let newErrors = {...errors};
      let name = "availabilityForHire";
      if (name in newErrors) delete newErrors[name];
      setErrors(newErrors);
    }
    // setSelectedOption(option);
  };
  // Function to delete a tag
  const deleteTag = (index) => {

    const updatedItems = [...savedItems];
    updatedItems.splice(index, 1);
    setSavedItems(updatedItems);
    sessionStorage.setItem("selectedItems", JSON.stringify(updatedItems));
  };

  const deleteTagCore = (index) => {
    const updatedItemsCore = [...savedItemsCore];
    updatedItemsCore.splice(index, 1);
    setSavedItemsCore(updatedItemsCore);
    sessionStorage.setItem("CoreExpertise", JSON.stringify(updatedItemsCore));
  };

  // Generate tags
  const tags = savedItems?.map((item, index) => (
    <div
      key={index}
      className={BankerTagStyle}
    >
      {keyAreaServicesData?.filter((items)=>items?.id===item)[0]?.name || keyAreaServicesData?.filter((items)=>items?.id===item?.id)[0]?.name}{" "}
      {!show && (
        <svg
          onClick={() => deleteTag(index)}
          className="mt-1 ml-2"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.30439 3.80439C2.89854 4.21025 2.89854 4.86826 3.30439 5.27412L7.03027 8.99998L3.30439 12.7259C2.89854 13.1317 2.89854 13.7898 3.30439 14.1956C3.71024 14.6015 4.36827 14.6015 4.77412 14.1956L8.49998 10.4697L12.2259 14.1956C12.6317 14.6015 13.2898 14.6015 13.6956 14.1956C14.1015 13.7898 14.1015 13.1317 13.6956 12.7259L9.96969 8.99998L13.6956 5.27413C14.1015 4.86828 14.1015 4.21026 13.6956 3.80441C13.2897 3.39855 12.6317 3.39855 12.2259 3.80441L8.49998 7.53026L4.77412 3.80439C4.36827 3.39854 3.71024 3.39854 3.30439 3.80439Z"
            fill="#2C2C2C"
          />
        </svg>
      )}
    </div>
  ));

  const tagsCore = savedItemsCore?.map((item, index) => (
    <div
      key={index}
      className={BankerTagStyle}
    >
     
      {coreExpertiseData?.filter((items)=>items?.id===item)[0]?.name || coreExpertiseData?.filter((items)=>items?.id===item?.id)[0]?.name}{" "}
      {!showCore && (
        <svg
          onClick={() => deleteTagCore(index)}
          className="mt-1 ml-2"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.30439 3.80439C2.89854 4.21025 2.89854 4.86826 3.30439 5.27412L7.03027 8.99998L3.30439 12.7259C2.89854 13.1317 2.89854 13.7898 3.30439 14.1956C3.71024 14.6015 4.36827 14.6015 4.77412 14.1956L8.49998 10.4697L12.2259 14.1956C12.6317 14.6015 13.2898 14.6015 13.6956 14.1956C14.1015 13.7898 14.1015 13.1317 13.6956 12.7259L9.96969 8.99998L13.6956 5.27413C14.1015 4.86828 14.1015 4.21026 13.6956 3.80441C13.2897 3.39855 12.6317 3.39855 12.2259 3.80441L8.49998 7.53026L4.77412 3.80439C4.36827 3.39854 3.71024 3.39854 3.30439 3.80439Z"
            fill="#2C2C2C"
          />
        </svg>
      )}
    </div>
  ));

  // Load saved items from session storage when value of refresh changes
  useEffect(() => {
      const storedItems = JSON.parse(sessionStorage.getItem("selectedItems"))
     const storedItemsCore = JSON.parse(sessionStorage.getItem("CoreExpertise"));
    setSavedItems(storedItems);
    setSavedItemsCore(storedItemsCore);
    setFormData({
      ...formData,
      keyAreaServices: storedItems,
      coreExpertise: storedItemsCore,
    });
  }, [refresh]);

  useEffect(() => {
    setFormData({ ...formData, coreExpertise: savedItemsCore,keyAreaServices: savedItems });
  }, [savedItemsCore,savedItems]);



  // Load saved items from session storage on component mount
  useEffect(() => {
    // setLoading(true);
 
    
    const storedItems = JSON.parse(sessionStorage.getItem("selectedItems")) || [];
    const storedItemsCore =
      JSON.parse(sessionStorage.getItem("CoreExpertise")) || [];
    const bankerSalary = sessionStorage.getItem("bankerLastDrawnSalary") || "";
    const consultancyMode = JSON.parse(sessionStorage.getItem("bankerConsultancyMode")) || "Select";
    const preferredLocation = JSON.parse(sessionStorage.getItem("bankerPreferredLocation")) || "";
    var hireMode = "Select";

    try {
      hireMode = JSON.parse(sessionStorage.getItem("bankerHireMode"));
    }
    catch {
      hireMode = "Select"
    }

    if (preferredLocation !== "") setCustomInputVisible(true);
    if ( consultancyMode === null || consultancyMode === "Select") {
      setCustomInputVisible(false);
      sessionStorage.removeItem("bankerPreferredLocation");
      // if (preferredLocation === "") sessionStorage.removeItem("bankerPreferredLocation");
    } else {
      setCustomInputVisible(true);
    }

    // setSelectedOption(consultancyMode);
    setSavedItems(storedItems);
    setSavedItemsCore(storedItemsCore);
    getKeyAreaServices(setKeyAreaServicesData);
    getCoreExpertise(setCoreExpertiseData);
    getAvailabilityModes(setAvalForHire);
    getConsultancyModes(setAvalForConsultancy);
    setFormData({ lastDrawnGrossConsolidatedSalary: bankerSalary,
      keyAreaServices: storedItems, coreExpertise: storedItemsCore, hireAvailability: hireMode?.id ?? "Select",
      preferredLocation: preferredLocation });
    setLoading(false);
  }, []);

 
  useEffect(()=>{
  if(success){
    ToastMessage('success',"Salary slip uploaded successfully");
    // setProfilePhoto(true);
  }
  if(error){
    ToastMessage('error',"Salary slip upload failed");
  }
  
   },[success,error])
   useEffect(()=>{

    const storedItems = JSON.parse(sessionStorage.getItem("selectedItems")) || [];
    const storedItemsCore =
      JSON.parse(sessionStorage.getItem("CoreExpertise")) || [];
    const bankerSalary = JSON.parse(sessionStorage.getItem("bankerLastDrawnSalary")) || "";
    if(getBankerData){
      sessionStorage.setItem('selectedItems',JSON.stringify(getBankerData?.data?.profile.key_service?.length > 0 ? getBankerData?.data?.profile.key_service?.map((item)=>item?.id) : storedItems));
      sessionStorage.setItem('CoreExpertise',JSON.stringify(getBankerData?.data?.profile.core_expertise?.length > 0 ? getBankerData?.data?.profile.core_expertise?.map((item)=>item?.id) : storedItemsCore));
      sessionStorage.setItem("bankerConsultancyMode", JSON.stringify(getBankerData?.data?.profile?.master_consultancy_mode));
     
      setFormData({
        ...formData,
        state:getBankerData?.data?.profile?.state || "",
        city:getBankerData?.data?.profile?.city || "",
        keyAreaServices: getBankerData?.data?.profile?.key_service || storedItems,
        coreExpertise: getBankerData?.data?.profile?.core_expertise || storedItemsCore,
        lastDrawnGrossConsolidatedSalary:parseInt(getBankerData?.data?.profile.last_salary) || bankerSalary,
        salary_slip:getBankerData?.data?.profile.salary_slip || "",
      })
      
      // setSavedItemsCore/(getBankerData.core_expertise)
    }
    if(getBankerData?.data?.profile?.salary_slip){
      setSalarySlip(true)
      setFile(extractFileName(getBankerData?.data?.profile?.salary_slip))
    }
    // setRefresh((prevValue) => prevValue + 1);
   },[getBankerData,errorServices])
  //  const hireMode=JSON.parse(sessionStorage.getItem('bankerhireMode')) || {};
   const consultancyMode=JSON.parse(sessionStorage.getItem('consultancyMode')) || {}
   
   let hireMode = {};
   try {
    hireMode = JSON.parse(sessionStorage.getItem("bankerhireMode"))
   }
   catch {
    hireMode = {}
   }

  useEffect(()=>{
    if((consultancyMode?.id !==1 || getBankerData?.data?.profile.master_consultancy_mode !==1) && consultancyMode?.id !== undefined){
      setCustomInputVisible(true)
    }

  },[getBankerData?.data?.profile.master_consultancy_mode])
  const stateCity=JSON.parse(sessionStorage.getItem('stateCity')) || {};
  const stateId=stateCity?.state || getBankerData?.data?.profile?.state;
  const cityId=stateCity?.city || getBankerData?.data?.profile?.city;
  const StateName=getStateData?.data?.filter((item)=>item?.id === stateId)?.[0]?.name || ""
  const CityName=getCityData1?.data?.filter((item)=>item?.id === cityId)?.[0]?.city  || ""
  return (
    <div>
      <Navbar BackBtn={true} hemBurgerIcon={false} buybtn={getPaymentData?.payment_status ? null : "Buy Package"}  />
      <NudgeNotification className=" -top-10 left-0 right-0" />

      <Timeline
        stepOneStatus={true}
        stepTwoStatus={false}
        stepThreeStatus={false}
        stepFourStatus={false}
        heading="Additional hiring details"
      />
      {loading ? <Loader/> : (<div className={BankerStep2MainDiv}>
        <div className={BankerStep2MainDiHRLine}></div>
    
        <div className={BankerStep2div}>
          <div className="s1-text">Key Area of services*</div>
          {show || savedItems?.length === 0 ? (
            <div className={BankerStep2divSaveIem}>
              <div
                onClick={() => setShow(false)}
                className={` ${
                  savedItems?.length === 0 ? "invisible" : "visible"
                } `}
              >
                <svg
                  className="-mt-1 mr-2"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.151 25L25 12.151L19.849 7L7 19.8491V25H12.151ZM19.849 9.2714L22.7286 12.151L20.652 14.2276L17.7725 11.348L19.849 9.2714ZM16.6367 12.4837L19.5164 15.3633L11.4857 23.3939H8.60612V20.5143L16.6367 12.4837Z"
                    fill="#2C2C2C"
                  />
                </svg>
              </div>

              <ExpertiseModal
                data={keyAreaServicesData}
                storageKey="selectedItems"
                modalTitle="Key Area of services"
                btnHandle={handleSave}
                setRefresh={setRefresh}
              />
            </div>
          ) : (
            <div onClick={() => setShow(true)} className={FlexJustEnd}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33325 15.9994L11.9329 22.599L26.0762 8.45703"
                  stroke="#1EB469"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
        <div className={BankerStep2divKeyServ}>
          {errors?.keyAreaServices && savedItems?.length === 0
            ? <p className="primary-text-red text-sm ml-auto">{errors.keyAreaServices}</p> 
            : tags}
        </div>
        <div className="hr-bgColor m-auto"></div>
        <div className={BankerStep2div}>
          <div className="s1-text">Core Expertise*</div>
          {showCore || savedItemsCore?.length === 0 ? (
            <div className={BankerStep2divSaveIem}>
              <div
                onClick={() => setShowCore(false)}
                className={` ${
                  savedItemsCore?.length === 0 ? "invisible" : "visible"
                } `}
              >
                <svg
                  className="-mt-1 mr-2"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.151 25L25 12.151L19.849 7L7 19.8491V25H12.151ZM19.849 9.2714L22.7286 12.151L20.652 14.2276L17.7725 11.348L19.849 9.2714ZM16.6367 12.4837L19.5164 15.3633L11.4857 23.3939H8.60612V20.5143L16.6367 12.4837Z"
                    fill="#2C2C2C"
                  />
                </svg>
              </div>

              <ExpertiseModal
              ShowAllAbove={true}
                data={coreExpertiseData}
                storageKey="CoreExpertise"
                modalTitle="Core Expertise*"
                btnHandle={handleSave}
                setRefresh={setRefresh}
              />
            </div>
          ) : (
            <div onClick={() => setShowCore(true)} className="flex justify-end">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33325 15.9994L11.9329 22.599L26.0762 8.45703"
                  stroke="#1EB469"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
        </div>

        <div className={`${BankerStep2divCoreError} text-red`}>
          {errors?.coreExpertise && savedItemsCore.length === 0 ? <p className="primary-text-red text-sm ml-auto">{errors.coreExpertise}</p> : tagsCore}
        </div>
        <div className={BankerStep2MainDiHRLine2}></div>
        <div>
          <div class={RelMT6mAuto}>
            <InputField
              span="INR"
              disabled={false}
              label="Last drawn gross consolidated salary"
              type="text"
              onkeyDown={handleNonNumericInput}
              id="salary"
              name="lastDrawnGrossConsolidatedSalary"
              value={formData?.lastDrawnGrossConsolidatedSalary}
              onChange={(e) => handleChange(e)}
              error={errors.lastDrawnGrossConsolidatedSalary}
              astrix={true}
              required={true}
            
            />
          </div>
          <div class={RelMT6mAuto}>
            
            <InputField
              remove={salarySlip}
              disabled={false}
              label="Upload salary slip (Optional)"
              type="file"
              accept=".pdf, .doc, .docx"
              id="salarySlip"
              name="salarySlip"
              astrix={false}
              required={false}
              UplaodIcon={true}
              setSalarySlip={setSalarySlip}
              dataSetFile={file}
              setDataSet={setFile}
              onChange={(e) => handleChange(e)}
         
            />
           {formData?.salary_slip && <a href={`${apiHost}/${formData?.salary_slip}`} target="_blank" rel="noreferrer" className="text-[red] flex justify-start">view</a>}
          </div>
        </div>
        <div>
          <div className="hr-bgColor m-auto mt-6"></div>
          <div className="mb-[10rem]">
            <div className={BankerStep2MainDivMode}>
              <div className="">
                <p className="s1-text"> Availability for Hire*</p>
              </div>
                <div class="w-full">
                  <SelectTagComponent
                    onChange={handleOptionHireChange}
                    defaultVal={hireMode?.name ? hireMode?.name : avalForHire?.filter((item)=>item?.id===getBankerData?.data?.profile.master_tenure)[0]?.name || "Select"}
                    options={avalForHire}
                    name="hireAvailability"
                    error={errors.availabilityForHire}
                  />
                </div>
             
            </div>

            <div className="hr-bgColor m-auto"></div>

            <div className={BankerStep2MainDivMode}>
              <div className="">
                <p className="s1-text">Mode of Consultancy*</p>
              </div>
                <div class=" w-full">
           
                  <SelectTagComponent
                    onChange={handleOptionChange}
                    defaultVal={consultancyMode?.name ? consultancyMode?.name : avalForConsultancy?.filter((item)=>item.id===getBankerData?.data?.profile.master_consultancy_mode)[0]?.name || selectedOption}
                    options={avalForConsultancy || []}
                    name="consultMode"
                    error={errors.modeOfConsultancy}
                  />
                </div>
             
            </div>

            {customInputVisible && ( 
          
              <div className="md:flex -mt-6 md:mt-0 gap-4">
              <div className={commenInputDivSpace}>
              <SelectTagComponent
              defaultVal={StateName ? StateName:"State"}
              options={getStateData?.data || []}
              error={errors?.state}
              onChange={(value) => handleSelectChange("state", value)}
            />
              </div>
              <div className={commenInputDivSpace}>
              <SelectTagComponent
              defaultVal={CityName ? CityName:"Current City"}
              options={getCityData?.data?.map((item)=>{
                return {
                  ...item,
                  name: item.city,
                }
              }) || []}
              error={errors.city}
              onChange={(value) => handleSelectChange("city", value)}

            />
              </div>
            </div>
            )}
          </div>
        </div>
      </div>)}
      <div className="w-full">
      <BottomBar
        OnCLickBackbtn={() => navigate("/profile/banker/complete-your-profile")}
        OnCLickNextBtn={() => handleNavigation()}
        btnName="Next"
        BackBtn="Back"
        SaveBtn="Save"
        handleSubmit={handleNavigationSave}
        step="2"
      />
      </div>
    </div>
  );
};

export default StepTwobanker;