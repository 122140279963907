export const BankerAddEventStyles1 = "fixed top-0 right-0 left-0 z-[51] flex justify-center items-center w-full h-full bg-[#1E1F24] bg-opacity-50 backdrop-blur-sm";
export const BankerAddEventStyles1m ="relative p-4 w-[90%] lg:w-[43.5rem] max-h-[100%] bg-white rounded-lg shadow"
export const BankerAddEventStyles2 ="sticky top-0 right-0 left-0 flex items-center  justify-between  border-b rounded-t"
export const BankerAddEventStyles3 ="h5-text mb-3"
export const BankerAddEventStyles4 ="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
export const BankerAddEventStyles5 ="overflow-auto h-[26rem] md:p-4 space-y-5 custom-scrollbar scrollbar-hide"
export const BankerAddEventStyles6 ="flex ml-1 items-center gap-6"
export const BankerAddEventStyles7 ="flex items-center gap-6"
export const BankerAddEventStyles8 ="ml-12 md:flex md:gap-[10rem]"
export const BankerAddEventStyles9 ='mt-4 md:mt-0'
export const BankerAddEventStyles10 ="flex items-center gap-6"
export const BankerAddEventStyles11 ="grid grid-cols-1 md:grid-cols-2 gap-4"
export const BankerAddEventStyles12 ="flex items-center justify-start gap-6"
export const BankerAddEventStyles13 ="flex items-center gap-2 mt-2 justify-between"
export const BankerAddEventStyles14 ="w-full pl-12 md:pl-0"
export const BankerAddEventStyles15 ='my-3 md:my-0'
export const BankerAddEventStyles16 ="w-[85%] md:pl-0"
export const BankerAddEventStyles17 ='flex relative items-center gap-8 w-full'
export const BankerAddEventStyles18 ='flex  w-[30%]  items-cente'
export const BankerAddEventStyles19 ='w-[100%]  flex items-center'
export const BankerAddEventStyles20 ='flex relative items-center my-6 gap-8 w-full'
export const BankerAddEventStyles21 ='flex  w-[30%]  items-cente'
export const BankerAddEventStyles22 ='w-[100%] md:flex gap-4 items-center '
export const BankerAddEventStyles23 ='w-full pt-2'
export const BankerAddEventStyles24 ='flex items-center md:ml-40 my-8 gap-2'
export const BankerAddEventStyles25 ='w-[30px] h-[30px]  md:w-[40px] md:h-[40px] flex items-center justify-center  rounded-full cursor-pointer'
export const BankerAddEventStyles26 ='flex relative items-center gap-8 w-full'
export const BankerAddEventStyles27 ='flex  w-[30%]  items-cente'
export const BankerAddEventStyles28 ='w-[100%]  flex items-center'
export const BankerAddEventStyles29 ='s3-text my-5 text-[#2C2C2C]'
export const BankerAddEventStyles30 ="flex my-4 gap-6"
export const BankerAddEventStyles31 ="mt-1 resize-none block w-full rounded-md border-gray-300 border p-4"
export const BankerAddEventStyles32 ="flex  items-start md:items-center gap-6"
export const BankerAddEventStyles33 ="grid grid-cols-1 md:grid-cols-4 gap-4"
export const BankerAddEventStyles34 ='pl-3 pr-5 py-1 rounded-full bg-[#B6E4CB] flex items-center gap-3'
export const BankerAddEventStyles35 ='w-[20px] h-[20px] rounded-full bg-[#0AA350]'
export const BankerAddEventStyles36 ='pl-3 pr-5 py-1 rounded-full bg-[#FFF6BE] flex items-center gap-3'
export const BankerAddEventStyles37 ='w-[20px] h-[20px] rounded-full bg-[#FDD33E]'
export const BankerAddEventStyles38 ='pl-3 pr-5 py-1 rounded-full bg-[#FFD3B3] flex items-center gap-3'
export const BankerAddEventStyles39 ='w-[20px] h-[20px] rounded-full bg-[#FF6B00]';
export const BankerAddEventStyles40 ='pl-3 pr-5 py-1 rounded-full bg-[#FFC6C6] flex items-center gap-3'
export const BankerAddEventStyles41 ='w-[20px] h-[20px] rounded-full bg-[#FD3E3E]'
export const BankerAddEventStyles42 ="absolute bottom-0 left-0 right-0 bg-white flex justify-between items-center py-2 px-6 border-t border-gray-200 rounded-b"
export const BankerAddEventStyles43 ="  redfolicbg  flex items-center gap-1 rounded-lg px-6 py-2 text-white"
export const BankerAddEventStyles44 ="greenbg rounded-lg px-10 py-2 text-white"
// export const BankerAddEventStyles45 =


export const BankerCalendarStyle1 = 'flex'
export const BankerCalendarStyle2 = 'w-0 lg:w-[7%]'
export const BankerCalendarStyle3 = 'flex w-full lg:w-[93%]'
export const BankerCalendarStyle4 = 'hidden w-0 lg:block lg:w-[25%] p-4 bg-white '
export const BankerCalendarStyle5 = 'text-start mt-4'
export const BankerCalendarStyle6 = 'h6-text font-semibold text-lg'
export const BankerCalendarStyle7 = 's3-text text-[#2C2C2C] my-2'
export const BankerCalendarStyle8 = 'mt-4'
export const BankerCalendarStyle9 = 's3-text text-[#2C2C2C] my-2'
export const BankerCalendarStyle10 = 'w-full px-4 flex items-center justify-between bg-white shadow-sm'
export const BankerCalendarStyle11 = 'block w-full m-auto lg:hidden p-4 bg-white '
export const BankerCalendarStyle12 = 'text-start mx-4 mt-4'
export const BankerCalendarStyle13 = 'h6-text font-semibold text-lg'
export const BankerCalendarStyle14= 's3-text text-[#2C2C2C] my-2'
export const BankerCalendarStyle15 = 'flex justify-between items-center my-2'
export const BankerCalendarStyle16 = 'flex items-center'
export const BankerCalendarStyle17 = 'inline-block w-2.5 h-2.5 rounded-full'
export const BankerCalendarStyle18 = 'body-text text-[#80828D]'




export const BankerCalendarViewStyle1 = "fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-[#1E1F24] bg-opacity-50 backdrop-blur-sm"
export const BankerCalendarViewStyle2 = "relative pl-2 h-[20rem] w-[457px] bg-white rounded-lg shadow"
export const BankerCalendarViewStyle3 = "flex items-center justify-between p-4 md:p-3"
export const BankerCalendarViewStyle4 = 'body-text text-start my-2 text-[#2C2C2C]'
export const BankerCalendarViewStyle5 = "text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex items-center justify-center mb-10 ml-10"
export const BankerCalendarViewStyle6 = "px-4"
export const BankerCalendarViewStyle7 = "flex text-start gap-4"
export const BankerCalendarViewStyle8 = "px-10 rounded-lg py-2 greenbg text-white"
export const BankerCalendarViewStyle9 = "px-10 rounded-lg py-2 bg-gray-300 text-white"
export const BankerCalendarViewStyle10 = "px-10 rounded-lg py-2 redfolicbg text-white"
export const BankerCalendarViewStyle11 = "px-8 py-2 rounded-lg redfolicbg text-white flex items-center justify-between"
export const BankerCalendarViewStyle12 = "absolute top-full left-0 mt-1 w-60 bg-white border border-gray-300 rounded shadow-lg z-10"
export const BankerCalendarViewStyle13 = "block w-full text-left px-4 py-2 hover:bg-gray-100"
export const BankerCalendarViewStyle14 = "block w-full text-left px-4 py-2 hover:bg-gray-100"
export const BankerCalendarViewStyle15 = "relative mt-2"
export const BankerCalendarViewStyle16 = "absolute top-full right-[-100px]  mt-1 w-48 bg-white border border-gray-300 rounded shadow-lg z-10"
export const BankerCalendarViewStyle17 = "block w-full text-left px-4 py-2 "
export const BankerCalendarViewStyle18 = 'flex items-center gap-2 my-4'
export const BankerCalendarViewStyle19 = 'body-text text-[#2C2C2C]'
export const BankerCalendarViewStyle20 = 'body-text text-[#2C2C2C] text-start w-[80%]'



export const BankerCalendarTestStyle1 = "flex justify-between items-center py-2 px-2"
export const BankerCalendarTestStyle2 = 'flex flex-wrap gap-4'
export const BankerCalendarTestStyle3 = 'hidden w-0 lg:w-[2em] lg:block'
export const BankerCalendarTestStyle4 = "w-24 text-[#1EB469] border-[#1EB469] border py-1.5 px-2 rounded"
export const BankerCalendarTestStyle5 = 'w-[10rem] flex justify-end'
export const BankerCalendarTestStyle6 = 'px-3 lg:mr-6 gap-2 py-2 body-text flex items-center bg-green-600 text-white rounded-lg'
export const BankerCalendarTestStyle7 = 'pl-7 grid grid-cols-7 border-t'
export const BankerCalendarTestStyle8 = "flex flex-col w-16"
export const BankerCalendarTestStyle9 = "h-16 border-t flex items-start justify-center"
export const BankerCalendarTestStyle10 = "h-16 overflow-auto flex flex-col gap-1 custom-scrollbar px-1 py-1 border-t relative"
export const BankerCalendarTestStyle11 = 'text-white p-1 text-start  rounded inset-0'
export const BankerCalendarTestStyle12 = "h-16 border-b flex items-start"
export const BankerCalendarTestStyle13 = "text-xs ml-1"
export const BankerCalendarTestStyle14 = "border-t grid w-full grid-cols-7"
export const BankerCalendarTestStyle15 = "h-16 overflow-auto flex flex-col gap-1 custom-scrollbar px-1 py-1  border-b relative"
export const BankerCalendarTestStyle16 = 'text-white text-start p-1 w-[5rem] lg:w-full rounded'
export const BankerCalendarTestStyle17 = "grid grid-cols-7 text-start"
export const BankerCalendarTestStyle18 = 'p-2 h-32 border overflow-hidden'
export const BankerCalendarTestStyle19 = "overflow-auto custom-scrollbar max-h-full"
export const BankerCalendarTestStyle20 = 'w-[20rem] lg:max-w-max flex items-center gap-1'
export const BankerCalendarTestStyle21 = "grid gap-1 w-full cursor-pointer"
export const BankerCalendarTestStyle22 = "grid grid-cols-7 text-xs mb-2"
export const BankerCalendarTestStyle23 = "text-center pl-1"
export const BankerCalendarTestStyle24 = "grid grid-cols-7 text-start"
export const BankerCalendarTestStyle25 = "bg-[#1EB469] rounded-full text-white flex items-center justify-center w-full h-[4rem] sm:h-[3rem] lg:h-[2rem] xl:h-[2rem]"
export const BankerCalendarTestStyle26 = 'flex items-center justify-center w-full h-[4rem] sm:h-[3rem] lg:h-[2rem] xl:h-[2rem]'
export const BankerCalendarTestStyle27 = "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4"
export const BankerCalendarTestStyle28 = "p-2 flex flex-col items-center"
export const BankerCalendarTestStyle29 = "text-lg font-bold mb-2"
export const BankerCalendarTestStyle30 = "grid grid-cols-4 gap-4"
export const BankerCalendarTestStyle31 = "p-2 rounded"
export const BankerCalendarTestStyle32 = "text-lg font-semibold mb-2"
// export const BankerCalendarTestStyle33 = ''
// export const BankerCalendarTestStyle34 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
// export const BankerCalendarTestStyle1 = ''
