import React, { useState } from "react";
import { dropdownContainer, dropdownContent, divider, dropdownStyleHeading, flexItemStart, dropdownSubTitle } from "./StyleCommon"; // Import styles
import { DropdownIconUpAndDown } from "./Icons";

const Dropdown = ({ title, subTitle, children }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div>
      <div onClick={toggleDropdown} className={dropdownContainer}>
        <div>
          <h6 className={dropdownStyleHeading}>{title}</h6>
          {!isDropdownVisible && <p className={dropdownSubTitle}>{subTitle}</p>}
        </div>
        <div className={flexItemStart}>
         <DropdownIconUpAndDown isDropdownVisible={isDropdownVisible}/>
        </div>
      </div>
      {isDropdownVisible && (
        <div className={dropdownContent}>
          {children}
        </div>
      )}
      <div className={divider}></div>
    </div>
  );
};

export default Dropdown;
