import React from 'react'
import Navbar from '../../../../common/Navbar'
import BankerSideNavbar from '../../../../common/BankerSideNavbar'
import { useNavigate } from 'react-router-dom';

const CancelSubscriptionFour = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar hemBurgerIcon={true} BackBtn={true} ProfileAndNotification={true} BankerNavbar={true}/>

      <div className='flex'>
        <div className='w-0 md:w-[7%]'>
            <BankerSideNavbar />
        </div>
        <div className='px-4 md:px-8  py-4 w-full md:w-[97%]'>
           <h4 className='h4-text text-start px-4 text-[#2C2C2C]'>Cancel Subscription</h4>
            <div className='w-full bg-[#F9F9FB] mt-8 rounded-lg border px-4 py-6'>
                <h6 className='h6-text text-[#2C2C2C]'>
                Your subscription is cancelled
                </h6>

                <div className='text-start body-text text-[#2C2C2C]'>
                <li className='my-1'>
                You can continue to use Intercom until your current billing cycle ends
                </li>
                <li className='my-1'>
                After your billing cycle ends, you will not be able to use Intercom
                </li>
                <li className='my-1'>
                If you'd like to export your customer data, click here
                </li>
                <li className='my-1'>
                To immediately and permanently delete your workspace, click here (this can't be undone)
                </li>
                </div>

                <h6 className='h6-text mt-8 text-[#2C2C2C]'>
                Restart Subscription
                </h6>
                <li className='body-text mt-1 text-start text-[#2C2C2C]'>
                Made a mistake? If you want to restart your subscription, just <span className='cursor-pointer text-[#1EB469]'>click here</span>
                </li>


              


            </div>
        </div>

      </div>
    </div>
  )
}

export default CancelSubscriptionFour
