import React from "react";
import { DoubleLeftArrowIcon, LeftArrowIcon, RightArrowIcon } from "./Icons";
import './Pagination.css';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleFirstPage = () => onPageChange(1);
  const handlePreviousPage = () => onPageChange(currentPage - 1);
  const handleNextPage = () => onPageChange(currentPage + 1);
  const handleLastPage = () => onPageChange(totalPages);

  return (
    <div className="pagination-controls ">
      <button className="mr-2" onClick={handleFirstPage} disabled={currentPage === 1}>
        <DoubleLeftArrowIcon />
      </button>
      <button onClick={handlePreviousPage} disabled={currentPage === 1}>
        <LeftArrowIcon />
      </button>
      <span className="border border-[#80828D] mx-4 my-1 py-2 px-2 rounded-[8px] text-[#80828D]">{`Page ${currentPage} of ${totalPages}`}</span>
      <button onClick={handleNextPage} disabled={currentPage === totalPages}>
        <RightArrowIcon />
      </button>
      <button onClick={handleLastPage} disabled={currentPage === totalPages}>
        {/* <DoubleRightArrowIcon /> */}
      </button>
    </div>
  );
};

export default Pagination;
