import { ToastMessage, apiHost } from "../common/Config";

export const uploadProfileData = (step, notification) => {
  const token = JSON.parse(sessionStorage.getItem('userToken')) || "";
  const data1 = JSON.parse(sessionStorage.getItem('bankerData')) || {};
  const keyServices = JSON.parse(sessionStorage.getItem('selectedItems')) || [];
  const coreExpertise = JSON.parse(sessionStorage.getItem('CoreExpertise')) || [];
  const StateCity = JSON.parse(sessionStorage.getItem('stateCity')) || [];
  const consultancyMode2 = JSON.parse(sessionStorage.getItem('consultancyMode')) || "";
  var consultancyMode = {};
  var hireMode = {};
  const bankerLastDrawnSalary = JSON.parse(sessionStorage.getItem('bankerLastDrawnSalary')) || "";
  const bankerPreferredLocation = JSON.parse(sessionStorage.getItem('bankerPreferredLocation')) || "";
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  try {
    consultancyMode = JSON.parse(sessionStorage.getItem('bankerConsultancyMode')) || {};
  }
  catch {
    consultancyMode = {};
  }

  try {
    hireMode = JSON.parse(sessionStorage.getItem('bankerhireMode')) || {};
  }
  catch {
    hireMode = {};
  }

  const formdata = new FormData();
  if (data1?.profilefile) {
    formdata.append("profile_image", data1?.profilefile);
  }

  formdata.append("designation", data1?.designation || "");
  keyServices?.length > 0 && keyServices?.map((item, index) => {
    formdata.append(`key_service`, item || 1);
  });
  coreExpertise?.map((item, index) => {
    formdata.append(`core_expertise`, item);
  })
  formdata.append("current_city", data1.city);
  formdata.append("last_salary", bankerLastDrawnSalary || 0);
  formdata.append("dob", data1.dob);
  formdata.append("preferred_location", bankerPreferredLocation);
  formdata.append("bank_name", data1.company);
  formdata.append("retirement_date", data1.retirementDate);
  formdata.append("banking_experience", data1.bankingExperience);
  formdata.append("custom_designation", data1.custom_designation);
  if (data1?.education == 3) {
    formdata.append("institute_name", data1?.certification || "");
  }
  formdata.append("payout_status", "pending");
  if (data1?.salarySlip) {
    formdata.append("salary_slip", null);
  }

  formdata.append("master_education", data1?.education || 1);
  formdata.append("master_experience", data1?.experience || "");

  if (Object.keys(hireMode).length > 0) {
    formdata.append("master_tenure", hireMode?.id || 1);
  }

  if (Object.keys(consultancyMode).length > 0) {
    formdata.append("master_consultancy_mode", consultancyMode?.id || 1);
  }
  // formdata.append("master_tenure", "2");
  // formdata.append("master_consultancy_mode", consultancyMode?.id || 1);
  formdata.append("designation", data1.designation);
  formdata.append("name", data1.name);
  formdata.append("form_step", step);
  formdata.append("current_address", data1?.current_address || "");
  formdata.append("permanent_address", data1?.permanent_address || "");
  formdata.append("city", StateCity?.city || "");
  formdata.append("state", StateCity?.state || "");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
  };

  fetch(`${apiHost}/onboarding/banker/bankers`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (notification) {
        ToastMessage("success", result?.message);
      }

    })
    .catch((error) => console.error(error));
};