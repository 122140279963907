import React, { useEffect, useRef, useState } from 'react'
import { ErrorIcon, NotificationBellIcon, NotificationErrorIcon, NotificationIcon, NotificationMessageIcon, TimeIcon, TimeIcon1 } from '../../../../common/Icons';
// import Button from './Button';
import { NavLink, useNavigate } from 'react-router-dom';
import useGetData from '../../../../Hook/useGetData';
import Button from '../../../../common/Button';
// import { NotificationBellIcon } from '../../../../common/Icons';
// import useGetData from '../Hook/useGetData';

const BankerNotificationModel = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [notification, setNotification] = useState(true);
    const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
    
    const { data: getNotificationData} = useGetData('notifications/all', token);
    const [data,setData]=useState([])
    const navigate = useNavigate();
    const popoverRef = useRef(null);
    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);
    useEffect(()=>{
        if(getNotificationData?.data?.length>0){
            setData(getNotificationData?.data?.reverse().slice(0, 5))
        }

    },[getNotificationData])
    return (
        <div className='relative' ref={popoverRef}>
            <div className={`${isOpen ? 'bg-[#E7E8EC] cursor-pointer py-2 px-2 rounded-full' : 'cursor-pointer py-2 px-2 rounded-full'}`}>
                <NotificationBellIcon
                 onClick={toggleModal} 
                 />
            </div>
            {isOpen && (
                <div className='w-[22rem] md:w-[492px] absolute -right-[4.5rem] top-14 rounded-lg border bg-white'>
                    <div className='p-4 border'>
                        <h6 className='h6-text text-start'>Notifications</h6>
                    </div>
                    {notification ? (
                        <div>
                            {/* <div className='flex border bg-[#F4FBF6] w-full py-5'>
                                <div className={`w-[54px] mx-5 h-[54px] rounded-full overflow-hidden ${!imageData ? "bg-[#1EB469]" : ""}`}>
                                    {!imageData ? <h2 className='h2-text text-white'>AK</h2> : <img className='w-full' src={imageData} alt='' />}
                                </div>
                                <div className='text-start'>
                                    <p className='notification'>  <span className='notification-span'>Mr. Sumit Mathur</span> selected <span className='notification-span'>24/07/2024 12:30 </span> to <span className='notification-span'>13:00</span > slot.</p>
                                    <p className='notification-ptag my-2 flex items-center'>1 min ago <div className='h-1 mx-2 w-1 bg-[#8B8D98]  rounded-full'></div> Internet Banking System</p>
                                    <Button className="redfolicbg h-[38px] rounded-lg text-start px-4">
                                        <p className='remove-text'>Schedule Meeting</p>
                                    </Button>
                                </div>
                            </div>
                            <div className='flex border bg-[#F4FBF6] w-full py-5'>
                                <div className='w-[54px] mx-5 h-[54px] bg-[#FFDADD] flex justify-center items-center rounded-full border'>
                                    <TimeIcon1 />
                                </div>
                                <div className='text-start'>
                                    <p className='notification'>  <span className='notification-span'>Meeting Title</span> will start in <span className='notification-span'>15 mins </span> with <span className='notification-span'>Mr. Sumit Mathur.</span ></p>
                                    <p className='notification-ptag my-2 flex items-center'>10 min ago <div className='h-1 mx-2 w-1 bg-[#8B8D98]  rounded-full'></div> Internet Banking System</p>
                                    <Button className="greenbg text-white h-[38px] rounded-lg text-start px-6">
                                        <p className='remove-text'>Join</p>
                                    </Button>
                                </div>
                            </div> */}
                            {data?.length >0 ? data?.map((item) => (
                                   <div className='flex border bg-[#F4FBF6] w-full py-5'>
                                  <NavLink to={`/${item?.event_url}`} >
                                   <div className='w-[54px] mx-5 h-[54px] bg-[#FFDADD] flex justify-center items-center rounded-full border'>
                                       <NotificationMessageIcon />
                                   </div>
                               </NavLink>
                                   <div className='text-start'>
                                       {/* <p className='notification'><span className='notification-span'>Congratulations!</span></p> */}
                                       <p className='notification'><span className='notification-span'>{item?.message}</span></p>
                                       {/* <p className='notification-ptag my-2 flex items-center'>20 min ago <div className='h-1 mx-2 w-1 bg-[#8B8D98]  rounded-full'></div> Internet Banking System</p> */}
                                       {/* <Button onClick={() => navigate('/dashboard/metting-confirmation')} className="redfolicbg text-white h-[38px] rounded-lg text-start px-6">
                                           <p className='remove-text'>View Confirmation</p>
                                       </Button> */}
                                   </div>
                               </div>
                            )) :
                            <p> Notification not available
                                </p>
                            }
                         
                            {/* <div className='flex border bg-white w-full py-5'>
                                <div className={`w-[54px] mx-5 h-[54px] rounded-full overflow-hidden ${!imageData ? "bg-[#1EB469]" : ""}`}>
                                    {!imageData ? <h2 className='h2-text text-white'>AK</h2> : <img className='w-full' src={imageData} alt='' />}
                                </div>
                                <div className='text-start'>
                                    <p className='notification'>  <span className='notification-span'>Mr. Sumit Mathur</span> marked <span className='notification-span'>Milestone 4 </span> as completed </p>
                                    <p className='notification-ptag my-2 flex items-center'>2 Day ago <div className='h-1 mx-2 w-1 bg-[#8B8D98]  rounded-full'></div> Internet Banking System</p>
                                </div>
                            </div>
                            <div className='flex border bg-white w-full py-5'>
                                <div className={`w-[54px] mx-5 h-[54px] rounded-full overflow-hidden ${!imageData ? "bg-[#1EB469]" : ""}`}>
                                    {!imageData ? <h2 className='h2-text text-white'>AK</h2> : <img className='w-full' src={imageData} alt='' />}
                                </div>
                                <div className='text-start'>
                                    <p className='notification'>  <span className='notification-span'>Mr. Sumit Mathur</span> add an update on <span className='notification-span'>Milestone 4. </span></p>
                                    <p className='notification-ptag my-2 flex items-center'>1 week ago <div className='h-1 mx-2 w-1 bg-[#8B8D98]  rounded-full'></div> Internet Banking System</p>
                                </div>
                            </div>
                            <div className='flex border bg-white w-full py-5'>
                                <div className='w-[54px] mx-5 bg-[#FFDADD] h-[54px] flex justify-center items-center rounded-full'>
                                    <ErrorIcon />
                                </div>
                                <div className='text-start'>
                                    <p className='notification'> <span className='notification-span'>Milestone 4 is running due.</span> </p>
                                    <p className='notification-ptag my-2 flex items-center'>1 month ago <div className='h-1 mx-2 w-1 bg-[#8B8D98]  rounded-full'></div> Internet Banking System</p>
                                </div>
                            </div> */}
                            <div className='p-4 border'>
                                <p onClick={()=>navigate("/dashboard/banker/notification")} className='cursor-pointer py-2 h6-text remove-text text-[#1EB469]'>Show All Notifications</p>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='flex justify-center py-10 items-center'>
                                <NotificationErrorIcon />
                            </div>
                            <div className='m-auto w-2/3 mb-14'>
                                <h6 className='h6-text text-[#2C2C2C]'>Currently, nothing to report!</h6>
                                <p className='checkBox-label'>This area will light up with new notifications once there’s activity in your account.</p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default BankerNotificationModel;
