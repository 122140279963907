import React, { useEffect, useState } from "react";
import JobListingComp from "./JobListingComp";
import Navbar from "../../../../common/Navbar";
import SideNavbar from "../../../../common/SideNavbar";
import Checkbox from "../../../../common/Checkbox";
import Button from "../../../../common/Button";
import SelectTagComponent from "../../../../common/SelectTagComponent";
import {
  DisableClosedIcon,
  DoubleLeftArrowIcon,
  DownArrorIcon,
  FilterIcon,
  LeftArrowIcon,
  RefreshIcon,
  RightArrowIcon,
  DoubleRightArrowIcon,
} from "../../../../common/Icons";
import InputField from "../../../../common/InputField";
import Dropdown from "../../../../common/Dropdown";
import {
  FlexJustBtItemCenter,
  HRLineJobListing,
  JobListingBannerDiv,
  JobListingBannerDivChild,
  JobListingBannerDivIcon,
  JobListingCardDiv,
  JobListingContainer,
  JobListingContainerClearAll,
  JobListingContainerDiv,
  JobListingContainerHeader,
  JobListingCountStyle,
  JobListingDropDownFilter1,
  JobListingHRLine1,
  JobListingInputDiv,
  JobListingMainContainer,
  JobListingMenuBar,
  JobListingMenuBarDiv,
  JobListingMenuBarDivAvticeTab,
  JobListingRightDiv,
  JobListingRightDivPostButton,
  JobListingSelectDiv,
  WFullFlex,
  WFullFlexAdd,
} from "./DashboardJobStyle";
import { useNavigate } from "react-router-dom";
import useGetData from "../../../../Hook/useGetData";
import Pagination from "../../../../common/Pagination";
import { ToastMessage, apiHost } from "../../../../common/Config";
import usePost from "../../../../Hook/usePost";
import { Loader } from "../../../../common/Loader";
import DataNotFound from "../../../../common/DataNotFound";
import LoaderAPI from "../../../../common/LoaderAPI";

const JobListing = () => {
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const PageOptions = [
    {
      id: 1,
      name: 5,
    },
    {
      id: 2,
      name: 10,
    },
    {
      id: 3,
      name: 20,
    },
    {
      id: 4,
      name: 30,
    },
    {
      id: 5,
      name: 40,
    },
    {
      id: 6,
      name: 50,
    },
  ];
  const [activeTab, setActiveTab] = useState("All Job");
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedIds,setSelectedIds]=useState([]);
  const [ActiveFilter , setActiveFilter] = useState(false)

  const [filterData,setFilterData]=useState({
    tenure:[],
    consultancy_mode:[]
  })
  const { data: getJobList, loading: loadingService, error: errorService,refetch:fetchJobList } = useGetData('job/list', token);
  const { data: closeData, loading: closeLoading, error: closeError, postData: closePostData } = usePost('job/close-job');
  const [listData,setListData]=useState([])
  const [checkedItems, setCheckedItems] = useState([]);
  
  const handleTabClick = (tab) => {
    setActiveTab(tab?.name);
    if (tab?.id === 1) {
      setList(getJobList?.data);
    }
    if (tab?.id === 2) {
      setList(getJobList?.data?.filter((item) => item?.status == 2));
    }
    if (tab?.id === 3) {
      setList(getJobList?.data?.filter((item) => item?.status == 0));
    }
  };

  const mainTag = [
    { id: 1, name: "All Job", length: getJobList?.data?.length || list?.length || 0 },
    { id: 2, name: "Draft", length: list?.filter((item) => item?.status == 2)?.length || getJobList?.data?.filter((item) => item?.status == 2)?.length || 0 },
    { id: 3, name: "Closed", length: list?.filter((item) => item?.status == 0)?.length ||getJobList?.data?.filter((item)=> item?.status == 0)?.length || 0 },
  ];
  const FilterDataLayOut = [
    {
      id:1,
      title:"Availability for hire",
      hire:[{name:"hire",title:"Short term (Up to 3 months)",value: "Short term"} ,
        {name:"hire",title:"Medium term (Up to 6 months)",value: "Medium term"} ,
        {name:"hire",title:"Long term (6 month & above))",value: "Long term"}],
    },
    {
      id:2,
      title:"Mode of consultancy",
      hire: [
        { name: "consultancy_mode", title: "Virtual", value: "Virtual" },
        { name: "consultancy_mode", title: "In-Person", value: "In-Person"},
        { name: "consultancy_mode", title: "Both", value: "Both" }
      ],
    },
  ]
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleChange = (options) => {
    setItemsPerPage(options?.name);
    setCurrentPage(1);
  };
 
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = list?.map(job => job.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleClose = async () => {
    try {
      if(selectedIds?.length>0){
        const payload = selectedIds?.map(id => ({ job: id }));
        
        // Await each closePostData call
        if (payload) {
            await Promise.all(payload.map(item => closePostData(item, token)));
        }

        // Clear the selected IDs
        setSelectedIds([]);

        // Fetch the job list after closing posts
        await fetchJobList();

        // Display success message if closePostData succeeded
        ToastMessage("success", "Job closed successfully");
      }else{
        ToastMessage("error", "Please select a job to close");
      }
  
    } catch (error) {
        // Display error message if any step failed
        ToastMessage("error", "Job close failed");
    }
}

  const handleFilter=async(obj)=>{
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify(obj);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${apiHost}/job/list-filter`, requestOptions);
      const result = await response.json();
      setList(result?.data)
      // setFilterData(result); // Assuming result is an array of filter data
    } catch (error) {
      console.error('Error fetching filter data:', error);
    }
  }

  const handleCheckboxChange = (item1, item2, id, val) => {
    let updatedItems = checkedItems;
    const index = updatedItems.indexOf(val);
  
    let obj = {
      tenure: filterData.tenure,
      consultancy_mode: filterData.consultancy_mode
    };
  
    if (index === -1) {
      updatedItems.push(val);
      if (item2?.name === 'hire') {
        obj.tenure = [...new Set([...filterData.tenure, id])];
      } else if (item2?.name === 'consultancy_mode') {
        obj.consultancy_mode = [...new Set([...filterData.consultancy_mode, id])];
      }
    } else {
      updatedItems.splice(index, 1);
      if (item2?.name === 'hire') {
        obj.tenure = filterData.tenure.filter(tenureId => tenureId !== id);
      } else if (item2?.name === 'consultancy_mode') {
        obj.consultancy_mode = filterData.consultancy_mode.filter(modeId => modeId !== id);
      }
    }
  
    setFilterData(obj);
    setCheckedItems(updatedItems);
  
    // If no filters are selected, fetch the default job list
    if (obj.tenure.length === 0 && obj.consultancy_mode.length === 0) {
      fetchJobList();
    } else {
      handleFilter(obj);
    }
  };

  const handleClearAll = () => {
    setCheckedItems([]);
    setFilterData({
      tenure:[],
      consultancy_mode:[]
    });
    fetchJobList();
  }
    
  useEffect(() => {
    if (getJobList?.data?.length > 0) {
      setList(getJobList?.data);
    }
  }, [getJobList, errorService]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems =list?.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(list?.length / itemsPerPage) || 1 ;

useEffect(()=>{
  handleFilter(filterData)
},[filterData?.search_query])

useEffect(() => {
  fetchJobList();
}, [closeData]);

const handleOptionChange = (option) => {

  if (option.id === 1) {
    // Sort by name in ascending order
    let sortedList = [...getJobList.data].sort((a, b) => a?.BkJobTitleAndCustom?.custom_title.localeCompare(b?.BkJobTitleAndCustom?.custom_title));
    setList(sortedList);
  } else if (option.id === 2) {
    // Sort by name in descending order
    let sortedList = [...getJobList.data].sort((a, b) => b?.BkJobTitleAndCustom?.custom_title.localeCompare(a?.BkJobTitleAndCustom?.custom_title));
    setList(sortedList);
  } else if (option.id === 3) {
    // Sort by created_at in ascending order
    let sortedList = [...getJobList.data].sort((a, b) => {
      let dateA = new Date(a.created_at);
      let dateB = new Date(b.created_at);

      // Check if date parsing is successful
      if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
        console.error("Invalid date format:", a.created_at, b.created_at);
        return 0;
      }

      return dateB -dateA;
    });

    setList(sortedList);
  }
};





  return (
    <div>
     
        <Navbar hemBurgerIcon={true} CompanyNavbar={true} ProfileAndNotification={true}/>
      

      <div className="flex ">
        {/* <div className="w-[9%] "> */}
        <div className="lg:w-[7%] lg:-[7%] w-0">
          <SideNavbar />
        </div>
        {/* <div className='flex w-[91%]'> */}
        <div className= "w-[100%] lg:flex px-[1rem] lg:w-[93%]  relative">
          <div className={JobListingContainer}>
            <div className={JobListingContainerDiv}>
              <div className="flex">
                <div>
                  <FilterIcon />
                </div>
                <div>
                  <h5 className={JobListingContainerHeader}>
                    Filters
                  </h5>
                </div>
              </div>
              <div className="ml-auto" role="button" onClick={handleClearAll}>
                <p className={JobListingContainerClearAll}>Clear all</p>
              </div>
            </div>
            <div className={HRLineJobListing}></div>

            <div className={JobListingInputDiv}>
              <div class="relative  mt-2 w-full">
                <InputField
                  type="text"
                  id="searchbytitle"
                  // label="Search by Title/ Job ID"
                  placeholder="Search by Title/ Job ID"
                  onChange={(e)=>setFilterData({...filterData,search_query:e.target.value})}
                  SearchIcon={true}
                />
              </div>
            </div>
            <div className={HRLineJobListing}></div>

            <div>
              {FilterDataLayOut?.map((item, index) => {
                
                return (
                  <Dropdown subTitle={index==0 ? "Short term, Medium term" :
                  "Virtual, In-Person"
                  } title={item?.title}>
                    <div className={JobListingDropDownFilter1}>
                      {item?.hire?.map((itemh, index) => {
                        return (
                          <Checkbox
                            key={index}
                            id="company-radio"
                            type="checkbox"
                            label={itemh?.title}
                            checked={checkedItems?.includes(itemh?.value)}
                            onChange={() => handleCheckboxChange(item,itemh,index+1,itemh?.value)}
                          />
                        );
                      })}
                    </div>
                  </Dropdown>
                );
              })}
            </div>
          </div>


          <div className="w-full lg:w-[70%] ">
            <div className={JobListingRightDiv}>
              <div className="text-start">
                <h4 className="h4-text">Job Listing</h4>
              </div>
              <div>
                <Button
                  onClick={() =>{ 
                    sessionStorage.removeItem('selectedItems')
                    sessionStorage.removeItem('addJob')
                    navigate("/dashboard/company/addjob/stepone")}}
                  type="submit"
                  className={JobListingRightDivPostButton}
                >
                  Add a Job
                </Button>
              </div>
            </div>

            <div className={JobListingMenuBar}>
              <div className={JobListingMenuBarDiv}>
                {mainTag?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div
                        className={` ${JobListingMenuBarDivAvticeTab} ${
                          activeTab === item?.name ? "" : ""
                        }`}
                        onClick={() => handleTabClick(item)}
                      >
                        <div>{item?.name}</div>
                        <div className={JobListingCountStyle}>
                          {item?.length}
                        </div>
                      </div>
                      <div
                        className={`h-1 mt-8 border-1 ${
                          activeTab === item?.name ? "greenbg" : "bg-transparent"
                        }`}
                      ></div>
                    </div>
                  );
                })}
              </div>
              <br className="block md:hidden"/>

              <div className={FlexJustBtItemCenter}>
                <div className={JobListingSelectDiv}>
                  <div>
                    <p className="s3-text">Show</p>
                  </div>
                  <div className="w-24">
                    <SelectTagComponent defaultVal="10" onChange={handleChange} options={PageOptions} />
                  </div>
                </div>
               <div className="">
               <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
               </div>
              </div>
            </div>

            <div className={`${JobListingHRLine1} hidden md:block`}></div>

            <div className={`${currentItems?.length <=0 ? "pointer-events-none !bg-gray-[#ecececf0] !text-gray-200" :""} md:mx-10 px-3 rounded-[8px] border  md:grid md:grid-cols-2 mt-10 py-5`}>
              <div className='flex justify-between  md:flex md:items-center md:space-x-8'>
                <div>
                  <Checkbox disabled={currentItems?.length <=0}
                  labelClassName={currentItems?.length <=0 ? " !text-gray-200" : ""}
                  type="checkbox" label=" Select All"  onChange={handleSelectAll} checked={selectedIds?.length>0 && selectedIds?.length === list?.length} />
                </div>
                <div className={`pointer-events-auto ${JobListingBannerDivIcon}`} onClick={fetchJobList}>
                  <RefreshIcon />
                  <p className="secondary-text-gray">Refresh</p>
                </div>
                <div className={JobListingBannerDivIcon} onClick={handleClose}>
                  <DisableClosedIcon />
                  <p>Close</p>
                </div>
              </div>

              <div className="flex justify-between mt-4 md:mt-0 items-center md:ml-auto">
                <p className={`${JobListingCardDiv} `}>
                  <div>Sort by :</div><div className="secondary-text-gray w-[70%] ml-1"><SelectTagComponent
                   defaultVal="Posted date"
                    options={[{id:1,name:"A To Z"},{id:2,name:"Z To A"},{id:3,name:"Posted date"}]}
                    onChange={(option) =>handleOptionChange(option)}
                    /></div>
                  {/* <DownArrorIcon /> */}
                  
                </p>
                <div onClick={()=>setActiveFilter(!ActiveFilter)} className={`block lg:hidden ${ActiveFilter?'bg-gray-200':''} px-1 py-1 rounded-lg`}>
                  <FilterIcon/>
                  
                  </div>
              </div>
            </div>

            { 
              ActiveFilter && <div className='w-full block lg:hidden p-5 light-gray-background'>
              <div className={JobListingContainerDiv}>
                <div className="flex">
                  <div>
                    <FilterIcon />
                  </div>
                  <div>
                    <h5 className={JobListingContainerHeader}>
                      Filters
                    </h5>
                  </div>
                </div>
                <div className="ml-auto" role="button" onClick={handleClearAll}>
                  <p className={JobListingContainerClearAll}>Clear all</p>
                </div>
              </div>
              <div className={HRLineJobListing}></div>
  
              <div className={JobListingInputDiv}>
                <div class="relative  mt-2 w-full">
                  <InputField
                    type="text"
                    id="searchbytitle"
                    label="Search by Title/ Job ID"
                    placeholder=" "
                    onChange={(e)=>setFilterData({...filterData,search_query:e.target.value})}
                    SearchIcon={true}
                  />
                </div>
              </div>
              <div className={HRLineJobListing}></div>
  
              <div>
                {FilterDataLayOut?.map((item, index) => {
                  return (
                    <Dropdown subTitle="Short term, Medium term" title={item?.title}>
                      <div className={JobListingDropDownFilter1}>
                        {item?.hire?.map((itemh, index) => {
                          return (
                            <Checkbox
                              key={index}
                              id="company-radio"
                              type="checkbox"
                              label={itemh?.title}
                              checked={checkedItems?.includes(itemh?.value)}
                              onChange={() => handleCheckboxChange(item,itemh,index+1,itemh?.value)}
                            />
                          );
                        })}
                      </div>
                    </Dropdown>
                  );
                })}
              </div>
            </div>
            }

            <div className=" " >
            {(loadingService || closeLoading)  ? <LoaderAPI /> : currentItems?.length >0 ? currentItems?.map((job, index) => (
                <JobListingComp key={index} job={job} selectedIds={selectedIds} setSelectedIds={setSelectedIds} refetch={fetchJobList} />
              )) :
              <DataNotFound title="No Job posted" />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobListing;
