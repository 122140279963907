import { CrossIcon1 } from '../../../../common/Icons';
import Button from '../../../../common/Button';
import { CP_AM_MainDiv4H4, CUM_Model_MainDiv, CUM_Model_MainDiv1, CUM_Model_MainDiv2, CUM_Model_MainDiv3, CUM_Model_MainDiv4, CUM_Model_MainDiv5, CUM_Model_MainDiv6 } from './CompanyProjectStyle';

const CompanyUpdateMilestoneModel = ({ isOpen, onClose, milestone }) => {

  if (!isOpen) return null;

  return (
    <div className={CUM_Model_MainDiv}>
      <div className={CUM_Model_MainDiv1}>
        <div className={CUM_Model_MainDiv2}>
          <h4 className={CP_AM_MainDiv4H4}>Update Milestones.</h4>
          
          <button onClick={onClose} className="text-gray-500">
            <CrossIcon1 />
          </button>
        </div>
       
        <div className={CUM_Model_MainDiv3}>
          
          <textarea className={CUM_Model_MainDiv4} placeholder='Type here' name="" id="">

          </textarea>
        </div>
       
        <div className={CUM_Model_MainDiv5}>
          {/* <Button
            onClick={onClose}
            className="px-10 py-2 redfolicbg1 rounded-lg mr-2"
          >
            Cancel
          </Button> */}
          <Button onClick={onClose} className={CUM_Model_MainDiv6}>
          Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyUpdateMilestoneModel;
