import React, { useEffect, useRef, useState } from 'react';
import Button from './Button';
import { DropdownDeleteBtnStyle, DropdownStyleDefaultVal, getErrorClassNames } from '../common/StyleCommon';
import { selectContainer, selectButton, selectButtonError, selectDropdown, selectOption } from "./StyleCommon";
import { SelectTagIcon } from './Icons';

const SelectTagComponent = ({hideDropDown, options, defaultVal, styles, onChange, error, name,disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value, e) => {
    setSelectedValue(value);
    setIsOpen(false);
    try {
      onChange(value); // Notify parent component about the change
    } catch (err) {
      onChange(e);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={selectContainer} ref={dropdownRef}>
      <Button
        id="dropdownDelayButton"
        disabled={disabled ? true : false}
        onClick={toggleDropdown}
        className={ ` pr-10
          ${styles ? styles : selectButton} 
          ${error ? selectButtonError : ''}
        `}
        type="button"
      >
        {selectedValue?.name || selectedValue?.city || defaultVal}
<span className='bg-white absolute right-1 top-4 transition-transform overflow-hidden px-2'>
{!hideDropDown &&<SelectTagIcon DropdownStyleDefaultVal={DropdownStyleDefaultVal} isOpen={isOpen} />}

</span>
      </Button>

      {isOpen && (
        <div className={`${selectDropdown} ${options?.length >= 4 ? "h-[10rem] overflow-auto " : ""}`}>
          <ul className={DropdownDeleteBtnStyle} aria-labelledby="dropdownDelayButton">
            {options?.map((option, index) => (
              <li key={index}>
                <Button name={name} onClick={(e) => handleOptionClick(option, e)} className={selectOption}>
                  {option?.name || option?.title || option?.city || option}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {!isOpen && error && (
        <p className={getErrorClassNames}>
          {error}
        </p>
      )}
    </div>
  );
};  

export default SelectTagComponent;
