import React from 'react'
import Navbar from '../../../common/Navbar'
import Button from '../../../common/Button'
import { LogOutIcon } from '../../../common/Icons'

const BankerLogOut = () => {
  return (
    <div>
        <Navbar hemBurgerIcon={false}/>

        <div className='flex justify-center items-center h-screen'>
                    <div className=''>
                        <h2 className='h2-text'>Banker Dashboard</h2>
                        <Button className="redfolicbg m-auto my-10 flex px-6 gap-4 py-2 justify-center items-center rounded-lg">
                            <LogOutIcon /> Logout
                        </Button>
                    </div>

        </div>
      
    </div>
  )
}

export default BankerLogOut
