import React, { useEffect, useState } from 'react';
import Navbar from '../../../common/Navbar';
import BankerSideNavbar from '../../../common/BankerSideNavbar';
import InputField from '../../../common/InputField';
import { DownloadGreenIcon, GreenPencilIcon, PencilIcon } from '../../../common/Icons';
import Button from '../../../common/Button';
import DetailField from '../DetailField';
import ProfilePhotoUploadModel from '../ProfilePhotoUploadModal';
import useGetData from '../../../Hook/useGetData';
import { ToastMessage, apiHost, formatDateToddmmyr, getCoreExpertise, getKeyAreaServices, handleAlphabeticInput, handleNonNumericInput } from '../../../common/Config';
import SelectTagComponent from '../../../common/SelectTagComponent';
import { BankerStep2div, BankerStep2divCoreError, BankerStep2divKeyServ, BankerStep2divSaveIem, BankerTagStyle, FlexJustEnd } from './BankerStyle';
import ExpertiseModal from '../../../common/ExpertiseModal';
import UploadProfileModal from '../company/UploadProfileModal';
import usePost from '../../../Hook/usePost';
import { allowOnlyNumbers, calculateMinDOBFor18YearsOld, convertDate, convertDateToInputFormat, handleTwoDigitInput, preventNumbersAndSpecialChars, validateIFSC } from '../../../Hook/validation';
import LoaderAPI from '../../../common/LoaderAPI';

const BankerMyProfile = () => {
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const { data, error, loading, refetch } = useGetData("profile/banker/basic-details", token);
  const [EditNameIcon, SetEditNameIcon] = useState(true);
  const [EditBasicIcon, SetEditBasicIcon] = useState(true);
  const [EditExperinceIcon, SetEditExperinceIcon] = useState(true);
  const [EditEducationIcon, SetEditEducationIcon] = useState(true);
  const [EditKeyServiceIcon, SetEditKeyServiceIcon] = useState(true);
  const [EditCoreIcon, SetEditCoreIcon] = useState(true);
  const [EditBankIcon, SetEditBankIcon] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [initials, setInitials] = useState("AK");
  const [profilePhoto, setProfilePhoto] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);



  const [errors, setErrors] = useState({});
  const [savedItems, setSavedItems] = useState([]);
  const [savedItemsCore, setSavedItemsCore] = useState([]);
  const [keyAreaServicesData, setKeyAreaServicesData] = useState([]);
  const [coreExpertiseData, setCoreExpertiseData] = useState([]);
  const [show, setShow] = useState(true);
  const [refresh, setRefresh] = useState(1);
  // const handleSave = (selectedItems) => {
  // };

  const [showCore, setShowCore] = useState(true);
  const [formData, setFormData] = useState({
    keyAreaServices: savedItems,
    coreExpertise: savedItemsCore,
  
  });
  const { data: getBankerData, loading: loadingServices, error: errorServices } = useGetData('onboarding/banker/get-bankers',token);
  const { data: edMasterData, loading: edMasterLoading, error: edMasterError } = useGetData("master-data/education/index");
  const { data: turnoverMasterData, loading: turnoverMasterLoading, error: turnoverMasterError } = useGetData("master-data/turnovers/index");
  const { data: vintageMasterData, loading: vintageMasterLoading, error: vintageMasterError } = useGetData("master-data/vintages/index");
  const { data: avalmodeMasterData, loading: avalmodeMasterLoading, error: avalmodeMasterError } = useGetData("master-data/tenure/index");
  const { data: consultmodeMasterData, loading: consultmodeMasterLoading, error: consultmodeMasterError } = useGetData("master-data/consultancy-mode/index");
  const [stateId,setStateId] = useState(getBankerData?.data?.state || 0);
  const { data: stateMasterData, loading: stateMasterLoading, error: stateMasterError } = useGetData("master-data/state/get");
  const { data: cityMasterData, loading: cityMasterLoading, error: cityMasterError } = useGetData(`master-data/city/${stateId}`);
  const { data: getDesignationData, loading: DesignationLoading, error: DesignationError } = useGetData("master-data/designation/get");
  const { data: updatedData, error: updateError, loading: updateLoading, postData: updateBankerDetails } = usePost("profile/banker/update-details");
  // const [loading, setLoading] = useState(true);

const [imageData, setImageData] = useState("https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg");
  const isEditing = !EditNameIcon || !EditBasicIcon || !EditExperinceIcon || !EditEducationIcon || !EditKeyServiceIcon || !EditCoreIcon || !EditBankIcon;
  const handleImageSelect = (image) => {
    setImageData(image);
  };

  const [UserName, setUserName] = useState("");
  const [userDetails, setUserDetails] = useState([
    { name: 'DOB', value: '2024-08-16',error:"" },
    { name: 'State', value: 'Haryana',error:"" },
    { name: 'City', value: 'Delhi',error:"" },
    { name: 'Email ID', value: 'sumitmathur@gmail.com',error:"" },
    { name: 'Availability for Hire', value: 'Short Term (Upto 3 months)',error:"" },
    { name: 'Phone Number', value: '+91-80768192678',error:"" },
    { name: 'Mode of Consultancy', value: 'Virtual, In-Person (Gurgaon, Delhi, Noida)',error:"" },
    { name: 'Current Address', value: 'Gurgaon, Haryana, 121001',error:"" },
    { name: 'Permanent Address', value: 'Gurgaon, Haryana, 121001',error:"" },
  ]);

  const [userExperienceDetails, setUserExperienceDetails] = useState([
    { name: 'Last company', value: 'SBI Card, Gurgaon' },
    { name: 'Date of retirement', value: '12/12/2023' },
    { name: 'Last Designation', value: 'Bank Manager' },
    { name: 'Banking Experience', value: '10 years' },
  ]);

  const [userEducationDetails, setUserEducationDetails] = useState([
    { name: 'Level', value: 'Custom' },
    { name: 'Degree', value: 'Not available' },
  ]);

  const [userBankDetails, setUserBankDetails] = useState([
    { name: 'Account number', value: '208352568927',error:"" },
    { name: 'Bank IFSC', value: 'ICICI00002240',error:"" },
    { name: 'Account holder name', value: 'Sumit Mathur',error:"" },

    { name: 'Type of Account', value: 'Saving Account',error:"" },
    { name: 'Bank Name', value: 'ICICI Bank',error:"" },
    { name: 'Branch Name', value: 'Gurgaon',error:"" },

  ]);
  const [Loading,setLoading] = useState(false);



 
  const handleInputChangeUser = (index, newValue) => {
    let updatedDetails = [...userDetails];

    if(updatedDetails[index]?.name==="State"){
      let updatedBasicDetailCompany = [...userDetails];
      setStateId(newValue);
      updatedDetails[index].value = newValue;
      updatedBasicDetailCompany[2].error = "Please select city";
      updatedBasicDetailCompany[2].value = "select city";
      
      // Update the state with the modified array
      setUserDetails(updatedBasicDetailCompany);
    }else{
      let updatedBasicDetailCompany = [...userDetails];
      updatedBasicDetailCompany[2].error = "";
      // updatedBasicDetailCompany[2].value = newValue;
      updatedDetails[index].value = newValue;
      setUserDetails(updatedDetails);
    }


  };
  const handleInputChangeExperience = (index, newValue) => {


    const updatedExperienceDetails = [...userExperienceDetails];
    updatedExperienceDetails[index].value = newValue;
    setUserExperienceDetails(updatedExperienceDetails);

  };

  const handleInputChangeBank = (index, newValue) => {



    const updatedBankDetails = [...userBankDetails];
    updatedBankDetails[index].value = newValue;
    setUserBankDetails(updatedBankDetails);

  };

  const handleInputChangeEducation = (index, newValue) => {
   

    const updatedEducationDetails = [...userEducationDetails];
    updatedEducationDetails[index].value = newValue;
    setUserEducationDetails(updatedEducationDetails);
  };


  
  const deleteTag = (index) => {

    const updatedItems = [...savedItems];
    updatedItems.splice(index, 1);
    setSavedItems(updatedItems);
    sessionStorage.setItem("selectedItems", JSON.stringify(updatedItems));
  };

  const deleteTagCore = (index) => {
    const updatedItemsCore = [...savedItemsCore];
    updatedItemsCore.splice(index, 1);
    setSavedItemsCore(updatedItemsCore);
    sessionStorage.setItem("CoreExpertise", JSON.stringify(updatedItemsCore));
  };

  // Generate tags
  const tags = savedItems?.map((item, index) => (
    <div
      key={index}
      className={BankerTagStyle}
    >
      {keyAreaServicesData?.filter((items)=>items?.id===item)[0]?.name || keyAreaServicesData?.filter((items)=>items?.id===item?.id)[0]?.name}{" "}
      {!show && (
        <svg
          onClick={() => deleteTag(index)}
          className="mt-1 ml-2"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.30439 3.80439C2.89854 4.21025 2.89854 4.86826 3.30439 5.27412L7.03027 8.99998L3.30439 12.7259C2.89854 13.1317 2.89854 13.7898 3.30439 14.1956C3.71024 14.6015 4.36827 14.6015 4.77412 14.1956L8.49998 10.4697L12.2259 14.1956C12.6317 14.6015 13.2898 14.6015 13.6956 14.1956C14.1015 13.7898 14.1015 13.1317 13.6956 12.7259L9.96969 8.99998L13.6956 5.27413C14.1015 4.86828 14.1015 4.21026 13.6956 3.80441C13.2897 3.39855 12.6317 3.39855 12.2259 3.80441L8.49998 7.53026L4.77412 3.80439C4.36827 3.39854 3.71024 3.39854 3.30439 3.80439Z"
            fill="#2C2C2C"
          />
        </svg>
      )}
    </div>
  ));

  const tagsCore = savedItemsCore?.map((item, index) => (
    <div
      key={index}
      className={BankerTagStyle}
    >
     
      {coreExpertiseData?.filter((items)=>items?.id===item)[0]?.name || coreExpertiseData?.filter((items)=>items?.id===item?.id)[0]?.name}{" "}
      {!showCore && (
        <svg
          onClick={() => deleteTagCore(index)}
          className="mt-1 ml-2"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.30439 3.80439C2.89854 4.21025 2.89854 4.86826 3.30439 5.27412L7.03027 8.99998L3.30439 12.7259C2.89854 13.1317 2.89854 13.7898 3.30439 14.1956C3.71024 14.6015 4.36827 14.6015 4.77412 14.1956L8.49998 10.4697L12.2259 14.1956C12.6317 14.6015 13.2898 14.6015 13.6956 14.1956C14.1015 13.7898 14.1015 13.1317 13.6956 12.7259L9.96969 8.99998L13.6956 5.27413C14.1015 4.86828 14.1015 4.21026 13.6956 3.80441C13.2897 3.39855 12.6317 3.39855 12.2259 3.80441L8.49998 7.53026L4.77412 3.80439C4.36827 3.39854 3.71024 3.39854 3.30439 3.80439Z"
            fill="#2C2C2C"
          />
        </svg>
      )}
    </div>
  ));

  // Load saved items from session storage when value of refresh changes
  useEffect(() => {
      const storedItems = JSON.parse(sessionStorage.getItem("selectedItems"))
     const storedItemsCore = JSON.parse(sessionStorage.getItem("CoreExpertise"));
    setSavedItems(storedItems);
    setSavedItemsCore(storedItemsCore);
    setFormData(prevFormData => ({
      ...prevFormData,
      keyAreaServices: savedItems,
      coreExpertise: savedItemsCore
    }));
  }, [refresh]);

  useEffect(() => {
    setFormData({ ...formData, coreExpertise: savedItemsCore,keyAreaServices: savedItems });
  }, [savedItemsCore,savedItems]);


  useEffect(() => {
    // setLoading(true);
 
    
    const storedItems = JSON.parse(sessionStorage.getItem("selectedItems")) || [];
    const storedItemsCore =
      JSON.parse(sessionStorage.getItem("CoreExpertise")) || [];




    // setSelectedOption(consultancyMode);
    setSavedItems(storedItems);
    setSavedItemsCore(storedItemsCore);
    getKeyAreaServices(setKeyAreaServicesData);
    getCoreExpertise(setCoreExpertiseData);
  
    setFormData({
      keyAreaServices: storedItems, coreExpertise: storedItemsCore,
     });
    // setLoading(false);
  }, []);



  const handleInputNameChange = (newValue) => {
    const updatedDetailName = [...UserName];
    updatedDetailName.value = newValue;
    setUserName(updatedDetailName);
  };

  const updateName = () => {
    if (!UserName || UserName === "") {
      SetEditNameIcon(!EditNameIcon);
      return;
    }
    let payload = { "bk_user": {"name": UserName} }
    updateBankerDetails(payload, token);
    SetEditNameIcon(!EditNameIcon);
  }

  const ModeOfHire=userDetails?.filter((item)=>item?.name==="Availability for Hire")[0]?.value;
    
  const handleBasicDetails=() => {
    const ModeOfConsultancy=userDetails?.filter((item)=>item?.name==="Mode of Consultancy")[0]?.value;
    const ModeOfHire=userDetails?.filter((item)=>item?.name==="Availability for Hire")[0]?.value;
    const CityId=cityMasterData?.data?.filter((item)=>item.city===userDetails?.filter((item)=>item?.name==="City")[0]?.value)[0]?.id || 0
    const MT=typeof ModeOfConsultancy=='number' ? ModeOfConsultancy : ModeOfConsultancy==='Virtual' ? 1 : ModeOfConsultancy==='in-person' ? 2 :3;
    
    const obj={
      dob:userDetails?.filter((item)=>item?.name==="DOB")[0]?.value,
      city:typeof userDetails?.filter((item)=>item?.name==="City")[0]?.value=="number" ? userDetails?.filter((item)=>item?.name==="City")[0]?.value :getBankerData?.data?.city,
      state:typeof userDetails?.filter((item)=>item?.name==="State")[0]?.value=="number" ? userDetails?.filter((item)=>item?.name==="State")[0]?.value :getBankerData?.data?.state,
      master_consultancy_mode:MT,
      master_tenure:typeof ModeOfHire==='number' ? ModeOfHire :ModeOfHire==='Short Term (Upto 3 months)' ? 1 : ModeOfHire==='Medium Term (Upto 6 months)' ? 2 :3,
      current_address:userDetails?.filter((item)=>item?.name==="Current Address")[0]?.value,
      permanent_address:userDetails?.filter((item)=>item?.name==="Permanent Address")[0]?.value,
    }
    if(obj?.city){
    setLoading(true)
    updateBankerDetails(obj, token);
    SetEditBasicIcon(!EditBasicIcon);
    }

    
    }


  const updateBankAcDetails = () => {
    let hasError = false;
    let tempPayload = {}
    for(let i=0;i<userBankDetails.length;++i) {
      if (userBankDetails[i]["name"] === "Account number" && userBankDetails[i]["value"] !== "") {
        tempPayload["account_number"] = userBankDetails[i]["value"];
      }
      if (userBankDetails[i]["name"] === "Bank IFSC" && userBankDetails[i]["value"] !== "") {
        tempPayload["ifsc_code"] = userBankDetails[i]["value"];
        if(!validateIFSC(userBankDetails[i]["value"])){
          userBankDetails[i]["error"] = "Invalid IFSC code"
          hasError = true; // Set error flag
          // return
        }
      }
      if (userBankDetails[i]["name"] === "Account holder name" && userBankDetails[i]["value"] !== "") {
        tempPayload["holder_name"] = userBankDetails[i]["value"];
      }
      if (userBankDetails[i]["name"] === "Type of Account" && userBankDetails[i]["value"] !== "") {
        tempPayload["account_type"] = userBankDetails[i]["value"];
      }
      if (userBankDetails[i]["name"] === "Bank Name" && userBankDetails[i]["value"] !== "") {
        tempPayload["bank_name"] = userBankDetails[i]["value"];
      }
      if (userBankDetails[i]["name"] === "Branch Name" && userBankDetails[i]["value"] !== "") {
        tempPayload["bank_branch_name"] = userBankDetails[i]["value"];
      }
    }

    if (Object.keys(tempPayload).length === 0) {
      SetEditBankIcon(!EditBankIcon)
      return;
    }

    let payload = {"bank": tempPayload}
    if(!hasError){
      updateBankerDetails(payload, token);
      SetEditBankIcon(!EditBankIcon)
    }

  }

  const handleSaveKeyServices = (selectedItems) => {
    let keyServiceIds = selectedItems?.map((item) => item.id);
    let payload = {"key_service": keyServiceIds}
    updateBankerDetails(payload, token);
  };

  const handleSaveCoreExpertise = (selectedItems) => {
    let coreExpertiseIds = selectedItems?.map((item) => item.id);;
    let payload = {"core_expertise": coreExpertiseIds}
    updateBankerDetails(payload, token);
  };
  const handleExperience=()=>{
    function convertYearsToNumber(input) {
      // Extract the numeric part from the input string
      const match = input.match(/[\d.]+/);
      
      // If a match is found, parse it as a float number
      if (match) {
        const number = parseFloat(match[0]);
        // Ensure the number is always returned with one decimal point
        return number.toFixed(1);
      }
      
      // If no numeric part is found, return 0.0
      return "0.0";
    }
    const DesignationId=userExperienceDetails?.filter((item)=>item?.name==="Last Designation")[0]?.value;
    let payload ={
      bank_name: userExperienceDetails?.filter((item)=>item?.name==="Last company")[0]?.value,
      designation:typeof DesignationId=='number' ? DesignationId : getDesignationData?.data?.filter((item)=>item?.name===userExperienceDetails?.filter((item)=>item?.name==="Last Designation")[0]?.value)[0]?.id,
      banking_experience:convertYearsToNumber(userExperienceDetails?.filter((item)=>item?.name==="Banking Experience")[0]?.value),
      retirement_date:userExperienceDetails?.filter((item)=>item?.name==="Date of retirement")[0]?.value,
    }
    setLoading(true);
    updateBankerDetails(payload, token);
    SetEditExperinceIcon(!EditExperinceIcon)
  }
  const handleEducation=()=>{
    const Id=edMasterData?.data?.filter((item)=>item?.id===userEducationDetails?.filter((item)=>item?.name==="Level")[0]?.value)[0]?.id;
  const DegreeData=userEducationDetails[1]?.value;
  let payload ={}
  if(Id<3){
     payload ={
      master_education:Id,

    }
  }else{
    payload ={
      master_education:Id,
     institute_name:DegreeData,
    }
  }
   
    setLoading(true);
    updateBankerDetails(payload, token);
    SetEditEducationIcon(!EditEducationIcon)
  }
  useEffect(() => {
    if (updatedData) {
      ToastMessage("success","Profile updated successfully");
      refetch();
    }
    setLoading(false);
    refetch()
  }, [updatedData,updateError]);
  useEffect(() => {
    setProfileData(data?.data);
  }, [data,data?.data]);
   useEffect(() => {
    let newUserDetails = [...userDetails];
    let newUserExperienceDetails = [...userExperienceDetails];
    let newEducationDetails = [...userEducationDetails];
    let newBankAccountDetails = [...userBankDetails];
    setUserName(profileData?.user?.name)
    if(profileData?.user?.name) {
      const [firstname, lastname] = profileData?.user?.name?.split(" ");
      const newInitials = `${firstname ? firstname[0]:"Not Available"}${lastname ? lastname[0] : ""}`;
      setInitials(newInitials);
    }
    if(profileData?.profile_image){
      setImageData(`${apiHost}/${profileData?.profile_image}`);
    }

    for(let i=0;i<newUserDetails.length;++i) {
      if(newUserDetails[i]["name"] === "DOB") {
        newUserDetails[i]["value"] = profileData?.dob || profileData?.dob ? profileData?.dob : "Not Available";
      }
      if(newUserDetails[i]["name"] === "City") {
        newUserDetails[i]["value"] = profileData?.city ? cityMasterData?.data?.filter((item) => item?.id === profileData?.city)[0]?.city : "Not Available";
        setUserDetails(newUserDetails);
      }
      if(newUserDetails[i]["name"] === "State") {
        newUserDetails[i]["value"] = profileData?.state ? stateMasterData?.data?.filter((item) => item?.id === profileData?.state)[0]?.name : "Not Available";
        setStateId(profileData?.state)
        // setUserDetails(newUserDetails);
      }
      if(newUserDetails[i]["name"] === "Email ID") {
        newUserDetails[i]["value"] = profileData?.user?.email ? profileData?.user?.email : "Not Available";
      }
      if(newUserDetails[i]["name"] === "Availability for Hire") {
        newUserDetails[i]["value"] = profileData?.tenure?.name ? profileData?.tenure?.name : "Not Available";
        setUserDetails(newUserDetails);
      }
      if(newUserDetails[i]["name"] === "Phone Number") {
        newUserDetails[i]["value"] = profileData?.user?.phone_number ? `+91-${profileData?.user?.phone_number}` : "Not Available";
      }
      if(newUserDetails[i]["name"] === "Mode of Consultancy") {
        newUserDetails[i]["value"] = profileData?.consultancy_mode?.name ? profileData?.consultancy_mode?.name : "Not Available";
        setUserDetails(newUserDetails);
      }
      if(newUserDetails[i]["name"] === "Current Address") {
        newUserDetails[i]["value"] = profileData?.current_address ? profileData?.current_address : "Not Available";
      }
      if(newUserDetails[i]["name"] === "Permanent Address") {
        newUserDetails[i]["value"] = profileData?.permanent_address ? profileData?.permanent_address : "Not Available";
      }
    }
    for(let i=0;i<newUserExperienceDetails.length;++i) {
      if(newUserExperienceDetails[i]["name"] === "Last company") {
        newUserExperienceDetails[i]["value"] = profileData?.bank_name ? profileData?.bank_name : "Not Available";
      }
      if(newUserExperienceDetails[i]["name"] === "Date of retirement") {
        newUserExperienceDetails[i]["value"] = profileData?.retirement_date ? profileData?.retirement_date : "Not Available";
      }
      if(newUserExperienceDetails[i]["name"] === "Last Designation") {
        newUserExperienceDetails[i]["value"] = profileData?.designation?.name ? profileData?.designation?.name : "Not Available";
      }
      if(newUserExperienceDetails[i]["name"] === "Banking Experience") {
        newUserExperienceDetails[i]["value"] = profileData?.banking_experience ? `${profileData?.banking_experience} year(s)` : "Not Available";
      }
    }
    for(let i=0;i<newEducationDetails.length;++i) {
      if(newEducationDetails[i]["name"] === "Level") {
        newEducationDetails[i]["value"] = profileData?.education?.name ? profileData?.education?.name : "Not Available";
      }
      if(newEducationDetails[i]["name"] === "Degree") {
        newEducationDetails[i]["value"] = profileData?.institute_name ? profileData?.institute_name : "Not Available";
      }
    }
    for(let i=0;i<newBankAccountDetails.length;++i) {
      if(newBankAccountDetails[i]["name"] === "Account number") {
        newBankAccountDetails[i]["value"] = profileData?.bank?.account_number ? profileData?.bank?.account_number : "Not Available";
      }
      if(newBankAccountDetails[i]["name"] === "Bank IFSC") {
        newBankAccountDetails[i]["value"] = profileData?.bank?.ifsc_code ? profileData?.bank?.ifsc_code : "Not Available";
      }
      if(newBankAccountDetails[i]["name"] === "Account holder name") {
        newBankAccountDetails[i]["value"] = profileData?.bank?.holder_name ? profileData?.bank?.holder_name : "Not Available";
      }
      if(newBankAccountDetails[i]["name"] === "Type of Account") {
        newBankAccountDetails[i]["value"] = profileData?.bank?.account_type ? profileData?.bank?.account_type : "Not Available";
      }
      if(newBankAccountDetails[i]["name"] === "Bank Name") {
        newBankAccountDetails[i]["value"] = profileData?.bank?.account_type ? profileData?.bank?.bank_name : "Not Available";
      }
      if(newBankAccountDetails[i]["name"] === "Branch Name") {
        newBankAccountDetails[i]["value"] = profileData?.bank?.account_type ? profileData?.bank?.bank_branch_name : "Not Available";
      }
    }
  }, [profileData,data?.data]);
  useEffect(()=>{
    if(data?.data?.key_service?.length>0) {
      setSavedItems(data?.data?.key_service)
      sessionStorage.setItem(
        "CoreExpertise",
        JSON.stringify(data?.data?.core_expertise?.map((item) => item.id))
      );
      sessionStorage.setItem(
        "selectedItems",
        JSON.stringify(data?.data?.key_service?.map((item) => item.id))
      );
    }
    if(data?.data?.core_expertise?.length>0) {
      setSavedItemsCore(data?.data?.core_expertise)
    }
  },[data?.data])

  function formatToIndianCurrency(amount) {
    return new Intl.NumberFormat('en-IN', {
      maximumFractionDigits: 2
    }).format(amount);
  }
  const handleServices=()=>{
    setShow(true)
    const selectedId=JSON.parse(sessionStorage?.getItem("selectedItems")) || [];
    const obj={
      key_service:selectedId
    }
    updateBankerDetails(obj,token)
  }
  const handleCoreExpertise=()=>{
    setShowCore(true)
    const selectedId=JSON.parse(sessionStorage?.getItem("CoreExpertise")) || [];
    const obj={
      key_service:selectedId
    }
    updateBankerDetails(obj,token)
  }
  // Example usage:
  const payout = profileData?.payout; // Assuming payout is a number
  const formattedPayout = formatToIndianCurrency(payout);

  return (
    <div>
      {(loading || Loading) && <LoaderAPI />}
      <Navbar hemBurgerIcon={true} BankerNavbar={true} ProfileAndNotification="true" />
      <div className='flex items-start'>
      <div className="lg:w-[7%] md:-[7%] w-0">

          <BankerSideNavbar />
        </div>
        {/* <div className='w-[93%] p-8'> */}
        <div className= "w-[100%] lg:w-[93%] px-3 md:px-8 lg:px-10 relative">

          <div className='sticky-profile'>
            <h4 className='h4-text pt-4 text-start text-[#2F3340]'>My Profile</h4>
          </div>
          <div className={`border relative w-full my-8 p-4 md:grid md:grid-cols-2 rounded-lg ${isEditing && EditNameIcon ? 'opacity-[30%]' : ''}`}>
            <div className='flex items-center gap-4'>
              <div className={`relative ${isEditing && EditNameIcon ? '' : 'mt-8'}`} >
             
        <UploadProfileModal EditNameIcon={!EditNameIcon} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto}  onClose={() => setIsModalOpen(false)} onImageSelect={handleImageSelect} />
              </div>
              <div className='w-full mt-8 md:w-[80%] text-start'>
                
                {EditNameIcon ? <h5 className='h5-text'>{profileData?.user?.name || "Not Available"}</h5> : <div className='w-full'> <InputField onChange={(e) =>setUserName(e.target.value)} label="User Name"
                  value={UserName} />
                  </div>
                  
                  }
                  <div>
                    <p className='max-w-max bg-[#EFF0F3] text-[#62636C] body-text px-4 py-2 mt-2 rounded-[24.5px]'>
                    Your Salary - ₹ { formattedPayout || "Not available"}/month
                    </p>
                  </div>
              </div>
            </div>
            <div className='flex justify-end items-start gap-4'>
              {EditNameIcon ? <PencilIcon onClick={() => SetEditNameIcon(!EditNameIcon)} /> : <Button onClick={() => updateName()} className="redfolicbg py-2 px-5 rounded-lg">Save Changes</Button>}
            </div>
          </div>

          
          <div className={`border w-full my-8 p-4 rounded-lg ${isEditing && EditBasicIcon ? 'opacity-[30%]' : ''}`}>
            <div className='grid grid-cols-2'>
              <div className='flex items-center gap-4'>
                <h6 className='h6-text'>Basic Details</h6>
              </div>
              <div className='flex justify-end items-start gap-4'>
                {EditBasicIcon ? <PencilIcon onClick={() => SetEditBasicIcon(!EditBasicIcon)} /> : <Button onClick={handleBasicDetails} className="redfolicbg py-2 px-5 rounded-lg">Save Changes</Button>}
              </div>
            </div>
            <div className={`grid grid-cols-1 gap-[3rem] md:grid md:grid-cols-2  mb-4 md:w-[90%] mt-8 ${!EditBasicIcon ? 'gap-10' : 'gap-7'}`}>
              {userDetails?.map((item, index) => (
                // <DetailField key={index} item={item} EditBasicIcon={EditBasicIcon} />
                <div className='grid relative grid-cols-2 text-start'>
                <div className='flex justify-between items-center'>
                  <p className='body-text text-[#80828D] '>{item?.name}</p>
                  <div className='mr-[40%]'>-</div>
                </div>
                <div className='absolute w-[65%] top-1 left-[35%] text-start flex justify-start items-center'>
                  {EditBasicIcon ? (
                    <p className='notification-span text-[#2C2C2C]'>{item?.name ==='DOB' ? convertDate(item?.value):item?.value}</p>
                  ) : (
                    <div className='-mt-6 w-full'>
                      {(item.name === 'Vintage Grid' || item.name === 'City' || item.name === 'Turnover Grid' || item.name === 'State' || item.name === 'Select Service' || item.name==='Availability for Hire' || item.name==='Mode of Consultancy') ? (
                        <div className='mt-2 w-full md:w-[16rem]'>
                          <SelectTagComponent defaultVal={item?.value} options={item.name === 'Vintage Grid' ? (
                          vintageMasterData?.data) : item.name === 'Turnover Grid' ? (
                          turnoverMasterData?.data) : item.name === 'City' ? cityMasterData?.data : item.name === 'State' ? (
                            stateMasterData?.data
                          ): item.name === 'Availability for Hire' ? avalmodeMasterData?.data: item.name === 'Mode of Consultancy' ? consultmodeMasterData?.data : [] }
                          onChange={(e)=>handleInputChangeUser(index,e?.id)}
                          error={item?.error}
                          /></div>
                      ) : (
                        <div className=' w-full md:w-[16rem]'>
                        <InputField disabled={item?.name==='Email ID' || item?.name==='Phone Number'} type={item?.name ==='DOB' ? 'date' : 'text'} MaxDate={item?.name === 'DOB' ? calculateMinDOBFor18YearsOld(new Date()) : null} value={item?.name ==='DOB'  ? item?.value :item?.value} onChange={(e) => handleInputChangeUser(index, e.target.value)} />

                        </div>
)}
                    </div>
                  )}
                </div>
              </div>
              ))}
            </div>
          </div>
          <div className={`border w-full my-8 p-4 rounded-lg ${isEditing && EditExperinceIcon ? 'opacity-[30%]' : ''}`}>
            <div className='grid grid-cols-2'>
              <div className='flex items-center gap-4'>
                <h6 className='h6-text'>Experience</h6>
              </div>
              <div className='flex justify-end items-start gap-4'>
                {EditExperinceIcon ? <PencilIcon onClick={() => SetEditExperinceIcon(!EditExperinceIcon)} /> : <Button onClick={handleExperience} className="redfolicbg py-2 px-5 rounded-lg">Save Changes</Button>}
              </div>
            </div>
            <div className={`grid grid-cols-1 gap-10 md:grid md:grid-cols-2  mb-4 md:w-[90%] mt-8 ${!EditExperinceIcon ? 'gap-10' : 'gap-7'}`}>
              {userExperienceDetails?.map((item, index) => (
                // <DetailField key={index} item={item} EditBasicIcon={EditExperinceIcon} />
                <div className='grid relative grid-cols-2 text-start'>
                <div className='flex justify-between items-center'>
                  <p className='body-text text-[#80828D] '>{item?.name}</p>
                  <div className='mr-[40%]'>-</div>
                </div>
                <div className='absolute w-[65%] top-1  left-[35%] text-start flex justify-start items-center'>
                  {EditExperinceIcon ? (
                    <p className='notification-span text-[#2C2C2C]'>{item?.name ==='Date of retirement' ? convertDate(item?.value) :item?.value}</p>
                  ) : (
                    <div className='-mt-6 w-full'>
                      {(item.name === 'Vintage Grid' || item.name === 'Turnover Grid' || item.name === 'state' || item.name === 'Select Service' || item.name==='Availability for Hire' || item.name==='Last Designation' || item.name==='Mode of Consultancy') ? (
                        <div className='mt-2 w-full md:w-[16rem]'><SelectTagComponent defaultVal={item?.value} options={item.name === 'Vintage Grid' ? (
                          vintageMasterData?.data) : item.name === 'Turnover Grid' ? (
                          turnoverMasterData?.data) : item.name === 'state' ? (
                            stateMasterData?.data
                          ): item.name === 'Availability for Hire' ? avalmodeMasterData?.data: item.name === 'Mode of Consultancy' ? consultmodeMasterData?.data : item?.name==='Last Designation' ? getDesignationData?.data : [] }
                          onChange={(e)=>handleInputChangeExperience(index,e?.id)}
                          /></div>
                      ) : (
                        <div className='w-full md:w-[16rem]'>
                        <InputField type={item?.name=='Banking Experience' ? 'number': item?.name==='Date of retirement' ? 'date':'text'}
                           onkeyDown={
                            item?.name=='Banking Experience' ? (e)=>handleTwoDigitInput(e):null }
                         MaxDate={item?.name==='Date of retirement' ? new Date().toISOString().split('T')[0] : null}
                        value={item?.value} onChange={(e) => handleInputChangeExperience(index, e.target.value)}/>

                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              ))}
            </div>
          </div>
          <div className={`border w-full my-8 p-4 rounded-lg ${isEditing && EditEducationIcon ? 'opacity-[30%]' : ''}`}>
            <div className='grid grid-cols-2'>
              <div className='flex items-center gap-4'>
                <h6 className='h6-text'>Education</h6>
              </div>
              <div className='flex justify-end items-start gap-4'>
                {EditEducationIcon ? <PencilIcon onClick={() => SetEditEducationIcon(!EditEducationIcon)} /> : <Button onClick={handleEducation} className="redfolicbg py-2 px-5 rounded-lg">Save Changes</Button>}
              </div>
            </div>
            <div className={`grid grid-cols-1 gap-10 md:grid md:grid-cols-2  mb-4 md:w-[90%] mt-8 ${!EditEducationIcon ? 'gap-10' : 'gap-7'}`}>
              {userEducationDetails?.map((item, index) => (
                // <DetailField key={index} item={item} EditBasicIcon={EditEducationIcon} />
                <div className='grid relative grid-cols-2 text-start'>
                <div className='flex justify-between items-center'>
                  <p className='body-text text-[#80828D] '>{item?.name}</p>
                  <div className='mr-[40%]'>-</div>
                </div>
                <div className='absolute w-[65%] top-1 left-[35%] text-start flex justify-start items-center'>
                  {EditEducationIcon ? (
                    <p className='notification-span text-[#2C2C2C]'>{item?.value}</p>
                  ) : (
                    <div className='-mt-6 w-full'>
                      {(item.name === 'Vintage Grid' || item.name === 'Turnover Grid' || item.name === 'State' || item.name === 'Select Service' || item.name==='Availability for Hire' || item.name==='Mode of Consultancy' || item.name==='Level') ? (
                        <div className='mt-2 w-full md:w-[16rem]'>
                          <SelectTagComponent defaultVal={item?.value} options={item.name === 'Vintage Grid' ? (
                            vintageMasterData?.data) : item.name === 'Turnover Grid' ? (
                            turnoverMasterData?.data) : item.name === 'State' ? (
                              stateMasterData?.data
                            ): item.name === 'Availability for Hire' ? avalmodeMasterData?.data: item.name === 'Mode of Consultancy' ? consultmodeMasterData?.data : item.name === 'Level' ? edMasterData?.data : [] }
                            onChange={(e) => handleInputChangeEducation(index, e?.id)}
                            
                            />
                        </div>
                      ) : (
                        <div className='w-full md:w-[16rem]'>
                        <InputField value={item?.value} onChange={(e) => handleInputChangeEducation(index, e.target.value)}
                        onkeyDown={(e) =>preventNumbersAndSpecialChars(e)}
                        />

                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              ))}
            </div>
          </div>
          {/* <div className={`border w-full my-8 p-4 rounded-lg ${isEditing && EditKeyServiceIcon ? 'opacity-[30%]' : ''}`}>
            <div className='grid grid-cols-2'>
              <div className='flex items-center gap-4'>
                <h6 className='h6-text'>Key Area of Services</h6>
              </div>
              <div className='flex justify-end items-start gap-4'>
                {EditKeyServiceIcon ? <PencilIcon onClick={() => SetEditKeyServiceIcon(!EditKeyServiceIcon)} /> : <Button onClick={() => SetEditKeyServiceIcon(!EditKeyServiceIcon)} className="redfolicbg py-2 px-5 rounded-lg">Save Changes</Button>}
              </div>
            </div>
          </div> */}

<div className={`border w-full  p-4 rounded-lg ${isEditing && EditKeyServiceIcon ? 'opacity-[30%]' : ''}`}>
<div className={BankerStep2div}>
          <div className="h6-text">Key Area of services*</div>
          {show || savedItems?.length === 0 ? (
            <div className={BankerStep2divSaveIem}>
              <div
                onClick={() => setShow(false)}
                className={` ${
                  savedItems?.length === 0 ? "invisible" : "visible"
                } `}
              >
                <svg
                  className="-mt-1 mr-2"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.151 25L25 12.151L19.849 7L7 19.8491V25H12.151ZM19.849 9.2714L22.7286 12.151L20.652 14.2276L17.7725 11.348L19.849 9.2714ZM16.6367 12.4837L19.5164 15.3633L11.4857 23.3939H8.60612V20.5143L16.6367 12.4837Z"
                    fill="#2C2C2C"
                  />
                </svg>
              </div>

              <ExpertiseModal
                data={keyAreaServicesData}
                storageKey="selectedItems"
                modalTitle="Key Area of services"
                btnHandle={handleSaveKeyServices}
                setRefresh={setRefresh}
              />
            </div>
          ) : (
            <div onClick={handleServices} className={FlexJustEnd}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33325 15.9994L11.9329 22.599L26.0762 8.45703"
                  stroke="#1EB469"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            
          )}
        </div>
        <div className={BankerStep2divKeyServ}>
          {errors?.keyAreaServices && savedItems?.length === 0
            ? <p className="primary-text-red text-sm ml-auto">{errors?.keyAreaServices}</p> 
            : tags}
        </div>
</div>




          {/* <div className={`border w-full my-8 p-4 rounded-lg ${isEditing && EditCoreIcon ? 'opacity-[30%]' : ''}`}>
            <div className='grid grid-cols-2'>
              <div className='flex items-center gap-4'>
                <h6 className='h6-text'>Core Expertise</h6>
              </div>
              <div className='flex justify-end items-start gap-4'>
                {EditCoreIcon ? <PencilIcon onClick={() => SetEditCoreIcon(!EditCoreIcon)} /> : <Button onClick={() => SetEditCoreIcon(!EditCoreIcon)} className="redfolicbg py-2 px-5 rounded-lg">Save Changes</Button>}
              </div>
            </div>
          </div> */}



<div className={`border w-full my-8 p-4 rounded-lg ${isEditing && EditCoreIcon ? 'opacity-[30%]' : ''}`}>

          <div className={BankerStep2div}>
          <div className="h6-text">Core Expertise*</div>
          {showCore || savedItemsCore?.length === 0 ? (
            <div className={BankerStep2divSaveIem}>
              <div
                onClick={() => setShowCore(false)}
                className={` ${
                  savedItemsCore?.length === 0 ? "invisible" : "visible"
                } `}
              >
                <svg
                  className="-mt-1 mr-2"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.151 25L25 12.151L19.849 7L7 19.8491V25H12.151ZM19.849 9.2714L22.7286 12.151L20.652 14.2276L17.7725 11.348L19.849 9.2714ZM16.6367 12.4837L19.5164 15.3633L11.4857 23.3939H8.60612V20.5143L16.6367 12.4837Z"
                    fill="#2C2C2C"
                  />
                </svg>
              </div>

              <ExpertiseModal
              ShowAllAbove={true}
                data={coreExpertiseData}
                storageKey="CoreExpertise"
                modalTitle="Core Expertise*"
                btnHandle={handleSaveCoreExpertise}
                setRefresh={setRefresh}
              />
            </div>
          ) : (
            <div onClick={handleCoreExpertise} className="flex justify-end">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33325 15.9994L11.9329 22.599L26.0762 8.45703"
                  stroke="#1EB469"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
        </div>

        <div className={`${BankerStep2divCoreError} text-red`}>
          {errors?.coreExpertise && savedItemsCore.length === 0 ? <p className="primary-text-red text-sm ml-auto">{errors.coreExpertise}</p> : tagsCore}
        </div>
          </div>
          <div className={`border w-full my-8 p-4 rounded-lg ${isEditing && EditBankIcon ? 'opacity-[30%]' : ''}`}>
            <div className='grid grid-cols-2'>
              <div className='flex items-center gap-4'>
                <h6 className='h6-text'>Bank Account Details</h6>
              </div>
              <div className='flex justify-end items-start gap-4'>
                {EditBankIcon ? <PencilIcon onClick={() => SetEditBankIcon(!EditBankIcon)} /> : <Button onClick={() => updateBankAcDetails()} className="redfolicbg py-2 px-5 rounded-lg">Save Changes</Button>}
              </div>
            </div>  
            <div className={`grid grid-cols-1 gap-10 md:grid md:grid-cols-2  mb-4 md:w-[90%] mt-8 ${!EditBankIcon ? 'gap-10' : 'gap-7'}`}>
              {userBankDetails?.map((item, index) => (
                // <DetailField key={index} item={item} EditBasicIcon={EditBankIcon} />
                <div className='grid relative grid-cols-2 text-start'>
                <div className='flex justify-between items-center'>
                  <p className='body-text text-[#80828D] '>{item?.name}</p>
                  <div className='mr-[40%]'>-</div>
                </div>
                <div className='absolute w-[65%] top-1 left-[35%] text-start flex justify-start items-center'>
                  {EditBankIcon ? (
                    <p className='notification-span text-[#2C2C2C]'>{item?.value}</p>
                  ) : (
                    <div className='-mt-6 w-full'>
                      {(item.name === 'Vintage Grid' || item.name === 'Turnover Grid' || item.name === 'State' || item.name === 'Select Service' || item.name==='Availability for Hire' || item.name==='Mode of Consultancy') ? (
                        <div className='mt-2 w-full md:w-[16rem]'>
                          <SelectTagComponent defaultVal={item?.value} options={item.name === 'Vintage Grid' ? vintageMasterData?.data : 
                          item.name === 'Turnover Grid' ? turnoverMasterData?.data : item.name === 'State' ? stateMasterData?.data :
                          item.name === 'Availability for Hire' ? avalmodeMasterData?.data : item.name === 'Mode of Consultancy' ? consultmodeMasterData?.data :
                          []}/>
                        </div>
                      ) : (
                        <div className='w-full md:w-[16rem]'>
                        <InputField 
                        onkeyDown={item?.name==='Account number' ? (e) => handleNonNumericInput(e) :item?.name==='Account holder name' ? (e) => preventNumbersAndSpecialChars(e) : item?.name==='Bank Name' ? (e) => handleAlphabeticInput(e) : item?.name==='Branch Name' ? (e) => handleAlphabeticInput(e) :item?.name==='Type of Account' ? (e)=>handleAlphabeticInput(e) : null} 
                        error={item?.error} value={item?.value} onChange={(e) => handleInputChangeBank(index, e.target.value)} />

                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              ))}
            </div>
          </div>

          <div className='h-[5rem]'>

          </div>
        </div>
      </div>
    </div>
  );
};

export default BankerMyProfile;
