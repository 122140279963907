import { useEffect, useState } from 'react';
import { CrossIcon, CrossIcon1, GrayStarIcon, YellowStartIcon } from './Icons';
import usePost from '../Hook/usePost';
import { ToastMessage } from './Config';
import useGetData from '../Hook/useGetData';
import { FeedbackModelStyle1, FeedbackModelStyle2, FeedbackModelStyle3, FeedbackModelStyle4, FeedbackModelStyle5, FeedbackModelStyle6, FlexJustiEnd } from './StyleCommon';
import LoaderAPI from './LoaderAPI';

const FeedbackModal = ({dataSet,setMessage,setShowNotification,data, onClose,milestone,User,type,fetchAgain }) => {
    const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: FeedBackData,loading:FeedBackLoading, 
    error: FeedBackError, postData: postFeedBackData } = usePost(`hiring/feedback-banker`);
    const { data: BankerFeedBackData,loading:BankerFeedBackLoading, 
        error: BankerFeedBackError, postData: postBankerFeedBackData } = usePost(`hiring/submit-user-feedbackss`);
        const { data: CompanyFeedBackData,loading:CompanyFeedBackLoading, 
            error: CompanyFeedBackError, postData: postCompanyFeedBackData } = usePost(`hiring/submit-feedback-company`);
            const { data: feedBackData, loading: feedBackLoading, error: feedBackError } = useGetData(`hiring/getprojectmilestonefeedback/${milestone?.id}`,token);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [error,setError]=useState("");
  const handleRatingClick = (rate) => {
      setRating(rate);
  };

  const handleSubmit = () => {
    if(rating===0 && feedback===""){
setError("Your feedback matters, Please type it above")
    }else{
        setError("")

        if(milestone){
          const obj={
              "banker_rating": rating,
              "banker_feedback_comment": feedback,
              "company_user":User,
              "milestone":milestone?.id
                }
                postFeedBackData(obj,token);
        }
        if(type==="Banker"){
       const obj={
          feedback:feedback,
          rating:rating,
       }
       postBankerFeedBackData(obj,token)
        }
        if(type==="Company"){
          const obj={
              banker_feedback_comment:feedback,
              rating:rating,
           }
          postCompanyFeedBackData(obj,token)
        }
    }
      // handle the feedback submission
     


    //   onClose();
  };

useEffect(()=>{
    if(BankerFeedBackData){
 ToastMessage("success","Thank you for your feedback! It means a lot to us. We strive relentlessly to improve your user experience on our platform")
 onClose();
    }
    if(FeedBackData || CompanyFeedBackData){
        if(type==="Banker" || type==='Banker1'){
            setMessage(`${data?.data?.company?.company_name || ""} has been notified for ${dataSet?.title || dataSet?.name} Completion. Wait for the approval`)
            setShowNotification(true)
           
        }
        if(type==="Company"){
            ToastMessage("success","Thank you for your feedback! Your feedback means a lot to us as it helps us improve the quality of our services.")
        }
    
        onClose();
        if(fetchAgain){
            fetchAgain()
        }
 

    }
    if(FeedBackError || BankerFeedBackError || CompanyFeedBackError){
        let text="";
        if(FeedBackError){
            text=FeedBackError?.response?.data?.message
        }
        if(BankerFeedBackError){
            text=BankerFeedBackError?.response?.data?.message
        }
        if(CompanyFeedBackError){
            text=CompanyFeedBackError?.response?.data?.message
        }
        ToastMessage("error",text || "Something went wrong")
    
    }
},[FeedBackData,FeedBackError,BankerFeedBackData,CompanyFeedBackData,BankerFeedBackError,CompanyFeedBackError])
  return (
    <>
    {feedBackLoading && <LoaderAPI />}
  { (feedBackData?.data?.banker_feedback_comment==null || feedBackData?.message===
"Feedback not found for this milestone") &&    <div className={FeedbackModelStyle2}>
          <div className={FeedbackModelStyle1}>
              <div className={FlexJustiEnd}>
                  <button onClick={onClose} className=''>
                     <CrossIcon1/>
                  </button>
              </div>
              <h4 className="h4-text">Rate your experience</h4>
              <div className={FeedbackModelStyle3}>
                  {[1, 2, 3, 4, 5].map((star) => (
                      <button
                          key={star}
                          onClick={() => handleRatingClick(star)}
                          className="my-6"
                      >
                          {/* &#9733; */}
                          { rating >= star ? <YellowStartIcon/> : <GrayStarIcon/>}
                      </button>
                  ))}
              </div>
              <textarea
                  className={FeedbackModelStyle4}
                  placeholder="If you have any feedback, please type it in here"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
              ></textarea>
              <p className={FeedbackModelStyle5}>{error}</p>
              <div className={FlexJustiEnd}>
              <button
                  onClick={handleSubmit}
                  className={FeedbackModelStyle6}
              >
                  Submit Feedback
              </button>
              </div>
          </div>
      </div>}
      </>
  );
};

export default FeedbackModal