// Profile Photo Style
export const ProfilePhotoStyleMainContainer = "w-[12rem] h-[12rem] relative mb-10 m-auto rounded-full bg-gray-300";
export const ProfilePhotoStyleMainContainer1 = "w-[88px]  h-[88px] relative m-auto rounded-full bg-gray-300";

export const ProfilePhotoStyleMainImg="pt- w-full rounded-full w-[12rem] h-[12rem]"
export const ProfilePhotoStyleMainImg1="h-full w-full overflow-hidden rounded-full "

export const ProfilePhotoStyleDiv4 = "w-[3.875rem] cursor-pointer flex items-center justify-center h-[3.875rem] text-5xl absolute text-white top-[8rem] right-0 bg-green-500 m-auto rounded-full bg-white border-white greenbg border-[10px]";

export const ProfilePhotoStyleDiv2 = "w-[38px] cursor-pointer flex items-center justify-center h-[38px] text-5xl absolute top-[3rem] -right-2 bg-green-500 m-auto text-white rounded-full bg-white border border-white shadow border-[10px]";
export const ProfilePhotoStyleDiv3 = "w-[45px] cursor-pointer flex items-center justify-center h-[45px] text-5xl absolute top-[8rem] right-0 bg-green-500 m-auto text-white rounded-full bg-white border border-white shadow border-[10px]";

export const ProfilePhotoStyleDivModel = "overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50";
export const ProfilePhotoStyleDivModelDiv = "relative p-4 w-full max-w-xl";
export const ProfilePhotoStyleDivModelModalContent = "relative bg-white rounded-lg shadow";
export const ProfilePhotoStyleDivModelModalHeader = "flex items-center justify-between p-2 border-b rounded-t";
export const ProfilePhotoStyleDivModelModalHeaderH3 = "text-lg pl-4 font-urbanist font-semibold text-gray-900 ";
export const ProfilePhotoStyleDivButton = "primary-text-gray bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center ";
export const ProfilePhotoStyleDivModelModalBody = "p-4 md:p-5 space-y-4";
export const ProfilePhotoStyleBodyDiv = "w-full grid m-auto pt-10  grid-cols-2"
export const ProfilePhotoStyleBodyDiv2 = "w-[7rem] h-[7rem] cursor-pointer  rounded-full m-auto bg-gray-300"
export const ProfilePhotoStyleBodyDiv3 = "w-[7rem] h-[7rem] cursor-pointer relative rounded-full m-auto bg-black"
export const ProfilePhotoStyleBodyDiv4 = "w-full grid pb-10 cursor-pointer m-auto  grid-cols-2"






