import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../../../common/InputField";
import {
  AuthButtonClass,
  AuthButtonDisabledClass,
  InputFieldClass,
  LabelFieldClass,
} from "../../../common/StyleCommon";
import Button from "../../../common/Button";
import { commenInputDivSpace, formStyle, termAndConditionStyle } from "../RegisterStyle";
import AuthTermCondition from "../RegisterTermsConditions";
import Checkbox from "../../../common/Checkbox";
import { validateBankerRegisterForm } from "../../../common/Validations";
import usePost from "../../../Hook/usePost";
import { onkeyDownforSpecialCharcter } from "../../../Hook/validation";
import { allowAlphabeticInputs, emailRegex, handleAlphabeticInput, handleNumericInput } from "../../../common/Config";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const Banker = () => {
  const { data: emailData, error: emailError, loading: emailLoading, postData: postEmailData } = usePost('onboarding/verify/verify-email');
  const { data: mobileData, error: mobileError, loading: mobileLoading, postData: postMobileData } = usePost('onboarding/verify/verify-phone');

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    termsAccepted: false,
    signUpStatus : "banker"
  });
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(true)
  const [previousDialCode, setPreviousDialCode] = useState('+91');
  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debouncedPostEmailData = debounce(postEmailData, 300);
  const debouncedPostMobileData = debounce(postMobileData, 300);

  const handleChange = (e) => {


    let { name, value, type, checked } = e.target;
    if (name == 'email' && !emailRegex.test(value)) {
      setErrors({ ...errors, 'email': "Enter valid email id" })
    } else {
      let newError = { ...errors };
      // if ('email' in newError && Object.keys(newError).length === 1) {
      //   delete newError.email;
      // }

      if (Object.keys(newError).length === 2 && name !== "email") {
        if(("mobileNumber" in newError) && newError.mobileNumber === "") {
          delete newError.mobileNumber;
        }
        if(("email" in newError) && formData.email === "" && name !== "email") {
          delete newError.email;
        }
      }
      
      if (name in newError) {
        delete newError[name];
      }
      
      setErrors(newError);
    }

    if (name === 'email') {
      debouncedPostEmailData({ email: value });
    }
    //  else if (name === 'mobileNumber') {
    //   debouncedPostMobileData({ phone: value });
    // }
    if (name === 'mobileNumber') {
      // value = value?.replace(/\D/g, '');
      debouncedPostMobileData({ phone: value?.replaceAll(" ","").slice(-10) })
    }

    const updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };

    setFormData(updatedFormData);
    if (errors[name] && Object.keys(errors).length >2) {
      validateBankerRegisterForm(updatedFormData, setErrors);
    }

    // setErrors({ ...errors, [name]: '' }); // Clear error on change
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateBankerRegisterForm({...formData,mobileNumber:formData?.mobileNumber?.replaceAll(" ","").slice(-10)}, setErrors)) {
      if (mobileData?.status === true) {
        setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Mobile number already exists" }));
      }
      else if (emailData?.status === true) {
        setErrors((prevErrors) => ({ ...prevErrors, email: "Email already exists" }));
      }
      else {
        sessionStorage.setItem("bankerData", JSON.stringify({...formData,mobileNumber:formData?.mobileNumber?.replaceAll(" ","").slice(-10)}));
        navigate("/banker-set-password");
      }
    }
  };

  useEffect(() => {
    if (emailData?.status === true) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "Email already exists" }));
    }
  }, [emailData, emailError]);

  useEffect(() => {
    if (mobileData?.status === true) {
      setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Mobile number already exists" }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "" }));
    }
  }, [mobileData, mobileError]);
  useEffect(() => {
    if (formData?.termsAccepted) {
      setIsDisabled(false)
    } else {
      setIsDisabled(!false)
    }
  }, [formData])
  return (
    <div>
      <div>
        <div className={formStyle}>
          <div className="hr-bgColor mt-8 lg:mt-0"></div>

          <form onSubmit={handleSubmit}>
            <div className={commenInputDivSpace}>
              <InputField
                astrix={true}
                disabled={false}
                required={true}
                label="Full name"
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                onkeyDown={handleAlphabeticInput}
                error={errors.fullName}
                labelClass={LabelFieldClass}
                className={InputFieldClass}
              />
            </div>

            <div>
              <div className={commenInputDivSpace}>
                <InputField
                  astrix={true}
                  disabled={false}
                  label="Email id"
                  type="email"
                  id="email"
                  name="email"
                  required={true}
                  value={formData.email}
                  onChange={handleChange}
                  error={errors.email}
                  labelClass={LabelFieldClass}
                  className={InputFieldClass}
                />
              </div>
            </div>

            <div>
              <div className={commenInputDivSpace}>
              <PhoneInput
        country={'in'}
        disableCountryGuess={true}
        countryCodeEditable={false}
        value={formData.mobileNumber}
        
        onChange={(fullPhoneNumber,country) => {
          const newDialCode = `+${country.dialCode}`;
          if (newDialCode !== previousDialCode) {
            // Reset mobile number to just the new country code
            const newMobileNumber = newDialCode;
            setFormData({ ...formData, mobileNumber: newMobileNumber });
            setPreviousDialCode(newDialCode);
            handleChange({ target: { name: 'mobileNumber', value: newMobileNumber } });
          } else {
            // Update the phone number as usual
            setFormData({ ...formData, mobileNumber: fullPhoneNumber });
            handleChange({ target: { name: 'mobileNumber', value: fullPhoneNumber } });
          }
        
        }}
        inputStyle={{ width: '100%', height: '45px' }}
        containerClass="phone-input-container"
      dropdownClass="phone-dropdown"
      />
      <p className="text-right primary-text-red text-sm">{errors && errors?.mobileNumber}</p>
                {/* <InputField
                  span="+91"
                  astrix={true}
                  disabled={false}
                  label="Mobile number"
                  type="tel"
                  id="mobileNumber"
                  name="mobileNumber"
                  onkeyDown={onkeyDownforSpecialCharcter}
                  required={true}
                  maxLength={10}
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  error={errors.mobileNumber}
                  labelClass={LabelFieldClass}
                  className={InputFieldClass}
                /> */}
              </div>
            </div>

            <div className={termAndConditionStyle}>
              <Checkbox name="termsAccepted" value={formData.termsAccepted} onChange={handleChange} type="checkbox" />
              <AuthTermCondition />
            </div>

            <div className="text-start ml-8 mb-4">

            {errors.termsAccepted && <span className="primary-text-red text-sm">{errors.termsAccepted}</span>}
              
            </div>


            <Button type="submit" className={`mb-4 md:mb-0 ${isDisabled ? AuthButtonDisabledClass :AuthButtonClass}`} disabled={isDisabled}>
              Register
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Banker;