import React, { useEffect, useState } from 'react';
import Navbar from '../../../common/Navbar';
import SideNavbar from '../../../common/SideNavbar';
import { DownloadIconInProfile, GreenPencilIcon, PencilIcon } from '../../../common/Icons';
import Button from '../../../common/Button';
import InputField from '../../../common/InputField';
import SelectTagComponent from '../../../common/SelectTagComponent';
import useGetData from '../../../Hook/useGetData';
import UploadProfileModal from './UploadProfileModal';
import usePost from '../../../Hook/usePost';
import { ToastMessage } from '../../../common/Config';
import { convertDate, convertDateToInputFormat, convertToInitials } from '../../../Hook/validation';
import BottomPagination from '../../../common/BottomPagination';
import LoaderAPI from '../../../common/LoaderAPI';
import ToolTip from '../../../common/ToolTip';
import Tooltip from '../../dashboard/company/calendar/Tooltip';
import DataNotFound from '../../../common/DataNotFound';


const CompanyMyProfile = () => {
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const { data, error, loading, refetch:fetchAgain } = useGetData("profile/company/basic-details", token);
  const { data: getStateData, loading: loadingState, error: errorState } = useGetData('master-data/state/get',"token");
  const { data: updatedData, error: updateError, loading: updateLoading, postData: updateBankerDetails } = usePost("profile/company/update-details");
  const { data: getTurnOverData } = useGetData('master-data/turnovers/index',"token");
  const { data: getVintageData } = useGetData('master-data/vintages/index',"token");
  const { data:TransectionList} = useGetData("profile/company/transactions", token);
  const { data: services, loading: loadingServices, error: errorServices } = useGetData('master-data/individual-services/index');
  const [EditNameIcon, SetEditNameIcon] = useState(true);
  const [EditBasicIcon, SetEditBasicIcon] = useState(true);
  const ImageData = "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg";
  const [profilePhoto, setProfilePhoto] = useState(false);
  const [imageData, setImageData] = useState(null);
  // const [editNameIcon, setEditNameIcon] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handlePencilClick = () => {
    setIsModalOpen(true);
  };

  const handleImageSelect = (image) => {
    setImageData(image);
  };
  
  const CompanyPro = data?.data?.company ? true :false;
  const IndividualPro = data?.data?.individual ? true :false;
  const [UserName, setUserName] = useState('');
  const itemsPerPage = 5;
  const [basicDetailCompany, setBasicDetailCompany] = useState([
    { name: "Company Name", value: "Sagar Enterprise",error:"" },
    { name: "Company Email ID", value: "example@gmail.com",error:"" },
    { name: "Phone Number", value: "+91-80768192678",error:"" },
    { name: "State", value: "Haryana",error:"" },
    { name: "City", value: "Gurgaon",error:"" },
    { name: "Turnover Grid", value: "0-10 Lac",error:"" },
    { name: "Vintage Grid", value: "0-2 years Old",error:"" },
  ]);
const [DummyBasicDetailIndividual,setDummyBasicDetailIndividual]=useState([
  { name: "Email ID", value: "example@gmail.com",id:1 },
  { name: "Phone Number", value: "+91-80768192678",id:2 },
  { name: "State", value: "Haryana",id:3 },
  { name: "City", value: "Gurgaon",id:4 },
  { name: "Select Service", value: "Home Loan",id:5 },
])

  const [stateId,setStateId] = useState(data?.data?.company?.state?.id || 0);
  const [currentPage, setCurrentPage] = useState(1);
  const [Loader,setLoader] = useState(false);
  const { data: getCityData, loading: loadingCity, error: errorCity } = useGetData(`master-data/city/${stateId}`,"token");

  const TransactionHistoryProject = ["Project 1", "1234", "₹25,000", "Om Prakash Sao", "May 9, 2024", ""];

  // useEffect(() => {
  //   if (updatedData?.status_code === 200 || updatedData?.status === "success") {
  //     ToastMessage("success", updatedData?.message);
  //     fetchAgain();
  //   }
  // }, [updatedData,updateError]);

  const updateName = () => {
    if (!UserName || UserName === "") {
      SetEditBasicIcon(!EditBasicIcon)
      return;
    }
    let payload = { "bk_user": {"name": UserName} }
    updateBankerDetails(payload, token);
    SetEditBasicIcon(!EditBasicIcon)
  }

  

  const handleBasicDetails=() => {
    
    const ModeOfConsultancy=basicDetailCompany?.filter((item)=>item?.name==="Mode of Consultancy")[0]?.value;
    const ModeOfHire=basicDetailCompany?.filter((item)=>item?.name==="Availability for Hire")[0]?.value;

    const CityId=typeof basicDetailCompany?.filter((item)=>item?.name==="City")[0]?.value==='number' ?      basicDetailCompany?.filter((item)=>item?.name==="City")[0]?.value : getCityData?.data?.filter((item)=>item.city===basicDetailCompany?.filter((item)=>item?.name==="City")[0]?.value)[0]?.id || 0

    const StateId=typeof basicDetailCompany?.filter((item)=>item?.name==="State")[0]?.value==='number' ?basicDetailCompany?.filter((item)=>item?.name==="State")[0]?.value : getStateData?.data?.filter((item)=>item.name===basicDetailCompany?.filter((item)=>item?.name==="State")[0]?.value)[0]?.id || 0

    const TurnOverId=typeof basicDetailCompany?.filter((item)=>item?.name==="Turnover Grid")[0]?.value==='number' ?basicDetailCompany?.filter((item)=>item?.name==="Turnover Grid")[0]?.value : getTurnOverData?.data?.filter((item)=>item.name===basicDetailCompany?.filter((item)=>item?.name==="Turnover Grid")[0]?.value)[0]?.id || 0

    const VintageId=typeof basicDetailCompany?.filter((item)=>item?.name==="Vintage Grid")[0]?.value==='number' ?basicDetailCompany?.filter((item)=>item?.name==="Vintage Grid")[0]?.value : getVintageData?.data?.filter((item)=>item.name===basicDetailCompany?.filter((item)=>item?.name==="Vintage Grid")[0]?.value)[0]?.id || 0
    const obj={
      "turnover":TurnOverId,
      "vintage":VintageId,
      "city": CityId,
      "state": StateId,
      "company_name":basicDetailCompany?.filter((item)=>item?.name==="Company Name")[0]?.value,
      // "bk_user": {
      //     "name": "John D"
      // }
  }
  if(obj?.city!==0){
    setLoader(true)
   updateBankerDetails(obj, token);
   SetEditBasicIcon(!EditBasicIcon);
  }else{
    const updatedBasicDetailCompany = [...basicDetailCompany];
    updatedBasicDetailCompany[4].error = "Please select city";
    
    // Update the state with the modified array
    setBasicDetailCompany(updatedBasicDetailCompany);
  }
 
    
    }

    const handleIndividualDetails=()=>{
      // DummyBasicDetailIndividual
      const obj={
        "city": DummyBasicDetailIndividual?.filter((item)=>item?.name==="City")[0]?.id || 0,
        "state": DummyBasicDetailIndividual?.filter((item)=>item?.name==="State")[0]?.id || 0,
        masterIndivisualService:DummyBasicDetailIndividual[4].id || 0,
    }
  if(obj?.city!==0){
    setLoader(true)
   updateBankerDetails(obj, token);
   SetEditBasicIcon(!EditBasicIcon);
  }else{
    const updatedBasicDetailCompany = [...basicDetailCompany];
    updatedBasicDetailCompany[4].error = "Please select city";
    
    // Update the state with the modified array
    setBasicDetailCompany(updatedBasicDetailCompany);
  }
    }
    const handleNameSave=()=>{
      const obj={
        // "turnover":TurnOverId,
        // "vintage":VintageId,
        // "city": CityId,
        // "state": StateId,
        // "company_name":basicDetailCompany?.filter((item)=>item?.name==="Company Name")[0]?.value,
        "bk_user": {
            "name": UserName
        }
    }
      updateBankerDetails(obj, token);
      SetEditNameIcon(!EditNameIcon)
    }

  useEffect(() => {
    const companyData = data?.data?.company;
    const individualData = data?.data?.individual;
    let companyDetails = [...basicDetailCompany];
if(companyData){
  setUserName(companyData?.user?.name)
}
    for (let i = 0; i < companyDetails?.length; ++i) {
      if (companyDetails[i]["name"] === "Company Name") {
        companyDetails[i]["value"] = companyData?.company_name ?? "Not available";
      }
      if (companyDetails[i]["name"] === "Company Email ID") {
        companyDetails[i]["value"] = companyData?.user?.email ? companyData?.user?.email : individualData?.user?.email ? individualData?.user?.email : "Not available";
      }
      if (companyDetails[i]["name"] === "Phone Number") {
        companyDetails[i]["value"] = companyData?.user?.phone_number ? `+91-${companyData?.user?.phone_number}` : individualData?.user?.phone_number ? `+91-${individualData?.user?.phone_number}` : "Not available";
      }
      if (companyDetails[i]["name"] === "State") {
        companyDetails[i]["value"] = companyData?.state?.name ? companyData?.state?.name : individualData?.state?.name ? individualData?.state?.name : "Not available";
        setStateId( companyData?.state?.id)
      }
      if (companyDetails[i]["name"] === "City") {
        companyDetails[i]["value"] = companyData?.city?.city ? companyData?.city?.city : individualData?.city?.city ? individualData?.city?.city : "Not available";
      }
      if (companyDetails[i]["name"] === "Turnover Grid") {
        companyDetails[i]["value"] = companyData?.turnover?.name ?? "Not available";
      }
      if (companyDetails[i]["name"] === "Vintage Grid") {
        companyDetails[i]["value"] = companyData?.vintage?.name ?? "Not available";
      }
    }
    setBasicDetailCompany(companyDetails);
  }, [data?.data?.company]);

  useEffect(() => {
    const individualData = data?.data?.individual;
    let companyDetails = [...DummyBasicDetailIndividual];

if(individualData){
  for (let i = 0; i < companyDetails?.length; ++i) {
    if (companyDetails[i]["name"] === "Email ID") {
      companyDetails[i]["value"] = individualData?.user?.email ? individualData?.user?.email : "Not available";
    }
    if (companyDetails[i]["name"] === "Phone Number") {
      companyDetails[i]["value"] =  individualData?.user?.phone_number ? `+91-${individualData?.user?.phone_number}` : "Not available";
    }
    if (companyDetails[i]["name"] === "State") {
      companyDetails[i]["value"] =  individualData?.state?.name ? individualData?.state?.name : "Not available";
      setStateId(individualData?.state?.id);
      companyDetails[i]["id"] =  individualData?.state?.id ? individualData?.state?.id : 0;

    }
    if (companyDetails[i]["name"] === "City") {
      companyDetails[i]["value"] =  individualData?.city?.city ? individualData?.city?.city : "Not available";
      companyDetails[i]["id"] =  individualData?.city?.id ? individualData?.city?.id : 0;
    }
    if (companyDetails[i]["name"] === "Select Service") {
      companyDetails[i]["value"] =  individualData?.indivisual?.name ? individualData?.indivisual?.name : "Not available";
      companyDetails[i]["id"] =  individualData?.indivisual?.id ? individualData?.indivisual?.id : 0; 
    }
  }
}
   
setDummyBasicDetailIndividual(companyDetails);
  }, [data?.data?.individual]);



  const handleInputChange = (index, newValue) => {
    const updatedDetails = [...basicDetailCompany];
    if(updatedDetails[index]?.name==="State"){
      setStateId(newValue);
      let updatedBasicDetailCompany = [...basicDetailCompany];
      updatedDetails[index].value = newValue;
      updatedBasicDetailCompany[4].error = "Please select city";
      updatedBasicDetailCompany[4].value = "select city";
      
      // Update the state with the modified array
      setBasicDetailCompany(updatedBasicDetailCompany);
    }else if(updatedDetails[index]?.name==="City"){
      const updatedBasicDetailCompany = [...basicDetailCompany];
      updatedBasicDetailCompany[4].error = "";
      updatedBasicDetailCompany[4].value = newValue;
        // Update the state with the modified array
        setBasicDetailCompany(updatedBasicDetailCompany);
      // Update the state with the modified array
    }else{
    updatedDetails[index].value = newValue;
    setBasicDetailCompany(updatedDetails);
    }


  };
  const handleInputChangeIndividuals = (index, newValue) => {
    const updatedDetails = [...DummyBasicDetailIndividual];
    if(updatedDetails[index]?.name==="State"){
      setStateId(newValue?.id);
    }

    updatedDetails[index].value = newValue?.name;
    updatedDetails[index].id = newValue?.id;
    setDummyBasicDetailIndividual(updatedDetails);
  };

  const handleInputNameChange = (newValue) => {
    // const updatedDetailName = [...UserName];
    // updatedDetailName.value = newValue;
    setUserName(newValue);
  };
  useEffect(()=>{
    if(updatedData){
      ToastMessage("success","Profile Updated Successfully")
    }
    if(updateError){
      ToastMessage("error","Something went wrong")
    
    }
    fetchAgain()
setLoader(false)
  },[updatedData,updateError])
  const totalPages = Math.ceil(TransectionList?.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const displayData = TransectionList?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )?.map((item)=>{
    return {
      ...item,
      amount:item?.amount/100
    }
  }) || [];
  return (
    <div>
      {(Loader || loading)&& <LoaderAPI />}

      <Navbar hemBurgerIcon={true} ProfileAndNotification={true} CompanyNavbar={true} />

      <div className='flex'>
        <div className='w-0 md:w-[9%]'>
          <SideNavbar />
        </div>
        <div className='w-full lg:w-[91%] px-2 md:px-8'>
          <div className='sticky-profile'>
            <h4 className='h4-text pt-4 text-start text-[#2F3340]'>My Profile</h4>
          </div>
          <div className={`border relative w-full my-8 p-4 grid grid-cols-1 md:grid md:grid-cols-2 rounded-lg ${!EditBasicIcon ? "opacity-[30%]" : ""}`}>
            <div className='flex items-center gap-4'>
    
<div className='relative'>

        <UploadProfileModal EditNameIcon={!EditNameIcon} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto}  onClose={() => setIsModalOpen(false)} onImageSelect={handleImageSelect} />


  
    </div>
              <div className='w-[80%] text-start'>
                {EditNameIcon && <h5 className='h5-text'>{data?.data?.company?.user?.name || data?.data?.individual?.user?.name || "Not Available"}</h5>}
                {!EditNameIcon && 
                <InputField
                 onChange={(e) => handleInputNameChange( e.target.value)}
                  value={UserName}
                //  value={data?.data?.company?.user?.name || data?.data?.individual?.user?.name || "Not Available"} 
                 />}
              </div> 
            </div>
            <div className='flex justify-end items-start gap-4'>
              {EditNameIcon && <PencilIcon onClick={() => SetEditNameIcon(!EditNameIcon)} />}
              {!EditNameIcon && <Button onClick={handleNameSave} className="redfolicbg py-2 px-5 rounded-lg ">
                Save Changes
              </Button>}
            </div>
          </div>

          <div className={`border w-full my-8 p-4 rounded-lg ${!EditNameIcon ? "opacity-[30%]" : ""}`}>
            <div className='grid grid-cols-2'>
              <div className='flex items-center gap-4'>
                <h6 className='h6-text'>Basic Details</h6>
              </div>
              <div className='flex justify-end items-start gap-4'>
                {EditBasicIcon && <PencilIcon onClick={() => SetEditBasicIcon(!EditBasicIcon)} />}
                {!EditBasicIcon && <Button
                //  onClick={() => SetEditBasicIcon(!EditBasicIcon)}
                onClick={CompanyPro ? handleBasicDetails:handleIndividualDetails}
                  className="redfolicbg py-2 px-5 rounded-lg ">
                  Save Changes
                </Button>}
              </div>
            </div>

            {CompanyPro && <div className={`grid grid-cols-1 md:grid md:grid-cols-2 mb-4 w-[90%] mt-6 ${!EditBasicIcon ? "gap-10" : "gap-7"}  `}>
              {basicDetailCompany?.map((item, index) => (
                 <div className='grid relative grid-cols-2 text-start h-[40px]'>
                 <div className='flex justify-between items-center'>
                   <p className='body-text  text-[#80828D]'>{item?.name}</p>
                   <div className='mr-[40%]'>-</div>
                 </div>
                <div className='absolute w-[65%] top-1 left-[35%] text-start flex justify-start items-center'>
                 
                  {/* <div className='absolute top-1 left-[35%] text-start flex justify-start items-center'> */}
                    {EditBasicIcon && <p className='notification-span mt-2 text-[#2C2C2C]'>{item?.value}</p>}
                    {!EditBasicIcon && <div className='-mt-6 w-full'>
                      {item.name === "Vintage Grid" || item.name === "Turnover Grid" || item.name === "State" || item.name === "City" ? (
                        <div className='mt-2 w-full lg:w-[16rem]'><SelectTagComponent defaultVal={item?.value} options={item?.name==="State" ? getStateData?.data :item?.name==='City' 
                        ? getCityData?.data?.map((item)=>{return {...item,name:item?.city}}):
                        item?.name==='Turnover Grid' ? getTurnOverData?.data:item?.name==='Vintage Grid' ? getVintageData?.data:
                        []} onChange={(e) => handleInputChange(index, e?.id)} />
                
                        </div>
                      ) : (
                        <div className= 'w-full lg:w-[16rem]'>
                        <InputField disabled={(item?.name=='Company Email ID' || item?.name=='Phone Number')} value={item?.value || ''} onChange={(e) => handleInputChange(index, e.target.value)} />

                        </div>
                      )}
                  <p className='text-[red]'>{item?.error}</p>

                    </div>}

                  </div>

                </div>
                
              ))}
            </div>}

            {IndividualPro && <div className={`grid grid-cols-2 mb-4 w-[90%] mt-2 ${!EditBasicIcon ? "gap-10" : "gap-7"} `}>
              {DummyBasicDetailIndividual?.map((item, index) => (
                <div className='grid relative grid-cols-2 text-start' key={index}>
                  <div className='flex justify-between items-center'>
                    <p className='body-text '>{item?.name}</p>
                    <div className='mr-[40%]'>-</div>
                  </div>
                  <div className='absolute top-1 left-[35%] text-start flex justify-start items-center'>
                    {EditBasicIcon && <p className='body-text text-[#2C2C2C]'>{item?.value}</p>}
                    {!EditBasicIcon && <div className='-mt-6 w-full'>
                      {item.name === "State" || item.name === "Select Service"  || item?.name==="City" ? (
                        <div className='mt-2 w-[14rem]'><SelectTagComponent defaultVal={item?.value} options={item?.name==="State" ? getStateData?.data : item?.name==="Select Service" ? services?.data :item?.name==="City" ? getCityData?.data?.map((item)=>{return {...item,name:item?.city}}):[]} onChange={(e) => handleInputChangeIndividuals(index, e)} /></div>
                      ) : (
                        <InputField value={item?.value} disabled={true} onChange={(e) => handleInputChangeIndividuals(index, e.target.value)} />
                      )}
                    </div>}
                  </div>
                </div>
              ))}
            </div>}
          </div>

          <div className={`border w-full my-8 p-4 rounded-lg ${!EditBasicIcon ? "opacity-[30%]" : ""} ${!EditNameIcon ? "opacity-[30%]" : ""}`}>
  <div>
    <h6 className='h6-text'>Transaction history</h6>
  </div>
  <div className='overflow-x-auto custom-scrollbar'>
    <div className='grid grid-cols-6 gap-8 mt-4 min-w-[900px]'>
      {["Project Name", "Invoice Number", "Payout Amount", "Banker Name", "Date", "Invoice"].map((item, index) => (
        <div className={` ${item === "Invoice" ? "text-center" : "text-start"} `} key={index}>
          <p className='notification-span w-full text-[#8B8D98]'>{item}</p>
        </div>
      ))}
    </div>
    {displayData?.length>0 ? displayData?.map((item, index) =>{
      const date = new Date(item?.order_date);

// Define options for formatting the date
const options = { year: 'numeric', month: 'long', day: 'numeric' };

// Format the date
const formattedDate = date.toLocaleDateString('en-US', options);
      return (
        <div key={index}>
          <div className='w-[900px] md:w-full border my-5 border-[#EFF0F3]'></div>
          <div className='grid grid-cols-6 gap-8 mt-4 min-w-[900px]'>
              <div className="text-start" >
                <p className={`body-text text-[#2C2C2C]`}>{item?.job_hire_details?.project_name || "Not Available"}</p>
              </div>
              <div className="text-start" >
                <p className={`body-text text-[#2C2C2C]`}>Not Available</p>
              </div>
              <div className="text-start" >
                <p className={`body-text text-[#2C2C2C]`}>₹{item?.amount.toLocaleString('en-IN') || 0}</p>
              </div>
              <div className="text-start" >
                <p className={`body-text text-[#2C2C2C]`}>{item?.job_hire_details?.banker_details?.name || "Not Available"}</p>
              </div>
              <div className="text-start" >
                
                <p className={`body-text text-[#2C2C2C]`}>{formattedDate}</p>
              </div>
              <div className="text-end flex justify-center" >
                {item?.invoice ? <p role="button" className={`body-text text-[#2C2C2C]`}>
                  <a href={item?.invoice} target="_blank" rel="noreferrer">
                  <DownloadIconInProfile />
                  </a>
                  </p> :
                  <ToolTip message="No Invoice Available">
   <p className={`body-text text-[#2C2C2C]`}><DownloadIconInProfile /></p> 
                  </ToolTip>
              
                }
              </div>
          </div>
        </div>
      )
    }
    ):
    <DataNotFound title="No Transaction History Found" />
    }
  </div>
</div>
<BottomPagination 
           currentPage={currentPage}
           totalPages={totalPages}
           onPageChange={handlePageChange}
          />

        </div>
      </div>
    </div>
  );
};

export default CompanyMyProfile;
