import React from 'react'
import Navbar from '../../../../common/Navbar'
import BankerSideNavbar from '../../../../common/BankerSideNavbar'
import Button from '../../../../common/Button'
import { DownloadIconInProfile, GetHelpIcon } from '../../../../common/Icons'
import { BMM_MainDiv, BMM_MainDiv1, BMM_MainDiv2, BMM_MainDiv3, BMM_MainDiv4, BMM_MainDiv5, BMM_MainDiv6, BMM_MainDiv7, BMM_MainDiv8, BMM_MainDiv9 } from './BankerProjectStyle'
import useGetData from '../../../../Hook/useGetData'
import DataNotFound from '../../../../common/DataNotFound'

const BankerManageMandate = () => {
    const token = JSON.parse(sessionStorage.getItem("userToken"));
    const { data:TransectionList} = useGetData("profile/banker/transactions", token);
    const { data: getData,loading: loadingPayment, error: errorPayment,refetch:fetchData } = useGetData(`onboarding/get-payment-manadate`, token);

  return (
    <div>
    <Navbar hemBurgerIcon={true} BackBtn={true} BankerNavbar={true} ProfileAndNotification={true} />

    <div className='flex w-full'>
      <div className='w-full hidden lg:block lg:w-[7%]'>
        <BankerSideNavbar />
      </div>


      <div className='w-full lg:w-[93%] p-4 lg:p-8'>
      <div className={BMM_MainDiv}>
        <div>
        <h4 className={BMM_MainDiv1}>Manage Mandates</h4>

        </div>
      <div>
      <Button
           
           className={BMM_MainDiv2}
         >
           <GetHelpIcon  />
           <p className='simple-text'>Get Help</p>
         </Button>
      </div>
      </div>

      <div>
        <div className={BMM_MainDiv3} >
            <h6 className={BMM_MainDiv4}>
            Mandates left
            </h6>
            <h6 className={BMM_MainDiv4}>
                {getData?.consume_plan_manadate}/{getData?.current_plan_manadate}
            </h6>
        </div>
        <div className={`border w-full h-full my-8 px-4 py-6 rounded-lg `}>
    <div>
        <h6 className='h6-text'>Transaction history</h6>
    </div>

   <div className=' w-full custom-scrollbar overflow-auto md:overflow-none '>
   <div className={`${BMM_MainDiv6} min-w-[600px]`}>
        {["Project", "Company", "Earning", "Payment Status", "Date", "Invoice"].map((item, index) => {
            return (
                <div key={index} className={`${item === "Invoice" ? "text-end" : "text-start"} ${index === 3 || index === 4 ? "flex justify-center" : "text-start"}`}>
                    <p className={BMM_MainDiv7}>{item}</p>
                </div>
            );
        })}
    </div>
    {getData?.mandate_history?.length>0 ? getData?.mandate_history?.map((transaction, index) => (
    <React.Fragment key={index}>
        <div className={BMM_MainDiv8}></div>
        <div className={`${BMM_MainDiv9} h-full min-w-[600px]`}>
            <div className="text-start">
                <p className="body-text text-[#2C2C2C] notification-span">{transaction?.project_name || 'N/A'}</p>
            </div>
            <div className="text-start">
                <p className="body-text text-[#2C2C2C]">{transaction?.company_name || 'N/A'}</p>
            </div>
            <div className="text-start">
                <p className="body-text text-[#2C2C2C]">{`₹${transaction.amount || 0}`}</p>
            </div>
            <div className="flex justify-center">
                <p className={`body-text text-[#2C2C2C] ${transaction.status ? 'bg-[#D5F2DD] px-3 py-2 rounded-[24.5px] -mb-2 -mt-1' : 'bg-[#FFF6BE] px-3 py-2 rounded-[24.5px] -mb-2 -mt-1'}`}>
                    {transaction.status ? "Received" : "Pending"}
                </p>
            </div>
            <div className="flex justify-center">
                <p className="body-text text-[#2C2C2C]">{new Date(transaction.created_at).toLocaleDateString()}</p>
            </div>
            <div className="flex justify-end">
                {transaction.invoice ? <DownloadIconInProfile /> : 'N/A'}
            </div>
        </div>
    </React.Fragment>
)) :
<DataNotFound title="No Transaction History Found" />
}

   </div>
</div>

      </div>
      </div>
   
    </div>
  </div>
  )
}

export default BankerManageMandate
