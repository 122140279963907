import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { commenInputDivSpace, formStyle, termAndConditionStyle } from "../../RegisterStyle";
import InputField from "../../../../common/InputField";
import { AuthButtonClass, AuthButtonDisabledClass, InputFieldClass, LabelFieldClass } from "../../../../common/StyleCommon";
import SelectTagComponent from "../../../../common/SelectTagComponent";
import Button from "../../../../common/Button";
import RegisterTermsConditions from "../../RegisterTermsConditions";
import Checkbox from "../../../../common/Checkbox";
import { validateIndividualRegisterForm } from "../../../../common/Validations";
import useGetData from "../../../../Hook/useGetData";
import usePost from "../../../../Hook/usePost";
import {  emailRegex, handleAlphabeticInput } from "../../../../common/Config";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css' // Add this line to import the necessary CSS

const Individual = () => {
  const { data: emailData, error: emailError, loading: emailLoading, postData: postEmailData } = usePost('onboarding/verify/verify-email');
  const { data: mobileData, error: mobileError, loading: mobileLoading, postData: postMobileData } = usePost('onboarding/verify/verify-phone');
  const [homeEror , setHomeError] = useState(false);
  const navigate = useNavigate();
  const { data: services, loading: loadingServices, error: errorServices } = useGetData('master-data/individual-services/index');
  const { data: getStateData, loading: loadingState, error: errorState } = useGetData('master-data/state/get',"token");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    service: "",
    termsAccepted: false,
    signUpStatus : "individual",
    state:0,
    city:0
  });
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(true)
  const [previousDialCode, setPreviousDialCode] = useState('+91');
  const serviceOptions = services?.data || [];
  const { data: getCityData, loading: loadingCity, error: errorCity } = useGetData(`master-data/city/${formData?.state}`,"token");
  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debouncedPostEmailData = debounce(postEmailData, 300);
  const debouncedPostMobileData = debounce(postMobileData, 300);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let formattedValue = value;
    let newErrors = { ...errors };

    if (name === 'email') {
      if (!emailRegex.test(value)) {
        newErrors.email = "Enter valid email id";
      } else {
        delete newErrors.email;
      }
      debouncedPostEmailData({ email: value });
    } else if (name === 'mobileNumber') {
      formattedValue = value.replace(/\D/g, '');
      debouncedPostMobileData({ phone: value?.replaceAll(" ","").slice(-10) })
    }
    
    if (('email' in newErrors) && Object.keys(newErrors).length === 1 && name !== "email") {
      delete newErrors.email;
    }

    if (Object.keys(newErrors).length === 2 && name !== "email") {
      if(("mobileNumber" in newErrors) && newErrors.mobileNumber === "") {
        delete newErrors.mobileNumber;
      }
      if(("email" in newErrors) && formData.email === "" && name !== "email") {
        delete newErrors.email;
      }
    }
    
    if (name in newErrors) {
      delete newErrors.name;

      // if (('email' in newErrors) && Object.keys(newErrors).length === 1) {
      //   delete newErrors.email;
      // }  
    }

    setErrors(newErrors);

    const updatedFormData = {
      ...formData,
      [name]: type === "checkbox" ? checked : formattedValue
    };

    setFormData(updatedFormData);

    setFormData(updatedFormData);

    // Check for errors and validate the form
    if ((errors[name] && Object.keys(errors).length >=3) || (
      errors[name] && name==="termsAccepted" && Object.keys(errors).length === 1) || 
    (errors[name] && name==="fullName" && ("email" in errors) === false && Object.keys(errors).length >=2)) {
      validateIndividualRegisterForm(updatedFormData, setErrors);
    }

    // Clear error on change
    // setErrors({ ...errors, [name]: '' });
  };


  const handleSelectChange = (name,value) => {
    if (value && typeof value === 'object') {
      setFormData({ ...formData, [name]: value.id }); // Update only with value name
    } else {
      setFormData({ ...formData, [name]: value.id });
    }
    setErrors({ ...errors, service: '' });
    if(name === "service") setHomeError(true);
    
    // Clear error on change
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateIndividualRegisterForm({...formData,mobileNumber:formData?.mobileNumber?.replaceAll(" ","").slice(-10)}, setErrors)) {
      if (mobileData?.status === true) {
        setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Mobile number already exists" }));
      }
      else if (emailData?.status === true) {
        setErrors((prevErrors) => ({ ...prevErrors, email: "Email already exists" }));
      }
      else {
        sessionStorage.setItem("IndivisualsData", JSON.stringify({...formData,mobileNumber:formData?.mobileNumber?.replaceAll(" ","").slice(-10)}));
        let companydata;
        let bkdata;
        
        try {
          bkdata = JSON.parse(sessionStorage.getItem("bankerData"));
          if(bkdata !== undefined) {
            sessionStorage.removeItem("bankerData");
          }
        }
        catch {
          console.error("Banker Data key does not exist in session storage");
        }

        try {
          companydata = JSON.parse(sessionStorage.getItem("companyData"));
          if(companydata !== undefined) {
            sessionStorage.removeItem("companyData");
          }
        }
        catch {
          console.error("Company Data key does not exist in session storage");
        }
        navigate("/set-password");
      }

    }
  };


  useEffect(() => {

    if (emailData?.status === true) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "Email already exists" }));
    }
  }, [emailData, emailError]);

  useEffect(() => {
    if (mobileData?.status === true) {
      setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Mobile number already exists" }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "" }));
    }
  }, [mobileData, mobileError]);
  useEffect(() => {

    if (formData?.termsAccepted) {
      setIsDisabled(false)
    } else {
      setIsDisabled(!false)
    }
  }, [formData])
  return (
    <div>
      <div className={formStyle}>
        <form onSubmit={handleSubmit}>
          <div className={commenInputDivSpace}>
            <InputField
              disabled={false}
              astrix={true}
              label="Full Name"
              type="text"
              id="fullName"
              name="fullName"
              required={true}
              value={formData.fullName}
              onChange={handleChange}
              onkeyDown={handleAlphabeticInput}
              error={errors.fullName}
              labelClass={LabelFieldClass}
              className={InputFieldClass}
            />
          </div>
          <div className={commenInputDivSpace}>
            <InputField
              astrix={true}
              disabled={false}
              label="Email ID"
              type="email"
              id="email"
              name="email"
              required={true}
              value={formData.email}
              onChange={handleChange}
              error={errors.email}
              labelClass={LabelFieldClass}
              className={InputFieldClass}
            />
          </div>
          <div className={commenInputDivSpace}>
          <PhoneInput
        country={'in'}
        disableCountryGuess={true}
        countryCodeEditable={false}
        value={formData.mobileNumber}
        
        onChange={(fullPhoneNumber,country) => {
          const newDialCode = `+${country.dialCode}`;
          if (newDialCode !== previousDialCode) {
            // Reset mobile number to just the new country code
            const newMobileNumber = newDialCode;
            setFormData({ ...formData, mobileNumber: newMobileNumber });
            setPreviousDialCode(newDialCode);
            handleChange({ target: { name: 'mobileNumber', value: newMobileNumber } });
          } else {
            // Update the phone number as usual
            setFormData({ ...formData, mobileNumber: fullPhoneNumber });
            handleChange({ target: { name: 'mobileNumber', value: fullPhoneNumber } });
          }
      
        }}
        inputStyle={{ width: '100%', height: '45px' }}
        containerClass="phone-input-container"
      dropdownClass="phone-dropdown"
      />
      <p className="text-right primary-text-red text-sm">{errors && errors?.mobileNumber}</p>
            {/* <InputField
              astrix={true}
              span="+91"
              disabled={false}
              label="Mobile Number"
              onkeyDown={onkeyDownforSpecialCharcter}
              type="tel"
              id="mobileNumber"
              name="mobileNumber"
              required={true}
              maxLength={10}
              value={formData.mobileNumber}
              onChange={handleChange}
              error={errors.mobileNumber}
              labelClass={LabelFieldClass}
              className={InputFieldClass}
            /> */}
          </div>

          <div className="flex gap-4">
            <div className={commenInputDivSpace}>
            <SelectTagComponent
              defaultVal="State"
              options={getStateData?.data || []}
              error={errors?.state}
              onChange={(value) => handleSelectChange("state", value)}
            />
            </div>
            <div className={commenInputDivSpace}>
            <SelectTagComponent
              defaultVal="Current City"
              options={getCityData?.data?.map((item)=>{
                return {
                  ...item,
                  name: item.city,
                }
              }) || []}
              error={errors?.city}
              onChange={(value) => handleSelectChange("city", value)}

            />
            </div>
          </div>
          <div className="mt-4 lg:mt-0">
            <SelectTagComponent
              error={errors.service}
              defaultVal="Select a service"
              options={serviceOptions}
              onChange={(value)=>handleSelectChange('service',value)}
            />
          </div>
          {homeEror && <div>
            <p className="body-text text-start pt-4 text-[#F48A94]">*There is no guarantee of getting the loan that is at the sole discretion of the lending institution and as per their lending policies*</p>
            </div>}
          <div className={termAndConditionStyle}>
            <Checkbox name="termsAccepted" value={formData.termsAccepted} onChange={handleChange} type="checkbox" />
            <RegisterTermsConditions />
          </div>

          <div className="text-start ml-8 mb-4">

          {errors.termsAccepted && <span className="primary-text-red text-sm">{errors.termsAccepted}</span>}

          </div>
          

          

          <Button type="submit" className={`mb-4 md:mb-0 ${isDisabled ? AuthButtonDisabledClass : AuthButtonClass}`} disabled={isDisabled}>

            Register
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Individual;