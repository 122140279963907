import React from "react";
import { LoaderIcon } from "./Icons";

export const Loader = ({shouldLoaderExpand = true}) => {
    
  return (
    <div className={`flex items-center justify-center ${shouldLoaderExpand ? "h-screen" : ""}`} title="2">
      <LoaderIcon />
    </div>
  );
};
