import React, { useEffect, useState } from 'react';
import Navbar from '../../../../common/Navbar';
import Button from '../../../../common/Button';
import TitleValueComp from '../../../../common/TitleValueComp';
import { FlexGap4, JobListingViewDiscriptionDiv, JobListingViewDiv, JobListingViewDivMainContainer, JobListingViewDivMainHeading,  JobListingViewKeyDiv, JobListingViewKeyDivHeading } from '../../company/job/DashboardJobStyle';
import { FilterIcon, LoaderIcon, RightTickWhiteIcon, SaveIconImg, SavedIcon } from '../../../../common/Icons';
import RoundedBox from '../../../../common/RoundedBox';
import FilterCard from '../../../../common/FilterCard';
import BankerJobCard from './BankerJobCard';
import BankerSideNavbar from '../../../../common/BankerSideNavbar';
import { useNavigate, useParams } from 'react-router-dom';
import useGetData from '../../../../Hook/useGetData';
import usePost from '../../../../Hook/usePost';
 
import BottomPagination from "../../../../common/BottomPagination";
import { ToastMessage } from '../../../../common/Config';
import { BankerJobViewStyle1, BankerJobViewStyle2, BankerJobViewStyle3, BankerJobViewStyle4 } from './BankersDasboardStyle';
const BankerJobView = () => {
    const [Interested  , setInterested] =useState(true);
  const [ActiveFilter , setActiveFilter] = useState(false)

    const [checkedItems, setCheckedItems] = useState({
      shortTerm: false,
      mediumTerm: false,
      longTerm: false,
      virtual: false,
      'In-person': false,
      both: false,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const {id}=useParams()
    const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
    const { data: interestData, error: interestError, loading: interestLoading, postData: shareInterest } = usePost("/banker/share-interest");
    const { data: unshareInterestData, error: unshareInterestError, loading: unshareInterestLoading, postData: unshareInterestJob } = usePost("/banker/share-not-interest");
    const { data: getProfileData ,refetch:fetchAgain} = useGetData(`job/list-by-banker/${id}`, token);
    const { data: saveData, error: saveError, postData: postSaveData } = usePost("/banker/job-saved");
    const { data: unsaveData, error: unsaveError, loading: unsaveLoading, postData: unsaveJob } = usePost("/banker/job-unsaved");
    const profileData = [
        {
          id: 1,
          title: "Posted on",
          // titleValue: "20/04/1965",
          titleValue:getProfileData?.data[0].postOn || sessionStorage.getItem("bkJobPostedDate") || "Not Available"
        },
        {
          id: 2,
          title: "Job Location",
          // titleValue: "Gurgaon",
          titleValue:getProfileData?.data[0].city?.city || sessionStorage.getItem("bkJobLocation") || "Not Available"
        },
        // {
        //   id: 3,
        //   title: "Job ID",
        //   // titleValue: "0001",
        //   titleValue: sessionStorage.getItem("bkCustJobID") || ""
        // }
      ];
 
      const KeyAreaService =getProfileData?.data[0]?.key_service_details?.map((item)=>item.name) || [
        "Small company",
        "SME",
        "Stress asset",
        "International banking",
        "Forex",
      ];

      let job_id = 1;

      try {
        job_id = sessionStorage.getItem("jobID");
      }
      catch {
        job_id = 1;
      }

      const {data, loading, error, refetch} = useGetData(`job/list/${job_id}`, token);
      const {data: jobSearchData, loading: jobSearchLoading, error: jobSearchError, refetch: refetchJobList} = useGetData("banker/job-search", token);
      const {data: filteredData, error: errorFilter, loading: filterLoading, postData: applyFilter} = usePost("banker/job-filter");
 

    const [jobList, setJobList] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    

    const handleInterested = () => {
      setInterested(!Interested);
      const jobId=sessionStorage.getItem("jobID");
      const payload = {"job_id": jobId};
      if(Interested) {
        unshareInterestJob(payload, token);
      
        return;
      }
      setInterested(!Interested)
      shareInterest(payload, token);
      refetchJobList()
      fetchAgain();
    }

const handleSave=()=>{
  postSaveData({job_id:id},token)
  fetchAgain()
}
const handleUnSave=()=>{
  unsaveJob({job_id:id},token)
  fetchAgain()
}
    useEffect(() => {
      let jobdata = isFilterApplied ? filteredData?.data : jobSearchData?.data
      let dataa = [];
  
      var savedCount = 0, intrestedCount = 0;
      
      for(let i=0;i<jobdata?.length;i++) {
        let obj = {"jobId": jobdata[i]["id"]};
  
        if("BkJobTitleAndCustom" in jobdata[i]) {
          let bkjobtitleobj = jobdata[i]["BkJobTitleAndCustom"];
          obj["jobTitle"] = bkjobtitleobj["custom_title"] ?? "Not Available";
        }
  
        if("consultancy_mode" in jobdata[i]) {
          let tenureobj = jobdata[i]["consultancy_mode"];
          obj["consultancy_mode"] = tenureobj["name"] ?? "Not Available";
        }
  
        if("tenure" in jobdata[i]) {
          let tenureobj = jobdata[i]["tenure"];
          obj["tenure"] = tenureobj["name"] ?? "Not Available";
        }
  
        let date = jobdata[i]["created_at"].split("T")[0] ?? "";
        obj["postOn"] = date;
        obj["postedBy"] = "Not Available";
        obj["location"] = jobdata[i]["preferred_location"] ?? "Not Available";
        obj["job_id"] = jobdata[i]["job_id"] ?? "";
        obj["job_description"] = jobdata[i]["job_description"] ?? "";
        obj["save_job"] = jobdata[i]["save_job"] ?? false;
        obj["share_interest"] = jobdata[i]["share_interest"] ?? false;
        if (jobdata[i]["save_job"]) {
          savedCount = savedCount + 1;
        }
  
        if (jobdata[i]["share_interest"]) {
          intrestedCount = intrestedCount + 1;
        }
        obj["interested_job"] = jobdata[i]["share_interest"] ?? false;
        dataa.push(obj);
      }
      setTotalPages(Math.ceil(dataa.length / itemsPerPage));
      setJobList(dataa);
      sessionStorage.setItem("jobList", JSON.stringify(dataa));
    }, [jobSearchData, filteredData]);

    useEffect(() => {
      let tenure = [];
      let consultancy_mode = [];
  
      if(checkedItems?.shortTerm) {
        tenure.push(1);
        setIsFilterApplied(true);
      }
  
      if(checkedItems?.mediumTerm) {
        tenure.push(2);
        setIsFilterApplied(true);
      }
  
      if(checkedItems?.longTerm) {
        tenure.push(3);
        setIsFilterApplied(true);
      }
  
      if (checkedItems?.virtual) {
        consultancy_mode.push(1);
        setIsFilterApplied(true);
      }
  
      if (checkedItems?.["In-person"]) {
        consultancy_mode.push(2);
        setIsFilterApplied(true);
      }
  
      if (checkedItems?.both) {
        consultancy_mode.push(3);
        setIsFilterApplied(true);
      }
  
      let payload = {"consultancy_mode": consultancy_mode, "tenure": tenure};

      applyFilter(payload, token);
    }, [checkedItems]);

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  
    const displayData = jobList?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
   
    useEffect(() => {
      if(unshareInterestData && !unshareInterestError) {
        ToastMessage("success", unshareInterestData?.message);
      } else {
        ToastMessage("error", unshareInterestError?.message);
      }
    }, [unshareInterestData, unshareInterestError]);
    useEffect(() => {
      if(interestData && !interestError) {
        ToastMessage("success", interestData?.message);
      } else {
        ToastMessage("error", interestError?.message);
      }
    }, [interestData, interestError]);
const isTrue=displayData?.filter(item => item?.jobTitle === sessionStorage.getItem("bkJobTitle") )[0]?.share_interest;
   useEffect(()=>{

     if(isTrue === true){
      setInterested(true)
        }else{
          setInterested(false)
        }
   },[isTrue])
   useEffect(()=>{
    if(saveData){
      ToastMessage("success", "Job Saved Successfully");
    }
    if(saveError){
      ToastMessage("error", saveError?.message);
    }
   },[saveData,saveError])
   useEffect(()=>{
    if(unsaveData){
      ToastMessage("success", "Job Unsaved Successfully");
    }
    if(unsaveError){
      ToastMessage("error", "Job Unsaved Successfully");
    }
   },[unsaveData,unsaveError])
  return (
    <div>
      
        <Navbar  BankerNavbar={true} BackBtn={true} ProfileAndNotification={true} />
    
 
      <div className="flex">
        <div className={BankerJobViewStyle1}>
          <BankerSideNavbar />
        </div>
        <div className={BankerJobViewStyle2}>
 
            <div className={JobListingViewDiv}>
            <div className="text-start">
            <h4 className="h4-text">{sessionStorage.getItem("bkJobTitle") || "Finance Banker"}</h4>
          </div>
          <div className={FlexGap4}>
          <Button  onClick={handleInterested} disabled={getProfileData?.share_interest_status} className="greenbg rounded-lg text-white px-2 lg:px-4 disabled lg:py-2">{getProfileData?.share_interest_status !== true ?"Share Interest" : <p className='flex gap-1 text-white'><RightTickWhiteIcon/> Interested</p>}</Button>
          { getProfileData?.is_saved !== true ? <SaveIconImg onClick={handleSave}/> : <SavedIcon onClick ={handleUnSave}/> }

            
          </div>
            </div>
 
            <div className={BankerJobViewStyle3}>
            <TitleValueComp data={profileData} />
            </div>
 
            <div className={JobListingViewDivMainContainer}>
                {/* {["Long Term" , "In-Person, Virtual"].map((item ,index) =>{
                    return <div className={JobListingViewDivMainHeading}>{item}</div>
                })} */}
                <div className={JobListingViewDivMainHeading}>{getProfileData?.data[0].tenure?.name  || sessionStorage.getItem("bkTenure") || ""} </div>
                <div className={JobListingViewDivMainHeading}>{getProfileData?.data[0].consultancy_mode?.name || sessionStorage.getItem("bkConsultancyMode") || ""} </div>
            </div>
 
            <div>
                <div className={JobListingViewDiscriptionDiv}>
                    <h6 className='h6-text'>Job Description</h6>
                    <p className='mt-2 body-text'>
                    {getProfileData?.data[0]?.job_description || sessionStorage.getItem("bkJobDescription") || ""}
                    </p>
                </div>
            </div>
 
            <div>
                <div className={JobListingViewKeyDiv}>
                    <h6 className={JobListingViewKeyDivHeading}>Key Area of Expertise</h6>

                 <RoundedBox data={KeyAreaService}/>
                </div>
            </div>
 
                
              <div className='px-8'>
                  
              <div className={BankerJobViewStyle4}>
                  <div>
                  <h6 className="h6-text my-7">More Jobs</h6>
                  </div>
                  <div onClick={()=>setActiveFilter(!ActiveFilter)} className="block lg:hidden md:hidden">
                  <FilterIcon/>
                  
                  </div>
              </div>

              { ActiveFilter && <div className="w-full block md:hideen lg:hidden ">
                    <FilterCard setCheckedItems={setCheckedItems} checkedItems={checkedItems} fetch={refetch} setFilterState={setIsFilterApplied} />

                    </div>}

              <div className="lg:flex  md:flex gap-4">
                    <div className="w-full md:w-[70%] lg:w-[70%]  mt-4 grid grid-cols-1 gap-4">
                      {jobSearchLoading || filterLoading ? <LoaderIcon /> : displayData?.map((item, index) => {
                        if (item?.jobTitle !== "Not Available") {
                          return (
                            <BankerJobCard
                              jobTitle={item.jobTitle}
                              postOn={item.postOn}
                              postedBy={item.postedBy}
                              location={item.location}
                              savedJob={item.save_job}
                              item={item}
                              refetchListing={refetchJobList}
                              handlePageChange={handlePageChange}
                            />
                          );
                        }
                      })}
                                  <BottomPagination 
           currentPage={currentPage}
           totalPages={totalPages}
           onPageChange={handlePageChange}
          />
                    </div>
                    <div className="w-full hidden md:block lg:block md:w-[30%] lg:w-[30%]">

                    <FilterCard setCheckedItems={setCheckedItems} checkedItems={checkedItems} fetch={refetchJobList} setFilterState={setIsFilterApplied} />
                    </div>
                 
                  </div>
              </div>

        </div>
      </div>
    </div>
  );
}
 
export default BankerJobView;