import React from "react";
import { RegisterTermAndConditionStyle } from "./RegisterStyle";
 
const RegisterTermsConditions = () => {
 
  return (
    <div>
      <p
        htmlFor="termsAccepted"
        className={RegisterTermAndConditionStyle}
      >
        I agree to BankersKlub’s{" "}
        <span className="primary-text-green">
          <a href="https://bankersklub.com/terms-and-conditions/" target="_blank">
          Terms of service
          </a>
        </span> &{" "}
        <span className="primary-text-green">
          <a href="https://bankersklub.com/privacy-policy/" target="_blank">
          Privacy Policy
          </a>
        </span>.
      </p>
    </div>
  );
};
 
export default RegisterTermsConditions;