
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BankerStep2MainDiHRLine, BankerStep2MainDiHRLine2, BankerStep2MainDiv, BankerStep2MainDivMode, BankerStep2div, BankerStep2divCoreError, BankerStep2divKeyServ, BankerStep2divSaveIem, BankerStepTwoCustomBox, FlexJustEnd, RelMT6mAuto } from "../../../../profile/banker/BankerStyle";
import { ToastMessage, getAvailabilityModes, getConsultancyModes, getCoreExpertise, getKeyAreaServices } from "../../../../../common/Config";
import { useEffect, useState } from "react";
import Navbar from "../../../../../common/Navbar";
import ExpertiseModal from "../../../../../common/ExpertiseModal";
import InputField from "../../../../../common/InputField";
import SelectTagComponent from "../../../../../common/SelectTagComponent";
import BottomBar from "../../../../../common/BottomBar";
import { validateStepTwoBanker } from "../../../../../common/Validations";
import { FlexCenter, commenInputDivSpace } from "../../../../register/RegisterStyle";
import { PencilIcon, RightTickIconInSteps } from "../../../../../common/Icons";
import useGetData from "../../../../../Hook/useGetData";
import { AddJobValidation } from "../../../../../Hook/validation";
import usePost from "../../../../../Hook/usePost";
import SideNavbar from "../../../../../common/SideNavbar";

const AddJobStepOne = () => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const navigate = useNavigate();
 const location=useLocation()
 const {id}=useParams()
  const { data: getJobData, loading: loadingServices, error: errorServices } = useGetData('master-data/job/get',token);
  const { data: getJobDetailsData, loading: loadingJobDetails, error: errorJobDetails } = useGetData(`jobs/edit-job/${id}`,token);
  const { data: JobData,loading:JobLoading, error: JobError, postData: postJobData } = usePost('jobs/company-job-draft');
  const { data: JobUpdateData,loading:JobUpdateLoading, error: JobUpdateError, postData: postJobUpdateData } = usePost(`jobs/update-job/${id}`);
  const { data: getStateData, loading: loadingState, error: errorState } = useGetData('master-data/state/get',"token");
  const [refresh, setRefresh] = useState(1);
  const [keyAreaServicesData, setKeyAreaServicesData] = useState([]);
  const [avalForHire, setAvalForHire] = useState([]);
  const [avalForConsultancy, setAvalForConsultancy] = useState([]);

  const [savedItems, setSavedItems] = useState([]);
  const [savedItemsCore, setSavedItemsCore] = useState([]);
  const [show, setShow] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Select");
  const [customInputVisible, setCustomInputVisible] = useState(false);
  const [formData, setFormData] = useState({
    job_title:"",
    job_description:"",
    preferred_location:"",
    tenure:"",
    consultancy_mode:"",
    key_service:savedItems || [],
    core_expertise:[],
    keyAreaServices: savedItems,
    // preferredLocation: ""
  });
  const [IsDisabled,setIsDisabled]=useState(true)

  const [errors, setErrors] = useState({});
  const { data: getCityData, loading: loadingCity, error: errorCity } = useGetData(`master-data/city/${formData?.state}`,"token");
  const handleChange = (e) => {
const newData={...formData};
if(e.target.name === "job_description" && e.target.value.length > 2200){
e.preventDefault()
}else{
  newData[e.target.name]=e.target.value;
  setFormData(newData);
  
      const { name, value } = e.target;
      if (errors[e.target.name]) {
        AddJobValidation(newData, setErrors);
        }
      if (name === "lastDrawnGrossConsolidatedSalary") {
        sessionStorage.setItem("bankerLastDrawnSalary", JSON.stringify(value))
      } else {
        sessionStorage.setItem("bankerPreferredLocation", JSON.stringify(value))
      }
      setFormData({ ...formData, [name]: value });
}

  };



  const handleOptionChange = (name,option) => {
    const newData={...formData};
    let errs = {...errors};
    newData[name]=option?.id
    setFormData(newData);
    
    if(name === "consultancy_mode") {
      if(option?.id === 1 && "preferred_location" in errs) {
        delete errs.preferred_location;
        setErrors(errs);
      }

      if (option?.name === "In Person" || option?.name === "in-person" || option?.name === "Both") {
        setCustomInputVisible(true);
      } else {
        setCustomInputVisible(false);
      }
    }
    
    if (errors[name]) {
      AddJobValidation(newData, setErrors);
      }
    setSelectedOption(option?.name);
    
  };

  const handleSave = (selectedItems) => {

    if(AddJobValidation(formData, setErrors)){

      sessionStorage.setItem("addJob",JSON.stringify(formData));
      if(id){
        sessionStorage.setItem("addJob",JSON.stringify(formData));
        postJobUpdateData({job_description:formData?.job_description,tenure:formData?.tenure,preferred_location:formData?.preferred_location,key_service:formData?.key_service_details.map((item)=>item.id),core_expertise:formData?.core_expertise_details || [],custom_title:formData?.custom_title,job_title:1,consultancy_mode:formData?.consultancy_mode || 0,state:formData?.state,city:formData?.city},token)
        // navigate("/dashboard/joblisting")
      }else{
        postJobData({...formData,key_service:formData?.keyAreaServices,core_expertise:formData?.core_expertise || []},token)
      }
    

    }
  };
  const handleSaveExpertise = (selectedItems) => {
      sessionStorage.setItem("addJob",JSON.stringify(formData));
  };

  const handleSelectChange = (name, value) => {
    if (value && typeof value === 'object') {
      setFormData({ ...formData, [name]: value.id }); // Update only with value name
    } else {
      setFormData({ ...formData, [name]: value.id });
    }
    setErrors({ ...errors, [name]: '' }); // Clear error on change
  };
  // Function to delete a tag
  const deleteTag = (index) => {
    const updatedItems = [...savedItems];
    updatedItems.splice(index, 1);
    setSavedItems(updatedItems);
    sessionStorage.setItem("selectedItems", JSON.stringify(updatedItems));
  };


  // Generate tags
  const tags = savedItems?.map((item, index) => (
    <div
      key={index}
      className="border border-[#2C2C2C] secondary-text-gray font-lato font-normal flex cursor-pointer rounded-full py-2 my-2 px-4 text-[15px] mr-2 mb-2"
    >
      {keyAreaServicesData.filter((items)=>items?.id===item)[0]?.name}{" "}
      {!show && (
        <svg
          onClick={() => deleteTag(index)}
          className="mt-1 ml-2"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.30439 3.80439C2.89854 4.21025 2.89854 4.86826 3.30439 5.27412L7.03027 8.99998L3.30439 12.7259C2.89854 13.1317 2.89854 13.7898 3.30439 14.1956C3.71024 14.6015 4.36827 14.6015 4.77412 14.1956L8.49998 10.4697L12.2259 14.1956C12.6317 14.6015 13.2898 14.6015 13.6956 14.1956C14.1015 13.7898 14.1015 13.1317 13.6956 12.7259L9.96969 8.99998L13.6956 5.27413C14.1015 4.86828 14.1015 4.21026 13.6956 3.80441C13.2897 3.39855 12.6317 3.39855 12.2259 3.80441L8.49998 7.53026L4.77412 3.80439C4.36827 3.39854 3.71024 3.39854 3.30439 3.80439Z"
            fill="#2C2C2C"
          />
        </svg>
      )}
    </div>
    
  ));


  // Load saved items from local storage on component mount
  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("selectedItems")) || [];
    setSavedItems(storedItems);
    setFormData({
      ...formData,
      keyAreaServices: storedItems,
    });
  }, [refresh]);


  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("selectedItems")) || [];
    const consultancyMode = JSON.parse(sessionStorage.getItem("bankerConsultancyMode")) || "Select";
    const preferredLocation = JSON.parse(sessionStorage.getItem("bankerPreferredLocation")) || "";

    // if (preferredLocation !== "") setCustomInputVisible(true);

    if (consultancyMode === "Virtual") {
      setCustomInputVisible(false);
      if (preferredLocation !== "") sessionStorage.removeItem("bankerPreferredLocation");
    }

    setSelectedOption(consultancyMode);
    setSavedItems(storedItems);
    getKeyAreaServices(setKeyAreaServicesData);
    getAvailabilityModes(setAvalForHire);
    getConsultancyModes(setAvalForConsultancy);
    setFormData({ keyAreaServices: storedItems,  preferredLocation: preferredLocation });
  }, []);

  useEffect(()=>{
if(JobData){
  ToastMessage("success",'Congratulations! You have successfully posted a job.')
}
if(JobError){
  ToastMessage("error","Job Added Failed")
}
  },[JobData,JobError])
  useEffect(()=>{
    if(JobUpdateData){
      ToastMessage("success",JobUpdateData?.message)
      navigate("/dashboard/joblisting");
    }
    if(JobUpdateError){
      ToastMessage("error","Job Updated Failed")
    }
    
  },[JobUpdateData,JobUpdateError])
  useEffect(()=>{
    const JobData=JSON.parse(sessionStorage.getItem("addJob"))
    if(JobData){
      setFormData(JobData)
      setSelectedOption(avalForConsultancy?.filter((item) => item.id == formData?.consultancy_mode)[0]?.name )
    }
  },[])
  useEffect(()=>{
    if(getJobDetailsData?.data){
      setFormData({...getJobDetailsData?.data,custom_title:getJobDetailsData?.data?.BkJobTitleAndCustom?.custom_title,consultancy_mode:getJobDetailsData?.data?.consultancy_mode?.id,tenure:getJobDetailsData?.data?.tenure?.id})
      const listIds=getJobDetailsData?.data?.key_service_details?.map((item)=>item?.id) || location?.state?.key_service|| [];
      sessionStorage.setItem("selectedItems", JSON.stringify(listIds));
      setSelectedOption(avalForConsultancy?.filter((item) => item.id == getJobDetailsData?.data?.consultancy_mode)[0]?.name )
      setSavedItems(listIds)
    }
  },[getJobDetailsData?.data])
  useEffect(()=>{
    if(formData?.consultancy_mode==2){
      setCustomInputVisible(true)
    }
    if(formData?.customInputVisible==3){
      setCustomInputVisible(true)
    }
  },[formData])
  useEffect(()=>{
    if(formData?.consultancy_mode==3){
      setCustomInputVisible(true)
    }
  },[formData])
  const city=getCityData?.data?.filter((item)=>item?.id==formData?.city)[0]?.city || "Select City"
  const state=getStateData?.data?.filter((item)=>item?.id==formData?.state)[0]?.name
  useEffect(() => {
    const isConsultancyModeValid = formData?.consultancy_mode === 2 || formData?.consultancy_mode === 3;
    const isStateAndCityValid = isConsultancyModeValid ? formData?.state && formData?.city : true;
  
    if (
      formData?.job_title !== '' &&
      formData?.consultancy_mode &&
      formData?.tenure &&
      isStateAndCityValid &&
      formData?.keyAreaServices?.length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData]);
  
  return (
    <div>
      <Navbar hemBurgerIcon={true} CompanyNavbar={true} ProfileAndNotification={true}  />
      <div className="md:hidden">
        <SideNavbar/>
      </div>
     <div className={FlexCenter}>
     <div className={BankerStep2MainDiv}>
        
        
        <div>
          <div className="sticky-AddJob py-8">
          <h3 className="h3-text">{id ? "Edit Job" :"Add a Job"}</h3>

          </div>
          <div className={RelMT6mAuto}>
            <InputField
              remove={false}
              disabled={false}
              error={errors?.custom_title}
              label="Job Title"
              type=""
              id="custom_title"
              astrix={false}
              name="custom_title"
              required={false}
              value={formData?.custom_title}
              onChange={handleChange}
              // labelClass={LabelFieldClass}
              // className={InputFieldClass}
            />
          </div>
          <div className='hr-bgColor w-full lg:w-[34.625rem] md:w-[34.625rem] mt-6 m-auto'></div>
          <div class={RelMT6mAuto}>
            <textarea
            className="w-full h-[8rem] resize-none border border-[#D9D9D9] rounded-[5px] p-[1rem] text-[#4F4F4F] outline-none"
              disabled={false}
              error={errors?.job_description}
              placeholder="Add a Description ( Optional )"
              type=""
              id="addDiscription"
              astrix={false}
              name="job_description"
              required={false}
              value={formData?.job_description}
              onChange={handleChange}
              // labelClass={LabelFieldClass}
              // className={InputFieldClass}
            />
          </div>
          <div>
          <div className="">
          <div className={BankerStep2MainDiHRLine}></div>
        <div className={BankerStep2div}>
          <div className="s1-text">Key Area of services*</div>
          {show || savedItems.length === 0 ? (
            <div className={BankerStep2divSaveIem}>
              <div
                onClick={() => setShow(false)}
                className={` ${
                  savedItems.length === 0 ? "invisible" : "visible"
                } `}
              >
                <PencilIcon/>
              </div>

              <ExpertiseModal
                data={keyAreaServicesData}
                storageKey="selectedItems"
                error={errors?.keyAreaServices}
                modalTitle="Key Area of services"
                btnHandle={handleSaveExpertise}
                setRefresh={setRefresh}
                isAddJob={true}
              />
            </div>
          ) : (
            <div onClick={() => setShow(true)} className={FlexJustEnd}>
              <RightTickIconInSteps/>
            </div>
          )}
        </div>
        <div className={BankerStep2divKeyServ}>
          {errors?.keyAreaServices && savedItems.length === 0
            ? <p className="primary-text-red text-sm ml-auto">{errors.keyAreaServices}</p>
            : tags}
        </div>
        <div className="hr-bgColor m-auto"></div>
            
            <div className={BankerStep2MainDivMode}>
              <div className="">
                <p className="s1-text"> Availability for Hire</p>
              </div>
            
                <div class="w-full">
                  <SelectTagComponent
                     error={errors?.tenure}
                    defaultVal={avalForHire?.filter((item) => item.id === formData?.tenure)[0]?.name || "Select"}
                    name="tenure"
                    onChange={(option) =>handleOptionChange("tenure",option)}
                    options={avalForHire}
                  />
                </div>
             
            </div>

            <div className="hr-bgColor m-auto"></div>

            <div className={`${BankerStep2MainDivMode} `}>
              <div className="">
                <p className="s1-text">Mode of Consultancy</p>
              </div>
                <div class=" w-full">
                  <SelectTagComponent
                  error={errors?.consultancy_mode}
                    onChange={(option) =>handleOptionChange("consultancy_mode",option)}
                    defaultVal={formData?.consultancy_mode ? avalForConsultancy?.filter((item) => item.id === formData?.consultancy_mode)[0]?.name : "Select" }
                    options={avalForConsultancy}
                  />
                </div>
             
            </div>

            {customInputVisible && formData?.consultancy_mode !==1 && (
              // <div className={BankerStepTwoCustomBox}>
              //   <div>
              //     <InputField
              //       disabled={false}
              //       label="Perferred Location"
              //       type=""
              //       id="perferredLocation"
              //       name="preferred_location"
              //       value={formData?.preferred_location}
              //       onChange={(e) => handleChange(e)}
              //       required={true}
              //       astrix={true}
              //       error={errors?.preferred_location}
              //     />
              //   </div>
              // </div>
              <div className="flex mb-10 gap-4">
            <div className={commenInputDivSpace}>
            <SelectTagComponent
              defaultVal={formData?.state ? state :"Select"}
              options={getStateData?.data || []}
              error={errors?.state}
              onChange={(value) => handleSelectChange("state", value)}
            />
            </div>
            <div className={commenInputDivSpace}>
         <SelectTagComponent
              defaultVal={formData?.city ? city :"Current City"}
              options={getCityData?.data?.map((item)=>{
                return {
                  ...item,
                  name: item.city,
                }
              }) || []}
              error={errors?.city}
              onChange={(value) => handleSelectChange("city", value)}

            />
            </div>
          </div>
            )}
          </div>
        </div>
        </div>
      
      </div>
     </div>
      <BottomBar
        OnCLickBackbtn={() => navigate(-1)}
        OnCLickNextBtn={() => {
          if(AddJobValidation(formData, setErrors)){
            sessionStorage.setItem("addJob",JSON.stringify(formData));
            navigate("/dashboard/company/addjob/steptwo")
          }
        
        }}
        nextButtonDisabled={IsDisabled}
        btnName={id ? "":"Add Job"}
        BackBtn="Back"
        SaveBtn="Save"
        handleSubmit={handleSave}
        type="Hire"

      />
    </div>
  );
};

export default AddJobStepOne;
