import React from 'react';
import { EyeIcon2, IButtonIcon } from '../../../../common/Icons';
import ToolTip from '../../../../common/ToolTip';
import { useNavigate } from 'react-router-dom';
import { CPToolTipDiv, CPToolTipDiv2 } from '../../company/project/CompanyProjectStyle';
import { isDueDateOverdue } from '../../../../Hook/validation';

const BankerProjectComp = ({ project  }) => {
  const navigate=useNavigate()
  const getStatusColor = (milestone) => {
    switch (milestone.status) {
      case 4:
        return 'bg-[#1EB469]';
      case 2:
        return 'bg-[#EA9515]';
      case 1:
        return 'bg-[#FDD33E]';
      case 0:
        return 'bg-[#FF0059]';
        case 5:
        return 'bg-[#D8D9E0]';
      default:
        return '';
    }
  };
  
  const ToolTipColer =[
    {
      id:1,
      color:"bg-[#1EB469]",
      status:"Completed"
    },
    {
      id:2,
      color:"bg-[#EA9515]",
      status:"In Approval"
    },
    {
      id:3,
      color:"bg-[#FDD33E]",
      status:"Ongoing"
    },
    
    {
      id:4,
      color:"bg-[#FF0059]",
      status:"Declined"
    } ,
    {
      id:5,
      color:"bg-[#D8D9E0]",
      status:"Not Start"
    },
    {
      id:6,
      color:"bg-[#FFCACE]",
      status:"Overdue"
    }
  ]
  const StatusToolTip = () => (
    <div className='w-[150px]  '>
        {ToolTipColer?.map((item , index)=>{
          return <div className={CPToolTipDiv}>
          <div className={`${CPToolTipDiv2} ${item?.color}`}></div>
          <div className='body-text'>{item?.status}</div>
          </div>
        })}
      
    </div>
  )
const handleDetails=()=>{
navigate(`/dashboard/banker/project-detail/${project.id}`)
}

const options = { year: 'numeric', month: 'short', day: 'numeric' };
const formattedDateofDueDate = new Date(project.end_date).toLocaleDateString('en-US', options);




  return (
    <>    <div className='w-[98%] md:border  md:pr-6'></div>
    <div className='md:flex border rounded-lg  md:border-none px-2 py-4 justify-between'>
      <div className='md:flex text-start w-full md:w-[73%]'>
        <div className= 'w-full md:w-[35%]' role='button' onClick={handleDetails}>
        <p className='s2-text text-[#8B8D98]  md:hidden block'>Project Name</p>
          <p className='notification-span text-[#2C2C2C] mb-2'>{project.project_name || "Not available"}</p>
          <p className='notification text-[#8B8D98]'>{project?.company?.company_name || "Not available"}</p>
        </div>
        <div className= 'w-full md:w-[65%] md:flex items-center'>
        <p className='s2-text text-[#8B8D98] flex mt-5 md:mt-0 mb-2 md:mb-0 items-center md:hidden gap-2 block'>Milestones Status <ToolTip message={<StatusToolTip/>}>
                          <IButtonIcon />
                        </ToolTip> </p>
          <p className='body-text gap-2 flex flex-wrap items-center'>
          {project.milestones.map((milestone, index) => (
        <ToolTip key={index} message={milestone.status==0 ? 'Declined' : milestone?.status==1 ?'Ongoing':milestone?.status==2 ? 'In Approval':milestone?.status==3 ?'Overdue':milestone?.status==4? 'Completed':'Not Started' }>
          <div
            className={`h-[8px] w-[36px] border ${getStatusColor(milestone)} rounded-[2px]`}
          ></div>
        </ToolTip>
      ))}
          </p>
        </div>
      </div>
      <div className='md:grid md:grid-cols-2 w-full md:w-[30%] justify-between'>
        <div className='md:flex justify-center'>
        <p className='s2-text text-[#8B8D98] text-start mt-6  md:hidden block'>Project Due Date</p>

          <p className='notification text-[#2C2C2C] flex items-center'>{formattedDateofDueDate}</p>
        </div>
        <div className='flex justify-start my-4 md:my-0 md:justify-center items-center' role='button' onClick={handleDetails}>
        <p className='flex items-center gap-2'><EyeIcon2 /> <span className='block md:hidden'>View Details</span></p>

        </div>
      </div>
    </div>
    {isDueDateOverdue(project?.end_date) && <div className='w-[99%]  mt-3 -ml-2'>
      <p className='checkBox-label text-[#6F0020] text-start px-2 py-2 bg-[#FFDADD] rounded-[4px]'>Milestone 4 is running due. Please close it quickly to be on your next deadlines.</p>
    </div>}
    </>

  )
};

export default BankerProjectComp;
