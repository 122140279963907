import React, { useState, useEffect } from 'react';
import useGetData from '../Hook/useGetData';

const NudgeNotification = ({  className}) => {
  const [isVisible, setIsVisible] = useState(!true);
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";

  const { data: getBankerData, loading: loadingServices, error: errorServices } = useGetData('onboarding/banker/get-bankers',token);
  const { data: getCompletionData, loading: loadingCompletion, error: errorCompletion } = useGetData('onboarding/banker/get-percent-complete',token);
  const { data: getPaymentData } = useGetData("onboarding/get-payment-status", token);


  
  const ProfileComplete = getBankerData?.data?.profile?.form_step ; 
const SalarySlip = getBankerData?.data?.profile?.salary_slip;

let DisplayData = '';
if (ProfileComplete === 4) {
  if (SalarySlip === null) {
    DisplayData = 'Your profile is 90% completed. Please complete it soon to improve your chances of getting hired soon.';
  } else {
    DisplayData = 'Congratulations! Your profile is 100% completed.';
  }
} else if (ProfileComplete === 1) {
  DisplayData = 'Congratulations! Your profile is 30% completed.';
}else if(ProfileComplete === 2 && SalarySlip === null){
  DisplayData = 'Your profile is 90% completed. Please complete it soon to improve your chances of getting hired soon.';
}
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures the effect runs only once
//className
useEffect(()=>{
  if(getCompletionData?.data?.percent_completion>-1){
    setIsVisible(true);
  }
  if(getCompletionData?.data?.percent_completion==30){
    setIsVisible(true)
  }
},[getCompletionData?.data?.percent_completion,errorCompletion])
  return (
    <>
      {isVisible && (
        <div className={className?className :"fixed z-[1000] top-10 left-0 right-0"}>
          <button className={`py-2 px-4 w-auto m-auto rounded-[4px] s3-text !bg-[#000000] text-white ${ProfileComplete === null ?"bg-white" : "bg-[#2C2C2C]"}`}>
            {(getCompletionData?.data?.percent_completion>=90 && getPaymentData?.payment_status) ? `Congratulations! Your profile is ${getCompletionData?.data?.percent_completion || 0}% completed.You are ready to get hired` :getCompletionData?.data?.percent_completion==90 ? "Your profile is 90% completed. Please complete it soon to improve your chances of getting hired soon." : `Congratulations! Your profile is ${getCompletionData?.data?.percent_completion || 0}% completed.`}
          </button>
        </div>
      )}
    </>
  );
};

export default NudgeNotification;
