import React, { createContext, useState, useContext } from "react";

const BankerSidebarContext = createContext();

export const BankerSidebarProvider = ({ children }) => {
  const [isBankerSidebarOpen, setIsBankerSidebarOpen] = useState(false);
  
  const toggleBankerSidebar = () => {
    setIsBankerSidebarOpen(!isBankerSidebarOpen);
  };

  return (
    <BankerSidebarContext.Provider value={{ isBankerSidebarOpen, toggleBankerSidebar }}>
      {children}
    </BankerSidebarContext.Provider>
  );
};

export const useBankerSidebar = () => {
  return useContext(BankerSidebarContext);
};
