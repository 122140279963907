import React, { useState, useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import Navbar from '../../../../common/Navbar';
import BankerSideNavbar from '../../../../common/BankerSideNavbar';
import { MenuIcon, PlusIconWhite } from '../../../../common/Icons';
import Button from '../../../../common/Button';
import SelectTagComponent from '../../../../common/SelectTagComponent';
import BankerAddEvent from './BankerAddEvent';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import BankerEventView from './BankerEventView';
import YearView from './YearView'; // Import the custom year view component
import CalendarTest from './CalendarTest';
import useGetData from '../../../../Hook/useGetData';
import { formatTime } from '../../../../Hook/validation';
import { BankerCalendarStyle1, BankerCalendarStyle10, BankerCalendarStyle11, BankerCalendarStyle12, BankerCalendarStyle13, BankerCalendarStyle14, BankerCalendarStyle15, BankerCalendarStyle16, BankerCalendarStyle17, BankerCalendarStyle18, BankerCalendarStyle2, BankerCalendarStyle3, BankerCalendarStyle4, BankerCalendarStyle5, BankerCalendarStyle6, BankerCalendarStyle7, BankerCalendarStyle8, BankerCalendarStyle9 } from './CalendarStyles';

const BankerCalendar = () => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: getEventsData, loading: loadingEvent, error: errorEvent } = useGetData(`hiring/banker-important-event`,token);
  const [activeTab, setActiveTab] = useState(true);
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEventViewModalOpen, setisEventViewModalOpen] = useState(false);
  const [dateInfo, setDateInfo] = useState(null);
  const [grid, setGrid] = useState('dayGridMonth');
  const [currentView, setCurrentView] = useState('dayGridMonth');
  const calendarRef = useRef(null);
  const [value, onChange] = useState(new Date());
  const [view, setView] = useState('week');
  const [calendarData, setCalendarData] = useState({
    month: null,
    week: null,
    year: null,
  });
  const [eventInfo, setEventInfo] = useState({
    "id": 0,
    "title": "",
    "location": "",
    "meeting_link": null,
    "event_date": "",
    "start_time": "",
    "end_time": "",
    "is_repeat": false,
    "repeat_interval": null,
    "repeat_unit": null,
    "repeat_start_date": null,
    "repeat_end_date": null,
    "description": "",
    "created_at": "",
    "updated_at": "",
    "banker": 0,
    "company": 0,
    "consultancy_mode": 0,
    "priority": 1
})
  useEffect(() => {
    const calendarBody = document.querySelector('.fc-daygrid-body');
    if (calendarBody) {
      if (currentView === 'year') {
        calendarBody.classList.add('no-border');
      } else {
        calendarBody.classList.remove('no-border');
      }
    }
  }, [currentView]);

  const handleDateClick = (info) => {
    setDateInfo(info);
    setIsModalOpen(true);
  };

  const handleAddEventClick = () => {
    setDateInfo(null);
    setIsModalOpen(true);
    setEventInfo({
      "id": 0,
      "title": "",
      "location": "",
      "meeting_link": null,
      "event_date": "",
      "start_time": "",
      "end_time": "",
      "is_repeat": false,
      "repeat_interval": null,
      "repeat_unit": null,
      "repeat_start_date": null,
      "repeat_end_date": null,
      "description": "",
      "created_at": "",
      "updated_at": "",
      "banker": 0,
      "company": 0,
      "consultancy_mode": 0,
      "priority": 1
  })
  };

  const handleClickViewEvent = () => {
    setisEventViewModalOpen(true);
  };

  const EventItem = ({ color, title, time }) => {
    return (
      <div className={BankerCalendarStyle15}>
        <div className={BankerCalendarStyle16}>
          <span className={`${BankerCalendarStyle17} ${color}`}></span>
          <p className={`body-text ${color}`}>{title}</p>
        </div>
        <p className={BankerCalendarStyle18}>{time}</p>
      </div>
    );
  };

  const handleSaveEvent = ({ title, eventType, color }) => {
    if (title) {
      const newEvent = {
        title,
        start: dateInfo ? dateInfo.date : null,
        end: dateInfo ? dateInfo.date : null,
        extendedProps: { eventType },
        color,
      };
      setEvents([...events, newEvent]);
    }
    setIsModalOpen(false);
  };

  const handleTimeGridChange = (e) => {
    if (e) {
      setGrid(e.value);
      setCurrentView(e.value);
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView(e.value);
      }
    }
  };

  const handleYearViewClick = () => {
    setGrid(''); // Temporarily set to empty to force re-render
    setTimeout(() => {
      setGrid('year');
      setCurrentView('year');
    }, 0);
  };

  const formatShortWeekday = (locale, date) => {
    const day = date.getDay();
    const dayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    return dayNames[day];
  };

  useEffect(()=>{
    sessionStorage.setItem("reloadCount",5);
  },[])
  // Function to calculate the week number
  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  // Handle change (for day clicks)
  const handleChange = (value) => {
    const selectedDate = new Date(value);
    const month = selectedDate.getMonth() + 1; // Months are 0-indexed
    const year = selectedDate.getFullYear();
    const week = getWeekNumber(selectedDate);

    setCalendarData({ month, week, year,date:selectedDate?.getDate() });
  };

  // Handle month click
  const handleMonthClick = (value) => {
    const selectedDate = new Date(value);
    const month = selectedDate.getMonth() + 1;
    const year = selectedDate.getFullYear();

    setCalendarData((prevData) => ({
      // ...prevData,
      month,
      year,
    }));
  };

  // Handle year click
  const handleYearClick = (value) => {
    const selectedYear = new Date(value).getFullYear();
    setCalendarData((prevData) => ({
      // ...prevData,
      year: selectedYear,
    }));
  };
  return (
    <div>
      <Navbar hemBurgerIcon={true} ProfileAndNotification={true} BankerNavbar={true} />
      <div className={BankerCalendarStyle1}>
        <div className={BankerCalendarStyle2}>
        <BankerSideNavbar />
        </div>
        <div className={BankerCalendarStyle3}>
          {activeTab && (
            <div className={BankerCalendarStyle4}>
              <div>
                <Calendar 
                   onChange={handleChange}
                   onClickMonth={handleMonthClick}
                   onClickYear={handleYearClick}
                    />
              </div>
              
              <div className={BankerCalendarStyle5}>
      <h6 className={BankerCalendarStyle6}>Important events</h6>
      <div>
        <p className={BankerCalendarStyle7}>Today</p>
        {getEventsData?.Today?.length>0 ? getEventsData?.Today?.filter((item)=>item?.deleted_at==null)?.map((item) => (
            <EventItem
            color={item?.priority ==1 ? 'text-green-500': item?.priority ==2 ? 'text-yellow-500' : item?.priority ==3 ? "text-orange-500" : 'text-red-500'}
            title={item.title}
            time={formatTime(item.start_time)}
          />
        )):
        <h6>Event Not Available</h6>
        }
      
       
      </div>
      <div className={BankerCalendarStyle8}>
        <p className={BankerCalendarStyle9}>Tomorrow</p>
        {getEventsData?.Tomorrow?.length>0 ? getEventsData?.Tomorrow?.filter((item)=>item?.deleted_at==null)?.map((item) => (
            <EventItem
            color={item?.priority ==1 ? 'text-green-500': item?.priority ==2 ? 'text-yellow-500' : item?.priority ==3 ? "text-orange-500" : 'text-red-500'}
            title={item.title}
            time={formatTime(item.start_time)}
          />
        )):
        <h6>Event Not Available</h6>
      }
      </div>
    </div>
            </div>
          )}
          <div className='w-full '>
            <div className={BankerCalendarStyle10}>
            
             
            </div>
           
           <div className='w-full'>
           <CalendarTest calendarData={calendarData} setEventInfo={setEventInfo} setisEventViewModalOpen={setisEventViewModalOpen} onClick={() => setActiveTab(!activeTab)} handleAddEventClick={handleAddEventClick}/> 
           </div>
          </div>
        </div>




       





      </div>
      <div className={BankerCalendarStyle11}>
              <div className=''>
                <Calendar view={view} onChange={onChange} value={value} formatShortWeekday={formatShortWeekday} />
              </div>
              
              <div className={BankerCalendarStyle12}>
      <h6 className={BankerCalendarStyle13}>Important events</h6>
      <div>
        <p className={BankerCalendarStyle14}>Today</p>
        {getEventsData?.Today?.length>0 ? getEventsData?.Today?.map((item) => (
            <EventItem
            color='text-green-500'
            title={item.title}
            time={item.start_time}
          />
        )):
        <h6>Event Not Available</h6>
        }
      
       
      </div>
      <div className={BankerCalendarStyle8}>
        <p className={BankerCalendarStyle14}>Tomorrow</p>
        {getEventsData?.Tomorrow?.length>0 ? getEventsData?.Tomorrow?.map((item) => (
            <EventItem
            color='text-green-500'
            title={item.title}
            time={item.start_time}
          />
        )):
        <h6>Event Not Available</h6>
      }
      </div>
    </div>
            </div>
      <BankerAddEvent
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveEvent}
        onEventViewClose={() => setisEventViewModalOpen(false)}
        eventInfo={eventInfo}
        type="addCalendarBanker"
      />
      <BankerEventView
        isOpen={isEventViewModalOpen}
        onClose={() => setisEventViewModalOpen(false)}
        onSave={handleSaveEvent}
        setIsModalOpen={setIsModalOpen}
           setEventInfo={setEventInfo}
        eventInfo={eventInfo}
      />
    </div>
  );
};

export default BankerCalendar;
