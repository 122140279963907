import React, { useEffect, useState } from 'react';
import Navbar from '../../../../common/Navbar';
import InputField from '../../../../common/InputField';
import Button from '../../../../common/Button';
import { useNavigate } from 'react-router-dom';
import { BankDetailsValidation } from '../../../../Hook/validation';
import { ToastMessage, handleAlphabeticInput, handleNonNumericInput, handleNumericInput } from '../../../../common/Config';
import usePost from '../../../../Hook/usePost';
import { BankerBankDetailsStyle1, BankerBankDetailsStyle2, BankerBankDetailsStyle3, BankerBankDetailsStyle4 } from './BankersDasboardStyle';

const BankerBankDetails = () => {
  const navigate = useNavigate();
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";

  const [formData, setFormData] = useState({
    account_number: '',
    holder_name: '',
    ifsc_code: '',
    account_type: '',
    bank_name:'',
    bank_branch_name:''
  });

  const [errors, setErrors] = useState({});

  const {data, error, loading, postData} = usePost("job/save-bank-details");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if(errors[name]) BankDetailsValidation(formData, setErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // BankDetailsValidation(formData, setErrors);

    if(BankDetailsValidation(formData, setErrors)) {
      postData(formData, token);
    }

    // API call to save the form data
    // Your API call logic here
  };

  useEffect(() => {
    if(data?.status_code === 200 && !error) {
      ToastMessage("success", data?.message);
      setFormData({account_number: '', holder_name: '', ifsc_code: '', account_type: '',bank_branch_name:'',bank_name:''});
      navigate("/dashboard/banker");
    }
    if(error){
      ToastMessage("error", error?.response?.data?.banker_user || 'Something went wrong');
    }
  }, [data, error])


  return (
    <div>
      
        <Navbar hemBurgerIcon={true} BankerNavbar={true} BackBtn={true} ProfileAndNotification={true} />
      

      <div className={BankerBankDetailsStyle1}>
        <h3 className={BankerBankDetailsStyle2}>Add Your Bank Details</h3>
        
        <form onSubmit={handleSubmit}>
          <div className={BankerBankDetailsStyle4}>
            <InputField
              label="Account number"
              name="account_number"
              value={formData.account_number}
              onChange={handleInputChange}
              astrix={true}
              disabled={false}
              onkeyDown={handleNonNumericInput}
              type="text"
              id="accountNumber"
              required={true}
              error={errors.account_number}
            />
          </div>
          <div className={BankerBankDetailsStyle4}>
            <InputField
              label="Account holder name"
              name="holder_name"
              value={formData.holder_name}
              onChange={handleInputChange}
              onkeyDown={handleAlphabeticInput}
              astrix={true}
              disabled={false}
              type="text"
              id="accountHolderName"
              required={true}
              error={errors.holder_name}
            />
          </div>
          <div className={BankerBankDetailsStyle4}>
            <InputField
              label="Bank IFSC"
              name="ifsc_code"
              value={formData.ifsc_code}
              onChange={handleInputChange}
              astrix={true}
              disabled={false}
              type="text"
              id="bankIFSC"
              required={true}
              error={errors.ifsc_code}
            />
          </div>
          <div className={BankerBankDetailsStyle4}>
            <InputField
              label="Type of Account"
              name="account_type"
              value={formData.account_type}
              onChange={handleInputChange}
              onkeyDown={handleAlphabeticInput}
              astrix={true}
              disabled={false}
              type="text"
              id="typeOfAccount"
              required={true}
              error={errors.account_type}
            />
          </div>
          <div className={BankerBankDetailsStyle4}>
            <InputField
              label="Branch Name"
              name="bank_branch_name"
              value={formData.bank_branch_name}
              onChange={handleInputChange}
              onkeyDown={handleAlphabeticInput}
              astrix={true}
              disabled={false}
              type="text"
              id="bank_branch_name"
              required={true}
              error={errors.bank_branch_name}
            />
          </div>
          <div className={BankerBankDetailsStyle4}>
            <InputField
              label="Bank Name"
              name="bank_name"
              value={formData.bank_name}
              onChange={handleInputChange}
              onkeyDown={handleAlphabeticInput}
              astrix={true}
              disabled={false}
              type="text"
              id="bank_name"
              required={true}
              error={errors.bank_name}
            />
          </div>

          <Button disabled={loading} className={BankerBankDetailsStyle3}>{loading ? "..." :"Save"}</Button>
        </form>
      </div>
    </div>
  );
};

export default BankerBankDetails;
