import React, { useEffect, useState } from 'react';
import Navbar from '../../../../common/Navbar';
import SideNavbar from '../../../../common/SideNavbar';
import Button from '../../../../common/Button';
import TitleValueComp from '../../../../common/TitleValueComp';
import ProfileCard from '../../../../common/ProfileCard';
import { FlexGap4, JobListingViewCloseJobButton, JobListingViewDiscriptionDiv, JobListingViewDiv, JobListingViewDivMainContainer, JobListingViewDivMainHeading, JobListingViewEditJobButton, JobListingViewKeyDiv, JobListingViewKeyDivHeading, JobListingViewMainDiv, JobListingViewToggleDiv, JobListingViewToggleDivCounter } from './DashboardJobStyle';
import { CloseJobIcon, EditJobIcon, LoaderIcon } from '../../../../common/Icons';
import RoundedBox from '../../../../common/RoundedBox';
import { useNavigate, useParams } from 'react-router-dom';
import useGetData from '../../../../Hook/useGetData';
import usePost from '../../../../Hook/usePost';
import { ToastMessage } from '../../../../common/Config';
import { convertDate } from '../../../../Hook/validation';
import BottomPagination from "../../../../common/BottomPagination";

const JobListingView = () => {
    const [activeTab, setActiveTab] = useState('Suggested Profiles');
    const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
    const {id}=useParams()
    const navigate=useNavigate()
    const { data: getJobList, loading: loadingService, error: errorService, refetch:fetchJobList } = useGetData(`job/list/${id}`,token);
    const { data: closeData, loading: closeLoading, error: closeError, postData: closePostData } = usePost('job/close-job');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage1, setCurrentPage1] = useState(1);
   const itemsPerPage = 5;
    const [data,setData]=useState([])
  const tabs = ['Suggested Profiles', 'Shortlisted'];
    const profileData = [
        {
          id: 1,
          title: "Posted on",
          titleValue: convertDate(getJobList?.data[0]?.created_at) || "20/04/1965",
        },
        {
          id: 2,
          title: "Job Location",
          titleValue: getJobList?.data[0]?.preferred_location  || getJobList?.data[0]?.city?.city || "Not Available",
        },
        {
          id: 3,
          title: "Job ID",
          titleValue: getJobList?.data[0]?.job_id || "0001",
        }
      ];
 
      const KeyAreaService = getJobList?.data[0]?.key_service_details?.map((item)=>item?.name) || [];
      const shortlistedData=getJobList?.bankers?.filter((item)=>item?.shortlist==true) || []
 
      const totalPages = Math.ceil(data.length / itemsPerPage);

      const handlePageChange = (page) => {
        setCurrentPage(page);
      };
      const handlePageChange1 = (page) => {
        setCurrentPage1(page);
      };
    
      const displayData = data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
      const totalPages1 = Math.ceil(shortlistedData?.length / itemsPerPage);
      const displayData1 = shortlistedData.slice(
        (currentPage1 - 1) * itemsPerPage,
        currentPage1 * itemsPerPage
      );
    const handleEditJob=()=>{

      const object={
        id: getJobList?.data[0]?.id,
        custom_title: getJobList?.data[0]?.BkJobTitleAndCustom.custom_title,
        job_title: getJobList?.data[0]?.BkJobTitleAndCustom.job_title?.id,
        job_description: getJobList?.data[0]?.job_description,
        preferred_location:getJobList?.data[0]?.preferred_location,
        tenure:getJobList?.data[0]?.tenure?.id || 1,
        consultancy_mode: getJobList?.data[0]?.consultancy_mode?.id || 1,
        key_service:getJobList?.data[0]?.key_service_details?.map((item)=>item?.id),
        core_expertise:getJobList?.data[0]?.core_expertise_details?.map((item)=>item?.id)
    }
    navigate(`/dashboard/company/addjob/stepone/${getJobList?.data[0]?.id}`)
    }
    const handleCloseJob=()=>{
      closePostData({job:id}, token);
    }
    useEffect(()=>{
      if(closeData){
        ToastMessage("success","job close successfully")
      
      }
      if(closeError){
        ToastMessage("error","job close failed")
      }
      },[closeData,closeError])
      useEffect(()=>{
        if(getJobList?.bankers?.length>0){
          setData(getJobList?.bankers)
        }
      })
  return (
    <div>
      
        <Navbar hemBurgerIcon={true} CompanyNavbar={true} BackBtn={true} ProfileAndNotification={true}/>
      
 
      <div className={JobListingViewMainDiv}>
        <div className='w-[9%]'>
          <SideNavbar />
        </div>
        <div className='w-[91%]'>
 
            <div className={JobListingViewDiv}>
            <div className="text-start">
             <h4 className="h4-text">{ getJobList?.data[0]?.BkJobTitleAndCustom?.custom_title || "Not Available"}</h4>
          </div>
          <div className={FlexGap4}>
            <Button
              type="submit"
              className={JobListingViewEditJobButton}
              onClick={handleEditJob}
 
            >
            <EditJobIcon/>
              Edit Job
            </Button>
            <Button
              type="submit"
              className={JobListingViewCloseJobButton}
              onClick= {handleCloseJob}
            >
              <CloseJobIcon/>
              Close Job
            </Button>
          </div>
            </div>
 
            <div className='mx-10 w-1/3'>
            <TitleValueComp data={profileData} />
            </div>
 
            <div className={JobListingViewDivMainContainer}>
                {[getJobList?.data[0]?.tenure?.name  , getJobList?.data[0]?.consultancy_mode?.name].map((item ,index) =>{
                    return <div className={JobListingViewDivMainHeading}>{item}</div>
                })}
            </div>
 
            <div>
                <div className={JobListingViewDiscriptionDiv}>
                    <h6 className='h6-text'>Job Description</h6>
                    <p className='mt-2 w-full flex break-all body-text'>

                    {getJobList?.data[0]?.job_description  || "Not Available"}
                    </p>
                </div>
            </div>
 
            <div>
                <div className={JobListingViewKeyDiv}>
                    <h6 className={JobListingViewKeyDivHeading}>Key Area of Expertise</h6>

                 <RoundedBox data={KeyAreaService}/>
                </div>
            </div>
 
 
                <div>
                <div className={JobListingViewToggleDiv}>
            {tabs.map((item, index) => (
              <div key={index} onClick={() => setActiveTab(item)} className="cursor-pointer">
                <div className="flex gap-1">
                  <div>{item}</div>
                  <div className={JobListingViewToggleDivCounter}>
                  {index==0 ? getJobList?.bankers?.length || 0 :shortlistedData?.length}
                  </div>
                </div>
                <div className={`h-1 mt-4 border-1 ${activeTab === item ? 'greenbg' : 'bg-transparent'}`}></div>
              </div>
            ))}
          </div>
 
              <div className='[bg-#E0E1E6] border mx-8'></div>  
 
              <div className="mx-8 mt-8">
            {activeTab === 'Suggested Profiles' && (
              <div className="w-full">
              {loadingService || closeLoading ? <LoaderIcon /> : displayData?.map((item) => {
                return (
                  <ProfileCard
                  SaveIconShow={true}
                  saveIconUI={true}
                  shortlistBtn = "Shortlist"
                     hireBtn = "Hire Now"
                    data={item}
                    job_id={id}
                    refetch={fetchJobList}
                    typeOf="SuggestedProfiles"
                    path="job-listing"
                  />
                );
              })}
                 <BottomPagination 
           currentPage={currentPage}
           totalPages={totalPages}
           onPageChange={handlePageChange}
          />
            </div>
            )}
            {activeTab === 'Shortlisted' && (
               <div className="w-full">
               {displayData1?.map((item) => {
                 return (
                   <ProfileCard
                   saveIconUI={true}
                   SaveIconShow={true}
                   shortlistBtn = "Shortlist"
                      hireBtn = "Hire Now"
                     data={item}
                     typeOf="JobListingComp"
                     refetch={fetchJobList}
                   />
                 );
               })}
                   <BottomPagination 
           currentPage={currentPage1}
           totalPages={totalPages1}
           onPageChange={handlePageChange1}
          />
             </div>
            )}
          </div>
                </div>

        </div>
      </div>
    </div>
  );
}
 
export default JobListingView;