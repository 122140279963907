import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../common/Button';
import { AddClientIcon, CrossIcon1, DeleteGreenButton, DeleteIcon, DisableClosedIcon, DiscriptionIcon, DownArrorIcon, DownloadGreemButton, GreenCrossICon, GroupIcon, IButtonIcon, JobListingCompPencil, LocationIcon, LoctionIcon1, MenuIcon1, MileStoneRightTick, ProfileIcon1, ProfileIcon2, StepOneIcon, TimeIcon } from '../../../../common/Icons';
import SelectTagComponent from '../../../../common/SelectTagComponent';
import useGetData from '../../../../Hook/useGetData';
import { formatTime } from '../../../../Hook/validation';
import usePost from '../../../../Hook/usePost';
import { ToastMessage } from '../../../../common/Config';

const CompanyEventView = ({ isOpen, onClose, onSave,eventInfo,setIsModalOpen }) => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const [showRSVPOptions, setShowRSVPOptions] = useState(false);
  const [showResponseOptions, setShowResponseOptions] = useState(false);
  const [reason,setReason]=useState("");
  const [choice,setChoice]=useState("");
  const { data: CancelEventData,loading:CancelEventLoading, 
    error: CancelEventError, postData: postCancelEventData } = usePost(`hiring/update-cancellation`);
    const { data: getBankerList } = useGetData(`hiring/get-bankers`,token);
    const bankerName=getBankerList?.data?.filter((item)=>item?.id===eventInfo?.banker)?.[0]?.name;

    const eventDate = eventInfo?.event_date || "";  // Get the event_date or an empty string
    const dateObj = new Date(eventDate);
    
    let formattedDate = "";
    
    if (!isNaN(dateObj.getTime())) {
      // If dateObj is a valid date, format it
      formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(dateObj);
    } else {
      // Handle invalid date, perhaps set a default value or leave it as an empty string
      console.error("Invalid date provided");
      formattedDate = "Invalid Date"; // or any fallback string you prefer
    }

  const toggleRSVPOptions = () => {
    let Condition=!showRSVPOptions;
    setShowRSVPOptions(!showRSVPOptions);
    if(Condition===false && showResponseOptions===true){
      setShowResponseOptions(false)
    }
  };

  const toggleResponseOptions = () => {
    setShowResponseOptions(!showResponseOptions);
  };
  const handleChoice=(choice)=>{
    setChoice(choice)
    const obj={
      repeat_unique_code:eventInfo?.repeat_unique_code,
      "event_id":eventInfo?.id || 37,
      "reason":reason,
      "reason_choice":choice
  }
  postCancelEventData(obj,token)

  }
  const handleEdit=()=>{
    // setEventInfo({...eventInfo,edit:true});
    setIsModalOpen(true)
  }
  useEffect(()=>{
    if(CancelEventData){
      if(choice==='accept'){
        ToastMessage("success","Event Accepted Successfully")
      }
      if(choice==='decline'){
        ToastMessage("success","Event Rejected Successfully")
      }
      if(choice==='tentative'){
        ToastMessage("success","Event Tentative Successfully")
      }
      onClose()
   
      // onSave();
    }
    if(CancelEventError){
      ToastMessage("error",CancelEventError?.response?.data?.message || "Something Went Wrong")
    }
  },[CancelEventData,CancelEventError])
  if (!isOpen) return null;

  return (
    
      <div className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-[#1E1F24] bg-opacity-50 backdrop-blur-sm">
        <div className="relative pl-2 h-[20rem] w-full mx-4 sm:px-0 sm:w-[457px] bg-white rounded-lg shadow">
          <div className="flex items-center  justify-between p-4 md:p-3">
            <div>
            <h6 className="h6-text">{eventInfo?.title || "Not Available"}</h6>
            <p className='body-text text-start my-2 text-[#2C2C2C]'>
          {formattedDate || "00:00"} - {formatTime(eventInfo?.start_time) || "00:00"} to {formatTime(eventInfo?.end_time) || "00:00"}
          </p>
            </div>
            <Button
              type="button"
              onClick={onClose}
              // onClick={toggleModal}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex items-center justify-center mb-10 ml-10"
            >
              <CrossIcon1 />
            </Button>
          </div>
         
         
         <div className='px-4'>      
            <div className='flex text-start gap-4'>
            {eventInfo?.meeting_link ?   <a href={eventInfo?.meeting_link} aria-disabled="true" target="_blank" rel="noreferrer">
                <Button className="px-10 rounded-lg py-2 greenbg text-white">
                    Join
                </Button>
                </a>:
                
                  <Button className="px-10 rounded-lg py-2 bg-gray-300 text-white">
                        Join
                  </Button>
                }
                {/* {<Button disabled={eventInfo?.company !==eventInfo?.created_by } className={`px-10 py-2 rounded-lg   ${eventInfo?.banker===eventInfo?.created_by ? " !bg-gray-500":"redfolicbg"} text-white`} role="button" onClick={()=>setIsModalOpen(true)}>
                    Edit
                </Button>} */}

{eventInfo?.company===eventInfo?.created_by ? 
                      <Button className="px-10 rounded-lg py-2 redfolicbg text-white" onClick={handleEdit}>
                      Edit
                </Button>:
   <div className="relative">
   <Button 
     className="px-8 py-2 rounded-lg redfolicbg text-white flex items-center justify-between"
     onClick={toggleRSVPOptions}
     disabled={eventInfo?.status !==0}
   >
  {eventInfo?.status !== 0 && (
  eventInfo?.status === 1 ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" className='mr-4'>
      <path fill="none" stroke="#000000" stroke-width="2" d="M20 6L9 17l-5-5"/>
    </svg>
  ) : eventInfo?.status === 2 ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
      <path fill="none" stroke="#000000" stroke-width="2" d="M9 9c0-1.656 1.344-3 3-3s3 1.344 3 3c0 1.47-1.053 2.412-2.412 3.005C11.158 12.823 11 13.194 11 14m0 4h2"/>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
      <path fill="none" stroke="#000000" stroke-width="2" d="M18 6L6 18M6 6l12 12"/>
    </svg>
  )
)}
     RSVP
     <DownArrorIcon className="ml-2" />
   </Button>
   {showRSVPOptions && (
     <div className="absolute top-full left-0 mt-1 w-60 bg-white border border-gray-300 rounded shadow-lg z-10">
       <Button
         className="block w-full text-left px-4 py-2 hover:bg-gray-100"
         onClick={() => {
          //  setShowRSVPOptions(false);
           toggleResponseOptions();
           setReason("respond_to_occurrence")
         }}
       >
         Respond to occurrence
       </Button>
       <Button
         className="block w-full text-left px-4 py-2 hover:bg-gray-100"
         onClick={() => {
          //  setShowRSVPOptions(false);
           toggleResponseOptions();
           setReason("respond_to_series")
         }}
       >
         Respond to series
       </Button>
     </div>
   )}
 </div>
              }
         
         {showResponseOptions && (
            <div className="relative mt-2">
              <div className="absolute top-full right-[-243px] mt-1 w-48 bg-white border border-gray-300 rounded shadow-lg z-10">
                <Button
                  className="block w-full text-left px-4 py-2 "
                  onClick={() => {
                    handleChoice("accept")
                    setShowResponseOptions(false);
                    setShowRSVPOptions(false);
                    // Handle "Accept"
                  }}
                >
                  Accept
                </Button>
                <Button
                  className="block w-full text-left px-4 py-2 "
                  onClick={() => {
                    handleChoice("tentative")
                    setShowResponseOptions(false);
                    setShowRSVPOptions(false);
                    // Handle "Tentative"
                  }}
                >
                  Tentative
                </Button>
                <Button
                  className="block w-full text-left px-4 py-2"
                  onClick={() => {
                    handleChoice("decline")
                    setShowResponseOptions(false);
                    setShowRSVPOptions(false);
                    // Handle "Decline"
                  }}
                >
                  Decline
                </Button>
              </div>
            </div>
          )}
            </div>

            <div className='flex items-center gap-2 my-4'>
                <LocationIcon />
                <p className='body-text text-[#2C2C2C]'>
                  {eventInfo?.consultancy_mode ==1 ? "Virtual":eventInfo?.consultancy_mode ==2 ? "In-Person": "Both"}
                  {eventInfo?.consultancy_mode !==1 && `(${eventInfo?.location || "Not Available"})`}</p>
            </div>

            <div className='flex items-center gap-2 my-4'>
                <ProfileIcon2 />
                <p className='body-text text-[#2C2C2C]'>{bankerName || "Not Available"}</p>
            </div>

            <div className='flex items-center gap-2 my-4'>
                <MenuIcon1/>
                <p className='body-text text-[#2C2C2C] text-start w-[80%]'>
                  {eventInfo?.description || "Not Available"}
                </p>
            </div>

         </div>
          
        </div>
      </div>
  
  );
};

export default CompanyEventView;
