import React, { useEffect, useState } from "react";
import Navbar from "../../../../common/Navbar";
import Checkbox from "../../../../common/Checkbox";
import InputField from "../../../../common/InputField";
import Button from "../../../../common/Button";
import { useNavigate } from "react-router-dom";
import SelectTagComponent from "../../../../common/SelectTagComponent";
import {
  RemoveTPgreen,
  S2mb1,
  SearchStyleCheckboxDiv,
  SearchStyleCheckboxTitle,
  SearchStyleInputDiv,
  SearchStyleInputDivText1,
  SearchStyleInputDivText2,
  SearchStyleMainDiv,
  SearchStyleMainDivSearchButton,
  SearchStyleMainDivSearchButtonDiv,
  SearchStyleSecondDiv,
  SearchStyleSecondDivContainer,
  SearchStyleSecondDivfillsearch,
  SearchStyleSecondDivtwo,
  SearchStyleSecondDivtwoText,
  SearchStyleSecondProfileFound,
  SearchStyleSecondRecentSearh,
  SearchStyleSecondSaved,
  SearchStyleSecondSavedSearch
} from "./SearchStyle";
import { RecentSearchIcon } from "../../../../common/Icons";
import usePost from "../../../../Hook/usePost";
import { ToastMessage } from "../../../../common/Config";
import useGetData from "../../../../Hook/useGetData";
import { commenInputDivSpace } from "../../../register/RegisterStyle";
import RoundedBox from "../../../../common/RoundedBox";
import SideNavbar from "../../../../common/SideNavbar";
import { convertToSearchArray } from "../../../../Hook/validation";
 
const DashboardSearch = () => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: bankerSearchData,loading:bankerSearchLoading,
    error: bankerSearchError, postData: postBankerSearchData } = usePost(`job/banker-search`);
    const { data: getSavedData,refetch:fetchsave } = useGetData('job/banker-saved-search', token);
    const { data: getRecentData,refetch:fetchRecent } = useGetData('job/banker-recent-search', token);
    const { data: getDesignationData } = useGetData('master-data/designation/get', token);
    const { data: keyServicesData, loading: loadingKeyServices, error: errorKeyServices,refetch:fetchKeyServicesData } = useGetData('master-data/service/index', token);
    const { data: getStateData, loading: loadingState, error: errorState } = useGetData('master-data/state/get',"token");
    const { data: getEducationData } = useGetData(`master-data/education/index`,"token")
  const LevelOfEducation = getEducationData?.data?.map((item)=>item?.name) || ["Graduate", "Post Graduate", "Any education"];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [selectedOptions3, setSelectedOptions3] = useState([]);
  const [desigIds, setDesigIds] = useState([]);
  const [keyServiceIds, setKeyServiceIds] = useState([]);
  const [formData, setFormData] = useState({
    tenure: [],
    consultancy_mode: [],
    designation:[],
    service_area:[],
    // banker_experience_min_yrs: 0,
    // banker_experience_max_yrs: 0,
    preferred_location: "",
    education_level: [],
    is_saved: false,
    mandatory_fields: [],
    active:1,
    state:0,
    city:0,
  });
  const [mandatory, setMandatory] = useState({
    designation: false,
    keyServices: false,
    jobLocation: false,
    education: false
  });
  const [filterAvailHire, setFilterAvailHire] = useState([
    { id: 1, label: "Short term", description:" (Up to 3 months)", isChecked: false },
    { id: 2, label: "Medium term",description:"(Up to 6 months)", isChecked: false },
    { id: 3, label: "Long term",description:"(6 months and above)", isChecked: false }
  ]);
 
  const [modeOfConsult, setModeOfConsult] = useState([
    { id: 1, label: "Virtual", isChecked: false },
    { id: 2, label: "In Person", isChecked: false },
    { id: 3, label: "Both", isChecked: false }
  ]);
  const [limit,setLimit]=useState(2)
  const { data: getCityData, loading: loadingCity, error: errorCity } = useGetData(`master-data/city/${formData?.state}`,"token");
  const { data: DeleteRecentData,loading:DeleteRecentLoading,
    error: DeleteRecentError, postData: postDeleteRecentData } = usePost(`job/delete-recent-search`);
    const { data: DeleteSavedData,loading:DeleteSavedLoading,
      error: DeleteSavedError, postData: postDeleteSavedData } = usePost(`job/delete-saved-search`);
  const handleAvailabilityHireChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => {
      const tenure = Array.isArray(prevState.tenure) ? prevState.tenure : [];
      const consultancy_mode = Array.isArray(prevState.consultancy_mode) ? prevState.consultancy_mode : [];
 
      switch (value) {
        case 'Short term':
          setFilterAvailHire((prevState) => prevState.map((item) => (item.label === value ? { ...item, isChecked: !item.isChecked } : item)));
          return {
            ...prevState,
            tenure: tenure.includes(1) ? tenure.filter(item => item !== 1) : [...tenure, 1],
          };
        case 'Medium term':
          setFilterAvailHire((prevState) => prevState.map((item) => (item.label === value ? { ...item, isChecked: !item.isChecked } : item)));
          return {
            ...prevState,
            tenure: tenure.includes(2) ? tenure.filter(item => item !== 2) : [...tenure, 2],
          };
        case 'Long term':
          setFilterAvailHire((prevState) => prevState.map((item) => (item.label === value ? { ...item, isChecked: !item.isChecked } : item)));
          return {
            ...prevState,
            tenure: tenure.includes(3) ? tenure.filter(item => item !== 3) : [...tenure, 3],
          };
        case 'Virtual':
          setModeOfConsult((prevState) => prevState.map((item) => (item.label === value ? { ...item, isChecked: !item.isChecked } : item)));
          return {
            ...prevState,
            consultancy_mode: consultancy_mode.includes(1) ? consultancy_mode.filter(item => item !== 1) : [...consultancy_mode, 1],
          };
        case 'In Person':
          setModeOfConsult((prevState) => prevState.map((item) => (item.label === value ? { ...item, isChecked: !item.isChecked } : item)));
          return {
            ...prevState,
            consultancy_mode: consultancy_mode.includes(2) ? consultancy_mode.filter(item => item !== 2) : [...consultancy_mode, 2],
          };
        case 'Both':
          setModeOfConsult((prevState) => prevState.map((item) => (item.label === value ? { ...item, isChecked: !item.isChecked } : item)));
          return {
            ...prevState,
            consultancy_mode: consultancy_mode.includes(3) ? consultancy_mode.filter(item => item !== 3) : [...consultancy_mode, 3],
          };
        default:
          return prevState;
      }
    });
  };
 
 
 
  const handleCheckboxChange = (name) => {
    // setMandatory({ ...mandatory, [name]: !mandatory[name] });
   
    // Check if the field is already in the array
    const updatedMandatoryFields = formData.mandatory_fields.includes(name)
        ? formData.mandatory_fields.filter(field => field !== name) // Remove the field if it's already in the array
        : [...formData.mandatory_fields, name]; // Add the field if it's not in the array
   
    setFormData({ ...formData, mandatory_fields: updatedMandatoryFields });
};
 
 
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const obj={
      tenure:formData?.tenure===1 ? "Short Term(Up to 3 months)":formData?.tenure===2 ? "Medium Term (Up to 6 months)" :"Long Term (6 months and above)",
      consultancy_mode:formData?.consultancy_mode===1 ? "Virtual" :formData?.consultancy_mode===2 ? "In-person" :"Both",
      designation:formData.designation || "Not Available",
      service_area:formData.service_area || "Not Available",
      preferred_location:formData.preferred_location || "Not Available",
      education_level:formData.education_level || "Not Available",
    }
    const currentData = JSON.parse(sessionStorage.getItem("bankerSearchData")) || [];
    const currentDataArray = Array.isArray(currentData) ? currentData : [];
    currentDataArray.push(obj);
    sessionStorage.setItem("bankerSearchData", JSON.stringify(currentDataArray));
    postBankerSearchData(formData,token)
    // sessionStorage.setItem("bankerSearchData", JSON.stringify(currentData));
    // Perform the search with the form data and checkbox states
   
  };
 
  const navigate = useNavigate();
 
 
 
 
  const handleSelectChange = (name, value) => {
    if (value && typeof value === 'object') {
      setFormData({ ...formData, [name]: value.id }); // Update only with value name
    } else {
      setFormData({ ...formData, [name]: value.id });
    }
  };
 
 
  const handleMandatory = (e,field) => {
    const { checked, value } = e.target;
    setFormData(prevState => {
      const updatedMandatoryFields = checked
        ? [...prevState.mandatory_fields, field]  // Add value if checked
        : prevState.mandatory_fields.filter(item => item !== field);  // Remove value if unchecked
      return { ...prevState, mandatory_fields: updatedMandatoryFields };
    });
  };
 
  const handleOptionSelect = (option) => {
    if (!selectedOptions.includes(option?.name)) {
      setSelectedOptions([...selectedOptions, option?.name]);
      var newArr = [...desigIds];
 
      if (newArr.indexOf(option?.id) === -1) {
        newArr.push(option?.id)
      }
      setDesigIds(newArr);
    }
  };
 
  const handleOptionSelect1 = (option) => {
    if (!selectedOptions1.includes(option?.name)) {
      setSelectedOptions1([...selectedOptions1, option?.name]);
      var newArr = [...keyServiceIds];
 
      if (newArr.indexOf(option?.id) === -1) {
        newArr.push(option?.id)
      }
      setKeyServiceIds(newArr);
    }
 
  };
 
  const handleOptionSelect3 = (option) => {
    if (!selectedOptions3.includes(option)) {
      setSelectedOptions3([...selectedOptions3, option]);
      const Id=getEducationData?.data?.filter((item)=>item.name===option)[0]?.id || 0;
      setFormData({ ...formData, education_level: [...formData?.education_level, Id] });
    }
  };
  const handleOptionSelect2 = (option) => {
    if (!selectedOptions2?.includes(option?.name)) {
      setSelectedOptions2([...selectedOptions2, option?.name]);
      const Id=getCityData?.data?.filter((item)=>item.city===option.name)[0]?.id || 0;
      setFormData({ ...formData, city:Id,preferred_location:[...formData?.preferred_location,Id] });
    }
  };
 
  const handleOptionRemove = (option) => {
    setSelectedOptions(selectedOptions.filter(item => item !== option));
  };
 
  const handleOptionRemove1 = (option) => {
    setSelectedOptions1(selectedOptions1.filter(item => item !== option));
  };
 
  const handleOptionRemove3 = (option) => {
    setSelectedOptions3(selectedOptions3.filter(item => item !== option));
    setFormData({ ...formData, education_level: selectedOptions3.filter(item => item !== option) });
  };
  const handleFillSearchRecent=()=>{

    // for designation
    const selectedOptions = getRecentData?.designation?.map(id => {
      const option = getDesignationData?.data?.find(item => item.id === id);
      return option?.name;
})
setFormData({ ...formData, designation: selectedOptions });
setSelectedOptions(selectedOptions);

// for key services
const selectedOptions1 = getRecentData?.service_area?.map(id => {
  const option = keyServicesData?.data?.find(item => item.id === id);
  return option?.name;
})
setFormData({ ...formData, service_area: selectedOptions1 });
setSelectedOptions1(selectedOptions1);
// for education
const selectedOptions3 = getRecentData?.education_level?.map(id => {
  const option = getEducationData?.data?.find(item => item.id === id);
  return option?.name;
})
// setFormData({ ...formData, education_level: getRecentData?.education_level });
setSelectedOptions3(selectedOptions3);

// for tenure
const updatedFilterAvailHire = filterAvailHire.map(item => ({
  ...item,
  isChecked: getRecentData?.tenure?.includes(item.id) || false
}));
setFilterAvailHire(updatedFilterAvailHire);


// for consultancy mode
let newConsultModes = modeOfConsult;
for(let i=0;i<newConsultModes.length;i++){
  if(getRecentData?.consultancy_mode?.includes(newConsultModes[i]["id"])) {
    newConsultModes[i]["isChecked"] = true;
  }
  else {
    newConsultModes[i]["isChecked"] = false;
  }

}
setModeOfConsult(newConsultModes);
if(getRecentData?.state?.length>0){
  setFormData({ ...formData, state: getRecentData?.state[0],city:getRecentData?.city[0],tenure:getRecentData?.tenure,consultancy_mode:getRecentData?.consultancy_mode,designation:getRecentData?.designation,service_area:getRecentData?.service_area,education_level:getRecentData?.education_level });

}else{
  setFormData({ ...formData, tenure: getRecentData?.tenure,consultancy_mode:getRecentData?.consultancy_mode,education_level: getRecentData?.education_level,designation: getRecentData?.designation,service_area: getRecentData?.service_area,preferred_location: getRecentData?.preferred_location });
}
   }
 
   const handleFillSearchSaved=(item)=>{
 
    // for designation
    const selectedOptions = item?.designation?.map(id => {
      const option = getDesignationData?.data?.find(item => item.id === id);
      return option?.name;
})
setSelectedOptions(selectedOptions);
 
// for key services
const selectedOptions1 = item?.service_area?.map(id => {
  const option = keyServicesData?.data?.find(item => item.id === id);
  return option?.name;
})
setSelectedOptions1(selectedOptions1);
// for education
const selectedOptions3 = item?.education_level?.map(id => {
  const option = getEducationData?.data?.find(item => item.id === id);
  return option?.name;
})
setSelectedOptions3(selectedOptions3);
 
// for tenure
let newFilterAvailHire = filterAvailHire;
for(let i=0;i<newFilterAvailHire.length;i++){
  if(item?.tenure?.includes(newFilterAvailHire[i]["id"])) {
    newFilterAvailHire[i]["isChecked"] = true;
   
  }
  else {
    newFilterAvailHire[i]["isChecked"] = false;
  }
}
setFilterAvailHire(newFilterAvailHire);
// for consultancy mode
let newConsultModes = modeOfConsult;
for(let i=0;i<newConsultModes.length;i++){
  if(item?.consultancy_mode?.includes(newConsultModes[i]["id"])) {
    newConsultModes[i]["isChecked"] = true;
  }
  else {
    newConsultModes[i]["isChecked"] = false;
  }
 
}
setModeOfConsult(newConsultModes);
if(item?.state?.length>0){
  setFormData({ ...formData, state: item?.state[0],city:item?.city[0],tenure:item?.tenure,consultancy_mode:item?.consultancy_mode,designation:item?.designation,service_area:item?.service_area,education_level:item?.education_level });
 
}
   }
  useEffect(()=>{
    if(bankerSearchData){
      ToastMessage("success","Search Completed")
      const City=getCityData?.data?.filter((item)=>item.id===formData?.city)[0]?.city || "";
    navigate("/dashboard/company/filter",{state:{bankerSearchData:bankerSearchData?.banker_profiles,formData:formData,City:City,selectedOptions:selectedOptions,selectedOptions1:selectedOptions1,selectedOptions3:selectedOptions3}});
 
    }
    if(bankerSearchError){
      ToastMessage("error","Search Failed")
    }
  },[bankerSearchData,bankerSearchError])
 
  useEffect(() => {
    setFormData({ ...formData, designation: desigIds });
  }, [desigIds]);
 
  useEffect(() => {
    setFormData({ ...formData, service_area: keyServiceIds });
  }, [keyServiceIds]);
  useEffect(() => {
    let newFilterAvailHire = filterAvailHire;
    let newConsultModes = modeOfConsult;
   
    for(let i=0;i<newFilterAvailHire.length;i++){
      if(formData?.tenure?.includes(newFilterAvailHire[i]["id"])) {
        newFilterAvailHire[i]["isChecked"] = true;
      }
      else {
        newFilterAvailHire[i]["isChecked"] = false;
      }
    }
    for(let i=0;i<newConsultModes.length;i++){
      if(formData?.consultancy_mode?.includes(newConsultModes[i]["id"])) {
        newConsultModes[i]["isChecked"] = true;
      }
      else {
        newConsultModes[i]["isChecked"] = false;
      }
    }
   
    setFilterAvailHire(newFilterAvailHire);
    setModeOfConsult(newConsultModes);
    }, [formData,filterAvailHire,modeOfConsult]);
    useEffect(()=>{
      if(DeleteRecentData){
        ToastMessage("success","Deleted Successfully")
        fetchRecent()
      }
      if(DeleteRecentError){
        ToastMessage("error","Delete Failed")
      }
    },[DeleteRecentData,DeleteRecentError])
    useEffect(()=>{
      if(DeleteSavedData){
        ToastMessage("success","Deleted Successfully")
        fetchsave()
      }
      if(DeleteSavedError){
        ToastMessage("success","Deleted Successfully")
        fetchsave()
      }
    },[DeleteSavedData,DeleteSavedError])
  const currentData = JSON.parse(sessionStorage.getItem("bankerSearchData")) || [];
  let BankerSearchData=currentData.length > 0 ? currentData[currentData.length - 1] : null;
  let state=getStateData?.data?.filter((item)=>item.id===formData?.state)[0]?.name || "";
  let city=getCityData?.data?.filter((item)=>item.id===formData?.city)[0]?.city || "";
 
 
  return (
    <div>
      <Navbar hemBurgerIcon={true} CompanyNavbar={true} ProfileAndNotification={true} BackBtn={true} />
      <div className="block lg:hidden">
        <SideNavbar/>
      </div>
      <div className="lg:flex ">
        <form onSubmit={handleSubmit} className={SearchStyleMainDiv}>
          <div className={SearchStyleMainDivSearchButtonDiv}>
            <Button type="submit" className={SearchStyleMainDivSearchButton}>
              Search
            </Button>
          </div>
          <div>
            <h3 className="h3-text">Search Bankers</h3>
          </div>
 
            <div  className="">
              <p className="h6-text my-4">Availability for hire</p>
              <div className="grid gap-4 grid-cols-1 md:grid-cols-3 my-4">
              {filterAvailHire?.map((item, index) => {
                return <Checkbox
                  key={index}
                  id="company-radio"
                  type="checkbox"
                  label={item?.label}
                  description={item?.description}
                  value={item?.label}
                  checked={item?.isChecked}
 
                  onChange={handleAvailabilityHireChange}
                />
              })}
            </div>
            </div>
            <div  className="">
              <p className="h6-text">Mode of Consultancy</p>
              <div className="grid gap-4 grid-cols-1 md:grid-cols-3 my-4">
              {modeOfConsult?.map((item, index) => {
                return <Checkbox
                  key={index}
                  id="company-radio"
                  type="checkbox"
                  label={item?.label}
                  value={item?.label}
                  checked={item?.isChecked}
                  onChange={handleAvailabilityHireChange}
                />
              })}
            </div>
            </div>
 
         
          <div>
          <p className="h6-text">Designation</p>
           
 
            <div className={commenInputDivSpace}>
              <SelectTagComponent
                defaultVal="Designation"
                options={getDesignationData?.data || []}
                // error={errors.turnoverGrid}
                onChange={(value) => handleOptionSelect(value)}
              />
            </div>
           
 
            <div className="mt-2 lg:mt-4">
            <Checkbox
              type="checkbox"
              onChange={(e) => handleMandatory(e, "designation")}
              label="Mark all designations as mandatory"
            />
            </div>
 
            <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions} onRemove={handleOptionRemove} />
            </div>
          </div>
          <div>
          <p className="h6-text">Key Services</p>
           
 
            <div className={commenInputDivSpace}>
              <SelectTagComponent
                defaultVal="Key Services"
                options={keyServicesData?.data || []}
                // error={errors.turnoverGrid}
                onChange={(value) => handleOptionSelect1(value)}
              />
            </div>
 
            <div className="mt-2 lg:mt-4">
            <Checkbox
              type="checkbox"
              onChange={(e) => handleMandatory(e, "designation")}
              label="Mark all keywords as mandatory"
            />
            </div>
 
            <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions1} onRemove={handleOptionRemove1} />
            </div>
          </div>
 
          <div className="">
            <p className="h6-text">Preferred job location</p>
            <div className="lg:flex mt-2 gap-4">
            <div className="mt-2 lg:mt-0 relative w-full">
            <SelectTagComponent
              defaultVal={state ? state : "State"}
              options={getStateData?.data || []}
              // error={errors.turnoverGrid}
              onChange={(value) => handleSelectChange("state", value)}
            />
            </div>
            <div className="mt-2 lg:mt-0 relative lg:mb-5 w-full">
            <SelectTagComponent
              defaultVal={city ? city :"Current City"}
              options={getCityData?.data?.map((item) => {
                return {
                  ...item,
                  name: item.city,
                }
              }) || []}
              error=""
              onChange={(value) => handleOptionSelect2(value)}
 
            />
            </div>
          </div>
 
 
            <div className="mt-2 lg:mt-0">
              <Checkbox
                labelClassName="checkBox-label"
                type="checkbox"
                label={"Exclude bankers who have mentioned anywhere in."}
                checked={mandatory["preferred_location"]}
                onChange={() => handleCheckboxChange("preferred_location")}
              />
                <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions2} onRemove={handleOptionRemove3} />
            </div>
            </div>
          </div>
          <div className="mt-4">
          <p className="h6-text">Level of Education</p>
 
         
            <div className={commenInputDivSpace}>
              <SelectTagComponent
                defaultVal="Level of Education"
                options={LevelOfEducation || []}
                // error={errors.turnoverGrid}
                onChange={(value) => handleOptionSelect3(value)}
              />
            </div>
 
            <div className="mt-2 lg:mt-4">
            <Checkbox
              type="checkbox"
              onChange={(e) => handleMandatory(e, "designation")}
              label="Any education will be applicable"
            />
            </div>
           
            <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions3} onRemove={handleOptionRemove3} />
            </div>
          </div>
          {/* <div className="h-[3rem]">
 
          </div> */}
        </form>
 
 
        <div className={SearchStyleSecondDiv}>
          <div className={SearchStyleSecondDivContainer}>
            <div className={SearchStyleSecondDivtwo}>
              <div>
                <RecentSearchIcon />
              </div>
              <div>
                <h5 className={SearchStyleSecondRecentSearh}>Recent Searches</h5>
              </div>
            </div>
            <div className="mt-4">
            {BankerSearchData &&  <p className={SearchStyleSecondDivtwoText}>
  <p className={SearchStyleSecondDivtwoText}>
    {getRecentData?.recent_searches }
   
  </p>
 
 
              </p>}
            </div>
            <div className={SearchStyleSecondDivfillsearch}>
              <div>
                <p className={RemoveTPgreen} role="button" onClick={handleFillSearchRecent}>
                  Fill this search
                </p>
              </div>
              <p className={RemoveTPgreen} role="button" onClick={() => {
               postDeleteRecentData({},token)
              }}>Delete</p>
              <div>
                <div className={SearchStyleSecondProfileFound}>
                  {getRecentData?.num_profiles_found || 0} profile found
                </div>
              </div>
            </div>
          </div>
 
          <div className={SearchStyleSecondSavedSearch}>
            <div className=' flex gap-2 items-center'>
              <div>
                <RecentSearchIcon />
              </div>
              <div className={SearchStyleSecondSaved}>
                <h5 className={SearchStyleSecondRecentSearh}>Saved Searches</h5>
                <p className="primary-text-green" role="button" onClick={()=>setLimit(10)}>View All</p>
              </div>
            </div>
            {convertToSearchArray(getSavedData?.saved_searches).slice(0,limit)?.map((item, index) => (
            <>
            <div className="mt-4">
              {/* <p className={S2mb1}>UX Designer 1</p> */}
              <p className={SearchStyleSecondDivtwoText}>
              <p className={SearchStyleSecondDivtwoText}>
  {item?.recent_search_str || "Not Available"}
</p>
              </p>
            </div>
            <div className={SearchStyleSecondDivfillsearch}>
              <div>
                <p className={RemoveTPgreen} role="button"  onClick={()=>handleFillSearchSaved(item)}>
                  Fill this search
                </p>
              </div>
              <p className={RemoveTPgreen} role="button" onClick={()=>{
                postDeleteSavedData({search_id:item.id},token)
              }}>Delete</p>
              <div>
                <div className={SearchStyleSecondProfileFound}>
                  {item?.num_profiles || 0} profile found
                </div>
              </div>
            </div>
 
            </>  
            ))}
         
 
           
          </div>
          <div className="h-[7rem] block md:hidden">
 
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default DashboardSearch;
 
 