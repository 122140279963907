import React, { useEffect, useState } from "react";
import Checkbox from "../../../../common/Checkbox";
import { jobListingCompStyles } from "../../../../common/StyleCommon";
import { ClosedButtonIcon, DeleteIcon, JobListingCompPencil, ThreePointIcon } from "../../../../common/Icons";
import { useNavigate } from "react-router-dom";
import usePost from "../../../../Hook/usePost";
import { ToastMessage, apiHost } from "../../../../common/Config";

const JobListingComp = ({ refetch,job, selectedIds, setSelectedIds }) => {
  const navigate = useNavigate();
  const [ThreeDotButton , setThreeDotButton] =  useState(false)
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: rePostData, loading: rePostLoading, error: rePostError, postData: postRePostData } = usePost('job/repost-job');
  const { data: closeData, loading: closeLoading, error: closeError, postData: closePostData } = usePost('job/close-job');
  const { data: deleteJobData, loading: deleteJobLoading, error: deleteJobError, postData: deleteJobPostData } = usePost('job/close-job');

  const handleDetails = (job) => {
    navigate(`/dashboard/joblisting/view/${job.id}`);
  };
const handleJobDelete=(job)=>{
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow"
  };
  
  fetch(`${apiHost}/jobs/destroy-company-job/${job.id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      ToastMessage("success", "Job Deleted Successfully");
      refetch();
    })
    .catch((error) =>{
      ToastMessage("error", "Job Deleted Failed");
    });
}

  const handleRePost = (job) => {
    // postRePostData({ "job": job.id }, token);
    navigate(`/dashboard/company/addjob/stepone/${job?.id}`);
    // refetch()
  };
  const handleClose=(job)=>{
    closePostData({job: job.id }, token);
    setThreeDotButton(!ThreeDotButton)
    // refetch()
  }

  useEffect(() => {
    if (rePostData) {
      ToastMessage("success", "Job Reposted Successfully");
      if (refetch) refetch();
    }
    if (rePostError) {
      ToastMessage("error", "Job Reposted Failed");
    }
  }, [rePostData, rePostError]);

  const handleCheckboxChange = (e, jobId) => {
    if (e.target.checked) {
      setSelectedIds((prev) => [...prev, jobId]);
    } else {
      setSelectedIds((prev) => prev.filter((id) => id !== jobId));
    }
  };
  const handleEdit=(job)=>{
    navigate(`/dashboard/company/addjob/stepone/${job?.id}`)
  }
  const date = new Date(job.created_at);
  const formattedDate = String(date.getDate()).padStart(2, '0') + '/' +String(date.getMonth() + 1).padStart(2, '0') + '/' +date.getFullYear();


  useEffect(()=>{
    if(closeData){
      ToastMessage("success","Job closed successfully")
      if (refetch) refetch();
    }
    if(closeError){
      ToastMessage("error","Job close failed")
    }
    },[closeData,closeError])

  return (
    <div className="border rounded-[8px] pb-4 md:pb-0 md:border-none md:mt-0 mt-4">
    <div className={` md:mx-10 px-3 py-3 mt-3 md:border md:rounded-[8px] grid grid-cols-2`}>
      <div className={` ${jobListingCompStyles.checkboxContainer}`}>
        <div className={jobListingCompStyles.checkbox}>
          <Checkbox type="checkbox" checked={selectedIds.includes(job.id)} onChange={(e) => handleCheckboxChange(e, job.id)} />
        </div>
        <div className={jobListingCompStyles.textContainer} role="button" onClick={() => handleDetails(job)}>
          <h6 className={jobListingCompStyles.title}>
            { job?.BkJobTitleAndCustom?.custom_title || job?.BkJobTitleAndCustom?.job_title?.title} 
            {job?.status === 2 ? <span className={jobListingCompStyles.statusDraft}>{job?.status === 0 ? "Closed" : job?.status === 1 ? "Active" : "Draft"}</span> 
            : job?.status === 0 ? <span className={jobListingCompStyles.statusClosed}>{job?.status === 0 ? "Closed" : job?.status === 1 ? "Active" : "Draft"}</span> : ""}
          </h6>
          <p className={jobListingCompStyles.text}>Job ID : {job?.id}</p>
        </div>
      </div>
      <div className='justify-between gap-8 w-full flex '>
        <div className='text-center ml-2'>     
          <p>{job?.shortlisted_count || 0}</p>
          <p className={jobListingCompStyles.text}>Shortlisted</p>
        </div>
        <div className='text-center ml-2 relative'>
          <div className={`${ job?.status === 2 ? 'justify-end' : 'justify-between' } ${jobListingCompStyles.svgContainer} `}>
            {job?.status === 0 ? (
              <div className={jobListingCompStyles.actionButton} role="button" onClick={() => handleRePost(job)}>Repost</div>
            ) : job?.status === 2 ? (
              <div className='hidden md:block border-[#1EB469] px-3 mr-6 mt-2 py-2 font-lato font-normal rounded-[8px] primary-text-green border' role="button" onClick={() => handleRePost(job)}>Review and post</div>
            ) : (
              <div role="button" onClick={()=>handleEdit(job)}>
              <JobListingCompPencil  />

              
              

              </div>
            )}
            <ThreePointIcon className={`cursor-pointer`} onClick={()=>setThreeDotButton(!ThreeDotButton)}/>
            {ThreeDotButton && <div className=" body-text text-[#2C2C2C] absolute bg-white border px-8 py-3 cursor-pointer  z-20 top-6 flex items-center gap-2 rounded-lg right-0">
            
                  {job?.status ===0 ? <p className="flex justify-around"  onClick={()=>handleJobDelete(job)}>
                  <DeleteIcon/>   
                  <p>Delete</p>
                  </p> : <p className="flex justify-around" onClick={() =>{ handleClose(job);
                  setThreeDotButton(!ThreeDotButton)
                  }}><ClosedButtonIcon /> 
                    <p>Close</p></p>}
              </div>}
          </div>
          <p className={jobListingCompStyles.text}>{job?.status !== 0 && formattedDate}</p>
        </div>
      </div>






     




    </div>
    <div className={`w-full `}>
          <div className={`${jobListingCompStyles.svgContainer} `}>
            {job?.status === 0 ? (
              <div className='block md:hidden border-[#1EB469] w-full px-6 ml-12 mr-4 mt-2 py-2 font-lato font-normal rounded-[8px] primary-text-green border' role="button" onClick={() => handleRePost(job)}>Repost</div>
            ) : job?.status === 2 ? (
              <div className='md:hidden block border-[#1EB469] w-full px-3 ml-12 mr-4 mt-2 py-2 font-lato font-normal rounded-[8px] primary-text-green border' role="button" onClick={() => handleRePost(job)}>Review and post</div>
            ) : (
              <div role="button" onClick={()=>handleEdit(job)}>
              {/* <JobListingCompPencil  /> */}
              </div>
            )}
            {/* <ThreePointIcon className={`cursor-pointer`} onClick={()=>setThreeDotButton(!ThreeDotButton)}/> */}
            {/* {ThreeDotButton && <div className=" body-text text-[#2C2C2C] absolute bg-white border px-8 py-3 cursor-pointer  z-20 top-6 flex items-center gap-2 rounded-lg right-0">
                  {job?.status ===0 ? <p className="flex justify-around"  onClick={()=>handleJobDelete(job)}>
                  <DeleteIcon/>
                  <p>Delete</p>
                  </p> : <p className="flex justify-around" onClick={() =>{ handleClose(job);
                  setThreeDotButton(!ThreeDotButton)
                  }}><ClosedButtonIcon /> 
                    <p>Close</p></p>}
              </div>} */}
          </div>
          {/* <p className={jobListingCompStyles.text}>{job?.status !== 0 && formattedDate}</p> */}
        </div>
    </div>
  );
};

export default JobListingComp;
