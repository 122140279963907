// const CheckmarkSVG = (`
//  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M1.5 5L5.5 9L13 1.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>

// `);

// export const CheckboxInput ="appearance-none w-9 h-9 rounded-full border-2 border-gray-300 checked:bg-[#EBF1EE] checked:border-transparent focus:outline-none"
export const CheckboxInput = ` 
  form-checkbox h-[16px] cursor-pointer w-[16px] text-white rounded-[4px] appearance-none bg-white border-[1.5px] border-[#80828D] 
  checked:bg-[#1EB469] checked:border-[#1EB469] focus:outline-none transition duration-200 ease-in-out 
  hover:border-[#1EB469] hover:shadow-[0_0_0_4px_rgba(30,180,105,0.2)] relative
  before:content-[''] before:absolute before:top-0.5 before:left-0.5 before:right-0  before:bg-no-repeat before:bg-center before:w-[80%] before:h-[80%]
  before:flex before:items-center before:justify-center
  checked:before:content-[''] checked:before:text-white before:transition before:duration-200 before:ease-in-out
`;

export const RadioInput = `
  form-radio h-[22px] w-[22px] text-white cursor-pointer rounded-full appearance-none bg-white border-2 border-[#2C2C2C] 
  checked:bg-white checked:border-[#1EB469] focus:outline-none transition duration-200 ease-in-out 
  hover:border-[#1EB469] hover:shadow-[0_0_0_4px_rgba(30,180,105,0.2)] relative
  before:content-[''] before:absolute before:left-1/2 before:top-1/2 before:transform before:-translate-x-1/2 before:-translate-y-1/2 
  before:w-[10px] before:h-[10px] before:bg-[#1EB469] before:rounded-full before:scale-0 checked:before:scale-100 
  before:transition before:duration-200 before:ease-in-out
`;

export const CheckboxLabel = "ml-2 text-gray-700 font-lato text-[14px] cursor-pointer";
export const CheckboxLabelTextStart = "ml-2 text-gray-700 text-start font-lato text-[14px] cursor-pointer";

export const flexitemCentre = "flex items-center";


export const dropdownContainer = "flex px-6 my-4 justify-between";
export const dropdownContent = "px-6";
export const divider = "h-[1px] w-[90%] m-auto bg-[#E0E1E6]";
export const dropdownSubTitle = "body-text text-[#80828D] my-1 text-start";

export const selectContainer = "w-full relative inline-block";
export const selectButton = "truncate secondary-text-gray w-full m-auto border-gray-200 hover:border-black bg-white border-[1px] font-medium rounded-lg text-sm pl-3 h-[48px] py-2.5 text-center inline-flex items-center";
export const selectButtonError = "border-red-500 primary-text-red";
export const selectDropdown = "absolute left-center mt-2 m-auto w-full bg-white z-50 divide-y divide-gray-100 rounded-lg shadow ";
export const selectOption = "bloc font-lato font-medium px-4 rounded-lg hover:bg-gray-100 text-start py-2 w-full";

// titleValueCompStyles.js
export const container = "flex items-left mt-2 gap-1";
export const title = "primary-text-gray w-[35%] text-start text-[14px]";
export const separator = "primary-text-gray w-[5%] text-start text-[14px]";
export const value = "text-[14px] font-bold w-[45%] text-start secondary-text-gray";


export const getInputClassNames = (span, error, disabled) => {
  const baseClass = `peer block custom-input w-full ${!disabled ?"bg-white" :""} appearance-none s3-text rounded-[8px] border px-3.5 py-3 text-sm focus:outline-none focus:ring-0`;
  const errorClass = error ? 'border-red-600' : 'border-[#DADCE0]';
  const focusClass = error ? 'border-red-600' : 'focus:border-green-500';
  const disabledClass = disabled ? "bg-[#E8E8E8] text-[#BDBDBD]" : "bg-transparent text-gray-700 hover:border-[#2C2C2C]";
  const paddingClass = span ? "pl-12 pr-14" : "px-2.5 py-2";
 
  return `${baseClass} ${errorClass} ${focusClass} ${disabledClass} ${paddingClass}`;
};

export const getInputClassNames1 = (span, error, disabled) => {
  const baseClass = `peer custom-input block w-full ${!disabled ?"bg-white" :""} appearance-none s3-text rounded-[8px] border px-3.5 py-3 text-sm focus:outline-none focus:ring-0`;
  const errorClass = error ? 'border-red-600' : 'border-[#DADCE0]';
  const focusClass = error ? 'border-red-600' : 'focus:border-green-500';
  const disabledClass = disabled ? "bg-white text-black border-dashed border-2" : "bg-transparent hover:border-[#2C2C2C]";
  const paddingClass = span ? "pl-12 pr-14" : "px-2.5 py-2 pt-4";
 
  return `${baseClass} ${errorClass} ${focusClass} ${disabledClass} ${paddingClass}`;
};
 
export const getLabelClassNames = (span, error, disabled) => {
  const baseClass = `absolute ${error?"primary-text-red" : "primary-text-green"} peer-placeholder-shown:text-gray-500 top-2 z-20 origin-[0] font-lato -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2`;
  const positionClass = span ? "left-12 " : "left-1";
  const colorClass = disabled ? "bg-[#E8E8E8] text-[#BDBDBD]" : error ? "primary-text-red" : "text-gray-700";
  const focusColorClass = error ? "" : "peer-focus:text-green-500";
 
  return `${baseClass} ${positionClass} ${colorClass} ${focusColorClass}`;
};
 
export const getSpanClassNames = (disabled) => {
  const baseClass = "absolute top-1/2 transform -translate-y-1/2 left-3 z-10";
  const stateClass = disabled ? "bg-[#E8E8E8] text-[#BDBDBD]" : "bg-transparent secondary-text-gray";
  return `${baseClass} ${stateClass}`;
};
 
export const getErrorClassNames = "primary-text-red text-sm absolute right-0";
 
 
export const getAstrixClassNames = "secondary-text-gray";
export const InputMainDiv ="relative mt-2 w-full"
export const InputFieldClass= "border-1 peer block w-full appearance-none rounded-lg border border-gray-300 bg-transparent px-2.5 pb-1.5 pt-4 text-sm text-gray-900 focus:greenborder focus:outline-none focus:ring-0"
export const InputFieldClass1= "hidden border-1 peer block w-full appearance-none rounded-lg border border-gray-300 bg-transparent px-2.5 pb-1.5 pt-4 text-sm text-gray-900 focus:greenborder focus:outline-none focus:ring-0"
export const LabelFieldClass1 = "hidden absolute top-2 left-1 z-10 origin-[0] font-lato -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm primary-text-gray duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:primary-text-green"

export const LabelFieldClass = "absolute top-2 left-1 z-10 origin-[0] font-lato -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm primary-text-gray duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:primary-text-green"
export const Heading1 = 'font-lato text-sm font-normal leading-4 text-left'
export const AuthButtonClass = "w-full rounded-lg greenbg py-3 text-white"
export const AuthButtonDisabledClass = "w-full rounded-lg bg-[#8ED9B4] py-3 text-white"
export const setPassButtonClass = " w-full lg:w-[560px] rounded-lg greenbg py-3 mt-6 px-auto text-white"

export const bottomBarStyles = {
  container: "relative z-40 mb-10",
  bar: "w-full border bg-white py-2 fixed bottom-0",
  flexContainer: "flex justify-between",
  backButton: "ml-10",
  buttonsContainer: "flex gap-4 mr-14",
};

export const ProfilePhotoUploadModelStyles= {
  mainContainer : "w-[15rem]  h-[15rem] relative m-auto rounded-full bg-gray-300",

}

export const SelectedTrue="bg-pink-500 text-white border-[#2C2C2C] font-lato font-normal";
export const modalContainer = "overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50";
export const modalContent = "relative p-4 w-full h-[30rem] max-w-2xl";
export const modalBackground = "relative bg-white rounded-lg shadow ";
export const modalHeader = "flex items-center justify-between p-4 md:p-5 border-b rounded-t ";
export const modalTitleText = "text-xl font-semibold text-gray-900 ";
export const modalInput = "border-1 peer block w-full appearance-none rounded-lg border border-gray-300 bg-transparent px-2.5 pb-1.5 pt-4 text-sm text-gray-900 focus:greenborder focus:outline-none focus:ring-0";
export const modalInputLabel = "absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm primary-text-gray duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:primary-text-green";
export const tagContainer = "light-gray-background border border-[#E0E1E6] overflow-auto custom-scrollbar h-[15rem] py-2 px-2 rounded-lg";
export const suggestText = "mediumFont text-[#000000] pl-3 my-4";
export const modalFooter = "flex items-center justify-between py-2 border-t border-gray-200 rounded-b ";
export const selectedStyle = "bg-[#FF0059] text-white font-lato font-normal";
export const unselectedStyle = "border border-[#2C2C2C] secondary-text-gray font-lato font-normal";


// jobListingItemStyles.js
export const jobListingcontainer = "mx-10 px-3 py-3 mt-3 border rounded-[8px] grid grid-cols-2";
export const jobListingtitle = "font-semibold text-lato";
export const statusTag = "border bg-[#E7E8EC] text-[#1E1F24] font-lato font-normal rounded-[24.5px] ml-4 py-0.5 px-3";
export const shortlistedInfo = "justify-between gap-8 w-full flex";
export const actionButtons = "font-semibold flex justify-between text-lato";
export const repostButton = "border-[#1EB469] px-6 mr-6 mt-2 py-2 font-lato font-normal rounded-[8px] primary-text-green border";
export const reviewButton = "border-[#1EB469] px-3 mr-6 mt-2 py-2 font-lato font-normal rounded-[8px] primary-text-green border";
 
 
export const jobListingCompStyles = {
    container: "md:mx-10 px-3 py-3 mt-3 border rounded-[8px] grid grid-cols-2",
    checkboxContainer: "flex",
    checkbox: "mt-1",
    textContainer: "text-start ml-2",
    title: "font-semibold text-lato",
    statusDraft: "border bg-[#E7E8EC] text-[#1E1F24] font-lato font-normal rounded-[24.5px] ml-4 py-0.5 px-3",
    statusClosed: "border bg-[#FFDADD] text-[#6F0020] font-lato font-normal rounded-[24.5px] ml-4 py-0.5 px-3",
    text: "s3-text mt-3 primary-text-gray",
    infoContainer: "justify-end gap-8 w-full flex",
    countContainer: "text-center ml-2 ",
    countTitle: "font-semibold text-lato",
    actionContainer: "text-center ml-2 relative",
    actionButton: "hidden md:block border-[#1EB469] px-6 mr-6 mt-2 py-2 font-lato font-normal rounded-[8px] primary-text-green border",
    actionButtonSmall: "hidden md:block border-[#1EB469] px-3 mr-6 mt-2 py-2 font-lato font-normal rounded-[8px] primary-text-green border",
    svgContainer: "font-semibold flex text-lato",
    svg: "mt-1 ml-4",
  };
 


  export const PrfileCardTitleStyle = "primary-text-gray w-[45%] text-start text-[14px]";
  export const PrfileCardDashtyle = "primary-text-gray w-[5%] text-start text-[14px]";
  export const PrfileCardValueStyle = "primary-text-gray w-[45%] text-start text-[14px] font-semibold ";



 
  export const profileCardStyles = {
    // container: "border bg-white border-1 mt-4 rounded-xl px-5 py-3",
    // header: "flex justify-between items-center",
    // profileInfoContainer: "flex items-center",
    // profileImage: "w-[3rem] h-[3rem] rounded-full bg-black",
    // profileDetails: "ml-4",
    // nameTitle: "font-lato text-[22px] font-bold secondary-text-gray",
    // subTitle: "font-lato text-start text-[14px] primary-text-gray",
    // actionContainer: "flex items-center space-x-4",
    // button: "w-[92px] h-[38px] rounded-lg text-white",
    // icon: "ml-2",
    // tabsContainer: "flex mt-4 flex-wrap overflow-x-auto gap-2",
    // tab: "px-4 bg-gray-100 text-[#62636C] text-[14px] font-lato rounded-xl py-1",
    // experienceTitle: "text-start mt-6 font-lato font-bold text-[18px] secondary-text-gray",
    // experienceGrid: "grid grid-cols-2",
    // experienceFlex: "grid grid-cols-1",
    // experienceItem: "flex items-left mt-2 gap-1",
    // expertiseTitle: "text-start mt-6 font-lato font-bold text-[18px] secondary-text-gray",
    // expertiseContainer: "flex w-full m-auto gap-2 py-1 flex-wrap",
    // expertiseItem: "text-start mt-4 gap-2 flex rounded-[24.5px] border border-[#2C2C2C] px-4 py-1",
    container: "border bg-white border-1 mt-4 rounded-xl px-5 py-3",
    header: "flex justify-between items-center flex-wrap",
    profileInfoContainer: "flex items-center flex-wrap",
    profileImage: "w-[3rem] h-[3rem] rounded-full bg-black",
    profileDetails: "ml-4",
    nameTitle: "font-lato text-[22px] text-start font-bold secondary-text-gray",
    subTitle: "font-lato text-start text-[14px] primary-text-gray",
    actionContainer: "flex items-center space-x-4 mt-2 sm:mt-0",
    button: "w-[92px] h-[38px] rounded-lg text-white",
    icon: "ml-2",
    tabsContainer: "flex mt-4 text-start flex-wrap overflow-x-auto gap-2",
    tab: "px-4 bg-gray-100 text-[#62636C] text-[14px] font-lato rounded-xl py-1",
    experienceTitle: "text-start mt-6 font-lato font-bold text-[18px] secondary-text-gray",
    experienceGrid: "grid grid-cols-1 sm:grid-cols-2",
    experienceFlex: "grid grid-cols-1",
    experienceItem: "flex items-left mt-2 gap-1",
    expertiseTitle: "text-start mt-6 font-lato font-bold text-[18px] secondary-text-gray",
    expertiseContainer: "flex w-full m-auto gap-2 py-1 flex-wrap",
    expertiseItem: "text-start mt-4 gap-2 flex rounded-[24.5px] border border-[#2C2C2C] px-4 py-1",

  };



  // Bottombar styles
  export const bottombarMainDivStyle = "relative z-40 mb-10";
  export const bottombarButtonStyle ="w-full  my-2 lg:my-0 redfolicbg font-medium rounded-lg text-sm px-12 py-2 text-center ";
  export const bottombarButtonStyle1 ="w-full  my-2 lg:my-0 redfolicbg1 font-medium rounded-lg text-sm px-12 py-2 text-center ";
  export const bottombarDivStyle ="w-full border bg-white py-2 fixed bottom-0"
  export const bottombarNextButtonStyle="w-full greenbg text-white hover:text-white border greenborder hover:greenbg focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2 text-center "
  export const flexjustifybt = "flex justify-between"
  export const bottombarSaveBtn = "flex justify-between w-full md:px-0 gap-4 lg:mr-14 md:mr-14 px-4 lg:px-0"
  export const bottombarButtonContainer = "lg:ml-10 px-4"


  // Dopdown Styles
  export const dropdownStyleHeading = "h6-text";
  export const flexItemStart = "flex items-start";


  // Expertise Model

  export const expertiseModelStyleButton = "primary-text-gray bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center ";
  export const expertiseModelStyleInputMainDiv = "p-4 md:p-5 space-y-4"
  export const expertiseModelStyleInputDiv = "relative mt-2 w-full"
  export const expertiseModelStyleTag = "flex w-full m-auto h-auto py-2 px-2 rounded-lg gap-3 flex-wrap"
  export const expertiseModelStyleSaveBtn = "py-2.5 ml-auto px-12 mr-5 text-sm font-lato font-medium text-white greenbg rounded-lg hover:greenbg"
  export const expertiseModelStyleSaveBtnDisable = "py-2.5 ml-auto px-12 mr-5 text-sm font-lato font-medium text-white bg-[#8ED9B4] rounded-lg hover:greenbg"

  
  export const ExpertiseTagStyle="cursor-pointer text-start body-text py-2.5 px-4 rounded-full flex gap-2 flex-wrap"


  // Navbar Style
  
  export const navbarStyleContainer = "sticky z-50 absolute top-0 right-0 left-0"
  export const navbarStyleDiv = " relative w-full bg-white border h-[58px] text-[23.56px] font-lato flex fixed top-0";
  export const navbarStyleLogo = "pl-4 h4-text text-lg text-[#FF0059]";
  export const navbarStyleDivTwo = "flex justify-center items-center";
  export const navbarStyleDivTwoBackBtn="flex justify-center items-center cursor-pointer remove-text gap-4 px-4"
  export const navbarStyleVLine = "h-[50%] border"
  export const NavbarStyleskipData = "absolute right-3 lg:right-8"
  export const NavbarStyleGetHired = "mr-4 mt-3 cursor-pointer s1-text text-[#000000]"
  export const NavbarStyleBuyBtn = "text-white mt-2.5 greenbg font-lato font-[500] rounded-lg text-sm px-5 py-2 text-center "


  // Select tag Style
  export const DropdownDeleteBtnStyle = "py-2 text-sm primary-text-gray ";
  export const DropdownStyleDefaultVal =""

  // Side Navbar
export const SideStyleContainer = "light-gray-background";
export const SideNavbarStyleMainContainer = "fixed shadow-xl px-1 py-2 pb-10";
export const SideNavbarStyleContainer = "my-2 mx-4 max-w-max cursor-pointer";
export const SideNavbarStyleContainerMobile = "w-full px-4 py-3 border gap-2 text-start flex justify-start items-center";

export const SideNavbarStyleDiv = "rounded-full flex items-center justify-center m-auto w-[48px] h-[48px]";
export const SideNavbarOptionActive = "bg-green-100";
export const SideNavbarOptionActiveMobile = "bg-green-100 gap-2 w-full px-4 py-3  border text-start flex justify-start items-center";

export const SideNavbarStyleDivTwo = "rounded-full flex items-center justify-center m-auto w-[48px] h-[48px] ";
export const SideNavbarStyleDivTwoMobile = "gap-4";

export const SideNavbarStyleDivPtag = "text-[14px] m-auto text-[#62636C] font-lato";
export const SideNavbarStyleDivPtagtwo = "text-[14px] text-[#62636C] font-lato"
export const SideNavbarStyleDivPtagtwoGreen = "text-[14px] text-[#1EB469] font-lato"

export const uploadPhotoInputHide = "h-[7rem] bg-red-600 rounded-full w-[7rem] opacity-0";
export const uploadPhotoInputHide1 = "h-[7rem] bg-red-600 rounded-full w-[7rem] opacity-0";

export const MarginForProfileCoreExpertise = "my-4"
export const RoundedBoxMainDiv = "flex gap-3 flex-wrap";
export const RoundedBoxItemStyle1 = "roundedBox px-4 py-1 flex items-center justify-center";
export const RoundedBoxItemStyle2 = "px-2 bg-gray-100 text-[#62636C] text-[12px]  font-lato rounded-[24.5px] py-1";


export const CommenHRLine = "h-[1px] w-[85%] my-2 m-auto bg-gray-300"


export const BankerSideNavbarStyleContainer1 = "xl:hidden fixed w-full h-full top-0 left-0 right-0 bg-white z-50 flex flex-col items-center justify-start";
export const BankerSideNavbarStyleContainer2 ="m-4 mb-4 w-full px-4 flex justify-between cursor-pointer"
export const BankerSideNavbarStyleContainer3 ="hidden lg:flex fixed bottom-0 left-0 right-0 lg:left-0 lg:right-auto lg:bottom-auto border md:justify-start lg:justify-start justify-between px-4 shadow-lg h-[100vh] w-[7%] bg-white z-40 flex-col items-center"

 
export const BottomBarStyle112 = 'lg:ml-10 px-4 hidden md:block'
export const BottomBarStyle113 ='flex justify-between md:justify-end w-full md:px-0 gap-4 lg:mr-14 md:mr-14 px-4 lg:px-0';

export const DoNotFoundStyle1 = 'w-full h-[50vh] flex flex-col items-center justify-center'

export const FeedbackModelStyle1 = "bg-white rounded-lg py-5 px-4 w-full md:w-[590px] relative";
export const FlexJustiEnd = 'flex justify-end';
export const FeedbackModelStyle2 = "fixed inset-0 px-4 flex items-center justify-center z-50 bg-black bg-opacity-50"
export const FeedbackModelStyle3 ="flex justify-center gap-3 mb-4"
export const FeedbackModelStyle4 ="w-full body-text h-[8rem] px-4 resize-none py-4 border rounded mb-4"
export const FeedbackModelStyle5 ='text-[red] text-sm'
export const FeedbackModelStyle6 ="greenbg px-3 body-text py-2 text-white rounded-lg "

export const FilterCardStyleMaindiv = "w-full sticky-timeline-filter mt-4"

export const LoaderAPIstyle1 = "w-full h-full fixed top-0 left-0 bg-white opacity-100 z-[1000]";
export const LoaderAPIstyle2 ="flex justify-center items-center mt-[50vh]"
export const LoaderAPIstyle3 ="fas fa-circle-notch fa-spin fa-5x text-violet-600";

export const NavbarStyle1 = "hidden md:block";
export const NavbarStyle2 ="lg:hidden pl-4 block"
export const NavbarStyle3 ="pl-4 cursor-pointer"
export const NavbarStyle4 ="text-end s2-text cursor-pointer mt-4"
export const NavbarStyle5 ="flex justify-center items-center gap-5 mt-2"

export const NotificationStyle1 = 'w-[492px] absolute right-0 top-14 rounded-lg border bg-white';
export const NotificationStyle2 ='p-4 border'
export const NotificationStyle3 ='h6-text text-start'
export const NotificationStyle4= 'flex border bg-[#F4FBF6] w-full py-5'
export const NotificationStyle5= 'w-[54px] mx-5 h-[54px] bg-[#FFDADD] flex justify-center items-center rounded-full border'
export const NotificationStyle6='cursor-pointer py-2 h6-text remove-text text-[#1EB469]'
export const NotificationStyle7= 'flex justify-center py-10 items-center'
export const NotificationStyle8= 'm-auto w-2/3 mb-14'
export const NotificationStyle9='h6-text text-[#2C2C2C]'






