import React from 'react'
import Navbar from '../../../../common/Navbar'
import BankerSideNavbar from '../../../../common/BankerSideNavbar'
import { useNavigate } from 'react-router-dom';
import Button from '../../../../common/Button';
import { CrossSubscriptionIcon } from '../../../../common/Icons';

const CancelSubscriptionThree = () => {
  const navigate = useNavigate();

  return (
    <div>
    <Navbar hemBurgerIcon={true} BackBtn={true} ProfileAndNotification={true} BankerNavbar={true}/>

    <div className='flex'>
      <div className='w-0 md:w-[7%]'>
          <BankerSideNavbar />
      </div>
      <div className='px-4 md:px-8 py-4 w-full md:w-[93%]'>
           <h4 className='h4-text text-start px-4 text-[#2C2C2C]'>Cancel Subscription</h4>
            <div className='w-full bg-[#F9F9FB] mt-8 rounded-lg border px-4 py-6'>
                <h6 className='h6-text text-[#2C2C2C]'>
                You are about to cancel your subscription
                </h6>
                <p className='s3-text text-start my-3 text-[#2C2C2C]' >
                Once your subscription expires on your next invoice date, you'll lose access to Intercom:
                </p>

                <div className='w-full border'></div>

                <div className='mt-6'>

                    <p className='flex items-center my-8 text-start gap-2 s3-text text-[#2C2C2C]'>
                      <div className='max-w-max'>
                      <CrossSubscriptionIcon/> 
                      </div>
                      Your customers won't be able to use the world's best business messenger
                    </p>
                    <p className='flex items-center my-8 gap-2 s3-text text-[#2C2C2C]'>
                     <div className='max-w-max'>
                     <CrossSubscriptionIcon/> 
                     </div>
                      You won't be able to reply to conversations
                    </p>
                    <p className='flex items-center text-start my-8 gap-2 s3-text text-[#2C2C2C]'>
                      <div className='max-w-max'>
                      <CrossSubscriptionIcon/> 
                      </div>
                      You'll lose your settings and configurations
                    </p>

                </div>

                <div className='text-start mt-6 lg::flex gap-4' >
                  <Button className="px-4 ml-4 py-2 greenbg text-white rounded-lg">
                  Return to billing
                  </Button>

                  <Button onClick={()=>navigate("/dashboard/banker/cancel-subscription")}  className="px-4 py-2 ml-4 md:mt-0 mt-4 redfolicbg text-white rounded-lg">
                  Cancel my subscription
                  </Button>
                </div>

            </div>
        </div>

    </div>
  </div>
  )
}

export default CancelSubscriptionThree
