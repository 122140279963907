import React, { useEffect, useRef } from "react";
import {
  InputMainDiv,
  getAstrixClassNames,
  getErrorClassNames,
  getInputClassNames,
  getInputClassNames1,
  getLabelClassNames,
  getSpanClassNames,
} from "./StyleCommon";
import { CopyIcon, InputDOBIcon, InputSearchIcon, UplaodSalarySlipIcon } from "./Icons";
import { ToastMessage } from "./Config";
import usePost from "../Hook/usePost";
import { onkeyDownforSpecialCharcter } from "../Hook/validation";

const InputField = ({
  label,
  type,
  name,
  id,
  value,
  onChange,
  astrix,
  required,
  remove,
  span,
  disabled,
  onkeyDown,
  error,
  DobInput,
  SearchIcon,
  maxLength,
  pattern,
  searchClick,
  allDate,
  shouldAllowPastDates = true,
  accept,
  UplaodIcon,
  setSalarySlip,
  dataSetFile,
  setDataSet,
  CopyIconValue,
  onClickCopy,
  onClick,
  isHireDocument = false,
  mindates,
  MaxDate,
  placeholder,
  className,
  UploadClass

}) => {

  const maxdate =MaxDate ? MaxDate : allDate ? new Date().toISOString().split("T")[0] : allDate;
  const mindate =mindates || shouldAllowPastDates ? "1900-01-01" : new Date().toISOString().split("T")[0];
  const fileInputRef = useRef(null);
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  
  const { data: salarySlipData,loading:salarySlipLoading, error: salarySlipError, postData: postSalarySlipData } = usePost('onboarding/banker/remove-salary-slip');
  const handleRemoveClick = () => {
    postSalarySlipData({},token)
  };
useEffect(()=>{
if(salarySlipData){
  ToastMessage('success','Salary Slip Removed Successfully')
  setDataSet('hjh')
  setSalarySlip(!remove)
}
if(salarySlipError){
  ToastMessage('error','Something went wrong')
}
},[salarySlipData,salarySlipError])
  return (
    <div className={InputMainDiv}>
      {span && <span className={getSpanClassNames(disabled)}>{span}</span>}
      {DobInput && <span className="absolute right-4 top-3">
        <InputDOBIcon />
        

      </span>}
      {remove &&
       <span onClick={isHireDocument ? remove?.props?.onClick : handleRemoveClick} className="absolute right-4 top-4 flex justify-between w-[95%] cursor-pointer primary-text-green remove-text">
        <span className="mr-4">{dataSetFile}</span>
        <span>
        Remove

        </span>

      </span>}
      {UplaodIcon && !remove && <label htmlFor="salarySlip" className=" py-8" style={{height:"100px"}}>
        <span  className="absolute right-4 top-2 cursor-pointer primary-text-green remove-text">
        <UplaodSalarySlipIcon/>

      </span></label>}

      {SearchIcon && <span className="absolute bg-white px-2 right-2 top-3.5 cursor-pointer " onClick={searchClick}>
        <InputSearchIcon />

      </span>}
      {CopyIconValue && <span className="absolute bg-white px-2 py-1  right-1 top-2.5 cursor-pointer primary-text-green remove-text" >
   <CopyIcon onClick={onClickCopy}/>

      </span>}
      {type === "date" ? (<input
        disabled={disabled}
        type={type}
        accept={accept}
        name={name}
        id={id}
        required={false}
        maxLength={maxLength}
        max={maxdate}
        min={mindates || mindate}
        pattern={pattern}
        onKeyDown={onkeyDown}
        onClick={onClick}
        value={value}
        onChange={onChange}
        className={ UploadClass ? getInputClassNames1(span, error, disabled) :getInputClassNames(span, error, disabled)}
        placeholder={placeholder?placeholder:" "}
      />) :type === "file" ? (
      <div  className={`${getInputClassNames(span, error, disabled)} p-6 !pt-[30px]`}>
      <input
        ref={fileInputRef}
        disabled={disabled}
        type={type}
        name={name}
        id={id}
        style={{display:"none"}}
      
        required={false}
        maxLength={maxLength}
        max={maxdate}
        pattern={pattern}
        onKeyDown={onkeyDown}
        accept={accept}
        value={value}
        onChange={onChange}
        className={UploadClass ? getInputClassNames1(span, error, disabled) :getInputClassNames(span, error, disabled)}
        placeholder={placeholder?placeholder:" "}
      /></div>
      ) :type=="number" ? (<input
      
        disabled={disabled}
        type="text"
        name={name}
        accept={accept}
        id={id}
        required={false}
        maxLength={maxLength}
        pattern={pattern}
       onKeyDown={(evt) => {
    if (onkeyDown(evt)) {
      onkeyDown(evt);
    } else {
      onkeyDownforSpecialCharcter(evt);
    }
  }}
        value={value}
        onChange={onChange}
        className={UploadClass ? getInputClassNames1(span, error, disabled) :getInputClassNames(span, error, disabled)}
        placeholder={placeholder?placeholder:" "}
      />):(<input
      
        disabled={disabled}
        type={type}
        name={name}
        accept={accept}
        id={id}
        required={false}
        maxLength={maxLength}
        pattern={pattern}
        onKeyDown={onkeyDown}
        value={value}
        onChange={onChange}
        className={UploadClass ? getInputClassNames1(span, error, disabled) :getInputClassNames(span, error, disabled)}
        placeholder={placeholder?placeholder:" "}
      />)}
      <label htmlFor={id} className={getLabelClassNames(span, error, disabled)}>
      {!disabled &&label}
        { !disabled && astrix && <span className={getAstrixClassNames}>*</span>}

      </label>


      {error && <p className={getErrorClassNames}>{error}</p>}
    </div>
  );
};

export default InputField;
