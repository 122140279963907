import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { apiHost } from '../common/Config';

const useGetData = (url, token) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refetchIndex, setRefetchIndex] = useState(0);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const headers = token ? { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } : {};
      const response = await axios.get(`${apiHost}/${url}`, { headers });
      setData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [url, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData, refetchIndex]);

  const refetch = () => setRefetchIndex(prev => prev + 1);

  return { data, loading, error, refetch };
};

export default useGetData;
