import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../common/Navbar";
import Button from "../../../common/Button";
import { 
  BankerPlanStyleContainer, 
  BankerPlanStyleContainerActiveDiv, 
  BankerPlanStyleH3, 
  customContainerStyle, 
  customMandateStyle, 
  customPriceStyle, 
  containerStyle, 
  paragraphStyle, 
  listContainerStyle, 
  listItemContainerStyle, 
  iconContainerStyle, 
  listItemTextStyle, 
  listItemParagraphStyle, 
  mostPopularBadge, 
  featuresList, 
  featureItem, 
  featureIcon, 
  featureText, 
  BankerPlanChooseBtn, 
  BankerPlanStyleContainerDiv, 
  BankerPlanStyleContainerSVG1, 
  BankerPlanChooseBtn1
} from "./BankerStyle";
import { BankerPlanSvgIcon, RightTickIcon } from "../../../common/Icons";
import NudgeNotification from "../../../common/NudgeNotification";
import useGetData from "../../../Hook/useGetData";

const BankerPlan = () => {
  const navigate = useNavigate();
  const [activePlan, setActivePlan] = useState("plan2");
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const {data: GSTData} = useGetData("master-data/package/get", token);
  const handlePlanClick = (plan) => {
    setActivePlan(plan);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getOneYearDateRange = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    const endDate = new Date(currentDate);

    endDate.setFullYear(currentDate.getFullYear() + 1);

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    return `${formattedStartDate} - ${formattedEndDate}`;
  };
  const handlePlan = (plan) => {
    if(plan === 'plan1'){
      const obj={
        price:GSTData?.data[0]?.total_cost,
        cost:GSTData?.data[0]?.cost,
        igst:GSTData?.data[0]?.igst,
        mandate: GSTData?.data[0]?.mandates_per_year,
        periodOfPlan:GSTData?.data[0]?.number_of_months,
        date: getOneYearDateRange(),
        planFeature:[
          "Earnings & Engagements",
          "Personalized dashboard",
          "Your own virtual office",
          "Loyalty points",
          "Lifestyle & wellness enhancement",
          "Visibility of your profile",
          "Personal branding support",
        ]
     
      }
       navigate('/profile/banker/plan-detail',{state:obj});
    }
    if(plan === 'plan2'){
      const obj={
        price:GSTData?.data[1]?.total_cost,
        cost:GSTData?.data[1]?.cost,
        igst:GSTData?.data[1]?.igst,
        mandate: GSTData?.data[1]?.mandates_per_year,
        periodOfPlan:GSTData?.data[1]?.number_of_months,
        date: getOneYearDateRange(),
        planFeature: ["Better Earnings and Higher Engagement" , "Your own Virtual Office" , "Attractive Loyalty points" ,"Lifestyle and wellness Enhancement" , "Personalised Dashboard" , "Higher Visibility of your profile"]

      }
       navigate('/profile/banker/plan-detail',{state:obj});
    }
   
  };

  return (
    <div>
      
        <Navbar hemBurgerIcon={false} skipdata="Skip For Now" />
      
            <div className={`relative my-[3rem]`}>
      {/* <NudgeNotification className="absolute -top-10 left-0 right-0" /> */}

        <h3 className={BankerPlanStyleH3}>
          Congratulations
        </h3>
        <h3 className={BankerPlanStyleH3}>
          You are just one step away
        </h3>
      </div>

      <div className={BankerPlanStyleContainer}>
        <div 
          onClick={() => handlePlanClick('plan1')}
          className={`${BankerPlanStyleContainerActiveDiv} ${activePlan === 'plan1' ? 'bg-white border-2 shadow-xl translate-y-[-40px]' : 'bg-gray-100'} cursor-pointer`}
        >
          <div className="p-4">
            <div className={customContainerStyle}>
              <h3 className={customPriceStyle}>
                ₹25,000
                <p className="s3-text mt-3 ml-2">Including Taxes</p>
              </h3>
              <h3 className={customMandateStyle}>
              4 Mandates/year
              </h3>
            </div>
            <div className={containerStyle}>
              <p className={paragraphStyle}>
              For most bankers that want to ensure optimal resource utilisation
              </p>
            </div>
            <div className={listContainerStyle}>
              {[
                "Earnings & Engagements",
                "Personalized dashboard",
                "Your own virtual office",
                "Loyalty points",
                "Lifestyle & wellness enhancement",
                "Visibility of your profile",
                "Personal branding support",
              ].map((item, index) => (
                <div key={index} className={listItemContainerStyle}>
                  <div className={iconContainerStyle}>
                    <RightTickIcon/>
                  </div>
                  <div className={listItemTextStyle}>
                    <p className={listItemParagraphStyle}>{item}</p>
                  </div>
                </div>
              ))}
            </div>
            <Button
              type="button"
              onClick={()=>handlePlan('plan1')}
              className={BankerPlanChooseBtn1}
            >
              Choose package
            </Button>
          </div>
        </div>
        <div 
          onClick={() => handlePlanClick('plan2')}
          className={`${BankerPlanStyleContainerDiv} ${activePlan === 'plan2' ? 'bg-white border-2 shadow-xl translate-y-[-40px]' : 'bg-gray-100'} mt-10 md:mt-0 cursor-pointer`}
        >
          <BankerPlanSvgIcon BankerPlanStyleContainerSVG1={BankerPlanStyleContainerSVG1}/>

          <div className="p-4 z-50">
            <div className="flex justify-end">
              <p className={`${mostPopularBadge}`}>
                MOST POPULAR
              </p>
            </div>
            <div className="mt-2 pl-2">
              <h3 className={customPriceStyle}>
                ₹45,000
                <p className="s3-text mt-3 ml-2">Including Taxes</p>
              </h3>
              <h3 className="text-start">8 Mandates/year</h3>
            </div>
            <div className="pl-2">
              <p className={`${featuresList}`}>
              For most businesses that want to maximise the profits
              </p>
            </div>
            <div className="my-4 ml-2">
              {[
                "Better Earnings and Higher Engagement",
                "Your own Virtual Office",
                "Attractive Loyalty points",
                "Lifestyle and wellness enhancement",
                "Personalised Dashboard",
                "Higher Visibility of your profile",
              ].map((item, index) => (
                <div className={`${featureItem}`} key={index}>
                  <div className={`${featureIcon}`}>
                    <RightTickIcon/>
                  </div>
                  <div className={`${featureText}`}>
                    <p className="mt-1">{item}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <Button
            type="button"
            onClick={()=>handlePlan('plan2')}
            className={BankerPlanChooseBtn}
          >
            Choose package
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BankerPlan;
