import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../common/Navbar';
import Button from '../../common/Button';
import { CongratulationStyleLoginButton, CongratulationStyleMainContainer, CongratulationStyleMainContainerDivHeading, CongratulationStyleMainContainerDivImg } from './RegisterStyle';
import { CongratulationIcon } from '../../common/Icons';

const Congratulations = () => {
    const navigate = useNavigate();

    const bankerData = JSON.parse(sessionStorage.getItem('bankerData'))
    const companyData = JSON.parse(sessionStorage.getItem('companyData'))
    const individualData = JSON.parse(sessionStorage.getItem('IndivisualsData'));

    let registrationType = '';
    

    if (bankerData || bankerData?.signUpStatus === "banker") {
        registrationType = 'Banker';
    } else if (companyData || companyData?.signUpStatus ==="company") {
        registrationType = 'Company';
    } else if (individualData || individualData?.signUpStatus === "individual") {
        registrationType = 'Individual';
    }

    return (
      <div>
      <Navbar hemBurgerIcon={false}/>
 
      <div className={CongratulationStyleMainContainer} >
        <div>
            {/* <img className={CongratulationStyleMainContainerDivImg} src="https://www.reshot.com/preview-assets/icons/YQGR7FCZME/email-marketing-YQGR7FCZME.svg" alt="" /> */}
<CongratulationIcon CongratulationStyleMainContainerDivImg ={CongratulationStyleMainContainerDivImg }/>
          

        </div>
        <div>
            <h4 className={CongratulationStyleMainContainerDivHeading}>Congratulations!</h4>
            <p>You have successfully registered as a {registrationType}.</p>
            <p>Please Login in to your account to get in.</p>
        </div>
        <Button onClick={()=>navigate('/login')} className={CongratulationStyleLoginButton}>
              Login
            </Button>
      </div>
    </div>
    );
};

export default Congratulations;
