import React from 'react'
import Navbar from '../../../common/Navbar'
import BankerSideNavbar from '../../../common/BankerSideNavbar'
import ResetPassword from '../../register/ResetPassword'

const BankerResetPassword = ({IsBanker}) => {
  return (
    <div>
        <Navbar BackBtn={true} hemBurgerIcon={true} BankerNavbar={true} ProfileAndNotification={true}/>

        <div className='flex'>
        <div className="lg:w-[7%] md:-[7%] w-0">
          <BankerSideNavbar />
        </div>

        <div className='w-[100%] lg:flex px-[1rem] md:w-[93%] lg:w-[93%]  relative'>
            <ResetPassword type={true}/>
        </div>
        </div>
      
    </div>
  )
}

export default BankerResetPassword
