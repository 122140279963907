import React from 'react';

const OfflineNotification = ({ onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-sm w-full mx-4">
      <p className="mb-4">You are currently offline. Some features may not be available.</p>
      <button
        onClick={onClose}
        className="px-4 py-2 bg-[#199c6a] text-white rounded hover:bg-[#199c6a] w-full sm:w-auto"
      >
        Close
      </button>
    </div>
  </div>
);

export default OfflineNotification;
