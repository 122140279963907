import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {  FlexJustitemCenter, ProceedPayStyleMainDiv1, ProceedPayStyleReview, ProceedPayStyleReviewButton, ProceedPayStyleReviewDiv, ProceedPayStyleReviewDiv2, ProceedPayStyleReviewDivPTag, S2TextGreen } from "../modules/dashboard/company/job/DashboardJobStyle";
import Navbar from "./Navbar";
import { flexitemCentre, flexjustifybt } from "./StyleCommon";
import { BankerPaymentStyeleBRLine, BankerPaymentStyeleSubTotal1, BankerPaymentStyeleSubTotalDiv, BankerPaymentStyeleTaxDiv, BankerPlanStyleContainerTitleGap, featureIcon } from "../modules/profile/banker/BankerStyle";
import { JustStartItemCenter } from "../modules/dashboard/company/search/SearchStyle";
import Checkbox from "./Checkbox";
import Button from "./Button";
import { GreenPencilIcon, PaymentModeIcon, RightTickIcon, RozerPayIcon } from "./Icons";
import { ToastMessage, formatPriceInput, paymentGatewayKey } from "./Config";
import usePost from "../Hook/usePost";
import { generateSignature } from "../Hook/validation";
import LoaderAPI from "./LoaderAPI";
import  imgLogo from "./logo.png"
import useGetData from "../Hook/useGetData";

const BankerPlanDetail = () => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const location=useLocation()
  const { data: OrderData,loading:OrderLoading, 
    error: OrderError, postData: postOrderData } = usePost(`payment/create-order`);
    const { data:ProfileData, error, loading, refetch } = useGetData("profile/banker/basic-details", token);
    const { data: PaymentData,loading:PaymentLoading, 
      error: PaymentError, postData: postPaymentData } = usePost(`payment/payment-callback`);
  const [data,setData]=useState({})
  const ReviewDetails = [ 
    {
      id: 1,
      name: "Plan price",
      value: `₹ ${formatPriceInput(location?.state?.price.toString()) || 0}` ,
      subValue: "",
    },
    {
      id: 2,
      name: "Number of mandates",
      value: `${location?.state?.mandate || 0} mandates`,
      subValue: "",
    },
    {
      id: 3,
      name: "Period of plan",
      value: `${location?.state?.periodOfPlan || 0} month`,
      subValue: location?.state?.date || "NA"
    }
   
  ];

  const MileStoneDetails = [
    {
      id: 1,
      name: "Plan features",
      value: location?.state?.planFeature || "NA"
    }
  
  ];
const navigate = useNavigate();
const formatDate = (date) => {
  const optionsDate = { day: '2-digit', month: 'short', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-GB', optionsDate);

  const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
  const formattedTime = date.toLocaleTimeString('en-GB', optionsTime);

  return `${formattedDate}, ${formattedTime}`;
};
const GST=parseInt(location?.state?.price)*0.18;
const value=parseInt(location?.state?.price)-GST;
const TAXvalue=parseInt(location?.state?.price)-parseInt(location?.state?.cost);
// Example usage:
const date = new Date();
const formattedDate = formatDate(date);
const options = {
  key:paymentGatewayKey,
  amount: (location?.state?.price || 1)*100, //  = INR 1
  name: "BankerKlub",
  description: "some description",
  image: imgLogo,
  handler: function(response) {
  setData(response)
  postPaymentData({razorpay_payment_id:response.razorpay_payment_id,razorpay_order_id:sessionStorage.getItem("orderId"),razorpay_signature:sessionStorage.getItem('generated_signature'),amount:(location?.state?.price || 1)},token)
  // navigate("/profile/banker/payment/status",{state:{data:response,predata:location?.state,formattedDate:formattedDate}})
    // alert(response.razorpay_payment_id);
  },
  prefill: {
    name: ProfileData?.data?.user?.name || "Not available",
    contact: ProfileData?.data?.user?.phone_number || "Not available",
    email: ProfileData?.data?.user?.email || "Not available"
  },
  notes: {
    address: ProfileData?.data?.permanent_address || "Not available"
  },
  theme: {
    color: "#FF0059",
    hide_topbar: false
  }
};

const openPayModal = options => {
  var rzp1 = new window.Razorpay(options);
  rzp1.open();
  postOrderData({product_cost:location?.state?.price || 1,gst:GST,tds:0,is_payment_onboarding:true},token)
};
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
 
  useEffect(()=>{
    if(OrderData){
      sessionStorage.setItem("orderId",OrderData?.order_id);
      sessionStorage.setItem("generated_signature",OrderData?.generated_signature);
      // ToastMessage("success","Order placed successfully")
    }
    if(OrderError){
      ToastMessage("error","Something went wrong")
    }
    },[OrderData,OrderError])
    useEffect(()=>{
      if(PaymentData){
        ToastMessage("success","Payment successfull")
        navigate("/profile/banker/payment/status",{state:{data:data,predata:location?.state,formattedDate:formattedDate, PaymentData:PaymentData}})
      }
      if(PaymentError){
        ToastMessage("error","Something went wrong")
      }
    
    },[PaymentData,PaymentError])
  return (
    <div>
      {PaymentLoading && <LoaderAPI/>}
      
      <Navbar hemBurgerIcon={false} BankerNavbar={true} BackBtn={true} ProfileAndNotification={true} />

      <div className={ProceedPayStyleMainDiv1}>
        <div className="">
          <div className={flexjustifybt}>
            <div>
              <p className="s1-text">Plan Details</p>
            </div>
            <div onClick={()=>navigate("/profile/banker/plan")} className={FlexJustitemCenter}>
              <GreenPencilIcon className="mt-0.4 mr-2"/>
              <p className={S2TextGreen}>Edit</p>
            </div>
          </div>

          <div className={ProceedPayStyleReviewDiv}>
            {ReviewDetails?.map((item, index) => {
              return (
                <div className="flex my-6 " key={index}>
                  <div className="w-[30%]">
                    <p className={ProceedPayStyleReviewDivPTag}>
                      {item?.name}
                    </p>
                  </div>
                  <div className={BankerPlanStyleContainerTitleGap}>
                    <p className="text-[#2C2C2C]">-</p>
                  </div>

                  <div className="w-[70%] pl-3">
                          <p className="s3-text text-start text-[#2C2C2C]">
                            {item?.value}
                          </p>
                         {item.subValue !== "" &&  <p className="s3-text my-4 text-start text-[#2C2C2C]">
                            {item?.subValue}
                          </p>}
                    </div>
                    
                </div>
              );
            })}
          </div>

          <div className={ProceedPayStyleReview}>
            {MileStoneDetails?.map((item, index) => {
              return (
                <div className="flex my-6  " key={index}>
                  <div className="w-[30%]">
                    <p className={ProceedPayStyleReviewDivPTag}>
                      {item?.name}
                    </p>
                  </div>
                  <div className={BankerPlanStyleContainerTitleGap}>
                    <p className="text-[#2C2C2C]">-</p>
                  </div>

                     <div className="w-[70%] pl-3">
                          {item.value.map((item , index)=>{
                            return <p className="s3-text mb-2 text-start gap-2 flex items-center text-[#2C2C2C]">
                            <div className={`${featureIcon}`}>
                                <RightTickIcon/>
                            </div> {item}
                            </p>
                          })}
                         
                    </div>
                    
                </div>
              );
            })}
          </div>

       
        </div>
        <div className="">
            <div className={JustStartItemCenter}>
                <PaymentModeIcon />
                <p className="s2-text text-[#2C2C2C]" > Payment Mode</p>
            </div>

            <div className={ProceedPayStyleReviewDiv2}>
            
                <div className={flexitemCentre}>
                <Checkbox
                        labelClassName="checkBox-label"
                        type="radio"
                        id="short-term-radio"
                        label="Razorpay"
                        description=""
                        value=""
                        checked="true"
                        onChange=""
                      />
                  </div>
                <div>
                    <RozerPayIcon/>
                </div>
              
          </div>

          <div className={BankerPaymentStyeleSubTotal1}>
        <div className={BankerPaymentStyeleSubTotalDiv}>
          <p className="s2-text">Subtotal</p>
          <p className="s2-text">{`₹ ${formatPriceInput(Math.floor(location?.state?.cost).toString())}`}</p>
        </div>
        <div className={BankerPaymentStyeleTaxDiv}>
          <p className="s2-text">
            Tax <span className="text-[#80828D]">(GST 18%)</span>
          </p>
          <p className="s2-text">{`₹ ${formatPriceInput(TAXvalue.toString())}`}</p>
        </div>
        <div className={BankerPaymentStyeleBRLine}></div>
        <div className={BankerPaymentStyeleTaxDiv}>
          <p className="s2-text">Payable Amount</p>
          <h5 className="h5-text">{`₹ ${formatPriceInput((location?.state?.price).toString())}`}</h5>
        </div>
      </div>
      <Button onClick={() => openPayModal(options)} className={ProceedPayStyleReviewButton}>
      Proceed to pay
      </Button>
        </div>
        
      </div>

     
    </div>
  );
};

export default BankerPlanDetail;
