import React, { useState, useRef, useEffect } from 'react';

const ToolTip = ({ children, message }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState('top');
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (showTooltip) {
      const tooltip = tooltipRef.current;
      const tooltipRect = tooltip.getBoundingClientRect();
      const parentRect = tooltip.parentNode.getBoundingClientRect();

      const isOverflowingTop = parentRect.top < tooltipRect.height + 100 ;
      const isOverflowingBottom = (window.innerHeight - parentRect.bottom) < tooltipRect.height;
      const isOverflowingLeft = parentRect.left < tooltipRect.width / 2;
      const isOverflowingRight = (window.innerWidth - parentRect.right) < tooltipRect.width / 2;

      if (isOverflowingTop) {
        setPosition('bottom');
      } else if (isOverflowingBottom) {
        setPosition('top');
      } else if (isOverflowingLeft) {
        setPosition('right');
      } else if (isOverflowingRight) {
        setPosition('left');
      } else {
        setPosition('top');
      }
    }
  }, [showTooltip]);

  const positionClasses = {
    top: 'bottom-full left-1/2 transform -translate-x-1/2 mb-2',
    bottom: 'top-full left-1/2 transform -translate-x-1/2 mt-2',
    left: 'right-full top-1/2 transform -translate-y-1/2 mr-2',
    right: 'left-full top-1/2 transform -translate-y-1/2 ml-2'
  };

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div
          ref={tooltipRef}
          className={`absolute px-2 py-1 bg-[#2C2C2C] text-white text-xs rounded opacity-100 z-10 ${positionClasses[position]} whitespace-nowrap`}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default ToolTip;
