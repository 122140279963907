import React from 'react'
import { DoNotFoundStyle1 } from './StyleCommon'

const DataNotFound = ({title}) => {
  return (
    <div className={DoNotFoundStyle1}>
      {title}
    </div>
  )
}

export default DataNotFound
