export const FlexMT3 = "flex mt-[3rem]"
export const DashboardCompanyMainDiv = "flex  mt-2  lg:items-center md:items-center items-start";
export const DashboardCompanyButtonAddJOb = "greenbg text-white w-[130px] h-[38px] font-lato rounded-md";
export const MarginLeftTenStart =  " flex justify-between items-center text-start"
export const DashboardCompanySearchTag = "my-2  h6-text secondary-text-gray"
export const DashboardCompanydiv = "";
export const DashboardCompanyRecentSearch= "text-start my-4 flex";
export const DashboardRoundedBoxMainDiv = "flex flex-wrap ml-3 gap-2";
export const DashboardNextMainDiv = "w-full flex lg:gap-4 mt-10";
export const DashboardNextMainDiv1 = "w-full flex lg:gap-4 mt-10";

export const DashboardNextFilterSec = "border p-4 shadow-lg bg-gray-100 border-1 rounded-xl";
export const DashboardNextFilterSecDiv ="flex px-6 my-5";
export const DashboardNextFilterTag = "secondary-text-gray h5-text ml-2";
export const DashboardClearAllDiv = "ml-auto mt-2";
export const DashboardClearAllpTag = "primary-text-green s3-text"
export const DashboardCheckboxTitle = "text-start mt-4 px-6";
export const DashboardCheckboxTitleValue ="px-6 items-center my-4";


export const AddJobMainContainer = "w-full px-2 md:w-[46%] m-auto mt-[1rem]";
export const AddJobMainContainerHeading="mt-[1rem] font-urbanist secondary-text-gray text-[32px] font-bold";
export const H5TextStart = "h5-text text-start";
export const FontLatoPrimaryGreen = "font-lato primary-text-green"
export const AddJobMainContainerHeadingDiv = "grid grid-cols-1 mb-[5rem]";
export const AddJobMainContainerDiv="flex items-left mt-8 gap-1"
export const H6TextStartSecGray="h6-text text-start secondary-text-gray";







