import React from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../common/Navbar'
import Button from '../../common/Button'
import { DoneStyleButton } from './LoginStyle'
import { DoneIcon } from '../../common/Icons'

 
const Done = () => {
    const navigate = useNavigate()
 
  return (
    <div>
      <Navbar hemBurgerIcon={false} />
 
      <div className='my-[8rem] w-full' >
        <div className='my-[2rem]'>
        <DoneIcon/>

        </div>
        <div>
            <h4 className='h4-text my-3'>Done!</h4>
            <p>You have successfully set a new password.</p>
            <p>Please Login in to your account to get in.</p>
        </div>
        <Button onClick={()=>navigate('/login')} className={DoneStyleButton}>
              Login
            </Button>
      </div>
    </div>
  )
}
 
export default Done