
import React, { useEffect, useState } from "react";
import { LoginOTPSVGicon } from "../../common/Icons";
import Navbar from "../../common/Navbar";
import OtpBox from "../../common/OtpBox";
import { ForgotContainer, ForgotOTPmainContainerLogin, ForgotOTPmainContainerh3, ForgotmainContainerOTP } from "../register/RegisterStyle"
import { maskEmail, maskPhoneNumber } from "../../Hook/validation";

const LoginOTP = () => {
    const SessionData=JSON.parse(sessionStorage.getItem('userLoginInfo'))
   return (
    <div>
      <Navbar hemBurgerIcon={false}/>
      <div className={ForgotmainContainerOTP}>
        <div>
          <h3 className={ForgotOTPmainContainerh3}>
            Please Enter OTP
          </h3>
          <div className={ForgotOTPmainContainerLogin}>
            <div className={ForgotContainer}>
              <LoginOTPSVGicon />


            </div>

            <p className="m-auto s3-text mt-10 ">
              We have sent you an OTP on your email{" "}
              (<span className="primary-text-green">{maskEmail(SessionData?.email || "bankername@example.com")}</span>){" "}
              and Mobile no.
              (<span className="primary-text-green">{maskPhoneNumber(SessionData?.phone_number || "+91 873965412")}</span>)
            </p>
            <div className="my-4">
              <OtpBox forgotPassword={false} ForgotData={JSON.parse(sessionStorage.getItem("userLoginInfo")) || {}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginOTP;