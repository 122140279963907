import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const WeeklyCalendar = ({ events }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const goToNextWeek = () => {
    setCurrentDate(moment(currentDate).add(1, 'week').toDate());
  };

  const goToPreviousWeek = () => {
    setCurrentDate(moment(currentDate).subtract(1, 'week').toDate());
  };

  const formats = {
    dayFormat: 'dddd', // Format for day names (e.g., Monday, Tuesday)
    dateFormat: 'D', // Format for day numbers (e.g., 14, 15)
  };

  const CustomToolbar = ({ label }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <button onClick={goToPreviousWeek}>Previous</button>
        <span>{label}</span>
        <button onClick={goToNextWeek}>Next</button>
      </div>
    );
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        date={currentDate}
        view="week"
        formats={formats}
        toolbar={false} // Disable default toolbar
        components={{
          toolbar: CustomToolbar,
        }}
        onNavigate={date => setCurrentDate(date)}
        views={{ week: true }}
        style={{ height: 150 }} // Adjust height to fit in one row
      />
    </div>
  );
};

export default WeeklyCalendar;
