import React, { useEffect, useState } from 'react';
import Navbar from '../../../../common/Navbar';
import BankerSideNavbar from '../../../../common/BankerSideNavbar';
import Button from '../../../../common/Button';
import { HireCongStleDivExperienceOpted, JobListingMenuBarDivAvticeTab } from '../../company/job/DashboardJobStyle';
import { AddUpdateProjectDetails, DonwloadIcon1, DownArrorIcon, DownloadGreenIcon, DropdownIconUpAndDown, MileStoneRightTick } from '../../../../common/Icons';
import BankerCompleteMileStoneModel from './BankerCompleteMileStoneModel';
import BankerAddUpdateModel from './BankerAddUpdateModel';
import RoundedBox from '../../../../common/RoundedBox';
import { BankerProjectDetailMainDiv, BPDetailImagediv1, BPDetailMaindiv2, BPDetailMaindiv3, BPDetailPtag1, BPDetailPtag2, BProActiveTag, BProActiveTag1, BProBreaLine1, BProDiv2, BProMainTag, BProMileStoneHeading, BProMileStoneMainDiv, BProStatus, BPScheduleMeeting, GridCol4, TextGrayNotification } from './BankerProjectStyle';
import { useParams } from 'react-router-dom';
import useGetData from '../../../../Hook/useGetData';
import BankerAddEvent from '../calendar/BankerAddEvent';
import { convertDate, convertToInitials, getTimeDifference, handleDownloadAll } from '../../../../Hook/validation';
import FeedbackModal from '../../../../common/FeedbackModal';
import { apiHost } from '../../../../common/Config';
import NudgeNotificationProject from '../../../../common/NudgeNotificationProject';
import usePost from '../../../../Hook/usePost';
const dummyContent = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. "


const getStatusColor = (status) => {
  switch (status) {
    case 'Completed':
      return 'bg-[#1EB469]';
    case 'In Approval':
      return 'bg-[#EA9515]';
    case 'Ongoing':
      return 'bg-[#FDD33E]';
    case 'Declined':
      return 'bg-[#FF0059]';
    case 'Not Started':
      return 'bg-[#D8D9E0]';
      case 'Overdue':
      return 'bg-[#FFCACE]';
    default:
      return '';
  }
};

const getStatusTextColor = (status) => {
  switch (status) {
    case 'Completed':
      return 'text-[#183D27]';
    case 'In Approval':
      return 'text-[#6B3C10]';
    case 'Ongoing':
      return 'text-[#6B5710]';
    case 'Declined':
      return 'text-white';
    default:
      return 'text-black';
  }
};

const BankerProjectDetails = () => {
  const {id} = useParams();
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data:getProjectData,refetch:fetchAgain} = useGetData(`projects/banker-job-hire-details/${id}`, token);
  const { data: AgreementData,loading:AgreementLoading, 
    error: AgreementError, postData: postAgreementData } = usePost(`hiring/get-agreement`);
  const [activeTab, setActiveTab] = useState("Milestones");
  const [selectedMilestone, setSelectedMilestone] = useState([]);
  const [dateInfo, setDateInfo] = useState(null);
  const [events, setEvents] = useState([]);
  const [milestonesData,setMilestonesData]=useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [MileStoneCompleteModel  , SetMileStoneCompleteModel] = useState(false);
  const [MileStoneAddUpdateModel  , SetMileStoneAddUpdateModel] = useState(false);
  const [dataSet,setDataSet]=useState({
    title:"",
    description:"",
    due_date:"",
    status:"",
    weeklyUpdates:[],

  })
  const [feedBackModal,setFeedbackModal]=useState(false);

 const [mobileMileStoneShwo ,  setMobileMileStoneShwo] = useState(true);
 const [showNotification, setShowNotification] = useState(false); 
 const [Message,setMessage]=useState("")
  const mainTag = [
    { id: 1, name: "Milestones" },
    { id: 2, name: "Project Details" },
  ];
  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return 'Declined';
      case 1:
        return 'Ongoing';
      case 2:
        return 'In Approval';
      case 3:
        return 'Overdue';
      case 4:
        return 'Completed';
      default:
        return 'Not Started';
    }
  };
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleMilestoneClick = (milestone) => {
    // setSelectedMilestone(milestone);
    setDataSet({
      id:milestone?.id,
      status:milestone?.status || "Not Started",
      title:milestone?.name || "",
      description:milestone?.description || "",
      due_date:milestone?.due_date || "4545454",
      sharedDocs:milestone?.sharedDocs || [],
      completedDate:milestone?.completedDate || "",
      weeklyUpdates:milestone?.weeklyUpdates || []
  
    })
  };

  const handleCloseModal = () => {
    SetMileStoneCompleteModel(false);
    SetMileStoneAddUpdateModel(false);


  };

  const handleAddEventClick = () => {
    setDateInfo(null);
    setIsModalOpen(true);
  };
  const profileData = [
    {
      id: 1,
      title: "Phone Number",
      titleValue: getProjectData?.data?.company?.user?.phone_number,
    },
    {
      id: 2,
      title: "Email ID",
      titleValue: getProjectData?.data?.company?.user?.email,
    },
    {
      id: 3,
      title: "Start Date",
      titleValue: convertDate(getProjectData?.data?.start_date) || "19/08/2024",
    } ,
    {
      id: 3,
      title: "Project Due Date",
      titleValue: convertDate(getProjectData?.data?.end_date) || "12/03/2024",
    }
  ];

  const PaymentStatusData = [
    {
      id: 1,
      title: "Amount",
      titleValue:`₹ ${getProjectData?.payment?.amount || "0"}`,
    },
    {
      id: 2,
      title: "Payment Status",
      titleValue:  "Pending",
      // getProjectData?.payment?.status ||
    },
    {
      id: 3,
      title: "Payment Date",
      titleValue: !convertDate(getProjectData?.payment?.payment_date) || "-",
    } ,
    
  ];

  
  const handleSaveEvent = ({ title, eventType, color }) => {
    if (title) {
      const newEvent = {
        title,
        start: dateInfo ? dateInfo.date : null,
        end: dateInfo ? dateInfo.date : null,
        extendedProps: { eventType },
        color,
      };
      setEvents([...events, newEvent]);
    }
    setIsModalOpen(false);
  };
  
  const ServiceOpted =getProjectData?.data?.job_details?.key_service_details?.map((item)=>item?.name) || getProjectData?.data?.job?.key_service_details?.map((item)=>item?.name) || []
  const ImageData = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDwmG52pVI5JZfn04j9gdtsd8pAGbqjjLswg&s";
  useEffect(() => {
    if(getProjectData?.data){
      const milestonesData =getProjectData?.data?.milestones?.map((item,index)=>{
        return {
          id:item?.id,
          name:`Milestone ${index+1}`,
          status:getStatusLabel(item?.status) || "Not Started",
          due_date:item?.due_date,
          sharedDocs:item?.documents || [],
          completedDate:item?.completed_date || "Not available",
          description:item?.description,
          weeklyUpdates: item?.updates?.map((items,index)=>{
            return {
              id:items?.id,
              name:`Milestone ${item?.id}`,
              WeekUpdate:items?.update_description,
              WeekUpdateTime:convertDate(items?.created_at)
            }
          })
          
        }
      }) ||  [
      ];
      setDataSet(milestonesData[0])
      setMilestonesData(milestonesData)
    }
  },[getProjectData])
  const handleDownloadAgreement=()=>{
    const obj={
        "job_hire_id":getProjectData?.data?.job
    }
    postAgreementData(obj,token)
    console.log("TTTTTT",getProjectData?.data?.job);
  }
  return (
    <>
    <div>
      <Navbar hemBurgerIcon={true} BackBtn={true} BankerNavbar={true} ProfileAndNotification={true} />
      
      <div className='flex w-full'>
        <div className='w-0 md:w-[7%]'>
          <BankerSideNavbar />
        </div>
        <div className={BankerProjectDetailMainDiv}>
          <div className={BPDetailMaindiv2}>
            <div className={BPDetailMaindiv3} >
              <div className='m-auto w-[96px] flex justify-center items-center overflow-hidden h-[96px] rounded-full border'>
                {/* <img src={`${apiHost}/${getProjectData?.data?.company?.profile_image}`} alt="" /> */}
                {!getProjectData?.data?.company?.profile_image ? <h2 className='h2-text bg-green-500 w-full h-full text-white flex items-center justify-center text-center'>{convertToInitials(getProjectData?.data?.project_name)}</h2> : <img className='w-full imgClassHeight' src={`${apiHost}/${getProjectData?.data?.company?.profile_image}`} alt='' />}
              </div>
              <div className=' '>
                <h4 className='h4-text text-center md:text-start'>
                  {getProjectData?.data?.project_name || "Not available"}
                </h4>
                <p className={BPDetailPtag1}>
                  <div>
                  {getProjectData?.data?.company?.company_name || "Not available"}
                  </div>
                  <div className='flex items-center justify-center gap-2'>
                  <div className={BPDetailPtag2}></div>
                   <div>
                   {getStatusLabel(getProjectData?.data?.is_completed ? 4 :1) || "Not Available"} - {getTimeDifference(getProjectData?.data?.updated_at)}

                   </div>
                  </div>
                </p>
              </div>
            </div>
            <div>
              <Button onClick={handleAddEventClick} className={BPScheduleMeeting}>
                <p className='simple-text'>Schedule a Meeting</p>
              </Button>
            </div>
          </div>

          <div>
            <div className={BProMainTag}>
              {mainTag.map((item, index) => (
                <div key={index}>
                  <div
                    className={` ${JobListingMenuBarDivAvticeTab} ${activeTab === item.name ? "font-bold" : ""}`}
                    onClick={() => handleTabClick(item.name)}
                  >
                    <div>{item.name}</div>
                  </div>
                  <div
                    className={`h-1 mt-4 border-1 ${activeTab === item.name ? "greenbg" : "bg-transparent"}`}
                  ></div>
                </div>
              ))}
            </div>
            <div className={BProBreaLine1}></div>
          </div>

          <div className='p-4 lg:p-8'>
            {activeTab === "Milestones" && (
              <div className={BProActiveTag}>
                <div onClick={()=>setMobileMileStoneShwo(!mobileMileStoneShwo)} className={BProActiveTag1}>
                  <div>
                    <h5 className={BProMileStoneHeading} >Milestones</h5>
                  </div>
                  {milestonesData.map((milestone) => (
                    <>
                    <div key={milestone.id} className='flex'>
                      <div className={`w-[7px] ${getStatusColor(milestone.status)}`}></div>
                      <div
                        className={`${BProMileStoneMainDiv}  ${dataSet.id === milestone.id ? 'bg-white' : ''}`}
                        onClick={() => handleMilestoneClick(milestone)}
                      >
                        <h3>{milestone.name}</h3>
                       <div className='flex gap-4'>
                       {milestone.status === "Completed" && <MileStoneRightTick />}
                       { <p className='block lg:hidden'>  <DropdownIconUpAndDown isDropdownVisible={dataSet.id === milestone.id ? mobileMileStoneShwo  : false } /></p> }
                       </div>
                      </div>

                     
                    </div>

                    { mobileMileStoneShwo && dataSet.id === milestone.id && <div className='w-full md:w-2/3 bg-white p-2 lg:hidden block md:p-8'>
                  <div className='grid grid-cols-1 md:grid-cols-4 '>
                    <div className='text-start'>
                      <div className={TextGrayNotification}>
                        Status
                      </div>
                      <div className=''>
                        <p className={`${BProStatus} ${getStatusColor(dataSet.status)} ${getStatusTextColor(dataSet.status)}`}>
                          {dataSet?.status}
                        </p>
                      </div>
                    </div>
                    <div className='text-start mt-4 md:mt-0'>
                      <div className='text-[#8B8D98] text-start  notification'>
                        Due Date
                      </div>
                      <div className='mt-1 md:mt-4'>
                        {convertDate(dataSet?.due_date)}
                      </div>
                    </div>
                    {!(dataSet.status === "Ongoing" || dataSet.status === "Overdue" || dataSet.status ===  "Not Start") && (
                      <>
                        <div>
                          <div className='text-[#8B8D98] mt-4 md:mt-0 notification text-start md:text-end'>
                            Shared Documents
                          </div>
                       
                          <div className=''>
                            <p className='mt-1 md:mt-4 cursor-pointer gap-2 flex justify-start md:justify-center text-[#1EB469]'>
                            { dataSet.sharedDocs?.length > 0 ? <p onClick={()=>handleDownloadAll(dataSet?.sharedDocs?.map((item)=>`${apiHost}/${item?.document}`))}><DonwloadIcon1 /> Download</p>:" No Documents" }  
                            </p>
                          </div>
                        </div>
                        <div className='mt-4 md:mt-0'>
                          <div className='text-[#8B8D98] text-center notification text-start md:text-end'>
                            Completed Date
                          </div>
                          <div className='mt-1 md:mt-4 text-start md:text-end'>
                          {dataSet?.status==="Completed" ?  convertDate(dataSet?.completedDate) :"--"}
                          </div>
                        </div>
                      </>
                    )}
                    {(dataSet.status === "Ongoing" || dataSet.status === "Overdue" || dataSet.status ===  "Not Start") && (
                      <div className='md:col-span-4 text-end -mt-12'>
                        <Button onClick={()=>SetMileStoneCompleteModel(!MileStoneCompleteModel) } className="px-4 py-2 greenbg text-white rounded-lg">
                          Mark as Complete
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className='mt-6 text-start'>
                    <p className='notification-span text-[#8B8D98] my-4'>Description</p>
                    <p className=' body-text text-[#2C2C2C]'>{dataSet.description}</p>
                  </div>
                  <div className='mt-4 border bg-red-400 rounded-lg'>

                        <div className='flex items-center justify-between p-4'>
                            <div>
                                <p>Weekly Updates</p>
                            </div>
                           {(dataSet?.status =="Ongoing" || dataSet?.status==="Not Started") && <div>
                            
                            <p onClick={()=>SetMileStoneAddUpdateModel(!MileStoneAddUpdateModel)} className='flex cursor-pointer items-center gap-2 text-[#1EB469]'> <AddUpdateProjectDetails/> Add update</p>
                            </div>}
                        </div>

                        <div className='w-full border mb-6'>
                             
                        </div>

                    
                    {dataSet?.weeklyUpdates.map((update, index) => (
                      <><p className='border body-text rounded-lg mx-4 p-4 bg-[#F9F9FB] text-start' key={index}>{update.WeekUpdate}</p>
                            <p className='body-text text-start pl-4 mt-2 mb-5 text-[#80828D]' key={index}>{update.WeekUpdateTime}</p>
                            </>
                    ))}
                  </div>
                </div>}


                   


                    </>
                  ))}
                </div>


                <div className='w-full md:w-2/3 p-2 hidden lg:block md:p-8'>
                  <div className='grid grid-cols-1 md:grid-cols-4 '>
                    <div className='text-start'>
                      <div className={TextGrayNotification}>
                        Status
                      </div>
                      <div className=''>
                        <p className={`${BProStatus} ${getStatusColor(dataSet?.status)} ${getStatusTextColor(dataSet?.status)}`}>
                          {dataSet?.status}
                        </p>
                      </div>
                    </div>
                    <div className='text-start mt-4 md:mt-0'>
                      <div className='text-[#8B8D98] text-start  notification'>
                        Due Date
                      </div>
                      <div className='mt-1 md:mt-4'>
                        {convertDate(dataSet?.due_date)} 
                      </div>
                    </div>
                    {!(dataSet?.status === "Ongoing" || dataSet?.status === "Overdue" || dataSet?.status ===  "Not Start") && (
                      <>
                        <div>
                          <div className='text-[#8B8D98] mt-4 md:mt-0 notification text-start md:text-end'>
                            Shared Documents
                          </div>
                          <div className=''>
                            <p className='mt-1 md:mt-4 cursor-pointer gap-2 flex justify-start md:justify-end text-[#1EB469]'>
                            { dataSet?.sharedDocs?.length > 0 ? <p className='flex items-center' onClick={()=>handleDownloadAll(dataSet?.sharedDocs?.map((item)=>`${apiHost}/${item?.document}`))}><DonwloadIcon1 /> Download</p>:" No Documents" }  
                            </p>
                          </div>
                        </div>
                        <div className='mt-4 md:mt-0'>
                          <div className='text-[#8B8D98] notification text-start md:text-end'>
                            Completed Date
                          </div>
                          <div className='mt-1 md:mt-4 text-start md:text-center'>
                            {dataSet?.status==="Completed" ?  dataSet?.completedDate :"--"}
                          </div>
                        </div>
                      </>
                    )}
                    {(dataSet?.status === "Ongoing" || dataSet?.status === "Overdue" || dataSet?.status ===  "Not Start") && (
                      <div className='md:col-span-4 text-end -mt-12'>
                        <Button onClick={()=>SetMileStoneCompleteModel(!MileStoneCompleteModel) } className="px-4 py-2 greenbg text-white rounded-lg">
                          Mark as Complete
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className='mt-6 text-start'>
                    <p className='notification-span text-[#8B8D98] my-4'>Description</p>
                    <p className=' body-text text-[#2C2C2C]'>{dataSet?.description}</p>
                  </div>
                  <div className='mt-4 border  rounded-lg'>

                        <div className='flex items-center justify-between p-4'>
                            <div>
                                <p>Weekly Updates</p>
                            </div>
                           {(dataSet?.status =="Ongoing" || dataSet?.status==="Not Started")  && <div>
                            
                            <p onClick={()=>SetMileStoneAddUpdateModel(!MileStoneAddUpdateModel)} className='flex cursor-pointer items-center gap-2 text-[#1EB469]'> <AddUpdateProjectDetails/> Add update</p>
                            </div>}
                        </div>

                        <div className='w-full border mb-6'>
                             
                        </div>

                      <div className='h-[20rem] custom-scrollbar overflow-auto'>
                        {dataSet?.weeklyUpdates?.map((update, index) => (
                      <><p className='border body-text rounded-lg  mx-4 p-4 bg-[#F9F9FB] text-start' key={index}>{update.WeekUpdate}</p>
                            <p className='body-text text-start pl-4 mt-2 mb-5 text-[#80828D]' key={index}>{update.WeekUpdateTime}</p>
                            </>
                    ))}
                        </div>
                    
                   
                  </div>
                </div>
              </div>
            )}



            
            {activeTab === "Project Details" && (
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-2'>
                <div>
                  <div className='rounded-lg  border p-4'>
                   <div className='flex justify-between items-center'>
                   <div className='flex justify-between gap-4 items-center '>
                      <div className='h-[58px] overflow-hidden w-[58px] rounded-full bg-[#F9F9FB] flex items-center justify-center'>
                        <img className='' src={ImageData} alt="" />
                      </div>
                      <div>
                          <h5 className='h5-text text-[#62636C]'>
                      {getProjectData?.data?.company?.user?.name || "Not Available"}
                          </h5>
                      </div>
                    </div>
                    <div>
                    <p className='flex items-center text-[#1EB469] remove-text' role='button'
                    //  onClick={()=>handleDownloadAll(getProjectData?.data?.documents?.map((item)=> item?.document))}
                    onClick={handleDownloadAgreement}

                    >
                          <DonwloadIcon1/>
                        Onboarding Documents
                        </p>
                    </div>
                   </div>

                    <div className='mt-7'>
                    {profileData.map((item , index) => {
                      return <div className="flex my-4" key={index}>
                      <div className="w-[30%]">
                        <p className='body-text text-[#80828D] text-start'>{item?.title}</p>
                      </div>
                      <div className="flex w-[5%] justify-center">
                        <p className="text-[#2C2C2C] -mt-1">-</p>
                      </div>
                      <div className={`w-[70%] text-start`}>
                  
                            <div>
                              <h6 className="body-text text-[#2C2C2C] text-start">{item?.titleValue}</h6>
                            </div>
                          
                     
                      </div>
                    </div>
                    })}
                     </div>
                  </div>


                  <div className={HireCongStleDivExperienceOpted}>
            <h6 className="h6-text my-4">Services opted for</h6>
          <RoundedBox data = {ServiceOpted}/>
        </div>
                </div>
                <div className='  '>

                <div className='rounded border p-6'>
                <h6 className='h6-text text-[#2C2C2C]'>
                Monthly Payout
                  </h6>

                  {PaymentStatusData.map((item , index) => {
                      return <div className="flex my-4" key={index}>
                      <div className="w-[30%]">
                        <p className='body-text text-[#80828D] text-start'>{item?.title}</p>
                      </div>
                      <div className="flex w-[5%] justify-center">
                        <p className="text-[#2C2C2C] -mt-1">-</p>
                      </div>
                      <div className={`w-[70%] text-start`}>
                  
                            <div>
                              <h6 className={`body-text text-[#2C2C2C] ${index===1 ? item.titleValue ==="Pending" ? "rounded-[24.5px] bg-[#FFF6BE] text-[#6B5710] -mt-1 px-3 py-1 max-w-min " : "rounded-[24.5px] bg-[#D5F2DD] text-[#183D27] px-3 py-1 max-w-min" : ""} text-start`}>{item?.titleValue}</h6>
                            </div>
                          
                     
                      </div>
                    </div>
                    })}
                  <div>

                  </div>
                </div>

                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    {showNotification && <NudgeNotificationProject message={Message}
  />}

<BankerCompleteMileStoneModel setMessage={setMessage} setShowNotification={setShowNotification} setFeedbackModal={setFeedbackModal} fetchAgain={fetchAgain} isOpen={MileStoneCompleteModel} onClose={handleCloseModal} milestone={dataSet} />
<BankerAddUpdateModel setMessage={setMessage} setShowNotification={setShowNotification} isOpen={MileStoneAddUpdateModel} onClose={handleCloseModal}  milestone={{...dataSet,project:getProjectData?.data?.project_name}}  fetchAgain={fetchAgain} />
<BankerAddEvent 
 isOpen={isModalOpen}
 onClose={() => setIsModalOpen(false)}
 onSave={handleSaveEvent}
 company={getProjectData?.data?.company_user}
 type="bankerProjectDetail"
 hideDropDown={true}
//  banker={getData?.data?.banker}
/>
{feedBackModal && <FeedbackModal setMessage={setMessage} data={getProjectData} dataSet={dataSet} setShowNotification={setShowNotification} fetchAgain={fetchAgain} type="Banker1" onClose={()=>{setFeedbackModal(!feedBackModal); handleCloseModal()}} milestone={dataSet} User={getProjectData?.data?.company_user}/>}
</>
  );
};

export default BankerProjectDetails;