import React, { useEffect, useState } from "react";
import Button from "../../../../common/Button";
import {
  CrossIcon1,
  DiscriptionIcon,
  GroupIcon,
  IButtonIcon,
  JobListingCompPencil,
  ProfileMiniIcon,
  ProfilePhotoDumyIcon,
  RightArrowIcon,
  TimeIcon,
} from "../../../../common/Icons";
import InputField from "../../../../common/InputField";
import Checkbox from "../../../../common/Checkbox";
import SelectTagComponent from "../../../../common/SelectTagComponent";
import { formatMeetingDetails } from "../../../../Hook/validation";
import usePost from "../../../../Hook/usePost";
import { useNavigate, useParams } from "react-router-dom";
import { ToastMessage } from "../../../../common/Config";

const BankerMeeting = ({ ScheduleBtn,data }) => {
  const {id}=useParams()
  const navigate=useNavigate()
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: MeetingData,loading:MeetingDataLoading, 
    error: MeetingDataError, postData: postMeetingData } = usePost(`hiring/update-meeting`);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isInPerson, setIsInPerson] = useState(false);
  const [customDate, setCustomDate] = useState("");
  const [customTime, setCustomTime] = useState({
    "preferred_date": "",
    "preferred_start_time": "",
    "preferred_end_time": "",
  });
  const [comment, setComment] = useState("");
  const [errors,setErrors]=useState({})

  const InPersion = false;
  const ButtonText = (
    <p className="flex text-white items-center gap-2">
      Acknowledge Meeting
      <RightArrowIcon className="" stroke="#FFFFFF" />
    </p>
  );

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (type) => {
    setSelectedTimeSlot(type);
    if (type === "customTimeSlot") {
      setIsInPerson(true);
    } else {
      setIsInPerson(false);
    }
  };

  const handleCustomTimeChange = (name,event) => {
    setCustomTime({...customTime,[name]:event});
  };
 

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const validation=()=>{
    let error={}
    if(selectedTimeSlot==='customTimeSlot'){
      if(customTime.preferred_date===''){
        error.preferred_date="Please select date"
      }
      if(customTime.preferred_start_time===''){
        error.preferred_start_time="Please select start time"
      }
      if(customTime.preferred_end_time===''){
        error.preferred_end_time="Please select end time"
      }

      
    }
    setErrors(error)
    return Object.keys(error).length===0
  }
  const handleSubmit = () => {
    if(selectedTimeSlot==='timeSlot1'){
      const obj={
        "preferred_date": data?.data?.preferred_date,
        "preferred_start_time": data?.data?.preferred_start_time,
        "preferred_end_time": data?.data?.preferred_end_time,
        "company_meeting": id
    }
    postMeetingData(obj,token)
    }
    if(selectedTimeSlot==='timeSlot2'){
      const obj={
        "preferred_date": data?.data?.preferred_date2,
        "preferred_start_time": data?.data?.preferred_start_time2,
        "preferred_end_time": data?.data?.preferred_end_time2,
        "company_meeting": id
    }
    postMeetingData(obj,token)
    }
    if(selectedTimeSlot==='customTimeSlot' && validation(customTime)){
      const obj={
        "preferred_date": customTime?.preferred_date,
        "preferred_start_time": customTime.preferred_start_time,
        "preferred_end_time": customTime.preferred_end_time,
        "company_meeting": id
    }
    postMeetingData(obj,token)
    }
    // Handle form submission logic
    const formData = {
      selectedTimeSlot,
      customTime,
      comment,
    };
    // toggleModal();
  };
  useEffect(()=>{
    if(data){
      setSelectedTimeSlot("timeSlot1")
    }
  },[data])
  useEffect(()=>{
    if(MeetingData){
      ToastMessage("success", "Meeting acknowledged successfully")
      toggleModal()
      navigate('/dashboard/banker')
    }
    if(MeetingDataError){
      ToastMessage("error", MeetingDataError?.message || "Something went wrong")
    }
  },[MeetingData,MeetingDataError])
  
  return (
    <div>
      <Button
        onClick={toggleModal}
        className="px-6 my-4 py-2 text-white greenbg rounded-lg"
      >
        {ButtonText}
      </Button>
      {isOpen && (
        <div className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-[#1E1F24] bg-opacity-50 backdrop-blur-sm">
        <div className="relative p-4 w-[43.5rem] max-h-[100%] bg-white rounded-lg shadow ">
          <div className="aboslute top-0 right-0 p-2 left-0 flex  border-b rounded-t ">
            <div>
            <h5 className="h5-text my-2 ml-1">{ InPersion ?"In-Person Meeting" : "Virtual Meeting"} : Technical Round Interview</h5>
            <div className="flex item-center gap-2 justify-start">
                <ProfileMiniIcon/>

                <p className="s2-text">With {data?.data?.banker?.user?.name}</p>
            </div>
            </div>
              <Button
                type="button"
                onClick={toggleModal}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              >
                <CrossIcon1 />
              </Button>
            </div>
            <div className="overflow-auto h-[20rem] my-4 mx-3 space-y-5 custom-scrollbar scrollbar-hide">
              { InPersion && <div>
                <h6 className="h6-text">Please visit below address:</h6>
                <p className="b2-text w-3/4 text-start mt-2 text-[#2C2C2C]">HDFC Bank, Tower B, MILLENNIUM PLAZA, B-1106,
                Sushant Lok Phase I, Sector 43, Gurugram, Haryana 122001</p>
                </div>}
             <div>
                <h6 className="h6-text">
                Please confirm below timings for meeting
                </h6>
             </div>
              <div className="grid grid-cols-1 gap-5">
                <Checkbox
                 type="radio"
                 id="timeSlot1"
                 label={formatMeetingDetails(data?.data)?.firstMeetingSlot}
                 checked={selectedTimeSlot === "timeSlot1"}
                 onChange={() => handleCheckboxChange("timeSlot1")}
                />
                <Checkbox
                   type="radio"
                   id="timeSlot2"
                   label={formatMeetingDetails(data?.data)?.secondMeetingSlot}
                   checked={selectedTimeSlot === "timeSlot2"}
                   onChange={() => handleCheckboxChange("timeSlot2")}
                />
                <Checkbox
                   type="radio"
                   id="customTimeSlot"
                   label="I want to change the timings"
                   checked={selectedTimeSlot === "customTimeSlot"}
                   onChange={() => handleCheckboxChange("customTimeSlot")}
                />
              </div>
              {isInPerson && (<>
                 <div className="grid grid-cols-2 gap-4">
                 <div className="flex items-center justify-start gap-6">
                   <div>
                     {/* <TimeIcon /> */}
                   </div>
                   <div className="w-full">
                     <InputField 
                     onChange={(e)=>handleCustomTimeChange("preferred_date",e.target.value)}
                     type="date"
                     error={errors?.preferred_date}
                     />
                   </div>
                 </div>
                 <div className="flex items-center gap-3 mt-2 justify-between">
                   <div className="w-full">
                     <SelectTagComponent
                       defaultVal={customTime?.preferred_start_time || "11:30"}
                       options={[
                         "10:00",
                         "10:30",
                         "11:00",
                         "11:30",
                         "12:00",
                         "12:30",
                         "13:00",
                       ]}
                       onChange={(e)=>handleCustomTimeChange("preferred_start_time",e)}
                       error={errors?.preferred_start_time}
                     />
                   </div>
                   <div>
                     <p>to</p>
                   </div>
                   <div className="w-full">
                     <SelectTagComponent
                       defaultVal={customTime?.preferred_end_time  || "11:30"}
                       options={[
                        "10:00",
                        "10:30",
                        "11:00",
                        "11:30",
                        "12:00",
                        "12:30",
                        "13:00",
                       ]}
                       onChange={(e)=>handleCustomTimeChange("preferred_end_time",e)}
                       error={errors?.preferred_end_time}
                     />
                   </div>
                
                 </div>
                 
               </div>
               <div className="">
                
               <textarea
                 id="comment"
                 name="comment"
                 rows="3"
                 value={comment}
                 onChange={handleCommentChange}
                 className="mt-1 resize-none w-[96%] ml-5 rounded-md border-gray-300 border p-4"
                 placeholder="Add Comment"
               />
             </div> </>
              )}
            
              <div className="h-[3rem]"></div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 bg-white flex items-center py-2 px-6 border-t border-gray-200 rounded-b ">
              <Button
               onClick={handleSubmit}
                className="ml-auto greenbg w-[156px] rounded-lg h-[48px] px-2 py-2 text-white"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BankerMeeting;
