import React, { useEffect, useState } from 'react';
import Navbar from '../../../../common/Navbar';
import BankerSideNavbar from '../../../../common/BankerSideNavbar';
import Button from '../../../../common/Button';
import { HireCongStleDivExperienceOpted, JobListingMenuBarDivAvticeTab } from '../../company/job/DashboardJobStyle';
import { AddUpdateProjectDetails, DonwloadIcon1, DownloadGreenIcon, DownloadIcon, DropdownIconUpAndDown, GreenPencilIcon, MileStoneRightTick, RightTickIcon } from '../../../../common/Icons';
import RoundedBox from '../../../../common/RoundedBox';
import { BankerProjectDetailMainDiv, BPDetailImagediv1, BPDetailMaindiv2, BPDetailMaindiv3, BPDetailPtag1, BPDetailPtag2, BProActiveTag, BProActiveTag1, BProBreaLine1, BProDiv2, BProMainTag, BProMileStoneHeading, BProMileStoneMainDiv, BProStatus, BPScheduleMeeting, GridCol4, TextGrayNotification } from '../../banker/project/BankerProjectStyle';
import SideNavbar from '../../../../common/SideNavbar';
import ApproveMilestoneModel from './ApproveMilestoneModel';
import UpdateDueDateModel from './UpdateDueDateModel';
import { CP_Comp_div11, CP_Detail_MainDiv, CP_Detail_MainDiv1, CP_Detail_MainDiv10, CP_Detail_MainDiv11, CP_Detail_MainDiv12, CP_Detail_MainDiv13, CP_Detail_MainDiv14, CP_Detail_MainDiv15, CP_Detail_MainDiv16, CP_Detail_MainDiv17, CP_Detail_MainDiv18, CP_Detail_MainDiv19, CP_Detail_MainDiv2, CP_Detail_MainDiv20, CP_Detail_MainDiv3, CP_Detail_MainDiv4, CP_Detail_MainDiv5, CP_Detail_MainDiv6, CP_Detail_MainDiv7, CP_Detail_MainDiv8, CP_Detail_MainDiv9, CP_Detail_MainDivPTag } from './CompanyProjectStyle';
import useGetData from '../../../../Hook/useGetData';
import { useNavigate, useParams } from 'react-router-dom';
import { convertDate, getTimeDifference, handleDownloadAll } from '../../../../Hook/validation';
import ScheduledMeeting from '../job/ScheduledMeeting';
import CompanyAddEvent from '../calendar/CompanyAddEvent';
import { apiHost, formatPriceInput } from '../../../../common/Config';
import NudgeNotificationProject from '../../../../common/NudgeNotificationProject';
import usePost from '../../../../Hook/usePost';



const dummyContent = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. "


const getStatusColor = (status) => {
  switch (status) {
    case 'Completed':
      return 'bg-[#1EB469]';
    case 'In Approval':
      return 'bg-[#EA9515]';
    case 'Ongoing':
      return 'bg-[#FFF6BE]';
    case 'Declined':
      return 'bg-[#FF0059]';
    case 'Not Started':
      return 'bg-[#D8D9E0]';
      case 'Overdue':
      return 'bg-[#FFCACE]';
    default:
      return '';
  }
};
const getStatusLabel = (status) => {
  switch (status) {
    case 0:
      return 'Declined';
    case 1:
      return 'Ongoing';
    case 2:
      return 'In Approval';
    case 3:
      return 'Overdue';
    case 4:
      return 'Completed';
    default:
      return '';
  }
};

const getStatusTextColor = (status) => {
  switch (status) {
    case 'Completed':
      return 'text-[#183D27]';
    case 'In Approval':
      return 'text-[#6B3C10]';
    case 'Ongoing':
      return 'text-[#6B5710]';
    case 'Declined':
      return 'text-white';
    default:
      return 'text-black';
  }
};

const CompanyProjectDetail = () => {
  const {id}=useParams()
  const navigate=useNavigate()
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: getData,loading: loadingPayment, error: errorPayment,refetch:fetchData } = useGetData(`projects/company-job-hire-details/${id}`, token);
  const { data: AgreementData,loading:AgreementLoading, 
    error: AgreementError, postData: postAgreementData } = usePost(`hiring/get-bank-agreement`);
  const { data: getBankerList } = useGetData(`hiring/get-bankers`,token);
  const [activeTab, setActiveTab] = useState("Milestones");
  const [selectedMilestone, setSelectedMilestone] = useState([]);
  const [MileStoneCompleteModel  , SetMileStoneCompleteModel] = useState(false);
  const [MileStoneApproveModel  , SetMileStoneApproveModel] = useState(false);
  const [mobileMileStoneShwo ,  setMobileMileStoneShwo] = useState(true);

  const [UpdateDueDate , SetUpdateDueDate] =  useState(false)
  const [dataSet,setDataSet]=useState({
    title:"",
    description:"",
    due_date:"",
    status:"",
    weeklyUpdates:[],

  })
  const [ScheduleBtn , setScheduledBtn] = useState(false)
  const [type,setType]=useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateInfo, setDateInfo] = useState(null);
  const [events, setEvents] = useState([]);
  const [showNotification, setShowNotification] = useState(false); 
  const [Message,setMessage]=useState("")
  // SetUpdateDueDate(!UpdateDueDate)


  const mainTag = [
    { id: 1, name: "Milestones" },
    { id: 2, name: "Project Details" },
  ];

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const handleAddEventClick = () => {
    setDateInfo(null);
    setIsModalOpen(true);
  };
  const handleSaveEvent = ({ title, eventType, color }) => {
    if (title) {
      const newEvent = {
        title,
        start: dateInfo ? dateInfo.date : null,
        end: dateInfo ? dateInfo.date : null,
        extendedProps: { eventType },
        color,
      };
      setEvents([...events, newEvent]);
    }
    setIsModalOpen(false);
  };
  const handleMilestoneClick = (milestone) => {
    setSelectedMilestone(milestone);
  };
  // const handleSaveEvent = ({ title, eventType, color }) => {
  //   if (title) {
  //     const newEvent = {
  //       title,
  //       start: dateInfo ? dateInfo.date : null,
  //       end: dateInfo ? dateInfo.date : null,
  //       extendedProps: { eventType },
  //       color,
  //     };
  //     setEvents([...events, newEvent]);
  //   }
  //   setIsModalOpen(false);
  // };
  const handleCloseModal = () => {
    // SetMileStoneCompleteModel(false);
    SetMileStoneApproveModel(false);
    SetUpdateDueDate(false)

  };
const handleMilestone=(milestone)=>{
  
  setDataSet({
    id:milestone?.id,
    status:milestone?.status || "Not Started",
    title:milestone?.name || "",
    description:milestone?.description || "",
    due_date:milestone?.dueDate || "",
    completedDate:milestone?.completedDate || "",
    weeklyUpdates:milestone?.weeklyUpdates || [],
    banker:milestone?.banker || "",
    sharedDocs:milestone?.sharedDocs || [],

  })
}

  const profileData = [
    {
      id: 1,
      title: "Phone Number",
      titleValue: getData?.data?.profile?.user?.phone_number || "+91 9876543210",
    },
    {
      id: 2,
      title: "Email ID",
      titleValue: getData?.data?.profile?.user?.email || "sumitmathur@hdfcbank.com",
    },
    {
      id: 3,
      title: "Start Date",
      titleValue:convertDate(getData?.data?.start_date) || "19/08/2024",
    } ,
    {
      id: 3,
      title: "Project Due Date",
      titleValue: convertDate(getData?.data?.end_date )|| "12/03/2024",
    } ,
   { 
    id:4 ,
    title:"Onboarding Document",
    titleValue:""
  },
  { 
    id:5 ,
    title:"certificate Agreement",
    titleValue:<a>hhh</a>
  }
  ];


  
  const ServiceOpted =getData?.data?.job_details?.key_service_details?.map((item)=>item?.name) || getData?.data?.profile?.key_service?.map((item)=>item?.name) || []
  const ImageData = "https://img.freepik.com/free-photo/portrait-young-man-talking-phone-walking-street_231208-2764.jpg";
useEffect(() => {
  if(getData?.data){
    const milestonesData =getData?.data?.milestones?.map((item,index)=>{
      
      return {
        id:item?.id,
        banker:item?.banker || "",
        name:`Milestone ${index+1}`,
        status:getStatusLabel(item?.status) || "Not Started",
        dueDate:item?.due_date,
        sharedDocs:item?.documents || [],
        completedDate:item?.completed_date || "Not available",
        description:item?.description,
        weeklyUpdates: item?.updates?.map((items,index)=>{
          return {
            id:items?.id,
            name:`Milestone ${item?.id}`,
            WeekUpdate:items?.update_description,
            WeekUpdateTime:convertDate(items?.created_at)
          }
        }) || []
        
      }
    }) ||  [
      { id: 1, name: "Milestone 1", status: "Completed", dueDate: "20/07/2024", sharedDocs: "Download", completedDate: "20/07/2024", description: dummyContent, weeklyUpdates: [{id:1, WeekUpdate:dummyContent ,WeekUpdateTime:"20/07/2024  12:00 PM" }, {id:1, WeekUpdate:dummyContent ,WeekUpdateTime:"20/07/2024  12:00 PM" },{id:1, WeekUpdate:dummyContent ,WeekUpdateTime:"20/07/2024  12:00 PM" }] }
      // Add more milestones as needed
    ];
    setDataSet(milestonesData[0])
    setSelectedMilestone(milestonesData)
  }
},[getData])
const handleApproved=()=>{
  setType("approved")
  SetMileStoneApproveModel(true)
}
const handleDeclined=()=>{
  setType("declined")
  SetMileStoneApproveModel(true)
}

const handleDownloadAgreement=()=>{
  const obj={
      "job_hire_id":getData?.data?.job
  }
  postAgreementData(obj,token)
  console.log("TTTTTT",getData?.data?.job);
}
// const date = "2024-07-25";

// Convert to Date object
const dateObj = new Date(dataSet?.due_date || dataSet?.dueDate);

// Extract day, month, and year
const day = String(dateObj.getDate()).padStart(2, '0');
const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
const year = dateObj.getFullYear();

// Format the date as DD/MM/YYYY
const formattedDate = `${day}/${month}/${year}`;
const bankerName=getBankerList?.data?.filter((item)=>item?.id===getData?.data?.banker)?.[0]?.name;

  return (
    <>
    <div>
      <Navbar hemBurgerIcon={true} BackBtn={true} CompanyNavbar={true} ProfileAndNotification={true} />

      <div className='flex w-full'>
        <div className='w-0 md:w-[9%]'>
          <SideNavbar />
        </div>
        <div className={BankerProjectDetailMainDiv}>
          <div className={BPDetailMaindiv2}>
            <div className={BPDetailMaindiv3} role='button' onClick={()=>navigate(`/dashboard/company/profile/${getData?.data?.banker}`,{state:{data:"ProjectDetail"}})}>
              <div className='m-auto flex items-center justify-center w-[96px] overflow-hidden h-[96px] rounded-full'>
                <img className='w-full' src={`${apiHost}/${getData?.data?.profile?.profile_image}`} alt="" />
              </div>
              <div className=' '>
                <h4 className='h4-text text-center md:text-start'>
                  {getData?.data?.project_name || "Not Available"}
                </h4>
                <p className={BPDetailPtag1}>
                 <div className=''>
                 <p className='b2-text cursor-pointer text-[#1EB469]'>{getData?.data?.profile?.user?.name || "Not Available"} </p>
                 </div>
                 <div className='flex justify-center items-center gap-2'>
                 <div className={BPDetailPtag2}></div>
                  <div>
                   {getStatusLabel(getData?.data?.is_completed ? 4 :1) || "Ongoing"} - {getTimeDifference(getData?.data?.updated_at)}

                  </div>
                 </div>
                </p>
              </div>
            </div>
            <div className=''>
            <Button className={BPScheduleMeeting} onClick={handleAddEventClick}>
                <p className='simple-text'>Schedule a Meeting</p>
            </Button>
            
            </div>
          </div>

          <div>
            <div className={BProMainTag}>
              {mainTag.map((item, index) => (
                <div key={index}>
                  <div
                    className={` ${JobListingMenuBarDivAvticeTab} ${activeTab === item.name ? "font-bold" : ""}`}
                    onClick={() => handleTabClick(item.name)}
                  >
                    <div>{item.name}</div>
                  </div>
                  <div
                    className={`h-1 mt-4 border-1 ${activeTab === item.name ? "greenbg" : "bg-transparent"}`}
                  ></div>
                </div>
              ))}
            </div>
            <div className={BProBreaLine1}></div>
          </div>

          <div className='p-4 lg:p-8'>
            {activeTab === "Milestones" && (
              <div className={BProActiveTag}>
                <div onClick={()=>setMobileMileStoneShwo(!mobileMileStoneShwo)} className={BProActiveTag1}>
                  <div>
                    <h5 className={BProMileStoneHeading} >Milestones</h5>
                  </div>
                  {selectedMilestone?.map((milestone) => (
                    <>
                    <div key={milestone.id} className='flex' role='button' onClick={()=>handleMilestone(milestone)}>
                    
                      <div className={`w-[7px] ${getStatusColor(milestone.status)}`}></div>
                      <div
                        className={`${BProMileStoneMainDiv} ${dataSet.id === milestone.id ? 'bg-white' : ''}`}
                        // onClick={() => handleMilestoneClick(milestone)}
                      >
                        
                        <h3>{milestone.name}</h3>
                        <div className='flex gap-4'>
                        { milestone.status === "Completed" && <MileStoneRightTick />}
                        { <p className='block lg:hidden'>  <DropdownIconUpAndDown isDropdownVisible={dataSet.id === milestone.id ? mobileMileStoneShwo  : false } /></p> }
                    
                        </div>
                         </div>
                    </div>

                  { dataSet.id === milestone.id && mobileMileStoneShwo  &&  <div className='w-full block lg:hidden '>
                 {dataSet.status === "In Approval" && <div className={CP_Detail_MainDiv}>
                      <p className={CP_Detail_MainDiv1}>
                      {bankerName} marked this milestone as completed. Please review and take action.
                      </p>

                      <div className={CP_Detail_MainDiv2}>
                      <Button onClick={handleApproved} className={CP_Detail_MainDiv4}>
                        <RightTickIcon className="white"/>
                        Approve Milestone
                      </Button>

                      <Button onClick={handleDeclined} className={CP_Detail_MainDiv3}>
                      Decline
                      </Button>
                      </div>
                  </div>}
                  <div className={`grid grid-cols-1 md:grid-cols-4  m-8`}>
                    <div className='text-start'>
                      <div className={TextGrayNotification}>
                        Status
                      </div>
                      <div className=''>
                        <p className={`${BProStatus} ${getStatusColor(dataSet.status)} ${getStatusTextColor(dataSet.status)}`}>
                          {dataSet.status}
                        </p>
                      </div>
                    </div>
                    <div className='text-start mt-5 md:mt-0' >
                      <div className={`${TextGrayNotification} flex gap-2 items-center` }>
                      <p>
                        Due Date
                       </p>
                       {(dataSet.status === "Overdue" || dataSet.status === "Not Started"  ||  dataSet.status === "Ongoing" || dataSet.status === "In Approval" || dataSet?.status ==="Declined") && <p onClick={()=>SetUpdateDueDate(!UpdateDueDate)} className='cursor-pointer'>
                        <GreenPencilIcon/>
                        </p>}
                        
                      </div>
                      <div className='mt-1 lg:mt-4'>
                        {dataSet.due_date}
                        {/* {formattedDate} */}
                      </div>
                    </div>
                      <>
                        <div>
                          <div className='text-[#8B8D98] mt-4 md:mt-0 notification text-start md:text-end'>
                            Shared Documents
                          </div>
                          <div className=''>
                            
                            <p className='mt-1 md:mt-4 cursor-pointer gap-2 flex justify-start md:justify-center  text-[#1EB469]'>
                            { dataSet.sharedDocs?.length>0 ? <p className='flex items-center' onClick={()=>handleDownloadAll(dataSet?.sharedDocs?.map((item)=>`${apiHost}${item?.document}`))}><DonwloadIcon1 /> Download</p>:" No Documents" }  
                            </p>
                          </div>
                        </div>
                        <div className='md:mt-0 mt-5'>
                        <div className='text-[#8B8D98] notification text-start md:text-end'>
                          
                            Completed Date
                          </div>
                          <div className='mt-1 lg:mt-4 text-start md:text-center'>
                          {dataSet?.status==="Completed" ?  dataSet?.completedDate :"--"}
                          </div>
                        </div>
                      </>
                
                  </div>
                  <div className={CP_Detail_MainDiv6}>
                    <p className={CP_Detail_MainDiv7}>Description</p>
                    <p className={CP_Comp_div11}>{dataSet.description}</p>
                  </div>
                  <div className={CP_Detail_MainDiv8}>

                        <div className={CP_Detail_MainDiv9}>
                            <div>
                                <p>Weekly Updates</p>
                            </div>
                            {/* <div>
                            
                            <p onClick={()=>SetMileStoneAddUpdateModel(!MileStoneAddUpdateModel)} className='flex cursor-pointer items-center gap-2 text-[#1EB469]'> <AddUpdateProjectDetails/> Add update</p>
                            </div> */}
                        </div>

                        <div className={CP_Detail_MainDiv10}>
                             
                        </div>

                    
                    {dataSet?.weeklyUpdates?.map((update, index) => (
                      <><p className={CP_Detail_MainDiv11} key={index}>{update.WeekUpdate}</p>
                            <p className={CP_Detail_MainDiv12} key={index}>{update.WeekUpdateTime}</p>
                            </>
                    ))}
                  </div>
                </div>}

                    </>
                  ))}
                </div>


                <div className='w-full hidden lg:block lg:w-2/3 '>
                 {dataSet.status === "In Approval" && <div className={CP_Detail_MainDiv}>
                      <p className={CP_Detail_MainDiv1}>
                      {bankerName} marked this milestone as completed. Please review and take action.
                      </p>

                      <div className={CP_Detail_MainDiv2}>
                      <Button onClick={handleApproved} className={CP_Detail_MainDiv4}>
                        <RightTickIcon className="white"/>
                        Approve Milestone
                      </Button>

                      <Button onClick={handleDeclined} className={CP_Detail_MainDiv3}>
                      Decline
                      </Button>
                      </div>
                  </div>}
                  <div className={`grid grid-cols-1 md:grid-cols-4  m-8`}>
                    <div className='text-start'>
                      <div className={TextGrayNotification}>
                        Status
                      </div>
                      <div className=''>
                        <p className={`${BProStatus} ${getStatusColor(dataSet.status)} ${getStatusTextColor(dataSet.status)}`}>
                          {dataSet.status}
                        </p>
                      </div>
                    </div>
                    <div className='text-start mt-5 md:mt-0' >
                      <div className={`${TextGrayNotification} flex gap-2 items-center` }>
                      <p>
                        Due Date
                       </p>
                       {(dataSet.status === "Overdue" || dataSet.status === "Not Started"  ||  dataSet.status === "Ongoing"  || dataSet.status === "In Approval" || dataSet?.status ==="Declined") && <p onClick={()=>SetUpdateDueDate(!UpdateDueDate)} className='cursor-pointer'>
                        <GreenPencilIcon/>
                        </p>}
                        
                      </div>
                      <div className='mt-1 lg:mt-4'>
                    
                        {/* {dataSet?.due_date || dataSet?.dueDate} */}
                        {formattedDate}
                      </div>
                    </div>
                      <>
                        <div>
                          <div className='text-[#8B8D98] mt-4 md:mt-0 notification text-start md:text-end'>
                            Shared Documents
                          </div>
                          <div className=''>
                            <p className='mt-1 md:mt-4 cursor-pointer gap-2 flex justify-start md:justify-end text-[#1EB469]'>
                            { dataSet.sharedDocs?.length>0 ? <p className='flex items-center' onClick={()=>handleDownloadAll(dataSet?.sharedDocs?.map((item)=>`${apiHost}/${item?.document}`))}><DonwloadIcon1 /> Download</p>:" No Documents" } 
                            </p>
                          </div>
                        </div>
                        <div className='md:mt-0 mt-5'>
                        <div className='text-[#8B8D98] notification  md:text-end'>
                          
                            Completed Date
                          </div>
                          <div className='mt-1 lg:mt-4 !text-end md:text-center'>
                          {dataSet?.status==="Completed" ?  convertDate(dataSet?.completedDate) :"--"}
                          </div>
                        </div>
                      </>
                
                  </div>
                  <div className={CP_Detail_MainDiv6}>
                    <p className={CP_Detail_MainDiv7}>Description</p>
                    <p className={CP_Comp_div11}>{dataSet.description}</p>
                  </div>
                  <div className={CP_Detail_MainDiv8}>

                        <div className={CP_Detail_MainDiv9}>
                            <div>
                                <p>Weekly Updates</p>
                            </div>
                            {/* <div>
                            
                            <p onClick={()=>SetMileStoneAddUpdateModel(!MileStoneAddUpdateModel)} className='flex cursor-pointer items-center gap-2 text-[#1EB469]'> <AddUpdateProjectDetails/> Add update</p>
                            </div> */}
                        </div>

                        <div className={CP_Detail_MainDiv10}>
                             
                        </div>

                    
                    <div className='h-[20rem] custom-scrollbar overflow-auto'>
                    {dataSet?.weeklyUpdates?.map((update, index) => (
                      <><p className={CP_Detail_MainDiv11} key={index}>{update.WeekUpdate}</p>
                            <p className={CP_Detail_MainDiv12} key={index}>{update.WeekUpdateTime}</p>
                            </>
                    ))}
                    </div>
                 
                  </div>
                </div>
                
              </div>

            )}
            
            {activeTab === "Project Details" && (
              <div className={CP_Detail_MainDiv13}>
                <div>
                  <div className={CP_Detail_MainDiv14}>
                   <div className={CP_Detail_MainDiv15}>
                   
                    
                   </div>

                    <div className='mt-7'>
                    {profileData.map((item , index) => {
                      return <div className="flex my-4" key={index}>
                      <div className="w-[30%]">
                        <p className={CP_Detail_MainDiv16}>{item?.title}</p>
                      </div>
                      <div className={CP_Detail_MainDiv17}>
                        <p className="text-[#2C2C2C]">-</p>
                      </div>
                      <div className={`w-[70%] text-start`}>
                  
                            <div>
                              <h6 className={CP_Detail_MainDiv18}>
                                 {index===4 ? <>
                                <p className={CP_Detail_MainDivPTag}
                                onClick={()=>handleDownloadAll(getData?.data?.documents?.map((item)=>`${apiHost}/${item?.document}`))}
                                >
                          <DonwloadIcon1/>
                        Onboarding Documents
                        </p>
                              </> :index===5 ? <>
                                <p className={CP_Detail_MainDivPTag}
                                onClick={handleDownloadAgreement}
                                >
                          <DonwloadIcon1/>
                        Download Documents
                        </p>
                              </>: item?.titleValue}
                              
                              </h6>
                            </div>
                          
                     
                      </div>
                    </div>
                    })}
                     </div>
                  </div>


                  <div className={HireCongStleDivExperienceOpted}>
            <h6 className="h6-text my-4">Services opted for</h6>
          <RoundedBox data = {ServiceOpted}/>
        </div>
                </div>
                <div className='  '>

                <div className={CP_Detail_MainDiv19}>
                <h6 className={CP_Detail_MainDiv20}>
                Monthly Payout
                  </h6>

                  <h6 className={CP_Detail_MainDiv20}>
                    
                  ₹ {formatPriceInput(getData?.data?.profile?.payout?.toString() || 0)}
                  </h6>

                 
                </div>

                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
     {showNotification && <NudgeNotificationProject message={Message}/>}
<UpdateDueDateModel setMessage={setMessage} setShowNotification={setShowNotification} dataSet={dataSet} fetchData={fetchData} isOpen={UpdateDueDate} onClose={handleCloseModal} milestone={selectedMilestone} />
<ApproveMilestoneModel dataSet={dataSet} setMessage={setMessage} setShowNotification={setShowNotification} fetchData={fetchData} type={type} milestone_id={dataSet?.id} isOpen={MileStoneApproveModel} onClose={handleCloseModal} />
<CompanyAddEvent
setMessage={setMessage} 
setShowNotification={setShowNotification}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveEvent}
        type="companyProjectDetail"
        banker={getData?.data?.banker}
        hideDropDown={true}
      />

</>
  );
};

export default CompanyProjectDetail;