import React from 'react';

const Button = (props) => {
  const {
    active = false,
    block = false,
    children,
    className = '',
    color = '',
    disabled ,
    icon,
    loading = false,
    shape = 'round',
    size,
    variant = 'default',
    ...rest
  } = props;

  const baseClass = 'font-lato rounded-[8px]';
  const activeClass = active ? 'active-class' : '';
  const blockClass = block ? 'block-class' : '';
  const disabledClass = disabled ? 'disabled-class' : '';
  const loadingClass = loading ? 'loading-class' : '';
  const shapeClass = shape === 'round' ? 'rounded-full' : 'rounded';
  const sizeClass = size ? `size-${size}` : '';
  const variantClass = `variant-${variant}`;

  return (
    <button
      className={`${baseClass} ${color} ${className} ${activeClass} ${blockClass} ${disabledClass} ${loadingClass} ${shapeClass} ${sizeClass} ${variantClass}`}
      disabled={disabled}
      {...rest}
    >
      {icon && <span className="icon-class">{icon}</span>}
      {loading && <span className="loading-spinner-class">Loading...</span>}
      {/* <p className='truncate flex items-center'> */}
      {!loading && children}
      {/* </p> */}
    </button>
  );
};

export default Button;
