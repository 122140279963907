import React, { useEffect, useState } from "react";
import Button from "../../../../common/Button";
import {
  CrossIcon1,
  DiscriptionIcon,
  GroupIcon,
  IButtonIcon,
  JobListingCompPencil,
  TimeIcon,
} from "../../../../common/Icons";
import InputField from "../../../../common/InputField";
import Checkbox from "../../../../common/Checkbox";
import SelectTagComponent from "../../../../common/SelectTagComponent";
import { ArrayOfTime, ValidationForMeeting, ValidationForMeetingUpdate, formatTime } from "../../../../Hook/validation";
import usePost from "../../../../Hook/usePost";
import { ToastMessage } from "../../../../common/Config";
import { useNavigate, useParams } from "react-router-dom";
import useGetData from "../../../../Hook/useGetData";
import Tooltip from "../calendar/Tooltip";

const EditScheduledMeeting = ({ ScheduleBtn,data,className,isOpen,setIsOpen }) => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const {id}=useParams();
  const navigate=useNavigate();
  const { data: meetingData,loading:meetingLoading, error: meetingError, postData: postMeetingData } = usePost(`hiring/company-schedule-meeting`);
  const { data: getData,loading: loadingPayment, error: errorData,refetch:fetchData } = useGetData(`hiring/meetings-company/${id}`, token);
  const { data: meetingUpdateData,loading:meetingUpdateLoading, error: meetingUpdateError, postData: postMeetingUpdateData } = usePost(`hiring/company-update-schedule-meeting`);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [isInPerson, setIsInPerson] = useState(false);
  const [errors,setErrors]=useState({})
  const [formState, setFormState] = useState({
  title: "",
  job_hire: data?.job_hire_id || 1,
  is_virtual: true,
  preferred_date: "",
  email:"",
  preferred_start_time: "",
  preferred_end_time: "",
  preferred_date2: "",
  preferred_start_time2: "",
  preferred_end_time2: "",
  description: "",
  priority: 1,
  link:"",
  banker_user: data?.bkUser?.id ||1
});

  const handlePriorityClick = (priority) => {
    setFormState({...formState,priority:priority})
    setSelectedPriority(priority);
  };

  const toggleModal = () => {
    
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (type) => {
    setIsInPerson(type === "in-person");
    setFormState({...formState,is_virtual:type==="virtual" ? true :false})
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if(errors[name])ValidationForMeetingUpdate(formState,setErrors)
  };
 const handleSelectStartTimeChange=(val)=>{
setFormState({...formState,preferred_start_time:val?.name})
ValidationForMeetingUpdate({...formState,preferred_start_time:val?.name},setErrors)
 }
 const handleSelectEndTimeChange=(val)=>{
setFormState({...formState,preferred_end_time:val?.name})
ValidationForMeetingUpdate({...formState,preferred_end_time:val?.name},setErrors)
 }
 const handleSelectStartTimeChange2=(val)=>{
   setFormState({...formState,preferred_start_time2:val?.name})
   ValidationForMeetingUpdate({...formState,preferred_start_time2:val?.name},setErrors)
 }
 const handleSelectEndTimeChange2=(val)=>{
   setFormState({...formState,preferred_end_time2:val?.name})
   ValidationForMeetingUpdate({...formState,preferred_end_time2:val?.name},setErrors)
 }

  const handleSubmit=()=>{
    if(ValidationForMeetingUpdate(formState,setErrors)){
      // postMeetingData(formState,token)
      postMeetingUpdateData({meeting_id:id,meeting_link:formState?.link},token)

    }
  }
  useEffect(()=>{
    if(meetingData){
      ToastMessage("success","Meeting Scheduled Successfully")
      setIsOpen(!isOpen);
      setTimeout(()=>{
        navigate('/dashboard/company')
      },2000)
    }
    if(meetingError){
      ToastMessage("error","Meeting Scheduled Failed")
    }
  },[meetingData,meetingError])
  useEffect(()=>{
    if(meetingUpdateData){
      ToastMessage("success","Meeting Scheduled Successfully")
      setIsOpen(!isOpen);
      
    }
    if(meetingUpdateError){
      ToastMessage("error","Meeting Scheduled Failed")
    }
  },[meetingUpdateData,meetingUpdateError])
  useEffect(()=>{
    if(getData?.data){
let FixedData=getData?.data?.bankers[getData?.data?.bankers?.length-1];
      const obj={
        title:getData?.data?.title || "",
        job_hire: data?.job_hire_id || 1,
        is_virtual: data?.is_virtual || true,
        preferred_date: FixedData?.preferred_date || "",
        email:"",
        preferred_start_time: formatTime(FixedData?.preferred_start_time) || "",
        preferred_end_time:formatTime(FixedData?.preferred_end_time) || "",
        preferred_date2: "",
        preferred_start_time2: "",
        preferred_end_time2: "",
        description: getData?.data?.description || "",
        priority: getData?.data?.priority || 1,
        banker_user: FixedData?.banker ||1,
        link:getData?.data?.meeting_link || ""
      }
      setFormState({...obj,...getData})
    }
    if(errorData){
      ToastMessage("error","Meeting Scheduled Failed")
    }
  },[getData,errorData])
  return (
    <div>
      <Button
        onClick={toggleModal}
        // className="px-6 py-2 text-white greenbg rounded-lg"
        className={`px-6 py-2 text-white ${className?className:'greenbg'} rounded-lg`}
      >
        {ScheduleBtn}
      </Button>
      {isOpen && (
        <div className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-[#1E1F24] bg-opacity-50 backdrop-blur-sm">
          <div className="relative p-4 w-[90%]  lg:w-[43.5rem] max-h-[100%] bg-white rounded-lg shadow">
          <div className="absolute z-50 bg-white top-0 right-0 left-0 flex items-center justify-between p-4 md:p-3 border-b rounded-t">
              <h5 className="h5-text">Edit Schedule a meeting</h5>
              <Button
                type="button"
                onClick={toggleModal}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              >
                <CrossIcon1 />
              </Button>
            </div>

            <div className="overflow-auto h-[26rem] pt-10 mt-4 md:p-8 space-y-5 custom-scrollbar scrollbar-hide">
              <div className="flex items-center gap-6">
                <JobListingCompPencil />
                <InputField
                  astrix={false}
                  disabled={true}
                  required={true}
                  label="Add Title"
                  type="text"
                  id="addTitle"
                  name="title"
                  error={errors?.title}
                  value={formState.title}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex items-center gap-6">
                <GroupIcon />
                <InputField
                  astrix={false}
                  disabled={true}
                  required={true}
                  label="Sumit Mathur"
                  type="text"
                  id="name"
                  name="name"
                  value={formState?.banker_user}
                />
              </div>
              <div className="ml-12 lg:flex gap-[10rem]">
               <div>
               <Checkbox
                  type="radio"
                  id="virtual"
                  label="Virtual"
                  disabled={true}
                  checked={!isInPerson}
                  onChange={() => handleCheckboxChange("virtual")}
                />
               </div>
               <div className="mt-3 lg:mt-0" >
               <Checkbox
                  type="radio"
                  id="in-person"
                  label="In-person"
                  disabled={true}
                  checked={isInPerson}
                  onChange={() => handleCheckboxChange("in-person")}
                />
               </div>
              </div>
              {isInPerson && (
                <div className="flex items-center gap-6">
                  <GroupIcon />
                  <InputField
                    astrix={false}
                    disabled={false}
                    required={true}
                    label="Enter Address"
                    type="text"
                    id="address"
                    name="email"
                    error={errors?.email}
                    value={formState.email}
                    onChange={handleInputChange}
                  />
                </div>
              )}
                 {!isInPerson && (
                <div className="flex items-center gap-6">
                  <GroupIcon />
                  <InputField
                    astrix={false}
                    disabled={false}
                    required={true}
                    label="Enter Meeting Link"
                    type="text"
                    id="address"
                    name="link"
                    error={errors?.link}
                    value={formState.link}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              <div>
                <div>
                  <p className="body-text text-[#2C2C2C] pl-12 text-start">
                    Preferred time
                  </p>
                </div>
                <div className="grid w-full grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="flex items-center justify-start gap-6">
                    <div>
                      <TimeIcon />
                    </div>
                    <div className="w-full">
                      <InputField
                        disabled={true}
                        name="preferred_date"
                        value={formState.preferred_date}
                        error={errors?.preferred_date}
                        type="date"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="lg:flex w-full items-center gap-3 mt-2 justify-between">
                    <div className="w-full lg:pl-0 pl-12">
                      <SelectTagComponent
                        defaultVal={formState?.preferred_start_time || "select time"}
                        options={ArrayOfTime}
                        disabled={true}
                          name="preferred_start_time"
                          value={formState.preferred_start_time}
                          onChange={(val)=>handleSelectStartTimeChange(val)}
                          error={errors?.preferred_start_time}
                      />
                    </div>
                    <div className="my-2 lg:my-0">
                      <p>to</p>
                    </div>
                    <div className="w-full lg:pl-0 pl-12">
                      <SelectTagComponent
                 defaultVal={formState?.preferred_end_time || "select time"}
                            options={ArrayOfTime}
                            disabled={true}
                            name="preferred_end_time"
                            value={formState.preferred_end_time}
                            onChange={(val)=>handleSelectEndTimeChange(val)}
                            error={errors?.preferred_end_time}
                      />
                    </div>
                  </div>
                </div>
              </div>
       
              <div className="flex my-4 gap-6">
                <div className="mt-2">
                  <DiscriptionIcon />
                </div>
                <textarea
                  id="description"
                  name="description"
                  rows="4"
                  disabled={true}
                  className="mt-1 resize-none block w-full rounded-md border-gray-300 border p-4"
                  placeholder="Add Description"
                  value={formState.description}
                  onChange={handleInputChange}
                  error={errors?.description}
                />
              </div>
              <p className="text-[#ff0000]">{errors?.description}</p>

              <p className="body-text text-[#2C2C2C] pl-12 text-start">
                Set Priority
              </p>
              <div className="flex items-start md:items-center gap-6">
              <Tooltip content="please select priority" >
              <IButtonIcon />

              </Tooltip>
                <div className=" grid lg:grid-cols-4 grid-cols-1 gap-4">
                  <Button
                    disabled={true}
                    onClick={() => handlePriorityClick(1)}
                    className={`pl-3 pr-5 py-1 rounded-full bg-[#B6E4CB] flex items-center gap-3 ${
                      selectedPriority === 1 ? "border-2 border-black" : ""
                    }`}
                  >
                    <div
                      className={`w-[20px] h-[20px] rounded-full bg-[#0AA350] ${
                        selectedPriority !== "low" ? "opacity-50" : ""
                      }`}
                    ></div>
                    Low
                  </Button>
                  <Button
                    disabled={true}
                    onClick={() => handlePriorityClick(2)}
                    className={`pl-3 pr-5 py-1 rounded-full bg-[#FFF6BE] flex items-center gap-3 ${
                      selectedPriority === 2
                        ? "border-2 border-black"
                        : ""
                    }`}
                  >
                    <div
                      className={`w-[20px] h-[20px] rounded-full bg-[#FDD33E] ${
                        selectedPriority !== 2 ? "opacity-50" : ""
                      }`}
                    ></div>
                    Medium
                  </Button>
                  <Button
                    disabled={true}
                    onClick={() => handlePriorityClick(3)}
                    className={`pl-3 pr-5 py-1 rounded-full bg-[#FFD3B3] flex items-center gap-3 ${
                      selectedPriority === 3 ? "border-2 border-black" : ""
                    }`}
                  >
                    <div
                      className={`w-[20px] h-[20px] rounded-full bg-[#FF6B00] ${
                        selectedPriority !== 3 ? "opacity-50" : ""
                      }`}
                    ></div>
                    High
                  </Button>
                  <Button
                    disabled={true}
                    onClick={() => handlePriorityClick(4)}
                    className={`pl-3 pr-5 py-1 rounded-full bg-[#FFC6C6] flex items-center gap-3 ${
                      selectedPriority === 4
                        ? "border-2 border-black"
                        : ""
                    }`}
                  >
                    <div
                      className={`w-[20px] h-[20px] rounded-full bg-[#FD3E3E] ${
                        selectedPriority !== 4 ? "opacity-50" : ""
                      }`}
                    ></div>
                    Critical
                  </Button>
                </div>
              </div>
              <div className="h-[3rem]"></div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 bg-white flex items-center py-2 px-6 border-t border-gray-200 rounded-b">
              <Button
                onClick={handleSubmit}
                className="ml-auto greenbg w-[156px] rounded-lg h-[48px] px-2 py-2 text-white"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditScheduledMeeting;
