import React from 'react'
import {  SideStyleContainer } from './StyleCommon'
import { SideImages } from './Icons'

const Side = () => {
  
  return (
    <div className={SideStyleContainer}>
      <SideImages/>

    </div>
  )
}

export default Side
