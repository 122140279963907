import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SetPasswordStyleSVG, commenInputDivSpace, forgetPasswordStyle, formStyle, logoFontStyle } from "../register/RegisterStyle";
import InputField from "../../common/InputField";
import { AuthButtonClass, InputFieldClass, LabelFieldClass } from "../../common/StyleCommon";
import Side from "../../common/Side";
import Button from "../../common/Button";
import { validateLoginForm } from "../../common/Validations";
import { ToastMessage, emailRegex } from "../../common/Config";
import usePost from "../../Hook/usePost";
import { Loader } from "../../common/Loader";
import { CloseEyePasswordIcon, LogoIconSVG, OpenEyePasswordIcon, SideLoginImage } from "../../common/Icons";
import LoaderAPI from "../../common/LoaderAPI";
import { encryptPassword } from "../../Hook/validation";
// import loginside from "../../assets/loginside.png";
// import loginside from "assets/loginside.png"
// import {LoginSideImage} from "../../../public/assets/loginside.png"

// const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

const Login = () => {
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const { data, error, loading, postData } = usePost('onboarding/sign-in');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(true); // State to track form validity
  const [showPassword, setShowPassword] = useState(true);
 const [state,setState] = useState(true);


  
  const validateEmail = useCallback(
    (email) => {
      if (!emailRegex.test(email)) {
        setErrors((prevErrors) => ({ ...prevErrors, email: "Enter valid email id" }));
      } else {
        setErrors((prevErrors) => {
          const { email, ...rest } = prevErrors;
          return rest;
        });
      }
    },
    []
  );
  const debouncedValidateEmail = useCallback(debounce(validateEmail, 1200), [validateEmail]);

  const handleChange = (e) => {
    const newData = { ...formData, [e.target.name]: e.target.value };
    let newErrors = { ...errors };

    setFormData(newData);

    if (e.target.name === 'email') {
      debouncedValidateEmail(e.target.value);
    }

    if (('email' in newErrors) && Object.keys(newErrors).length === 1) {
      delete newErrors.email;
    }
    
    if (e.target.name in newErrors) {
      delete newErrors[e.target.name];
    }
    setErrors(newErrors);
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    const plainTextPassword = formData?.password;
    const encryptedPassword = await encryptPassword(plainTextPassword);
    if (validateLoginForm(formData, setErrors)) {
      sessionStorage.setItem("Password",encryptedPassword)
      postData(formData);
    }
  };

  useEffect(() => {
    if (error?.response?.data?.error) {
      ToastMessage("error", error?.response?.data?.error);
      return;
    } else if (data?.otp_verified === false) {
      let userReponse = data?.data[0];
      userReponse["password"] = formData?.password;
      sessionStorage.setItem("userLoginInfo", JSON.stringify(userReponse));
      navigate("/login-otp");
    } else if ((data?.status === 200 || data?.status === 'success') && data?.token && data?.otp_verified === true) {
      sessionStorage.setItem("userEmail", JSON.stringify(formData.email));
      sessionStorage.setItem("userLoginInfo", JSON.stringify(formData));
      sessionStorage.setItem("userToken", JSON.stringify(data?.token));
      sessionStorage.setItem("user_type", JSON.stringify(data?.user_type));
      sessionStorage.setItem("company", JSON.stringify(data?.company));
      sessionStorage.setItem("otp_verified", JSON.stringify(data?.otp_verified));
      window.location.reload();
      if (data?.user_type === 1) {
        navigate("/profile/banker/complete-your-profile");
      } else {
        navigate("/dashboard/company");
        sessionStorage.setItem("reloadCount",1);
      }
    }
  }, [data, error]);
  useEffect(() => {
    if (formData?.email && formData?.password) {
      setIsFormValid(false)
    } else {
      setIsFormValid(!false)
    }
  }, [formData])
  useEffect(()=>{
if(!token){
  setState(true)
}
setTimeout(()=>{
  setState(false)     
},2000)

  },[token])
  return (<>
  {state && <LoaderAPI />

}
    <div className="lg:grid lg:grid-cols-2">
      <div className="">
        <div className="flex items-center p-3">
        <div><p className="pl-4 cursor-pointer">
            <LogoIconSVG/>
          </p></div>
          <div className="text-right w-full font-lato">
             New to Open?{" "}
            <span className="text-green pl-2 cursor-pointer" onClick={() => navigate("/register")}>
              Register Now
            </span>
          </div>
        </div>
        <h2 className="h2-text py-10">Sign in</h2>
        <div className={formStyle}>
          <form onSubmit={handleSubmit} >
            <div className={commenInputDivSpace}>
              <InputField
                label="Your registered email id"
                astrix={true}
                disabled={false}
                required={true}
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                error={errors.email}
                labelClass={LabelFieldClass}
                className={InputFieldClass}
              />
              
            </div>
            <div className={commenInputDivSpace}>
              <InputField
                label="Password"
                type={!showPassword ? "text" : "password"}
                name="password"
                astrix={true}
                disabled={false}
                required={true}
                value={formData.password}
                onChange={handleChange}
                error={errors.password}
                id="password"
                labelClass={LabelFieldClass}
                className={InputFieldClass}
              />
              {showPassword ? (
                    <CloseEyePasswordIcon
                      onClick={() => setShowPassword(!showPassword)}
                      className={SetPasswordStyleSVG}
                    />
                  ) : (
                    <OpenEyePasswordIcon
                      onClick={() => setShowPassword(!showPassword)}
                      className={SetPasswordStyleSVG}
                    />
                  )}
            </div>
            <div className="flex justify-end ">
              <span className={forgetPasswordStyle} onClick={() => navigate("/forgot-password")}>
                Forgot Password?
              </span>
            </div>
            <Button type="submit" disabled={loading} className={`${AuthButtonClass} ${"bg-green-500"}`}>
              {loading ? "..." : "Login"}
            </Button>
          </form>
        </div>
      </div>
      <div className="bg-[#F5F5F5] hidden lg:block relative h-screen">
        {/* {loginside} */}
        {/* <img src="../../../public/assets/loginside.png" className="w-[20rem] mt-[5rem] m-auto items-center flex"  alt="" /> */}
       <div className="m-auto flex h-screen justify-center items-center">
       <SideLoginImage/>
       </div>

      <div className="absolute right-0 left-0 bottom-10 ">
      <h4 className="h4-text ">#Togetherisbetter</h4>
      <p className="s3-text">Redefining Financial Advisory with Seasoned Bankers.</p>
      </div>
      </div>
     
      
    </div>
    </>
  );
};

export default Login;
