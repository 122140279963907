import React, { useEffect, useState } from 'react'
import { RightTickWhiteIcon, SaveIconImg, SavedIcon } from '../../../../common/Icons'
import Button from '../../../../common/Button'
import { useNavigate } from 'react-router-dom'
import usePost from '../../../../Hook/usePost'
import { ToastMessage } from '../../../../common/Config'
import { formatDateBanker } from '../../../../Hook/validation'
import { BankerBankDetailsStyle10, BankerBankDetailsStyle11, BankerBankDetailsStyle5, BankerBankDetailsStyle6, BankerBankDetailsStyle7, BankerBankDetailsStyle8, BankerBankDetailsStyle9 } from './BankersDasboardStyle'

const BankerJobCard = ({
  jobId, custJobId, jobTitle, postOn ,postedBy ,location, consultancy_mode, tenure,
  jobDescription, savedJob, interestedJob, refetchListing, setCheckedItems, setFilterApplied,
setComponentMounted, handlePageChange,item,currentPage }) => {

    const [Interested  , setInterested] =useState(interestedJob)
    const [saveIcon , SetSaveIcon] = useState(savedJob)
    const navigate = useNavigate()
    
    const handleViewDetails = () => {
      sessionStorage.setItem("jobID", jobId || item?.jobId);
      sessionStorage.setItem("bkJobTitle", jobTitle || item?.jobTitle);
      sessionStorage.setItem("bkCustJobID", custJobId || item?.custJobId);
      sessionStorage.setItem("bkJobLocation", location || item?.location);
      sessionStorage.setItem("bkJobPostedDate", postOn || item?.postOn);
      sessionStorage.setItem("bkConsultancyMode",consultancy_mode || item?.consultancy_mode);
      sessionStorage.setItem("bkTenure", tenure || item?.tenure);
      sessionStorage.setItem("bkJobDescription", jobDescription || item?.job_description);
      navigate(`/dashboard/banker/job/detail/${item?.jobId}`,{state:{data:item}})
    }

    const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
    const { data, error, loading, postData: saveJob } = usePost("/banker/job-saved");
    const { data: interestData, error: interestError, loading: interestLoading, postData: shareInterest } = usePost("/banker/share-interest");
    const { data: unsaveData, error: unsaveError, loading: unsaveLoading, postData: unsaveJob } = usePost("/banker/job-unsaved");
    const { data: unshareInterestData, error: unshareInterestError, loading: unshareInterestLoading, postData: unshareInterestJob } = usePost("/banker/share-not-interest");

    const payload = {"job_id": jobId || item?.jobId};
    const handleSave = () => {
      if(saveIcon) {
        unsaveJob(payload, token);
        SetSaveIcon(!saveIcon);
        return;
      }
      SetSaveIcon(!saveIcon)
      saveJob(payload, token);
    }

    const handleInterestSave = () => {
      // if(Interested) {
      //   // unshareInterestJob(payload, token);
      //   // setInterested(!Interested);
      //   return;
      // }
      sessionStorage.setItem("currentPage",currentPage)
      setInterested(!Interested)
      shareInterest(payload, token);
    }

    useEffect(() => {
      if(data && !error) {
        ToastMessage("success", data?.message);
        
        if (setFilterApplied) setFilterApplied(false);
        if (setCheckedItems) {
            setCheckedItems({
              shortTerm: false,
              mediumTerm: false,
              longTerm: false,
              virtual: false,
              'In-person': false,
              both: false,
            });
        }
        if (refetchListing) refetchListing();
        if (setComponentMounted) setComponentMounted(false);
        if (handlePageChange) handlePageChange(1);
      } else {
        ToastMessage("error", error?.message);
      }
    }, [data, error]);

    useEffect(() => {
      if(interestData && !interestError) {
        ToastMessage("success", "Interest shared successfully");
        
        if (setFilterApplied) setFilterApplied(false);
        if (setCheckedItems) {
            setCheckedItems({
              shortTerm: false,
              mediumTerm: false,
              longTerm: false,
              virtual: false,
              'In-person': false,
              both: false,
            });
        }
        if (refetchListing) refetchListing();
        if (setComponentMounted) setComponentMounted(false);
        if (handlePageChange) handlePageChange(1);
      } 
      if(interestError){
        ToastMessage("error", interestError?.response?.data?.message || "Something went wrong");
      }
      let PageNum;

      try {
        // Attempt to parse the stored value
        const storedPage = sessionStorage.getItem("currentPage");
      
        // If storedPage is not null or undefined, parse it, otherwise use the default value of 1
        PageNum = storedPage ? JSON.parse(storedPage) : 1;
      } catch (error) {
        // If an error occurs (e.g., invalid JSON), fall back to the default value
        console.error("Error parsing currentPage from sessionStorage:", error);
        PageNum = 1;
      }
      
      
      handlePageChange(PageNum)
    }, [interestData, interestError]);

    useEffect(() => {
      if(unsaveData && !unsaveError) {
        ToastMessage("success", unsaveData?.message);
        
        if (setFilterApplied) setFilterApplied(false);
        if (setCheckedItems) {
            setCheckedItems({
              shortTerm: false,
              mediumTerm: false,
              longTerm: false,
              virtual: false,
              'In-person': false,
              both: false,
            });
        }
        if (refetchListing) refetchListing();
        if (setComponentMounted) setComponentMounted(false);
        if (handlePageChange) handlePageChange(1);
      } else {
        ToastMessage("error", unsaveError?.message);
      }
    }, [unsaveData, unsaveError]);

    useEffect(() => {
      if(unshareInterestData && !unshareInterestError) {
        // ToastMessage("success", unshareInterestData?.message);
        
        if (setFilterApplied) setFilterApplied(false);
        if (setCheckedItems) {
            setCheckedItems({
              shortTerm: false,
              mediumTerm: false,
              longTerm: false,
              virtual: false,
              'In-person': false,
              both: false,
            });
        }
        if (refetchListing) refetchListing();
        if (setComponentMounted) setComponentMounted(false);
        if (handlePageChange) handlePageChange(1);
      } else {
        ToastMessage("error", unshareInterestError?.message);
      }
    }, [unshareInterestData, unshareInterestError]);
useEffect(()=>{
  SetSaveIcon(savedJob);
},[savedJob])
useEffect(()=>{
  if(interestData && !interestError){
    let PageNum=JSON.parse(sessionStorage.getItem("currentPage"));
    handlePageChange(PageNum)
  }
 
},[interestData,interestError])
  return (
    <div className={BankerBankDetailsStyle5}>
        <div className={BankerBankDetailsStyle6}>
            <div className='w-full'>
                <h6 className='h6-text'>
                {jobTitle}
                </h6>
            </div>
            <div>
            { savedJob === false ? <SaveIconImg onClick ={()=>handleSave()}/> : <SavedIcon onClick ={()=> handleSave() }/> }

            </div>
        </div>

        <div className={BankerBankDetailsStyle7}>
                
                    <p className={BankerBankDetailsStyle8}>Posted On: {formatDateBanker(postOn)}</p>
                    <p className={BankerBankDetailsStyle8}>Posted By: Admin
                    {/* {postedBy} */}
                    </p>
                    <p className={BankerBankDetailsStyle8}>Location: {location}</p>

        
        </div>

        <div className={BankerBankDetailsStyle9}>
        <Button disabled={item?.share_interest}  onClick={()=>handleInterestSave()} className={`${BankerBankDetailsStyle10} ${item?.share_interest ? "opacity-60" :""} `}>{item?.share_interest === false ? "Share Interest" : <p className='flex gap-1 text-white'><RightTickWhiteIcon/> Interested</p>}</Button>

        <Button onClick={()=> handleViewDetails()} className={BankerBankDetailsStyle11}> View Details </Button>

        </div>
    </div>
  )
}

export default BankerJobCard
