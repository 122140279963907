import React from 'react'
import Navbar from '../../../../common/Navbar'
import BankerSideNavbar from '../../../../common/BankerSideNavbar'
import Checkbox from '../../../../common/Checkbox'
import Button from '../../../../common/Button'
import { useNavigate } from 'react-router-dom'

const CancelSubscriptionTwo = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar hemBurgerIcon={true} BackBtn={true} ProfileAndNotification={true} BankerNavbar={true}/>

      <div className='flex'>
        <div className='w-0 md:w-[7%]'>
            <BankerSideNavbar />
        </div>
        <div className='px-4 md:px-8 py-4 w-full md:w-[93%]'>
           <h4 className='h4-text text-start px-4 text-[#2C2C2C]'>Cancel Subscription</h4>
            <div className='w-full bg-[#F9F9FB] mt-8 rounded-lg border px-8 py-6'>
                <h6 className='h6-text text-[#2C2C2C]'>
                Are you switching to somewhere else 
                </h6>

                <div className='mt-6'>
                {["Yes" , "No" , "Don't Know"].map((item , index) => {
                  return <div className='my-3'>
                     <Checkbox type="checkbox" label={item}/>
                  </div>
                })}
                </div>

                <div className='text-start mt-6 flex gap-4' >
                  <Button className="px-4 py-2 greenbg text-white rounded-lg">
                  Return to billing
                  </Button>

                  <Button onClick={()=>navigate("/dashboard/banker/cancel-subscription-about")}  className="px-4 py-2 redfolicbg text-white rounded-lg">
                  Continue
                  </Button>
                </div>

            </div>
        </div>

      </div>
    </div>
  )
}

export default CancelSubscriptionTwo
