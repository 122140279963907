import React, { useEffect, useState, useCallback, useRef } from "react";
import Navbar from "../../../../common/Navbar";
import SideNavbar from "../../../../common/SideNavbar";
import Button from "../../../../common/Button";
import ProfileCard from "../../../../common/ProfileCard";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../common/InputField";
import {  
  DashboardCompanyButtonAddJOb, 
  DashboardCompanyMainDiv, 
  DashboardCompanyRecentSearch, 
  DashboardCompanySearchTag, 
  DashboardCompanydiv, 
  DashboardNextMainDiv1, 
  DashboardRoundedBoxMainDiv, 
  MarginLeftTenStart 
} from "./DashboardStyle";
import { InputMainDiv, RoundedBoxItemStyle2 } from "../../../../common/StyleCommon";
import RoundedBox from "../../../../common/RoundedBox";
import FilterCard from "../../../../common/FilterCard";
import useGetData from "../../../../Hook/useGetData";
import { Loader } from "../../../../common/Loader";
import DataNotFound from "../../../../common/DataNotFound";
import usePost from "../../../../Hook/usePost";
import { ToastMessage } from "../../../../common/Config";
import BottomPagination from "../../../../common/BottomPagination";
import { FilterIcon, LoaderIcon } from "../../../../common/Icons";

const CompanyDashboard = () => {
  const navigate = useNavigate();
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: searchData, loading: searchLoading, error: searchError, postData: postSearchData } = usePost(`job/banker-filter`);
  const { data: filterData, loading: filterLoading, error: filterError, postData: postFilterData } = usePost(`job/banker-filter`);
  const { data: getBankerData, loading: loadingGetBanker, error: errorGetBanker, refetch: refetchGetBanker } =
    useGetData(`job/get-bankers-by-company`, token);
    
  const [currentPage, setCurrentPage] = useState(1);
  const [ActiveFilter, setActiveFilter] = useState(false);
  const itemsPerPage = 5;
  const [data, setData] = useState([]);
  const [checkedItems, setCheckedItems] = useState({
    shortTerm: false,
    mediumTerm: false,
    longTerm: false,
    virtual: false,
    'In-person': false,
    both: false,
  });
  const [Text, setText] = useState("");
  const RecentSearch = searchData?.recent_searches || [];
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const displayData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    if (getBankerData?.data?.length > 0) {
      setData(getBankerData?.data?.map((item) => {
        return {
          ...item,
          bkUser: item?.user
        };
      }));
    }
  }, [getBankerData, errorGetBanker]);

  useEffect(() => {
    if (searchData?.data?.length > 0) {
      setData(searchData?.data?.map((item) => {
        return {
          ...item,
          bkUser: item?.user
        };
      }));
      setCurrentPage(1);
    }
    if (searchData?.data?.length === 0) {
      setData([]);
    }
    if (searchError && Text?.length > 0) {
      ToastMessage('error', searchError?.response?.data?.message);
    }
  }, [searchData, searchError, Text]);

  useEffect(() => {
    const consultancy_mode_values = [];
    if (checkedItems?.virtual) consultancy_mode_values.push(1);
    if (checkedItems['In-person']) consultancy_mode_values.push(2);
    if (checkedItems?.both) consultancy_mode_values.push(3);

    const tenure_values = [];
    if (checkedItems?.shortTerm) tenure_values.push(1);
    if (checkedItems?.mediumTerm) tenure_values.push(2);
    if (checkedItems?.longTerm) tenure_values.push(3);

    const obj = {
      consultancy_mode: consultancy_mode_values,
      tenure: tenure_values,
    };

    if (obj?.consultancy_mode.length > 0 || obj?.tenure.length > 0) {
      postSearchData(obj, token);
    }
    postFilterData(obj, token);
  }, [checkedItems]);

  useEffect(() => {
    if (filterData?.data?.length > 0) {
      // Set data from filter if needed
    } else {
      setData([]);
    }
    if (filterError) {
      ToastMessage('error', filterError?.response?.data?.message);
    }
  }, [filterData, filterError]);
  const timeoutRef = useRef(null);

  const debounceSearch = useCallback((value) => {
    // Create a reference to store the timeout ID
  
    // Clear any previous timeout before setting a new one
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  
    // Set a new timeout to call postSearchData after the user stops typing
    timeoutRef.current = setTimeout(() => {
      postSearchData({ search_query: value }, token);
    }, 2000); // Adjust the delay as needed
  
    // Clear timeout on unmount
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [postSearchData, token]);
  
  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setText(value);
  
    // Call debounce function with the new value
    debounceSearch(value);
  };

  return (
    <div>
      <Navbar hemBurgerIcon={true} CompanyNavbar={true} ProfileAndNotification={true} />
      <div className="flex">
        <div className="lg:w-[7%] md:-[7%] w-0">
          <SideNavbar />
        </div>
        <div className="w-[100%] px-[1rem] md:px-[2rem] lg:px-[2rem] lg:w-[93%] relative">
          <div className="">
            <div className="bg-red-500 pt-2 sticky-dashboard1">
              <div className={`${DashboardCompanyMainDiv}`}>
                <div>
                  <h4 className="h4-text text-start">
                    Welcome to <span className="text-[#FF0059]">BankersKlub</span>
                  </h4>
                </div>
                <div className="ml-auto">
                  <Button
                    className={DashboardCompanyButtonAddJOb}
                    onClick={() => {
                      sessionStorage.removeItem('selectedItems');
                      sessionStorage.removeItem('addJob');
                      navigate("/dashboard/company/addjob/stepone");
                    }}
                  >
                    Add a Job
                  </Button>
                </div>
              </div>

              <div className="">
                <div className='flex justify-between items-center text-start'>
                  <h6 className={DashboardCompanySearchTag}>
                    Search
                  </h6>
                  <div onClick={() => setActiveFilter(!ActiveFilter)} className={`block ${ActiveFilter ? 'bg-gray-200' : ''} px-1 py-1 rounded-lg lg:hidden`}>
                    <FilterIcon />
                  </div>
                </div>
                {ActiveFilter && <div className="w-full block lg:hidden ">
                  <FilterCard setCheckedItems={setCheckedItems} fetch={refetchGetBanker} checkedItems={checkedItems} />
                </div>}
                <div className={DashboardCompanydiv}>
                  <div className={InputMainDiv}>
                    <InputField
                      type="text"
                      id="search"
                      SearchIcon={true}
                      placeholder="Search Banker By Name, Expertise, Designation, Location, Last Company"
                      onChange={handleSearchInputChange}
                      searchClick={() => postSearchData({ search_query: Text }, token)}
                    />
                  </div>
                </div>

                <div className={DashboardCompanyRecentSearch}>
                  <p className="mt-1">Recent Searches :</p>
                  <RoundedBox mainClassName={DashboardRoundedBoxMainDiv} className={RoundedBoxItemStyle2} data={RecentSearch} />
                </div>
              </div>
              <h6 className="h6-text">
                Suggested Profiles
              </h6>
            </div>

            <div className='w-full flex lg:gap-4 mt-5'>
              <div className="lg:w-[70%] w-full ">
                {searchLoading || filterLoading || loadingGetBanker ? <LoaderIcon /> : displayData?.length > 0 ? displayData?.map((item) => {
                  return (
                    <ProfileCard
                      saveIconUI={true}
                      SaveIconShow={true}
                      hireBtn="Hire Now"
                      data={item}
                      typeOf="CompnayDashboard"
                      refetch={refetchGetBanker}
                    />
                  );
                }) :
                  <DataNotFound title="No Banker Found" />
                }
                <BottomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
              <div className="w-full hidden lg:block lg:w-[30%]">
                <FilterCard setCheckedItems={setCheckedItems} fetch={refetchGetBanker} checkedItems={checkedItems} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block md:hidden lg:hidden h-[6rem]"></div>
    </div>
  );
};

export default CompanyDashboard;
