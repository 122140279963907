import React, { useState } from "react";
import Navbar from "../../../../common/Navbar";
import BankerSideNavbar from "../../../../common/BankerSideNavbar"
import {
  BankerPaymentStyeleMainDiv1,

  BankerPaymentStyeleMainDivHeadin,
  FlexJustCenter,
} from "../../../profile/banker/BankerStyle";
import {
  CongratulationIcon
} from "../../../../common/Icons";
import RoundedBox from "../../../../common/RoundedBox";
import { HireCongStleDivExperienceOpted } from "../../company/job/DashboardJobStyle";
import TitleValueComp from "../../../../common/TitleValueComp";
import BankerMeeting from "./BankerMeeting";
import SideNavbar from "../../../../common/SideNavbar";
import { useParams } from "react-router-dom";
import useGetData from "../../../../Hook/useGetData";
import { MeetingConfirmationStyle1, MeetingConfirmationStyle2, MeetingConfirmationStyle3, MeetingConfirmationStyle4, MeetingConfirmationStyle5, MeetingConfirmationStyle6, MeetingConfirmationStyle7 } from "./BankersDasboardStyle";

const MeetingConfirmation = () => {
  const {id}=useParams()
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";

  const { data: dataCongrts, error: errorDataCongrts, loading: loadingDataCongrts, refetch: refetchDataCongrts } =
  useGetData(`hiring/meetings/${id}`, token);
  const [isHovered, setIsHovered] = useState(false);


  const ServiceOpted = dataCongrts?.banker?.key_service?.map((item)=>item?.name) || [
    "Debt fund raise",
    "Equity Fund Raise","Debt & Equity","Debt Resturing Advisory","International expansion Advisory","Export import Advisory","Financial statement analysis"
  ]
  const profileData = [
    {
      id: 1,
      title: "Contact Name",
      titleValue: dataCongrts?.data?.company?.company_name || 'Not available',
    },
    {
      id: 2,
      title: "Contact Number",
      titleValue: dataCongrts?.data?.company?.user?.phone_number || 'Not available',
    },
    {
      id: 3,
      title: "Email ID",
      titleValue: dataCongrts?.data?.company?.user?.email || 'Not available',
    } ,
    {
      id: 3,
      title: "Period of engagement",
      titleValue: "3 months, 20/05/2024 to 19/08/2024",
    }
  ];
  const CompanyName="hjhj"
  return (
    <div>
      <Navbar hemBurgerIcon={true} BankerNavbar={true} ProfileAndNotification={true} />
      <div className="flex">
        <div className="w-[7%]">
            {/* <SideNavbar/> */}
          <BankerSideNavbar />
        </div>
        <div className={MeetingConfirmationStyle1}>
          <div className={BankerPaymentStyeleMainDiv1}>
            <div className={FlexJustCenter}>
              <CongratulationIcon />
            </div>
            <div>
              <h5 className={BankerPaymentStyeleMainDivHeadin}>
              Congratulations {dataCongrts?.data?.banker?.user?.name || 'Not available'}!
              </h5>
              <p className={MeetingConfirmationStyle2}>
              You have successfully engaged with {dataCongrts?.data?.company?.user?.name || 'Not available'} From { dataCongrts?.data?.company?.company_name || 'Not available'}.
              </p>
              <p className={MeetingConfirmationStyle3}>
              The engagement details are mentioned below:
              </p>
            </div>
            <div>

             <BankerMeeting data={dataCongrts}  />


                
            </div>
            <p className={MeetingConfirmationStyle4}>Please confirm meeting within 24hrs.</p>
          </div>

        

        

        <div className={HireCongStleDivExperienceOpted}>
                <h5 className={MeetingConfirmationStyle5}>{dataCongrts?.data?.company?.company_name || 'Not available'}</h5>
                <p className={MeetingConfirmationStyle6}>View Project</p>
                <TitleValueComp data={profileData} />
        </div>

        <div className={HireCongStleDivExperienceOpted}>
            <h6 className={MeetingConfirmationStyle7}>Services opted for</h6>
          <RoundedBox data = {ServiceOpted}/>
        </div>

        </div>

        
      </div>
    </div>
  );
};

export default MeetingConfirmation;
