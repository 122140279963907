import React from "react";
import Button from "./Button";
import { bottombarButtonContainer, bottombarButtonStyle, bottombarButtonStyle1, bottombarDivStyle, bottombarMainDivStyle, bottombarNextButtonStyle, bottombarSaveBtn, BottomBarStyle112, BottomBarStyle113, flexitemCentre, flexjustifybt } from "./StyleCommon";
import { uploadProfileData } from "../Hook/formDataBanker";
 
const  BottomBar = (props) => {
  const {type, btnName, OnCLickNextBtn, OnCLickBackbtn ,SaveBtn , handleSubmit,BackBtn,banker,step,nextButtonDisabled} = props;
  const handlesave=(e)=>{
    if(handleSubmit){
      handleSubmit(e)

    }
   if(type !=="Hire"){
    uploadProfileData(step,true) 

   }
  }
  return (
    <div className={bottombarMainDivStyle}>
      <div className={bottombarDivStyle}>
        <div className={flexjustifybt}>
          <div className={BottomBarStyle112}>
            {BackBtn && <Button
              onClick={()=>{
                if(OnCLickBackbtn){
                  sessionStorage.setItem("back",1)
                OnCLickBackbtn()
                }
                }}
              type="button"
              disabled={!OnCLickBackbtn}
              className={bottombarButtonStyle1}
            >
              {BackBtn}
            </Button>}
          </div>
          <div className={BottomBarStyle113}>
           <div >
           {SaveBtn && <Button onClick={handlesave}
              type="button"
              className={bottombarButtonStyle}
            >
              {SaveBtn}
            </Button>}
           </div>
            <div className={flexitemCentre}>
            {btnName &&  <Button
              onClick={(e)=>{
                if(type !=="Hire"){
                  uploadProfileData(step,false)
                }
              
                OnCLickNextBtn(e)

              }}
              disabled={nextButtonDisabled}
              type="button"
              className={`${bottombarNextButtonStyle} ${nextButtonDisabled ? "!bg-[#b3d1c2]" : ""}`}
            >
              {btnName}
            </Button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default BottomBar;