import { useState } from "react";
import { apiHost } from "../common/Config";

const useUploadProfilePicture = (url) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const uploadProfilePicture = async (key, file) => {
    setUploading(true);
    setError(null);
    setSuccess(null);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${JSON.parse(sessionStorage.getItem("userToken"))}`);

    const formdata = new FormData();
    formdata.append(key, file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${apiHost}/${url}`, requestOptions);
      if (!response.ok) {
        throw new Error("Failed to upload image");
      }
      const result = await response.json();
      setSuccess(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setUploading(false);
    }
  };

  return {
    uploading,
    error,
    success,
    uploadProfilePicture
  };
};

export default useUploadProfilePicture;
