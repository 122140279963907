export const BankerBankDetailsStyle1 = 'w-full lg:w-[652px] m-auto md:w-[652px] px-3 my-5'
export const BankerBankDetailsStyle2 = 'h3-text my-10'
export const BankerBankDetailsStyle3 = "greenbg py-3 px-16 text-white rounded-lg"
export const BankerBankDetailsStyle4 = "my-5"


export const BankerBankDetailsStyle5 = 'w-full border rounded-[8px]'
export const BankerBankDetailsStyle6 = 'flex px-4 pt-4'
export const BankerBankDetailsStyle7 = 'text-start flex flex-col md:flex-row lg:flex-row px-4 gap-2 md:gap-6 lg:gap-6'
export const BankerBankDetailsStyle8 = 's3-text text-[#80828D]'
export const BankerBankDetailsStyle9 = 'flex gap-5 my-4 px-5'
export const BankerBankDetailsStyle10 = 'greenbg rounded-lg text-white px-4 disabled py-2'
export const BankerBankDetailsStyle11 = "redfolicbg rounded-lg px-4 py-2"




export const BankerJobViewStyle1 = 'md:w-[7%] lg:w-[7%] w-0';
export const BankerJobViewStyle2 ='w-full md:w-[93%] lg:w-[93%]'
export const BankerJobViewStyle3 ='mx-3 md:mx-10 lg:mx-10 md:w-1/3 lg:w-1/3'
export const BankerJobViewStyle4 ="flex justify-between items-center"


export const BankerDashboardStyle1 = "flex custom-scrollbar";
export const BankerDashboardStyle2 = "lg:w-[7%] lg:-[7%] w-0"
export const BankerDashboardStyle3 = "w-[100%] lg:w-[93%] px-3 lg:px-10"
export const BankerDashboardStyle4 = "h4-text py-6 text-start"
export const BankerDashboardStyle5 = "bg-green-100 sticky-BankDetail w-full mb-4 text-start  py-3 px-4 lg:px-5 rounded-[8px] flex flex-col lg:flex-row justify-start lg:justify-between items-center "
export const BankerDashboardStyle6 = "mb-4 lg:mb-0"
export const BankerDashboardStyle7 = "s3-text text-start"
export const BankerDashboardStyle8 = "greenbg px-4 py-2 text-start rounded-lg text-white"
export const BankerDashboardStyle9 = "flex items-center justify-center gap-1 text-[13px]"
export const BankerDashboardStyle10 = 'h-1 mt-4 border-1';
export const BankerDashboardStyle11 = "flex gap-1 text-[13px]"
export const BankerDashboardStyle12 = "flex gap-1 text-[13px]"
export const BankerDashboardStyle13 = "[bg-#E0E1E6] border"
export const BankerDashboardStyle14 = "bg-white pt-2 sticky-tabs1"
export const BankerDashboardStyle15 = "flex justify-start item-center my-1"
export const BankerDashboardStyle16 = "body-text text-[#2C2C2C] mt-1.5"
export const BankerDashboardStyle17 = "h6-text my-2"
export const BankerDashboardStyle18 = "flex justify-between items-center"
export const BankerDashboardStyle19 ="block lg:hidden"
export const BankerDashboardStyle20 ="w-full block lg:hideen lg:hidden"
export const BankerDashboardStyle21 ="lg:flex gap-4"
export const BankerDashboardStyle22 ="w-full lg:w-[70%] mt-4 grid grid-cols-1 gap-4"
export const BankerDashboardStyle23 ="w-full hidden lg:block lg:w-[30%] "
export const BankerDashboardStyle24 =" mt-4 grid grid-cols-1 gap-4"
export const BankerDashboardStyle25 ="block lg:hidden h-[6rem]"

export const MeetingConfirmationStyle1  = '"m-auto w-[93%]"'
export const MeetingConfirmationStyle2  = "s3-text"
export const MeetingConfirmationStyle3  = "s3-text mt-2"
export const MeetingConfirmationStyle4  = "body-text primary-text-red"
export const MeetingConfirmationStyle5  = "h5-text text-start"
export const MeetingConfirmationStyle6  = "body-text text-green-500 text-start mb-4"
export const MeetingConfirmationStyle7  = "h6-text my-4"
// export const MeetingConfirmationStyle8  = ''
// export const MeetingConfirmationStyle9  = ''
// export const MeetingConfirmationStyle10  = ''
// export const MeetingConfirmationStyle11  = ''


