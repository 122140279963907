import React from "react";
import {
    HideElement,
  InputMainDiv,
  getAstrixClassNames,
  getErrorClassNames,
  getInputClassNames,
  getLabelClassNames,
  getSpanClassNames,
} from "./StyleCommon";

const ImageInputField = ({
  label,
  name,
  type,
  id,
  className,
  value,
  onChange,
  astrix,
  required,
  remove,
  span,
  disabled,
  error,
  DobInput,
  ref,
  
  accept,
  capture
}) => {
  return (
    <div className={InputMainDiv}>
      <input
        disabled={disabled}
        type={type}
        name={name}
        id={id}
        value={value}
        className={className}
        onChange={onChange}
        placeholder=" "
        ref={ref}
        capture={capture}
        accept={accept}

      />
    </div>
  );
};

export default ImageInputField;
