import React from 'react'
import { LoaderAPIstyle1, LoaderAPIstyle2, LoaderAPIstyle3 } from './StyleCommon'

const LoaderAPI = () => {
  return (
      <div className={LoaderAPIstyle1}>
  <div className={LoaderAPIstyle2}>
    <div className={LoaderAPIstyle3}>
      Loading...
    </div>
  </div>
</div> 
  )
}

export default LoaderAPI
