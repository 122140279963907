import React, { useEffect, useState } from "react";
import Timeline from "./Timeline";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../common/Navbar";
import InputField from "../../../common/InputField";
import SelectTagComponent from "../../../common/SelectTagComponent";
import BottomBar from "../../../common/BottomBar";
import { validateStepOneBanker } from "../../../common/Validations";
import { BankerStepOneAddMore, BankerStepOneCustomBox, BankerStepOneEducationDiv, BankerStepOneEducationPTag, BankerStepOneEducationSubDiv, BankerStepOneSelectTag, BankerstepOneMainDiv, BankerstepOneMainDivTop, marginTopBottom6, s1TextGray } from "./BankerStyle";
import { Axios, apiHost, handleNonNumericInput } from "../../../common/Config";
import { uploadProfileData } from "../../../Hook/formDataBanker";
import useGetData from "../../../Hook/useGetData";
import Checkbox from "../../../common/Checkbox";
import LoaderAPI from "../../../common/LoaderAPI";
import { LogOut } from "../../../Hook/validation";

const Steponebanker = () => {
  const navigate = useNavigate();
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: getBankerData, loading: loadingServices, error: errorServices } = useGetData('onboarding/banker/get-bankers', token);
  const { data: getHireData } = useGetData('master-data/tenure/index', token);
  const { data: getConsultancyData } = useGetData('master-data/tenure/index', token);
  const { data: getPaymentData,loading: loadingPayment, error: errorPayment } = useGetData('onboarding/get-payment-status', token);
  const [formData, setFormData] = useState({
    form_step:1,
    name: "",
    dob: "",
    email: getBankerData?.user?.email || "",
    mobile: getBankerData?.user?.phone_number || "",
    city: "",
    company: "",
    designation: "",
    retirementDate: "",
    bankingExperience: "",
    education: "",
    certification: "",
    permanent_address:"",
    current_address:"",
    sameAddress: false,
    custom_designation:""
  });
  const [errors, setErrors] = useState({});
  const [customInputVisible, setCustomInputVisible] = useState(false);
  const [designationOptions, setdesignationOptions] = useState([]);
  const [disablePermanentAddress, setDisablePermanentAddress] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newData = {...formData}

    if(type === "checkbox") {
      if(checked) {
        newData["permanent_address"] = formData["current_address"];
        sessionStorage.setItem("shouldCopyAddress",checked);
      }
      else {
        sessionStorage.removeItem("shouldCopyAddress");
      }
      newData[name] = checked;
      setDisablePermanentAddress(checked);
    }
    else {
      newData[name] = value;
      if(name === "current_address" && newData["sameAddress"]) newData["permanent_address"] = value;
    }

    setFormData(newData);
    // setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleOptionChange = (name, option) => {
    setFormData({ ...formData, [name]: option?.id });
    if(name=="designation"){
      setFormData({ ...formData, [name]: option?.id });
      setErrors({ ...errors, [name]: "" });
    }
    if (name === "education") {
      setCustomInputVisible(option?.name === "Custom");
      if (option?.name !== "Custom") {
        setErrors({ ...errors, certification: "" });
      }
    }
  };

  const fetchLevelOfEducation = async () => {
    const response = await Axios("GET", `${apiHost}/master-data/education/index`);
    const data = response?.data?.data;
    setLevelOfEducation(data);
  }
 
  const fetchTurnoverGridOptions = async () => {
    // const response = await axios.get(`${apiHost}/turnovers/index`);

    const response = await Axios("GET", `${apiHost}/master-data/designation/get`);
    // // ("Response data ",response)
    const data = response?.data?.data;
    setdesignationOptions(data.map((item) => item));
  }
 
 
 
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateStepOneBanker(formData);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      sessionStorage.setItem("bankerData", JSON.stringify(formData));
      // uploadProfileData(formData)
      navigate("/profile/banker/additional-hiring-details");
    }
  };
  const handleSave = (e) => {
    e.preventDefault();
    const newErrors = validateStepOneBanker(formData);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      sessionStorage.setItem("bankerData", JSON.stringify(formData));
      // uploadProfileData(formData)
    }
  };



  // const LevelofEducation = ["Graduation", "Post Graduation", "Custom"];
  const [levelOfEducation, setLevelOfEducation] = useState([]);
  useEffect(()=>{
    const backTrue=sessionStorage.getItem("back");
    if(getBankerData?.data?.profile?.form_step===1 && !backTrue){
      navigate("/profile/banker/additional-hiring-details");
    }else if(getBankerData?.data?.profile?.form_step===2  && !backTrue){
      navigate("/profile/banker/add-profile-picture");
    }else if(getBankerData?.data?.profile?.form_step===3  && !backTrue){
      navigate("/profile/banker/profile-preview");  
    }
    else if(getBankerData?.data?.profile?.form_step===4  && !backTrue && getPaymentData){

      if(getPaymentData?.payment_status===false || getPaymentData?.payment_status===null || 
        getPaymentData?.payment_status===undefined || getPaymentData?.payment_status===""
        ){
        navigate('/profile/banker/plan')
      }else{
        navigate("/dashboard/banker");
      }

    }
  },[getBankerData,getPaymentData])
  useEffect(() => {
    fetchLevelOfEducation();
    fetchTurnoverGridOptions();
 
  }, []);

  useEffect(() => {
    const BankerData = JSON.parse(sessionStorage.getItem("bankerData"));

    if (getBankerData) {
      const objData={
        ...formData,
        name: getBankerData?.data?.user?.name || BankerData?.name || "",
        email: getBankerData?.data?.user?.email || "",
        mobile: getBankerData?.data?.user?.phone_number || "",
        dob: getBankerData?.data?.profile?.dob || BankerData?.dob || "",
        custom_designation:getBankerData?.data?.profile?.custom_designation || "",
        city:
          getBankerData?.data?.profile?.current_city || BankerData?.city || "",
        company:
          getBankerData?.data?.profile?.bank_name || BankerData?.company || "",
        designation:
          getBankerData?.data?.profile?.designation ||
          BankerData?.designation ||
          "",
        retirementDate:
          getBankerData?.data?.profile?.retirement_date ||
          BankerData?.retirementDate ||
          "",
        bankingExperience:
          getBankerData?.data?.profile?.banking_experience ||
          BankerData?.bankingExperience ||
          "",
        education:
          getBankerData?.data?.profile?.master_education ||
          BankerData?.education ||
          "",
          permanent_address:getBankerData?.data?.profile?.permanent_address ||BankerData?.permanent_address || "",
          current_address:getBankerData?.data?.profile?.current_address || BankerData?.current_address || "",
          certification:getBankerData?.data?.profile?.institute_name || "",
      }
      setFormData(objData);
      if(getBankerData?.data)
      sessionStorage.setItem("bankerData", JSON.stringify(objData));
      sessionStorage.setItem("bankerLastDrawnSalary", JSON.stringify(getBankerData?.data?.profile?.last_salary));
      if(getBankerData?.data?.profile?.core_expertise?.length>0){
        sessionStorage.setItem("CoreExpertise", JSON.stringify(getBankerData?.data?.profile?.core_expertise?.map((item)=>item?.id)));
      }
    
      if(getBankerData?.data?.profile?.key_service?.length>0){
        sessionStorage.setItem("selectedItems", JSON.stringify(getBankerData?.data?.profile?.key_service?.map((item)=>item?.id)));
      }
     
      if(getHireData?.data?.filter((item)=>item?.id===getBankerData?.data?.profile?.master_tenure)?.length>0){
        sessionStorage.setItem("bankerhireMode", JSON.stringify(getHireData?.data?.filter((item)=>item?.id===getBankerData?.data?.profile?.master_tenure)[0]));
      }
  if(getConsultancyData?.data?.filter((item)=>item?.id===getBankerData?.data?.profile?.master_consultancy_mode)?.length>0){
    sessionStorage.setItem("consultancyMode", JSON.stringify(getConsultancyData?.data?.filter((item)=>item?.id===getBankerData?.data?.profile?.master_consultancy_mode)[0]));
  }
   
    sessionStorage.setItem("bankerPreferredLocation", JSON.stringify(getBankerData?.data?.profile?.preferred_location || ""));
    }
  }, [getBankerData, getBankerData?.user]);

  useEffect(()=>{
    const BankerData=JSON.parse(sessionStorage.getItem('bankerData'))

    // if (!token) {
    //   navigate("/login");
    // }
 
    if(BankerData){

      setFormData({
        ...formData,
        name: BankerData?.name || "",
        dob: BankerData?.dob || "",
        email: BankerData?.email || "",
        custom_designation:BankerData?.custom_designation || "",
        mobile: BankerData?.mobile || "",
        city: BankerData?.city || "",
        company: BankerData?.company || "",
        designation: BankerData?.designation || "",
        retirementDate: BankerData?.retirementDate || "",
        bankingExperience: BankerData?.bankingExperience || "",
        education: BankerData?.education || "",
        certification: BankerData?.certification || "",
      })
      if (BankerData?.education === 3) {
        setCustomInputVisible(true);
      }
    }

        
    let sameAddress = false;

    try {
      sameAddress = sessionStorage.getItem('shouldCopyAddress');
    }
    catch {
      sameAddress = false;
    }

    let newData = {...formData};
    newData['sameAddress'] = sameAddress;
    setFormData(newData);
    setDisablePermanentAddress(sameAddress);

  }, [])

  useEffect(() => {
    sessionStorage.setItem("bankerData", JSON.stringify(formData));
  }, [formData]);
  return (
    <div className="relative">
      {(loadingServices || loadingPayment) && <LoaderAPI />}
      <Navbar BackBtn={true} hemBurgerIcon={false} buybtn="" />
      <Timeline
        stepOneStatus={false}
        stepTwoStatus={false}
        stepThreeStatus={false}
        stepFourStatus={false}
        heading="Complete your profile"
      />
      <div className={BankerstepOneMainDiv}>
        <form onSubmit={handleSubmit}>
          <div className={BankerstepOneMainDivTop}>
            <InputField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              astrix={true}
              disabled={false}
              type="text"
              id="name"
              required={true}
              error={errors.name}
            />
          </div>

          <div className={marginTopBottom6}>
            <InputField
              label="Date of Birth"
              name="dob"
              allDate={true}
              id="dob"
              pattern="\d{1,2}/\d{1,2}/\d{4}"
              type="date"
              DobInput={false}
              value={formData.dob}
              onChange={handleInputChange}
              disabled={false}
              astrix={true}
              required={true}
              error={errors.dob}
            />
          </div>

          <div className={marginTopBottom6}>
            <InputField
              label="Email"
              name="email"
              type="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              disabled={true}
              astrix={true}
              required={true}
              error={errors.email}
            />
          </div>

          <div className={marginTopBottom6}>
            <InputField
              label="Mobile Number"
              name="mobile"
              id="mobile"
              pattern="[1-9]{1}[0-9]{9}"
              maxLength="10"
              type="number"
              value={formData.mobile}
              onChange={handleInputChange}
              disabled={true}
              astrix={true}
              required={true}
              // span="+91"
              error={errors.mobile}
            />
          </div>

          <div className={marginTopBottom6}>
            <InputField
              label="Current Address"
              name="current_address"
              id="currentAddress"
              value={formData.current_address}
              onChange={handleInputChange}
              disabled={false}
              astrix={false}
              required={true}
              error={errors.current_address}
            />
          </div>
          <div className={marginTopBottom6}>
            <InputField
              label="Permanent Address"
              name="permanent_address"
              id="permanentAddress"
              value={formData.permanent_address}
              onChange={handleInputChange}
              disabled={disablePermanentAddress}
              astrix={false}
              required={true}
              error={errors.permanent_address}
            />
          </div>
          <div className="-mt-4">
            <Checkbox type="checkbox" name="sameAddress" id="sameAddress" value={formData?.sameAddress} checked={formData?.sameAddress} onChange={handleInputChange} label="Same as current address"/>
          </div>

          <div className="hr-bgColor my-10"></div>

          <div className="flex my-4">
            <p className={s1TextGray}>Banking Background*</p>
            {/* <p className={BankerStepOneAddMore}>Add More</p> */}
          </div>

          <div className={marginTopBottom6}>
            <InputField
              label="Company/ Bank name at the time of leaving/retirement"
              name="company"
              id="company"
              value={formData.company}
              onChange={handleInputChange}
              disabled={false}
              astrix={false}
              required={true}
              error={errors.company}
            />
          </div>

          <div className={marginTopBottom6}>
            {/* <InputField
              label="Designation at the time of leaving/retirement"
              name="designation"
              value={formData.designation}
              onChange={handleInputChange}
              disabled={false}
              astrix={false}
              required={true}
              error={errors.designation}
            /> */}

            <SelectTagComponent
              name="designation"
              defaultVal={designationOptions?.filter((item) => item?.id == formData.designation)[0]?.name || "Designation at the time of leaving/retirement"}
              value={formData.designation}
              disabled={false}
              astrix={false}
              selected={formData?.designation}
              required={true}
              // name="designation"
              error={errors.designation}

              options={designationOptions}

              onChange={(value) => handleOptionChange("designation", value)}
            />
          </div>
          {formData?.designation===7 && <div className={marginTopBottom6}>
            <InputField
              label="Designation at the time of leaving/retirement"
              name="custom_designation"
              value={formData.custom_designation}
              onChange={handleInputChange}
              disabled={false}
              astrix={false}
              required={true}
              error={errors.custom_designation}
            />
            </div>}

          <div className={marginTopBottom6}>
            <InputField
              label="Date of Retirement"
              DobInput={false}    
              type="date"
              id="date"
              name="retirementDate"
              value={formData.retirementDate}
              allDate={false}
              onChange={handleInputChange}
              disabled={false}
              astrix={false}
              required={true}
              error={errors.retirementDate}
            />
          </div>

          <div className="text-start my-4">
            <p className="mediumFont">Total banking experience</p>
          </div>

          <div className={marginTopBottom6}>
            <InputField
              label="Banking Experience"
              name="bankingExperience"
              id="bankingExperience"
              value={formData.bankingExperience}
              onChange={handleInputChange}
              onkeyDown={handleNonNumericInput}
              disabled={false}
              astrix={true}
              required={true}
              error={errors.bankingExperience}
            />
          </div>

          <div className="hr-bgColor my-8"></div>

          <div className={BankerStepOneEducationDiv}>
            <div className={BankerStepOneEducationSubDiv}>
              <p className={BankerStepOneEducationPTag}>Educational Qualification</p>
            </div>
            <div className={BankerStepOneSelectTag}>
              <SelectTagComponent
                error={errors.education}
                defaultVal={levelOfEducation?.filter((item) => item?.id == formData?.education)[0]?.name || "Level of Education"}
                options={levelOfEducation}
                onChange={(value) => handleOptionChange("education", value)}
              />
            </div>
            {customInputVisible && (
              <div className={BankerStepOneCustomBox}>
                <InputField
                  label="XYZ Certification from ABC Institute"
                  name="certification"
                  id="certification"

                  value={formData.certification || formData?.institute_name}
                  onChange={handleInputChange}
                  disabled={false}
                  astrix={true}
                  required={true}
                  error={errors.certification}
                />
              </div>
            )}
          </div>
        </form>
      </div>
      <BottomBar
        OnCLickBackbtn={() => {
          LogOut()
          navigate('/login')
        }

          }
        OnCLickNextBtn={handleSubmit}
        btnName="Next"
        BackBtn="Back"
        SaveBtn="Save"
        handleSubmit={handleSave}
        step="1"
      />
    </div>
  );
};

export default Steponebanker;