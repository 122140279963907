import React, { useEffect } from "react";
import Navbar from "../../../../../common/Navbar";
import BottomBar from "../../../../../common/BottomBar";
import { useNavigate } from "react-router-dom";
import { GreenPencilIcon } from "../../../../../common/Icons";
import { AddJobMainContainer, AddJobMainContainerDiv, AddJobMainContainerHeading, AddJobMainContainerHeadingDiv, FontLatoPrimaryGreen, H5TextStart, H6TextStartSecGray } from "../DashboardStyle";
import { BankerStepfourContainerDiv, FlexTextJustend } from "../../../../profile/banker/BankerStyle";
import RoundedBox from "../../../../../common/RoundedBox";
import useGetData from "../../../../../Hook/useGetData";
import { AddJobValidation } from "../../../../../Hook/validation";
import { ToastMessage } from "../../../../../common/Config";
import usePost from "../../../../../Hook/usePost";
import SideNavbar from "../../../../../common/SideNavbar";

 
const AddJobStepTwo = () => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const JobData=JSON.parse(sessionStorage.getItem("addJob"))
  const selectedItems=JSON.parse(sessionStorage.getItem("selectedItems"))
  const navigate = useNavigate();

const { data: getJobTitle, loading: loadingServices, error: errorServices } = useGetData('master-data/job/get',token);
const { data: getHireData, loading: loadingHire, error: errorHire } = useGetData('master-data/tenure/index',token);

const { data: getConsultancyData, loading: loadingConsultancy, error: errorConsultancy } = useGetData('master-data/consultancy-mode/index',token);
const { data: getServiceData, loading: loadingService, error: errorService } = useGetData('master-data/service/index',token);
const { data: getStateData } = useGetData('master-data/state/get',token);
const { data: getCityData } = useGetData('master-data/city/get',token);
const { data: JobDatas,loading:JobLoading, error: JobError, postData: postJobData } = usePost('jobs/company-job');
  
const { data: JobUpdateData,loading:JobUpdateLoading, error: JobUpdateError, postData: postJobUpdateData } = usePost(`jobs/update-job/${JobData?.id}`);
const State=getStateData?.data?.filter((item)=>item?.id==JobData?.state)[0]?.name || ""
const City=getCityData?.data?.filter((item)=>item?.id==JobData?.city)[0]?.city || ""
  const AddJobData = [
    {
      id: 1,
      title: "Job Title",
      titleValue:JobData?.custom_title || ""
    },
    {
      id: 2,
      title: "Job Description",
      titleValue: JobData?.job_description || ""
    },
    {
      id: 3,
      title: "Key Area of services",
      titleValue:getServiceData?.data?.filter(item => JobData?.keyAreaServices?.includes(item?.id))?.map((items)=>items?.name)?.length>0 ? getServiceData?.data?.filter(item => JobData?.keyAreaServices?.includes(item?.id))?.map((items)=>items?.name) : getServiceData?.data?.filter(item => selectedItems?.includes(item?.id))?.map((items)=>items?.name)
    },
    {
      id: 4,
      title: "Availability for hire",
      titleValue: getHireData?.data?.filter((item)=>item.id==JobData?.tenure)[0]?.name || "Long Term",
    },
    {
      id: 5,
      title: "Mode of Consultancy",
      titleValue:getConsultancyData?.data?.filter((item)=>item?.id==JobData?.consultancy_mode)[0]?.name || "Both",
    },
    {
      id: 6,
      title: "State",
      titleValue: State|| "Not Available",
    },
    {
      id: 7,
      title: "City",
      titleValue:City || "Not Available",
    },
  ];
  const handleSave = (selectedItems) => {
    // if(AddJobValidation(JobData, setErrors)){
      // sessionStorage.setItem("addJob",JSON.stringify(formData));
      if(JobData?.id){
        postJobUpdateData({...JobData,custom_title:JobData?.custom_title,key_service:JobData?.keyAreaServices,core_expertise:JobData?.core_expertise || []},token)
      }else{
        postJobData({...JobData,custom_title:JobData?.custom_title,key_service:JobData?.keyAreaServices,core_expertise:JobData?.core_expertise || []},token)
      }
    

    // }
  };
  useEffect(()=>{
    if(JobDatas){
      ToastMessage("success",'Congratulations! You have successfully posted a job.')
      sessionStorage.removeItem("addJob")
      sessionStorage.removeItem("selectedItems")
    }
    if(JobError){
      ToastMessage("error","Job Added Failed")
    }
      },[JobDatas,JobError])
      useEffect(()=>{
        if(JobUpdateData){
          ToastMessage("success",JobUpdateData?.message)
        }
        if(JobUpdateError){
          ToastMessage("error","Job Updated Failed")
        }
        
      },[JobUpdateData,JobUpdateError])
  return (
    <div>
      
      <Navbar hemBurgerIcon={true} CompanyNavbar={true} ProfileAndNotification={true}  />

      <div className="md:hidden">
        <SideNavbar/>
      </div>
      
      <div className={AddJobMainContainer}>
        <h3 className={`${AddJobMainContainerHeading} py-4 sticky-AddJob`}>
          Add a Job
        </h3>
        <div className={BankerStepfourContainerDiv}>
          <div>
            <h5 className={H5TextStart}>Review Details</h5>
          </div>
          <div>
            <div className={FlexTextJustend} role="button" onClick={() => navigate("/dashboard/company/addjob/stepone")}>
            <GreenPencilIcon className="mt-0.4 mr-2"/>
              <p className={FontLatoPrimaryGreen}>Edit</p>
            </div>
          </div>
        </div>
 
        <div className={AddJobMainContainerHeadingDiv}>
          {AddJobData.map((item) => (
            <div key={item.id} className={`${AddJobMainContainerDiv} text-wrap`}>
              <div className="w-[35%] ">
                <p className="s2-text">{item.title}</p>
              </div>
              <div className="w-[5%] text-start">
                -
              </div>
              {item.id === 3 ? (
                      <div className="w-full">
                        <RoundedBox data={item?.titleValue} />
                      </div>

              ) : (
                <div className="w-full ">
                  <h6 className={`${item.title ==="Job Description"? "b2-text w-full flex break-all text-start leading-8 -mt-1 " : H6TextStartSecGray }`}>{item.titleValue}</h6>
                </div>
              )}
            </div>
          ))}

          
        </div>
      </div>

      <div className="h-[5rem] block md:hidden">

      </div>
 
      <div>
        <BottomBar
        btnName="Submit"
        BackBtn="Back"
        SaveBtn="Save"
        OnCLickBackbtn={() => navigate("/dashboard/company/addjob/stepone")}
        OnCLickNextBtn={() =>{
          handleSave()
          setTimeout(()=>{
            navigate("/dashboard/company")
          },3000)
           
          }}
          banker={true}
          type="Hire"
        handleSubmit={handleSave}
        />
      </div>
    </div>
  );
};
 
export default AddJobStepTwo;