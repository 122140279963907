import React, { useEffect, useState } from "react";
import Navbar from "../../../../common/Navbar";
import ProfileCard from "../../../../common/ProfileCard";
import TitleValueComp from "../../../../common/TitleValueComp";
import { LeftArrowIcon, RightArrowIcon } from "../../../../common/Icons";
import { 
  ProfileViewContainerDiv2, 
  ProfileViewContainer, 
  ProfileViewContainerDiv, 
  ProfileViewHRLine, 
  ProfileViewContainerDivText, 
  ProfileViewSimilarTag, 
  ProfileViewSimilarTagContainer, 
  ProfileViewSimilarCard 
} from "./SearchStyle";
import RoundedBox from "../../../../common/RoundedBox";
import useGetData from "../../../../Hook/useGetData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastMessage, apiHost } from "../../../../common/Config";
import DataNotFound from "../../../../common/DataNotFound";
import { convertDate } from "../../../../Hook/validation";
import LoaderAPI from "../../../../common/LoaderAPI";

const ProfileView = () => {
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { profileId, JobId } = useParams();
  const navigate = useNavigate();
  const location=useLocation();
  const TypeOf=sessionStorage.getItem("typeOf");
  const IsView=location?.state?.data==="ProjectDetail"
  const { data: getBankerDetails, loading: loadingBankerDetails, error: errorBankerDetails, refetch: fetchBankerDetails } = useGetData(
    JobId ? `job/list-banker-detailed/${JobId}/${profileId}` : (TypeOf==='CompnayDashboard' || IsView) ?`job/get-bankers-by-company-details/${profileId}` : TypeOf==='SavedBanker' ? `job/get-saved-banker-details/${profileId}` :TypeOf==='AdvanceSearch' ?`job/banker-search-details/${profileId}` : `banker/list/${profileId}`,
    token
  );
  const [data, setData] = useState();
  const [similarData, setSimilarData] = useState([]);

  let profileData = [
    {
      id: 1,
      title: "DOB",
      titleValue:data?.dob? convertDate(data?.dob): "Not Available" || "20/04/1965",
    },
    {
      id: 4,
      title: "Current City",
      titleValue: data?.current_city || data?.city?.city || "Not available",
    },
    {
      id: 4,
      title: "Last Designation",
      titleValue: data?.designation?.name || "Not available",
    }
  ];
  const ProfileViewData=[
    {
      id: 1,
      title: "DOB",
      titleValue:data?.dob? convertDate(data?.dob): "Not Available" || "20/04/1965",
    },
    {
      id: 4,
      title: "Phone Number",
      titleValue: data?.bkUser?.phone_number || "Not available",
    },
    {
      id: 4,
      title: "Email ID",
      titleValue: data?.bkUser?.email || "Not available",
    },

    {
      id: 5,
      title: "Current Address",
      titleValue: data?.current_address || "Not available",
    },
    {
      id: 6,
      title: "Permanent Address",
      titleValue: data?.permanent_address || "Not available",
    },
  ]

  const experienceData = [
    {
      id: 1,
      title: "Last company",
      titleValue: data?.bank_name || "Not available",
    },
    {
      id: 2,
      title: "Total experience",
      titleValue: `${data?.banking_experience} years` || "Not available",
    },
    {
      id: 3,
      title: "Date of retirement",
      titleValue:data?.retirement_date ?  convertDate(data?.retirement_date):"Not Available" || "12/12/2023",
    },
  ];

  const educationData = [
    {
      id: 1,
      title: "Level",
      titleValue: data?.master_education?.name ||data?.master_education === 1 ? "Graduate" : data?.master_education === 2 ? "Post Graduate" : "Custom",
    },
    {
      id: 2,
      title: "Degree",
      titleValue: data?.institute_name || "Not Available",
    },
  ];


  const handleNext = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    const Url=JobId ? `${apiHost}/job/list-banker-detailed-next/${JobId}/${profileId}`:TypeOf==='CompnayDashboard' ?`${apiHost}/job/get-bankers-by-company-next/${profileId}`: TypeOf==='SavedBanker' ? `${apiHost}/job/get-saved-banker-next/${profileId}` :`${apiHost}/banker/list/${profileId}`

    fetch(Url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result?.data?.length!==0){
        setData(result?.data);
        setSimilarData(result?.next_profiles);
        if(TypeOf==='CompnayDashboard'){
          navigate(`/dashboard/company/profile/${result?.data?.id}`);
        } 
        if(TypeOf==='SavedBanker'){
          navigate(`/dashboard/company/profile/${result?.data?.id}`);
        }
        if(TypeOf==='SuggestedProfiles'){
          navigate(`/dashboard/company/profile/${profileId}/${result?.data[0]?.id}`);
        }
       
        }else{
          ToastMessage('error', 'No Next Profile Found')
        }
      })
      .catch((error) => {
        ToastMessage('error', 'No Next Profile Found')
      });
  };
  const handlePrevious=()=>{
    const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${token}`);

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};
const Url=JobId ? `${apiHost}/job/list-banker-detailed-previous/${JobId}/${profileId}`:TypeOf==='CompnayDashboard' ?`${apiHost}/job/get-bankers-by-company-previous/${profileId}`: TypeOf==='SavedBanker'?`${apiHost}/job/get-saved-banker-previous/${profileId}` : `${apiHost}/banker/list/${profileId}`
fetch(Url, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result?.data?.length!==0 && result.status !=='error'){
      setData(result?.data);
      setSimilarData(result?.next_profiles);
  if(TypeOf==='CompnayDashboard' && result?.data?.id){
    navigate(`/dashboard/company/profile/${result?.data?.id}`);
  }
  if(TypeOf==='SavedBanker' && result?.data?.id){
    navigate(`/dashboard/company/profile/${result?.data?.id}`);
  }
  if(TypeOf==='SuggestedProfiles' && result?.data[0]?.id){
    navigate(`/dashboard/company/profile/${profileId}/${result?.data[0]?.id}`);
  }
      
    }else{
      ToastMessage('error', 'No Previous Profile Found')
    }
   
  })
  .catch((error) => {
    ToastMessage('error', 'No Previous Profile Found')
  });
  }

  const ExpertiseDesign = [
    {
      id: 1,
      title: "Key Area of services",
      data: data?.key_service?.map((item) => item?.name) || [],
    },
    {
      id: 2,
      title: "Core expertise",
      data: data?.core_expertise?.map((item) => item?.name) || [],
    }
  ];

  useEffect(() => {
    if (getBankerDetails) {
      if(IsView){
        setData({...getBankerDetails?.data,city:{id:0,city:getBankerDetails?.data?.designation?.name}});
      }else{
        setData({ ...getBankerDetails?.data });

      }
      setSimilarData(getBankerDetails?.next_profiles);
    }
  }, [getBankerDetails, errorBankerDetails]);


  return (
    <div>
      {loadingBankerDetails && <LoaderAPI />}
      <Navbar hemBurgerIcon={true} CompanyNavbar={true} BackBtn={true} ProfileAndNotification={true} />

      <div className="md:flex px-4 md:px-0">
        <div className="w-full md:w-[62%] md:mx-10">
        {!IsView &&  <div className={ProfileViewContainer}>
            {getBankerDetails?.previous_exists ? <div className={ProfileViewContainerDiv} onClick={handlePrevious}>
              <LeftArrowIcon strokewidth="2" fill="none" width="14" height="14" stroke="#1E1F24" />
              <p>Previous</p>
            </div> :<div className={`${ProfileViewContainerDiv} text-[#c1e3de]`} >
              <LeftArrowIcon strokewidth="2" fill="none" width="14" height="14" stroke="#c1e3de" />
              <p>Previous</p>
            </div>}
            <div className={ProfileViewContainerDiv} onClick={handleNext}>
              <p>Next</p>
              <RightArrowIcon strokewidth="2" fill="none" width="9" height="14" stroke="#1E1F24" />
            </div>
          </div>}

          <div>
            <ProfileCard
            saveIconUI={!IsView && true}
              SaveIconShow={true}
              shortlistBtn={false}
              hireBtn={!IsView && "Hire Now"}
              data={data}
              ExperinceDataUI={false}
              CoreUI={false}
              fullName={true}
              refetch={fetchBankerDetails}
              typeOf={TypeOf}
            />
          </div>

          <div className={ProfileViewContainerDiv2}>
            <div className="w-full md:w-[50%] mb-4">
              <TitleValueComp data={IsView ? ProfileViewData :profileData} />
            </div>
          </div>
          <div className={ProfileViewContainerDiv2}>
            <h6 className="h6-text">Experience</h6>

            <div className="w-full md:w-[50%] mb-4">
              <TitleValueComp data={experienceData} />
            </div>
          </div>

          <div className={ProfileViewContainerDiv2}>
            <h6 className="h6-text">Education</h6>
            <div className="w-full md:w-[50%] mb-4">
              <TitleValueComp data={educationData} />
            </div>
          </div>

          {ExpertiseDesign.map((item, index) => (
            <div key={index} className={ProfileViewHRLine}>
              <div>
                {item?.title && (
                  <div className="mt-2">
                    <h6 className={ProfileViewContainerDivText}>
                      {item?.title}
                    </h6>
                  </div>
                )}
                <RoundedBox data={item?.data} />
              </div>
            </div>
          ))}
        </div>

       {!IsView && <div className={ProfileViewSimilarTag}>
          <div className={ProfileViewSimilarTagContainer}>
            <div><p className="s3-text ">{similarData?.length || 0} similar profiles</p></div>
            {/* <div className="primary-text-green cursor-pointer">View</div> */}
          </div>

          <div className={ProfileViewSimilarCard}>
            {similarData?.length>0 ? similarData?.map((item, index) => (
              <ProfileCard
                key={index}
                saveIconUI={true}
                SaveIconShow={true}
                sideCard={true}
                shortlistBtn={false}
                fullName={true}
                hireBtn={false}
                typeOf={TypeOf}
                refetch={fetchBankerDetails}
                data={item}
                
              />
            )) :
            <DataNotFound title="No Similar Profiles Found" />
            }
          </div>
        </div>}
      </div>
    </div>
  );
};

export default ProfileView;
