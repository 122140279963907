import { convertDateToMilliseconds, daysDiff, emailRegex, numericRegex } from "./Config";

export const validateLoginForm = (formData, setErrors) => {
    let formValid = true;
    let newErrors = {};

    if (!formData.email) {
        formValid = false;
        newErrors.email = 'Email id is required';
    }

    if (formData.email && !emailRegex.test(formData.email)) {
        newErrors.email = 'Invalid Email';
    }

    // if (formData?.mobileNumber) {
    //     // const regex = /^[0-9]$/;

    //     if (formData.mobileNumber.length < 10 || formData.mobileNumber.length > 10) {
    //         if (parseInt(formData?.mobileNumber[0]) >= 6) {
    //             formValid = false;
    //             newErrors.mobileNumber = 'Mobile Number must be of 10 digits';
    //         }
    //         else {
    //             formValid = false;
    //             newErrors.mobileNumber = 'Mobile Number is invalid';
    //         }
    //     }
    //     else if (formData?.mobileNumber === "0000000000" || formData?.mobileNumber === "1111111111" || formData?.mobileNumber ===
    //         "2222222222" || formData?.mobileNumber === "3333333333" || formData?.mobileNumber === "4444444444" || formData?.mobileNumber === "5555555555"
    //     ) {
    //         formValid = false;
    //         newErrors.mobileNumber = 'Mobile Number is invalid';
    //     }
    //     else {
    //         formValid = true;
    //         if ("mobileNumber" in newErrors) {
    //             delete newErrors["mobileNumber"];
    //         }
    //     }
    // }

    if (!formData.password) {
        formValid = false;
        newErrors.password = 'Password is required';
    }

    setErrors(newErrors);
    return formValid;
};

export const validateStepOneBanker = (formData) => {
    let newErrors = {};
    function isEighteenOrOlder(birthdate) {
        const today = new Date();
        const birthDate = new Date(birthdate);
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
      
        // If today's month is before birth month or it's the birth month but today is before the birth day, subtract one from the age
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          return age - 1 >= 18;
        }
      
        return age >= 18;
      }
    if (!formData.name) newErrors.name = "Name is required";
    if (formData?.name && numericRegex.test(formData.name)) {
        newErrors.name = "Name must be a string";
    }

    if (!formData.dob) newErrors.dob = "Date of Birth is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.mobile) newErrors.mobile = "Mobile Number is required";

    if (formData?.mobile) {
        if (formData.mobile.length < 10 || formData.mobile.length > 10) {
            if (parseInt(formData?.mobile[0]) >= 6) {
                newErrors.mobile = 'Mobile Number must be of 10 digits';
            }
            else {
                newErrors.mobile = 'Mobile Number is invalid';
            }
        }
        else if (formData?.mobile === "0000000000" || formData?.mobile === "1111111111" || formData?.mobile ===
            "2222222222" || formData?.mobile === "3333333333" || formData?.mobile === "4444444444" || formData?.mobile === "5555555555"
        ) {
            newErrors.mobile = 'Mobile Number is invalid';
        }
        else {
            if ("mobile" in newErrors) {
                delete newErrors["mobile"];
            }
        }
    }

    // if (!formData.city) newErrors.city = "Current City is required";
    // if (formData?.city && numericRegex.test(formData.city)) {
    //     newErrors.city = "Current City must be a string";
    // }
    if (!formData.current_address) newErrors.current_address = "Current address is required";
    if (!formData.permanent_address) newErrors.permanent_address = "Permanent Address is required";


    if (!formData.company) newErrors.company = "Company name is required";
    if (formData?.company && numericRegex.test(formData.company)) {
        newErrors.company = "Company name must be a string";
    }

    if (!formData.designation || formData?.designation === "Select") newErrors.designation = "Designation is required";

    // if (formData?.designation && numericRegex.test(formData.designation)) {
    //     newErrors.designation = "Designation must be a string";
    // }
    if (!formData.retirementDate) newErrors.retirementDate = "Date of Retirement is required";

    // if (formData?.dob && formData?.retirementDate && (formData?.retirementDate <= formData?.dob)) {
    //     newErrors.dob = "Date of Birth should be earlier to Date of Retirement";
    // }
    if (!formData.bankingExperience) newErrors.bankingExperience = "Banking Experience is required";
   if(!formData?.dob){
    newErrors.dob = "Date of Birth is required";
   }
   if(formData?.dob && !isEighteenOrOlder(formData?.dob)){
newErrors.dob = "Age must be 18 or older";

   }
    // if (formData?.dob && formData?.retirementDate) {
    //     var dobinms = convertDateToMilliseconds(formData?.dob);
    //     var retirement_in_ms = convertDateToMilliseconds(formData?.retirementDate);

    //     var difference_in_yrs = daysDiff(dobinms,retirement_in_ms)/365;
        
    //     if (formData?.retirementDate <= formData?.dob) {
    //         newErrors.dob = "Date of Birth should be earlier than Date of Retirement";
    //     }
    //     else {
    //         if(formData.bankingExperience === '0') {
    //             newErrors.bankingExperience = "Banking Experience must be greater than 0"
    //         }
    //         else {
    //             if (parseFloat(formData?.bankingExperience) > difference_in_yrs) {
    //                 newErrors.bankingExperience = `Banking Experience cannot be greater than ${difference_in_yrs.toFixed(2)} year(s)`
    //             }
    //         }
    //     }
    // }

    if(formData?.bankingExperience && formData?.bankingExperience === '0') {
        newErrors.bankingExperience = "Banking Experience cannot be 0"
    }
    if(formData?.designation===7 && !formData?.custom_designation){
        newErrors.custom_designation = "Custom designation is required"
    }
    if(!formData?.education){
        newErrors.education = "Education is required"
    }
    if (formData.education === 3) {
      if (!formData.certification) newErrors.certification = "Certification is required";
      if (formData?.certification && numericRegex.test(formData.certification)) {
        newErrors.certification = "Certification must be a string";
      }
    }
    return newErrors;
};

export const validateStepTwoBanker = (
    formData, setErrors, customInputVisible, keyservices = [], core_expertise = [],
    consultMode = {}, avalHire = {}, stateCity = {}) => {
    let newErrors = {};
    let formValid = true;

    if (formData?.keyAreaServices?.length == 0 && keyservices.length == 0) {
        formValid = false;
        newErrors.keyAreaServices = "Key Area Services is required";
    }

    if (formData?.coreExpertise?.length == 0 && core_expertise?.length == 0) {
        formValid = false;
        newErrors.coreExpertise = "Core Expertise is required";
    }

    if (!formData.lastDrawnGrossConsolidatedSalary) {
        formValid = false;
        newErrors.lastDrawnGrossConsolidatedSalary = "Last Drawn Gross Consolidated Salary is required";
    }
    if (!formData.modeofconsultancy && consultMode === null) {
        newErrors.modeOfConsultancy = 'Mode of consultancy is required';
      }
      if (!formData.availabilityforhire && avalHire === null) {
        newErrors.availabilityForHire = 'Availability for hire is required';
      }

    if (customInputVisible) {
        if((formData?.state === 0 || !formData?.state) && !stateCity?.state) {
            formValid = false;
            newErrors.state = "State is required";
        }
        if((formData?.city === 0 || !formData?.city) && !stateCity?.city) {
            formValid = false;
            newErrors.city = "City is required";
        }
        // formValid = false;
        // newErrors.preferredLocation = "Preferred Location is required";
    }
    setErrors(newErrors);

    return formValid;
}

export const validateCompanyRegisterForm = (formData, setErrors) => {
    const errors = {};

    if (!formData.fullName) {
        errors.fullName = 'Full Name is required';
    } else if (numericRegex.test(formData.fullName)) {
        errors.fullName = 'Full Name must be a string';
    }

    if (!formData.companyName) {
        errors.companyName = 'Company Name is required';
    } else if (numericRegex.test(formData.companyName)) {
        errors.companyName = 'Company Name must be a string';
    }

    if (!formData.companyEmail) {
        errors.companyEmail = 'Company Email ID is required';
    } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.companyEmail)) {
            errors.companyEmail = 'Invalid email address';
        }
    }
    if(!formData?.state){
        errors.state = "State is required";
    }
    if(!formData?.city){
        errors.city = "city is required";
    }

    if (!formData.mobileNumber) {
        errors.mobileNumber = 'Mobile Number is required';
    } else if(!isNotAllDigitsSame(formData.mobileNumber)){
        errors.mobileNumber = "Mobile Number must not be same";
    }
    // else {
    //     const mobile = formData.mobileNumber.toString();
    //     if (mobile.length !== 10 || isNaN(mobile) || /^(0|1|2|3|4|5)/.test(mobile)) {
    //         errors.mobileNumber = 'Mobile Number is invalid';
    //     }
    // }

    if (!formData.turnoverGrid) {
        errors.turnoverGrid = 'Please select a turnover grid';
    }

    if (!formData.vintageGrid) {
        errors.vintageGrid = 'Please select a vintage grid';
    }

    if (!formData.termsAccepted) {
        errors.termsAccepted = 'You must accept the Term of service & Privacy Policy.';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
};


export const validateIndividualRegisterForm = (formData, setErrors) => {
    let newErrors = {};

    if (!formData.fullName) {
        newErrors.fullName = 'Full Name is required';
    }

    if (!formData.email) {
        newErrors.email = 'Email ID is required';
    } else if (!emailRegex.test(formData.email)) {
        newErrors.email = 'Invalid email address';
    }

    if (!formData.mobileNumber) {
        newErrors.mobileNumber = 'Mobile Number is required';
    } else if (
        formData.mobileNumber.length !== 10 ||
        !/^\d+$/.test(formData.mobileNumber)
    ) {
        newErrors.mobileNumber = 'Mobile Number must be of 10 digits';
    } else if(!isNotAllDigitsSame(formData.mobileNumber)){
        newErrors.mobileNumber = "Mobile Number must not be same";
    }

    if (!formData.service) {
        newErrors.service = 'Please select a service';
    }

    if (!formData.termsAccepted) {
        newErrors.termsAccepted = 'You must accept the Term of service & Privacy Policy.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

const isNotAllDigitsSame = (phoneNumber) => {
  // Remove any non-digit characters (e.g., spaces, dashes)
  const digits = phoneNumber.replace(/\D/g, '');

  // Check if the number is composed of all the same digits
  const firstDigit = digits[0];
  return !digits.split('').every(digit => digit === firstDigit);
};
export const validateBankerRegisterForm = (formData, setErrors) => {
    let newErrors = {};

    if (!formData.fullName) {
        newErrors.fullName = "Full Name is required";
    }

    if (!formData.email) {
        newErrors.email = "Email ID is required";
    } else if (!emailRegex.test(formData.email)) {
        newErrors.email = "Invalid email address";
    }

    if (!formData.mobileNumber) {
        newErrors.mobileNumber = "Mobile Number is required";
    } else if (
        formData.mobileNumber.length !== 10 ||
        isNaN(formData.mobileNumber)
    ) {
        newErrors.mobileNumber = "Mobile Number must be a 10-digit number";
    }else if(!isNotAllDigitsSame(formData.mobileNumber)){
        newErrors.mobileNumber = "Mobile Number must not be same";
    }

    if (!formData.termsAccepted) {
        newErrors.termsAccepted = "You must accept the Term of service & Privacy Policy.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};
