import React, { useEffect, useState } from "react";
import Checkbox from "../../../common/Checkbox";
import Company from "./company/Company";
import Individual from "./individual/Individual";
import { ClientStyleMainContainer, ClientStyleMainContainerDiv } from "../RegisterStyle";
import { flexitemCentre } from "../../../common/StyleCommon";
 
const Client = () => {
  const [isCompany, setIsCompany] = useState(true);
 
  const handleOptionChange = (e) => {
    const value = e.target.value === 'company'; // or 'individual'
    setIsCompany(value);
    sessionStorage.setItem('isIndivisual', value);
  };
 useEffect(()=>{
const isIndivisual = sessionStorage.getItem('isIndivisual');
if(isIndivisual === 'false'){
  setIsCompany(false);
}
else if(isIndivisual === 'true'){
  setIsCompany(true);
}

 },[])
  return (
    <div>
      <p className={ClientStyleMainContainer}>You're creating account as a :</p>
      <div className={ClientStyleMainContainerDiv}>
        <div className={flexitemCentre}>
          <Checkbox
           label="Company"
            checked={isCompany}
            onChange={handleOptionChange}
            id="company-radio"
            type="radio"
            value="company"
          />
         
        </div>
        <div className={flexitemCentre}>
          <Checkbox
            checked={!isCompany}
            onChange={handleOptionChange}
            id="individual-radio"
            type="radio"
            value="individual"
            label="Individual"
          />
         
        </div>
      </div>
 
      {isCompany ? <Company /> : <Individual />}    
    </div>
  );
};
 
export default Client;