import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Client from "./client/Client";
import Banker from "./bankingExpert/Banker";
import { RegisterStyleContainer, RegisterStyleContainerClientDiv, RegisterStyleContainerDiv, RegisterStyleContainerDiv2, RegisterStyleContainerDivSpan, RegisterStyleContainerHanldle, RegisterStyleContainerHanldleBanker, RegisterStyleMainContainer, logoFontStyle } from "./RegisterStyle";
import { LogoIconSVG } from "../../common/Icons";
 
const Register = () => {
  const [isCompany, setIsCompany] = useState(true);
  const [isBanker, setIsBanker] = useState(false);
 
  const handleCompany = () => {
    setIsCompany(true);
    setIsBanker(false);
    sessionStorage.setItem("isCompany", true);
  };
  const handleBanker = () => {
    setIsCompany(false);
    setIsBanker(true);
    sessionStorage.setItem("isCompany", false);
  };
  const navigate = useNavigate();
 useEffect(()=>{
  const isCompany = sessionStorage.getItem("isCompany");
  if(isCompany === "true"){
    setIsCompany(true);
    setIsBanker(false);
  }
  else{
    setIsCompany(false);
    setIsBanker(true);
  }

 },[])
  return (
    <div className={RegisterStyleMainContainer}>
      <div className={RegisterStyleContainer}>
      <div><p className="pl-4 cursor-pointer">
            <LogoIconSVG/>
          </p></div>

        <div className={RegisterStyleContainerDiv2}>
          <p className="lg:ml-auto flex justify-end">
            Already have an account ?{" "}
            
          </p>
          <span
              onClick={() => navigate("/login")}
              className={RegisterStyleContainerDivSpan}
            >
            
              Log in
            </span>
        </div>
      </div>
 
      <div className={RegisterStyleMainContainer}>
        {/* <h2 className="text-center font-urbanist text-[42px] py-10 font-bold"> */}
        <h2 className="h2-text py-3">
 
          Register Now
        </h2>
      </div>
 
      <div className={RegisterStyleContainerClientDiv}>
        <div
          onClick={handleCompany}
          className={` ${RegisterStyleContainerHanldle} ${
            isCompany ? "bg-black text-white" : "secondary-text-gray"
          }`}
        >
          <p className="">I am a Client</p>
        </div>
        <div
          onClick={handleBanker}
          className={`${RegisterStyleContainerHanldleBanker} ${
            isBanker ? "bg-black text-white" : "secondary-text-gray"
          }`}
        >
          <p>I am Banking Expert</p>
        </div>
      </div>
 
      {isCompany ? <Client /> : <Banker />}
    </div>
  );
};
 
export default Register;