import CryptoJS from 'crypto-js';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import bcrypt from 'bcryptjs';

export const encryptPassword = async (password) => {
  const salt = await bcrypt.genSalt(10); // Generate salt with 10 rounds
  const hashedPassword = await bcrypt.hash(password, salt); // Hash the password with the generated salt
  return hashedPassword;
};
export const comparePassword = async (password, hashedPassword) => {
  const isMatch = await bcrypt.compare(password, hashedPassword);
  return isMatch; // Returns true if the password matches the hashed password
};
export const onkeyDownforSpecialCharcter = (e) => {
  if (
    e.key === 'e' ||
    e.key === 'E' ||
    e.key === '--' ||
    e.key === '+' ||
    e.key === '!' ||
    e.key === '@' ||
    e.key === '#' ||
    e.key === '$' ||
    e.key === '%' ||
    e.key === '^' ||
    e.key === '&' ||
    e.key === '*' ||
    e.key === '(' ||
    e.key === ')' ||
    e.key === '_' ||
    e.key === '' ||
    e.key === '<' ||
    e.key === '>' ||
    e.key === '/' ||
    e.key == ',' ||
    e.key == '=' ||
    e.key == ':' ||
    e.key == ';' ||
    e.key == '"' ||
    e.key == "'" ||
    e.key === '[' ||
    e.key === ']' ||
    e.key === '{' ||
    e.key === '}' ||
    e.key === '?' ||
    e.key === '|' ||
    e.key === '-' ||
    e.key === '\\' ||
    e.key === '`' ||
    e.key === '~' ||
    e.key === 'A' ||
    e.key === 'B' ||
    e.key === 'C' ||
    e.key === 'D' ||
    e.key === 'E' ||
    e.key === 'F' ||
    e.key === 'G' ||
    e.key === 'H' ||
    e.key === 'I' ||
    e.key === 'J' ||
    e.key === 'K' ||
    e.key === 'L' ||
    e.key === 'M' ||
    e.key === 'N' ||
    e.key === 'O' ||
    e.key === 'P' ||
    e.key === 'Q' ||
    e.key === 'R' ||
    e.key === 'S' ||
    e.key === 'T' ||
    e.key === 'U' ||
    e.key === 'V' ||
    e.key === 'W' ||
    e.key === 'X' ||
    e.key === 'Y' ||
    e.key === 'Z' ||
    e.key === 'a' ||
    e.key === 'b' ||
    e.key === 'c' ||
    e.key === 'd' ||
    e.key === 'e' ||
    e.key === 'f' ||
    e.key === 'g' ||
    e.key === 'h' ||
    e.key === 'i' ||
    e.key === 'j' ||
    e.key === 'k' ||
    e.key === 'l' ||
    e.key === 'm' ||
    e.key === 'n' ||
    e.key === 'o' ||
    e.key === 'p' ||
    e.key === 'q' ||
    e.key === 'r' ||
    e.key === 's' ||
    e.key === 't' ||
    e.key === 'u' ||
    e.key === 'v' ||
    e.key === 'w' ||
    e.key === 'x' ||
    e.key === 'y' ||
    e.key === 'z'
  ) {
    e.preventDefault() // Prevent the default behavior (i.e., typing 'e' or '-')
  }
}
export function allowOnlyNumbers(event) {
  const charCode = event.which ? event.which : event.keyCode;

  // Allow control keys: backspace (8), delete (46), arrow keys (37-40), tab (9), enter (13)
  const controlKeys = [8, 9, 13, 37, 38, 39, 40, 46];

  // Get the current value of the input
  const inputValue = event.target.value;

  // Allow numbers: 0-9 (48-57)
  const isNumber = charCode >= 48 && charCode <= 57;

  // Prevent input if:
  // - The key pressed is not a number or a control key
  // - The input length is already 17 and the key pressed is a number
  if ((!isNumber && !controlKeys.includes(charCode)) ||
    (isNumber && inputValue.length >= 17)) {
    event.preventDefault();
  }
}
export function extractFileName(filePath) {
  // Split the file path by '/' and get the last element
  const parts = filePath.split('/');
  return parts.pop();
}
export function isDueDateOverdue(dueDate) {
  // Check if dueDate is valid
  if (!dueDate) {
    return false; // If no due date, return false
  }

  const currentDate = new Date(); // Get the current date
  const due = new Date(dueDate); // Convert dueDate to a Date object

  // Compare the due date with the current date
  return due.getTime() < currentDate.getTime(); // Returns true if overdue
}



export const maskPhoneNumber = (phoneNumber) => {
  // Remove any non-digit characters (e.g., spaces, dashes)
  const digits = phoneNumber.replace(/\D/g, '');

  // Define how many digits to show at the beginning and end
  const visibleStart = 2; // Number of digits to show at the start
  const visibleEnd = 2;   // Number of digits to show at the end

  if (digits.length <= visibleStart + visibleEnd) {
    return digits; // If number is too short to mask, return as is
  }

  // Mask the middle part
  const start = digits.substring(0, visibleStart);
  const end = digits.substring(digits.length - visibleEnd);
  const maskedMiddle = '*'.repeat(digits.length - visibleStart - visibleEnd);

  return `${start}${maskedMiddle}${end}`;
}
export const maskEmail = (email) => {
  // Split the email into local part and domain part
  const [localPart, domainPart] = email.split('@');

  if (!domainPart) return ''; // Invalid email format

  // Split the domain part into domain and extension
  const [domain, extension] = domainPart.split('.');

  // Mask the local part
  const maskedLocal = localPart.length > 2
    ? `${localPart[0]}${'*'.repeat(localPart.length - 2)}${localPart[localPart.length - 1]}`
    : localPart;

  // Mask the domain part
  const maskedDomain = domain.length > 2
    ? `${domain[0]}${'*'.repeat(domain.length - 2)}${domain[domain.length - 1]}`
    : domain;

  // Return the masked email
  return `${maskedLocal}@${maskedDomain}.${extension}`;
};
export const preventNumbersAndSpecialChars = (event) => {
  const { key } = event;

  // Allow navigation keys (arrow keys, backspace, etc.)
  if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', ' '].includes(key)) {
    return;
  }

  // Allow only alphabetic characters (A-Z, a-z)
  if (!/^[a-zA-Z]$/.test(key)) {
    event.preventDefault();
  }
};

export const handleTwoDigitInput = (event) => {
  const { key, target } = event;
  const value = target.value;

  // Allow navigation keys (arrow keys, backspace, etc.)
  if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
    return;
  }

  // Allow only numbers and decimal points
  if (!/^\d$/.test(key) && key !== '.') {
    event.preventDefault();
    return;
  }

  // Prevent multiple decimal points
  if (key === '.' && value.includes('.')) {
    event.preventDefault();
    return;
  }

  // Restrict to two digits before the decimal point
  if (value.split('.')[0].length >= 2 && key !== '.' && !value.includes('.')) {
    event.preventDefault();
    return;
  }

  // Restrict to two digits after the decimal point
  if (value.includes('.') && value.split('.')[1].length >= 2) {
    event.preventDefault();
  }
};


export function formatDateBanker(dateString) {
  // Create a Date object from the input string
  const date = new Date(dateString);

  // Define month names array
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Extract day, month, and year from the date
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Return the formatted date
  return `${day} ${month} ${year}`;
}
export function convertDateToInputFormat(dateString) {
  // Split the date string into parts [day, month, year]
  const [day, month, year] = dateString.split('/');

  // Return the date in YYYY-MM-DD format
  return `${year}-${month}-${day}`;
}
export const convertDateToInputFormatMeeting = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date); // Ensure date is a Date object
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
export function convertToSearchArray(dataObject) {
  // If dataObject is not an object or is null, return an empty array
  if (typeof dataObject !== 'object' || dataObject === null) {
    console.warn('Warning: Invalid input. Expected a non-null object.');
    return [];
  }

  return Object.entries(dataObject).map(([key, value]) => ({
    id: parseInt(key, 10),
    ...value
  }));
}
export function validateIFSC(ifsc) {
  // IFSC code must be 11 characters long
  if (typeof ifsc !== 'string' || ifsc.length !== 11) {
    return false;
  }

  // Regular expression for validating IFSC code
  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

  // Test the IFSC code against the regex pattern
  return ifscRegex.test(ifsc);
}
export const LogOut = () => {
  sessionStorage.clear();
  window.location.href = "/";
}
export const calculateNextDay = (dateString) => {
  const specificDate = new Date(dateString);
  const nextDay = new Date(specificDate);
  nextDay.setDate(specificDate.getDate() + 1);


  // Format the date to YYYY-MM-DD
  return nextDay.toISOString().split('T')[0];
};
export const calculateMinDOBFor18YearsOld = (dateString) => {
  const currentDate = new Date(dateString);

  // Calculate the date 18 years ago
  const minDOB = new Date(currentDate);
  minDOB.setFullYear(currentDate.getFullYear() - 18);

  // Format the date to YYYY-MM-DD
  return minDOB.toISOString().split('T')[0];
};

export function splitName(fullName) {
  if (typeof fullName !== 'string') {
    return 'Invalid input. Please provide a valid string.';
  }

  // List of common titles to check for
  const commonTitles = ['Dr', 'Mr', 'Mrs', 'Ms', 'Prof'];

  // Remove extra spaces and split the name into parts
  const nameParts = fullName.trim().split(' ');

  if (nameParts.length === 0) {
    return 'No name provided.';
  }

  // Initialize variables for title, first name, and last name
  let title = '';
  let firstName = '';
  let lastName = '';

  // Check if the first part is a common title and if there are more name parts
  if (commonTitles.includes(nameParts[0]) && nameParts.length > 1) {
    title = nameParts[0];
    nameParts.shift(); // Remove the title from the parts array
  }

  // The first part after the title (if any) is the first name
  if (nameParts.length > 0) {
    firstName = nameParts[0];
  }

  // The remaining parts form the last name
  if (nameParts.length > 1) {
    lastName = nameParts.slice(1).join(' ');
  }

  // Return the appropriate string based on the presence of a title
  if (title) {
    return `${title} ${firstName}`;
  } else {
    return firstName ? firstName : 'Name not provided.';
  }
}


export const toPascalCase = (text) => {
  // Ensure text is a string, or use an empty string as default
  let Text = typeof text === 'string' ? text : "";
  return Text.replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
};
export function getTimeDifference(updatedAt) {
  const currentTime = new Date();
  const updatedTime = new Date(updatedAt);

  const timeDifference = currentTime - updatedTime; // difference in milliseconds
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (days > 0) {
    return `Last update ${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `Last update ${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `Last update ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return `Last update ${seconds} second${seconds > 1 ? 's' : ''} ago`;
  }
}
export function formatMeetingDetails(meetingData) {
  // Extract meeting data
  const {
    preferred_date, preferred_start_time, preferred_end_time,
    preferred_date2, preferred_start_time2, preferred_end_time2
  } = meetingData;

  // Helper function to format date and time
  const formatDateTime = (date, startTime, endTime) => {
    const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };

    // Ensure the date is in a valid format for the Date constructor
    const formattedDate = new Date(date).toLocaleDateString('en-US', dateOptions);

    // Ensure the time strings are in the correct format for the Date constructor
    const formattedStartTime = new Date(`1970-01-01T${startTime}`).toLocaleTimeString('en-US', timeOptions);
    const formattedEndTime = new Date(`1970-01-01T${endTime}`).toLocaleTimeString('en-US', timeOptions);

    return `${formattedDate} at ${formattedStartTime} to ${formattedEndTime}`;
  };

  // Format both meeting slots
  const firstMeetingSlot = formatDateTime(preferred_date, preferred_start_time, preferred_end_time);
  const secondMeetingSlot = formatDateTime(preferred_date2, preferred_start_time2, preferred_end_time2);

  // Display the formatted meeting details
  return { firstMeetingSlot, secondMeetingSlot };
}



export const AddJobValidation = (formData, SetError) => {
  const errors = {};

  // Validation criteria
  if (!formData.custom_title) {
    errors.custom_title = "Job title is required";
  }

  // if (!formData.job_description) {
  //   errors.job_description = "Job description is required";
  // }

  // if (!formData.preferred_location && formData?.consultancy_mode !== "" && (
  //   formData?.consultancy_mode === 2 || formData?.consultancy_mode === 3)) {
  //   errors.preferred_location = "Preferred location is required";
  // }
  if (formData?.consultancy_mode === 2 || formData?.consultancy_mode === 3) {
    if (!formData.state) {
      errors.state = "State is required";
    }
    if (!formData.city) {
      errors.city = "City is required";
    }
  }
  if (!formData.tenure) {
    errors.tenure = "Tenure is required";
  }

  if (!formData.consultancy_mode) {
    errors.consultancy_mode = "Consultancy mode is required";
  }

  if (formData?.key_service?.length === 0) {
    errors.key_service = "At least one key service is required";
  }

  // if (formData?.core_expertise?.length === 0) {
  //   errors.core_expertise = "At least one core expertise is required";
  // }

  if (formData?.keyAreaServices?.length === 0) {
    errors.keyAreaServices = "At least one key area service is required";
  }

  // Set the errors using the SetError function
  SetError(errors);

  // Return a boolean indicating if the form is valid
  return Object.keys(errors).length === 0;
};
export function convertToInitials(name) {
  if (!name || typeof name !== 'string') {
    return ''; // Return an empty string if the input is not valid
  }

  const nameParts = name.trim().split(' '); // Split the name by spaces

  if (nameParts.length === 1) {
    // If it's a single name, return the first letter
    return nameParts[0].charAt(0).toUpperCase();
  }

  // Get the first letter of the first and last name only
  const firstInitial = nameParts[0].charAt(0).toUpperCase();
  const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();

  return firstInitial + lastInitial;
}

export function formatTime(timeString) {
  // Split the time string into its components
  const [hours, minutes] = timeString.split(":");

  // Return the formatted time string
  return `${hours}:${minutes}`;
}
export const HiringFormValidation = (formData, setErrors) => {
  const newErrors = {};

  if (!formData.projectName) {
    newErrors.projectName = 'Project name is required';
  }
  if (!formData.modeofconsultancy) {
    newErrors.modeofconsultancy = 'Mode of consultancy is required';
  }
  if (!formData.availabilityforhire) {
    newErrors.availabilityForHire = 'Availability for hire is required';
  }
  if (!formData.selectMonth) {
    newErrors.selectMonth = 'Select month is required';
  }
  if (formData?.selectMonth && formData?.availabilityforhire == 3) {
    if (formData?.selectMonth < 6) {
      newErrors.selectMonth = 'Month should be greater than 6';
    }
  }
  if (!formData.startDate) {
    newErrors.startDate = 'Start date is required';
  }
  if (formData?.uploadDocument?.length == 0) {
    newErrors.uploadDocument = 'At least one document is required';
  }
  // if (!formData.endDate) {
  //   newErrors.endDate = 'End date is required';
  // }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
}
export const getFutureTimes = (times, preferredDate) => {
  const now = new Date();
  const selectedDate = new Date(preferredDate);

  // If the selected date is today
  if (selectedDate.toDateString() === now.toDateString()) {
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();

    return times.filter(time => {
      const [hour, minute] = time.name.split(':').map(Number);
      // Keep times that are later than the current time
      return hour > currentHour || (hour === currentHour && minute > currentMinute);
    });
  }

  // If the selected date is in the future, return all times
  if (selectedDate > now) {
    return times;
  }

  // If the selected date is in the past, return an empty array
  return [];
};

export const ValidationForMeetingBanker = (formData, setErrors) => {
  let errors = {};

  if (!formData.title) {
    errors.title = "Title is required";
  }
  if (!formData?.preferred_date) {
    errors.preferred_date = "Preferred date is required";
  }
  if (formData?.is_virtual == false && !formData?.email) {
    errors.email = "Location is required";
  }
  if (!formData?.preferred_start_time) {
    errors.preferred_start_time = "Preferred start time is required";
  }
  if (!formData?.banker_user) {
    errors.banker_user = "Company user is required";
  }
  if (!formData?.preferred_end_time) {
    errors.preferred_end_time = "Preferred end time is required";
  } else if (formData?.preferred_start_time && formData?.preferred_end_time) {
    // Validate time format and ensure end time is greater than start time
    const startTime = formData.preferred_start_time;
    const endTime = formData.preferred_end_time;

    const startTimeSplit = startTime.split(':');
    const endTimeSplit = endTime.split(':');

    const startHours = parseInt(startTimeSplit[0], 10);
    const startMinutes = parseInt(startTimeSplit[1], 10);

    const endHours = parseInt(endTimeSplit[0], 10);
    const endMinutes = parseInt(endTimeSplit[1], 10);

    if (
      endHours < startHours ||
      (endHours === startHours && endMinutes <= startMinutes)
    ) {
      errors.preferred_end_time = "Preferred end time should be greater than preferred start time";
    }
    const currentDate = new Date();
    const selectedStartTime = new Date(`${formData.preferred_date}T${startTime}`);
    const selectedEndTime = new Date(`${formData.preferred_date}T${endTime}`);

    if (selectedStartTime <= currentDate) {
      errors.preferred_start_time = "Preferred start time must be in the future.";
    }

    if (selectedEndTime <= currentDate) {
      errors.preferred_end_time = "Preferred end time must be in the future.";
    }

  }
  if (!formData?.description) {
    errors.description = "Description is required";
  }
  if (formData?.is_virtual == false && !formData?.email) {
    errors.email = "Location is required";
  }
  if (formData?.is_virtual == true && !formData?.link && formData?.edit === true) {
    errors.link = "Link is required";
  }
  if (formData?.link && !isValidUrl(formData?.link)) {
    errors.link = "Invalid url";
  }
  // if (!formData?.preferred_date2) {
  //   errors.preferred_date2 = "Preferred date is required"
  // }
  // if (!formData?.preferred_start_time2) {
  //   errors.preferred_start_time2 = "Preferred start time is required"
  // }
  // if (!formData?.preferred_end_time2) {
  //   errors.preferred_end_time2 = "Preferred end time is required"
  // }
  setErrors(errors);
  return Object.keys(errors).length === 0;
}
export const handleDownloadAll = async (sharedDocs) => {
  const zip = new JSZip();

  // Helper function to extract the file extension from the URL
  const getFileExtension = (url) => {
    const splitUrl = url.split('.');
    if (splitUrl.length > 1) {
      return `.${splitUrl.pop()}`;  // Get the last part of the URL after the dot (extension)
    }
    return ''; // Return empty string if no extension found
  };

  // Fetch and store the content of each file
  for (let i = 0; i < sharedDocs.length; i++) {
    try {
      const response = await fetch(sharedDocs[i]);
      const blob = await response.blob();

      // Get file extension based on the URL
      const fileExtension = getFileExtension(sharedDocs[i]);
      const fileName = `document-${i + 1}${fileExtension}`;

      // Add each file to the zip
      zip.file(fileName, blob);
    } catch (err) {
      console.error(`Failed to fetch or process document: ${sharedDocs[i]}`, err);
    }
  }

  // Generate the zip file
  zip.generateAsync({ type: 'blob' }).then((content) => {
    // Trigger the download
    saveAs(content, 'documents.zip');
  }).catch((err) => {
    console.error('Failed to create zip file:', err);
  });
};


const getFileExtension = (url) => {
  return url.slice(((url.lastIndexOf(".") - 1) >>> 0) + 2);
};
function isValidUrl(url) {
  const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
  const regex = new RegExp(expression);

  return regex.test(url);
}
// Manually create a zip-like blob (simplified example)
const createZipBlob = (files) => {
  const boundary = "--boundary";
  const contentType = "application/zip";

  // Manually create a zip-like file structure
  let zipData = `${boundary}\r\n`;
  files.forEach(file => {
    zipData += `Content-Disposition: form-data; name="file"; filename="${file.name}"\r\n`;
    zipData += `Content-Type: ${file.blob.type}\r\n\r\n`;
    zipData += `${file.blob}\r\n`;
    zipData += `${boundary}\r\n`;
  });

  return new Blob([zipData], { type: contentType });
};

export const ValidationForMeetingUpdate = (formData, setErrors) => {
  let errors = {};

  if (!formData.title) {
    errors.title = "Title is required";
  }
  if (!formData?.preferred_date) {
    errors.preferred_date = "Preferred date is required";
  }
  if (formData?.is_virtual == false && !formData?.email) {
    errors.email = "Location is required";
  }
  if (!formData?.preferred_start_time) {
    errors.preferred_start_time = "Preferred start time is required";
  }
  if (!formData?.preferred_end_time) {
    errors.preferred_end_time = "Preferred end time is required";
  } else if (formData?.preferred_start_time && formData?.preferred_end_time) {
    // Validate time format and ensure end time is greater than start time
    const startTime = formData.preferred_start_time;
    const endTime = formData.preferred_end_time;

    const startTimeSplit = startTime.split(':');
    const endTimeSplit = endTime.split(':');

    const startHours = parseInt(startTimeSplit[0], 10);
    const startMinutes = parseInt(startTimeSplit[1], 10);

    const endHours = parseInt(endTimeSplit[0], 10);
    const endMinutes = parseInt(endTimeSplit[1], 10);

    if (
      endHours < startHours ||
      (endHours === startHours && endMinutes <= startMinutes)
    ) {
      errors.preferred_end_time = "Preferred end time should be greater than preferred start time";
    }

  }
  if (!formData?.description) {
    errors.description = "Description is required";
  }
  if (!formData?.link) {
    errors.link = "Link is required";
  }
  if (formData?.link && !isValidUrl(formData?.link)) {
    errors.link = "Invalid url";
  }

  // if (!formData?.preferred_date2) {
  //   errors.preferred_date2 = "Preferred date is required"
  // }
  // if (!formData?.preferred_start_time2) {
  //   errors.preferred_start_time2 = "Preferred start time is required"
  // }
  // if (!formData?.preferred_end_time2) {
  //   errors.preferred_end_time2 = "Preferred end time is required"
  // }
  setErrors(errors);
  return Object.keys(errors).length === 0;
}
export const ValidationForMeeting = (formData, setErrors) => {
  let errors = {};

  // Required fields validation
  if (!formData.title) {
    errors.title = "Title is required";
  }
  if (!formData?.preferred_date) {
    errors.preferred_date = "Preferred date is required";
  }
  if (!formData?.banker_user) {
    errors.banker_user = "Banker is required";
  }
  if (formData?.is_virtual === false && !formData?.email) {
    errors.email = "Location is required";
  }
  if (!formData?.preferred_start_time) {
    errors.preferred_start_time = "Preferred start time is required";
  }
  if (!formData?.preferred_end_time) {
    errors.preferred_end_time = "Preferred end time is required";
  } else if (formData?.preferred_start_time && formData?.preferred_end_time) {
    // Validate that end time is greater than start time
    const startTime = formData.preferred_start_time;
    const endTime = formData.preferred_end_time;

    const startTimeSplit = startTime.split(':');
    const endTimeSplit = endTime.split(':');

    const startHours = parseInt(startTimeSplit[0], 10);
    const startMinutes = parseInt(startTimeSplit[1], 10);

    const endHours = parseInt(endTimeSplit[0], 10);
    const endMinutes = parseInt(endTimeSplit[1], 10);

    if (
      endHours < startHours ||
      (endHours === startHours && endMinutes <= startMinutes)
    ) {
      errors.preferred_end_time = "Preferred end time should be greater than preferred start time";
    }

    // Validate that both start and end times are less than the current time
    const currentDate = new Date();
    const selectedStartTime = new Date(`${formData.preferred_date}T${startTime}`);
    const selectedEndTime = new Date(`${formData.preferred_date}T${endTime}`);

    if (selectedStartTime <= currentDate) {
      errors.preferred_start_time = "Preferred start time must be in the future.";
    }

    if (selectedEndTime <= currentDate) {
      errors.preferred_end_time = "Preferred end time must be in the future.";
    }
  }

  if (!formData?.description) {
    errors.description = "Description is required";
  }

  if (!formData?.link && formData?.is_virtual === true && formData?.edit === true) {
    errors.link = "Link is required";
  }
  if (formData?.link && !isValidUrl(formData?.link)) {
    errors.link = "Invalid url";
  }
  // if (!formData?.preferred_date2) {
  //   errors.preferred_date2 = "Preferred date is required";
  // }
  // if (!formData?.preferred_start_time2) {
  //   errors.preferred_start_time2 = "Preferred start time is required";
  // }
  // if (!formData?.preferred_end_time2) {
  //   errors.preferred_end_time2 = "Preferred end time is required";
  // }

  setErrors(errors);
  return Object.keys(errors).length === 0;
};

export const ValidationForMeetingSchedule = (formData, setErrors) => {
  let errors = {};

  // Required fields validation for the first set of fields
  if (!formData.title) {
    errors.title = "Title is required";
  }
  if (!formData?.preferred_date) {
    errors.preferred_date = "Preferred date is required";
  }
  if (!formData?.banker_user) {
    errors.banker_user = "Banker is required";
  }
  if (formData?.is_virtual === false && !formData?.email) {
    errors.email = "Location is required";
  }
  if (!formData?.preferred_start_time) {
    errors.preferred_start_time = "Preferred start time is required";
  }
  if (!formData?.preferred_end_time) {
    errors.preferred_end_time = "Preferred end time is required";
  } else if (formData?.preferred_start_time && formData?.preferred_end_time) {
    // Validate that end time is greater than start time
    const startTime = formData.preferred_start_time;
    const endTime = formData.preferred_end_time;

    const startTimeSplit = startTime.split(':');
    const endTimeSplit = endTime.split(':');

    const startHours = parseInt(startTimeSplit[0], 10);
    const startMinutes = parseInt(startTimeSplit[1], 10);

    const endHours = parseInt(endTimeSplit[0], 10);
    const endMinutes = parseInt(endTimeSplit[1], 10);

    if (
      endHours < startHours ||
      (endHours === startHours && endMinutes <= startMinutes)
    ) {
      errors.preferred_end_time = "End time must be after start time.";
    }

    // Validate that both start and end times are less than the current time
    const currentDate = new Date();
    const selectedStartTime = new Date(`${formData.preferred_date}T${startTime}`);
    const selectedEndTime = new Date(`${formData.preferred_date}T${endTime}`);

    if (selectedStartTime <= currentDate) {
      errors.preferred_start_time = "Select a future time.";
    }

    if (selectedEndTime <= currentDate) {
      errors.preferred_end_time = "Select a future time.";
    }
  }

  if (!formData?.description) {
    errors.description = "Description is required";
  }

  if (!formData?.link && formData?.is_virtual === true && formData?.edit === true) {
    errors.link = "Link is required";
  }
  if (formData?.link && !isValidUrl(formData?.link)) {
    errors.link = "Invalid url";
  }

  // Validation for the second set of date and time fields
  if (!formData?.preferred_date2) {
    errors.preferred_date2 = "Preferred date 2 is required";
  }
  if (!formData?.preferred_start_time2) {
    errors.preferred_start_time2 = "Preferred start time 2 is required";
  }
  if (!formData?.preferred_end_time2) {
    errors.preferred_end_time2 = "Preferred end time 2 is required";
  } else if (formData?.preferred_start_time2 && formData?.preferred_end_time2) {
    // Validate that end time 2 is greater than start time 2
    const startTime2 = formData.preferred_start_time2;
    const endTime2 = formData.preferred_end_time2;

    const startTimeSplit2 = startTime2.split(':');
    const endTimeSplit2 = endTime2.split(':');

    const startHours2 = parseInt(startTimeSplit2[0], 10);
    const startMinutes2 = parseInt(startTimeSplit2[1], 10);

    const endHours2 = parseInt(endTimeSplit2[0], 10);
    const endMinutes2 = parseInt(endTimeSplit2[1], 10);

    if (
      endHours2 < startHours2 ||
      (endHours2 === startHours2 && endMinutes2 <= startMinutes2)
    ) {
      errors.preferred_end_time2 = "End time must be after start time.";
    }

    // Validate that both start and end times 2 are less than the current time
    const currentDate2 = new Date();
    const selectedStartTime2 = new Date(`${formData.preferred_date2}T${startTime2}`);
    const selectedEndTime2 = new Date(`${formData.preferred_date2}T${endTime2}`);

    if (selectedStartTime2 <= currentDate2) {
      errors.preferred_start_time2 = "Select a future time.";
    }

    if (selectedEndTime2 <= currentDate2) {
      errors.preferred_end_time2 = "Select a future time.";
    }
  }

  setErrors(errors);
  return Object.keys(errors).length === 0;
};


export function convertDate(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

export function getMonthDifference(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const yearsDifference = end.getFullYear() - start.getFullYear();
  const monthsDifference = end.getMonth() - start.getMonth();

  return yearsDifference * 12 + monthsDifference;
}
export const BankDetailsValidation = (formData, setErrors) => {
  // Perform form validation
  let validationErrors = {};
  if (!formData.account_number) {
    validationErrors.account_number = 'Account number is required';
  }
  if (!formData.holder_name) {
    validationErrors.holder_name = 'Account holder name is required';
  }
  if (!formData.ifsc_code) {
    validationErrors.ifsc_code = 'Bank IFSC is required';
  }
  if (!formData.account_type) {
    validationErrors.account_type = 'Type of account is required';
  }
  if (!formData.bank_branch_name) {
    validationErrors.bank_branch_name = 'Bank branch name is required';
  }
  if (!formData.bank_name) {
    validationErrors.bank_name = 'Bank name is required';
  }
  setErrors(validationErrors);
  return Object.keys(validationErrors).length === 0;
}

export const createSignature = () => {
  // Fixed string (could be a constant or some predefined data)
  const fixedString = 'fixedStringToSign';

  // Secret key (store this securely in a real application)
  const secretKey = '1234567890abcdef';

  // Combine the fixed string with the secret key
  const combinedString = fixedString + secretKey;

  // Generate hash using SHA-256
  const hash = CryptoJS.SHA256(combinedString);

  // Convert hash to a string
  const signature = hash.toString(CryptoJS.enc.Hex);

  return signature;
};
export const generateSignature = (orderId, paymentId) => {
  const keySecret = 'O8yhoq0jhpVCAk7R5dxlVKU8'; // Replace with your actual key secret
  const message = `${orderId}|${paymentId}`;
  const signature = CryptoJS.HmacSHA256(message, keySecret).toString(CryptoJS.enc.Hex);
  return signature;
};

export const ArrayOfTime = [
  { id: 1, name: '01:00' },
  { id: 2, name: '01:15' },
  { id: 3, name: '01:30' },
  { id: 4, name: '01:45' },
  { id: 5, name: '02:00' },
  { id: 6, name: '02:15' },
  { id: 7, name: '02:30' },
  { id: 8, name: '02:45' },
  { id: 9, name: '03:00' },
  { id: 10, name: '03:15' },
  { id: 11, name: '03:30' },
  { id: 12, name: '03:45' },
  { id: 13, name: '04:00' },
  { id: 14, name: '04:15' },
  { id: 15, name: '04:30' },
  { id: 16, name: '04:45' },
  { id: 17, name: '05:00' },
  { id: 18, name: '05:15' },
  { id: 19, name: '05:30' },
  { id: 20, name: '05:45' },
  { id: 21, name: '06:00' },
  { id: 22, name: '06:15' },
  { id: 23, name: '06:30' },
  { id: 24, name: '06:45' },
  { id: 25, name: '07:00' },
  { id: 26, name: '07:15' },
  { id: 27, name: '07:30' },
  { id: 28, name: '07:45' },
  { id: 29, name: '08:00' },
  { id: 30, name: '08:15' },
  { id: 31, name: '08:30' },
  { id: 32, name: '08:45' },
  { id: 33, name: '09:00' },
  { id: 34, name: '09:15' },
  { id: 35, name: '09:30' },
  { id: 36, name: '09:45' },
  { id: 37, name: '10:00' },
  { id: 38, name: '10:15' },
  { id: 39, name: '10:30' },
  { id: 40, name: '10:45' },
  { id: 41, name: '11:00' },
  { id: 42, name: '11:15' },
  { id: 43, name: '11:30' },
  { id: 44, name: '11:45' },
  { id: 45, name: '12:00' },
  { id: 46, name: '12:15' },
  { id: 47, name: '12:30' },
  { id: 48, name: '12:45' },
  { id: 49, name: '13:00' },
  { id: 50, name: '13:15' },
  { id: 51, name: '13:30' },
  { id: 52, name: '13:45' },
  { id: 53, name: '14:00' },
  { id: 54, name: '14:15' },
  { id: 55, name: '14:30' },
  { id: 56, name: '14:45' },
  { id: 57, name: '15:00' },
  { id: 58, name: '15:15' },
  { id: 59, name: '15:30' },
  { id: 60, name: '15:45' },
  { id: 61, name: '16:00' },
  { id: 62, name: '16:15' },
  { id: 63, name: '16:30' },
  { id: 64, name: '16:45' },
  { id: 65, name: '17:00' },
  { id: 66, name: '17:15' },
  { id: 67, name: '17:30' },
  { id: 68, name: '17:45' },
  { id: 69, name: '18:00' },
  { id: 70, name: '18:15' },
  { id: 71, name: '18:30' },
  { id: 72, name: '18:45' },
  { id: 73, name: '19:00' },
  { id: 74, name: '19:15' },
  { id: 75, name: '19:30' },
  { id: 76, name: '19:45' },
  { id: 77, name: '20:00' },
  { id: 78, name: '20:15' },
  { id: 79, name: '20:30' },
  { id: 80, name: '20:45' },
  { id: 81, name: '21:00' },
  { id: 82, name: '21:15' },
  { id: 83, name: '21:30' },
  { id: 84, name: '21:45' },
  { id: 85, name: '22:00' },
  { id: 86, name: '22:15' },
  { id: 87, name: '22:30' },
  { id: 88, name: '22:45' },
  { id: 89, name: '23:00' },
  { id: 90, name: '23:15' },
  { id: 91, name: '23:30' },
  { id: 92, name: '23:45' },
  { id: 93, name: '24:00' }
];


