import React from 'react';
import './BottomPagination.css';

const BottomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 8;

    if (totalPages <= maxPagesToShow) {
      // Show all pages if totalPages is less than or equal to maxPagesToShow
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`pagination-button ${i === currentPage ? 'active' : ''
              } px-2 py-1 mx-1 rounded-md text-sm sm:text-base ${i === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200'
              }`}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show first page, last page, and the pages near the current page
      let startPage = currentPage - 2;
      let endPage = currentPage + 2;

      if (startPage <= 1) {
        startPage = 1;
        endPage = startPage + 4;
      }

      if (endPage >= totalPages) {
        endPage = totalPages;
        startPage = endPage - 4;
      }

      // Handle edge case when total pages are less than maxPagesToShow
      if (startPage <= 1) startPage = 1;

      // Show the first page
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageClick(1)}
          className={`pagination-button ${currentPage === 1 ? 'active' : ''
            } px-2 py-1 mx-1 rounded-md text-sm sm:text-base ${currentPage === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'
            }`}
        >
          1
        </button>
      );

      // Show ellipsis if there is a gap between first page and startPage
      if (startPage > 2) {
        pageNumbers.push(
          <span key="ellipsis-start" className="mx-1 text-sm sm:text-base">
            ...
          </span>
        );
      }

      // Show pages around the current page
      for (let i = startPage; i <= endPage; i++) {
        if (i > 1 && i < totalPages) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handlePageClick(i)}
              className={`pagination-button ${i === currentPage ? 'active' : ''
                } px-2 py-1 mx-1 rounded-md text-sm sm:text-base ${i === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200'
                }`}
            >
              {i}
            </button>
          );
        }
      }

      // Show ellipsis if there is a gap between endPage and last page
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <span key="ellipsis-end" className="mx-1 text-sm sm:text-base">
            ...
          </span>
        );
      }

      // Show the last page
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageClick(totalPages)}
          className={`pagination-button ${currentPage === totalPages ? 'active' : ''
            } px-2 py-1 mx-1 rounded-md text-sm sm:text-base ${currentPage === totalPages
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200'
            }`}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="pagination flex justify-center items-center flex-wrap py-4">
      <button
        onClick={() => handlePageClick(currentPage - 1)}
        className="pagination-button px-2 py-1 mx-1 rounded-md text-sm sm:text-base bg-gray-200"
        disabled={currentPage === 1}
      >
        &lt;
      </button>
      {renderPageNumbers()}
      <button
        onClick={() => handlePageClick(currentPage + 1)}
        className="pagination-button px-2 py-1 mx-1 rounded-md text-sm sm:text-base bg-gray-200"
        disabled={currentPage === totalPages}
      >
        &gt;
      </button>
    </div>
  );
};

export default BottomPagination;
