import React, { useState } from 'react'
import Navbar from '../../../../common/Navbar'
import BankerSideNavbar from '../../../../common/BankerSideNavbar'
import Referrals from '../../../../common/Referrals'

const BankerReferrals = () => {
 
  return (
    
    <div>
     
        <Navbar hemBurgerIcon={true} BankerNavbar={true} BackBtn={false} ProfileAndNotification={true} />
     
      <div className='flex'>
        <div className='md:w-[7%] lg:w-[7%] '>
            <BankerSideNavbar />
        </div>

<div className='w-full md:w-[93%] lg:w-[93%]'>
<Referrals/>
</div>        
      </div>
    </div>
  )
}

export default BankerReferrals
