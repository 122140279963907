import React, { useState, useEffect } from 'react';
import { format, addDays, subDays, startOfWeek, endOfWeek, addWeeks, subWeeks, addMonths, subMonths, startOfMonth, endOfMonth, isSameMonth, isSameDay, parseISO, startOfYear, endOfYear, addYears, subYears, eachDayOfInterval, eachWeekOfInterval } from 'date-fns';
import Button from '../../../../common/Button';
import { MenuIcon, PlusIconWhite } from '../../../../common/Icons';
import axios from 'axios';
import { ToastMessage, apiHost } from '../../../../common/Config';
import Tooltip from './Tooltip';
import SelectTagComponent from '../../../../common/SelectTagComponent';
import { convertDate, formatTime } from '../../../../Hook/validation';
import useGetData from '../../../../Hook/useGetData';

const CompanyCanlenderComp = ({calendarData, handleAddEventClick, onClick,setisEventViewModalOpen,setEventInfo }) => {
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: getBankerList } = useGetData(`hiring/get-bankers`,token);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState('month'); // default view to 'year'
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.post(`${apiHost}/hiring/get-calender-company`, {
          filter_type: view
        },
        {
          headers: {
            Authorization: `Bearer ${token}` // Include the token in the request headers
          }
        });
        const fetchedEvents = response.data?.data?.filter((item)=>item?.deleted_at==null) || [];
        if (Array.isArray(fetchedEvents)) {
          setEvents(fetchedEvents);
        } else {
          console.error('Unexpected response structure:', response.data);
          setEvents([]); // Fallback to an empty array if response structure is not as expected
        }
      } catch (error) {
        console.error('Error fetching events:', error);
        setEvents([]); // Fallback to an empty array on error
      }
    };

    fetchEvents();
  }, [view,handleAddEventClick, token]);
  useEffect(() => {
    const triggeredEvents = new Set(); // Set to keep track of events that have triggered a toast
  
    const checkForUpcomingEvents = () => {
      const currentTime = new Date();
  
      events.forEach(event => {
        const eventDateTime = `${event.event_date}T${event.start_time}`; // Combine event date and time
        const eventTime = parseISO(eventDateTime); // Parse the combined date and time
  
        const timeDifference = eventTime - currentTime; // in milliseconds
        const timeDifferenceInMinutes = timeDifference / (1000 * 60);
  
        // Check if the event is within 19 minutes and hasn't triggered a toast yet
        if (
          timeDifferenceInMinutes > 0 &&
          timeDifferenceInMinutes <= 15 &&
          !triggeredEvents.has(event.id) // Assuming each event has a unique 'id'
        ) {
          ToastMessage(
            "success",
            `Please make yourself available for your upcoming meeting on ${convertDate(event.event_date)} at ${formatTime(event.start_time)}`
          );
          triggeredEvents.add(event.id); // Mark the event as triggered
        }
      });
    };
  
    const intervalId = setInterval(checkForUpcomingEvents, 60000); // Check every minute
  
    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [events]);
  
  
  

  const renderHeader = () => {
    const dateFormat = "dd MMMM yyyy";

    const handlePrev = () => {
      switch (view) {
        case 'day':
          setCurrentDate(subDays(currentDate, 1));
          break;
        case 'week':
          setCurrentDate(subWeeks(currentDate, 1));
          break;
        case 'month':
          setCurrentDate(subMonths(currentDate, 1));
          break;
        case 'year':
          setCurrentDate(subYears(currentDate, 1));
          break;
        default:
          break;
      }
    };

    const handleNext = () => {
      switch (view) {
        case 'day':
          setCurrentDate(addDays(currentDate, 1));
          break;
        case 'week':
          setCurrentDate(addWeeks(currentDate, 1));
          break;
        case 'month':
          setCurrentDate(addMonths(currentDate, 1));
          break;
        case 'year':
          setCurrentDate(addYears(currentDate, 1));
          break;
        default:
          break;
      }
    };

    const ArrayOfTime = [
      { id: 1, name: 'Day', value:'day' },
      { id: 2, name: 'Week',value:'week' },
      { id: 3, name: 'Month',value:'month' },
      { id: 4, name: 'Year' ,value:'year' },
     
    ];
    const renderDate = () => {
      switch (view) {
        case 'week':
          const startWeek = startOfWeek(currentDate, { weekStartsOn: 1 }); // Week starts on Monday (1)
          const endWeek = endOfWeek(currentDate, { weekStartsOn: 1 }); // Week ends on Sunday
          return `${format(startWeek, 'dd')} - ${format(endWeek, 'dd')} ${format(endWeek, 'MMMM yyyy')}`;
        case 'month':
          return format(currentDate, 'MMMM yyyy');
        case 'year':
          return format(currentDate, 'yyyy');
        default:
          return format(currentDate, 'MMM dd, yyyy');
      }
    };

    return (
      <div className="flex justify-between items-center py-2 px-2 ">
      <div className='flex flex-wrap gap-4'>
     <div className='hidden w-0 lg:w-[2em] lg:block'>
     <MenuIcon onClick={onClick} />
     </div>
      
        <div className="h5-text">{renderDate()}</div>
          <select
            className="w-24 text-[#1EB469] border-[#1EB469] border py-1.5 px-2 rounded"
            value={view}
            onChange={(e) => setView(e.target.value)}
          >
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>
          {/* <SelectTagComponent
           defaultVal='Month'
           options={ArrayOfTime}
           name="preferred_end_time"
           value={view}
           onChange={(e) => setView(e.target.value)}
                    /> */}
        </div>
        <div className='w-[10rem] flex justify-end'>
          <Button
            className='px-3 md:mr-6 gap-2 py-1.5 flex items-center bg-green-600 text-white rounded-lg'
            onClick={handleAddEventClick}
          >
            <PlusIconWhite /> Add Event
          </Button>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentDate, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="text-center py-2" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="pl-7 grid grid-cols-7 border-t">{days}</div>;
  };

  const renderDayCells = () => {
    const hours = Array.from({ length: 24 }, (_, i) => i);
    return (
      <div className="flex">
        {/* Time slots column */}
        <div className="flex flex-col w-16">
          {hours.map((hour, index) => (
            <div key={index} className="h-16 border-t flex items-start justify-center">
              <span className="text-xs">{hour}:00</span>
            </div>
          ))}
        </div>
        {/* Events column */}
        <div className="flex-1">
          {hours.map((hour, index) => (
            <div key={index} className="h-16 border-t overflow-auto custom-scrollbar relative">
              {events
                .filter((event) => {
                  const eventDate = parseISO(event.event_date);
                  const eventHour = parseInt(event.start_time.split(":")[0], 10);
                  return isSameDay(eventDate, currentDate) && eventHour === hour && event?.deleted_at===null;
                })
                .map((event, idx) => (
                  <div
                    key={idx}
                    className={`${event.priority === 1 ? 'bg-[#85D19F]' : event.priority === 2 ? 'bg-[#FDD33E]' : event.priority === 3 ? 'bg-[#FF6B00]' : 'bg-[#FD3E3E]'} text-white p-1 my-1 text-start rounded  inset-0`}
                    role='button' 
                    onClick={() => {
                      setEventInfo({ ...event, edit: true });
                      setisEventViewModalOpen(true);
                    }}
                  >
                 
                    <div className="text-xs font-bold">{event.title}</div>
                    <div className="text-xs">{
                            event?.consultancy_mode==1 ? `Virtual`:event?.consultancy_mode==2 ? `In-Person (${event?.location || "Not Specified"})`:`Both (${event?.location || "Not Specified"})`
                          }</div>
                    <div className="text-xs">{getBankerList?.data?.filter((item)=>item?.id==event?.banker)[0].name || "Not Specified"}</div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderWeekCells = () => {
    const startDate = startOfWeek(currentDate, { weekStartsOn: 1 });
    const endDate = endOfWeek(currentDate, { weekStartsOn: 1 });
    const days = eachDayOfInterval({ start: startDate, end: endDate });
    const hours = Array.from({ length: 24 }, (_, i) => i);
  
    return (
      <div className='flex'>
        <div className="border-r ">
          {hours.map((hour, idx) => (
            <div key={idx} className="h-16 border-b flex items-start">
              <span className="text-xs ml-1">{hour}:00</span>
            </div>
          ))}
        </div>
        <div className="border-t grid w-full grid-cols-7">
          {days.map((day, idx) => (
            <div key={idx} className="border-r">
              {hours.map((hour, hIdx) => (
                <div key={hIdx} className="h-16 px-1 py-1 overflow-auto flex flex-col custom-scrollbar gap-2 border-t relative">
                  {events
                    .filter((event) => {
                      const eventDate = parseISO(event.event_date);
                      const [eventHour, eventMinute] = event.start_time.split(':').map(Number);
  
                      return isSameDay(eventDate, day) &&
                        eventHour === hour &&
                        event?.deleted_at === null;
                    })
                    .map((event, eIdx) => {
                      const eventStartTime = parseISO(event.start_time);
                      const minutes = eventStartTime.getMinutes();
                      const topOffset = (minutes / 60) * 100; // Percentage of the hour block
  
                      return (
                        <div
                          key={eIdx}
                          className={`${event.priority === 1 ? 'bg-[#0AA350]' : event.priority === 2 ? 'bg-[#FDD33E]' : event.priority === 3 ? 'bg-[#FF6B00]' : 'bg-[#FD3E3E]'} text-white w-[15rem] lg:w-full text-start px-1 py-1 rounded`}
                          // style={{ top: `${topOffset}%`, height: 'calc(100% - 4px)' }} // Adjust height to fit within the hour
                          role='button'
                          onClick={() => {
                            setEventInfo({ ...event, edit: true });
                            setisEventViewModalOpen(true);
                          }}
                        >
                          <div className="text-xs">{event.title}</div>
                          <div className="text-xs">{
                            event?.consultancy_mode==1 ? `Virtual`:event?.consultancy_mode==2 ? `In-Person (${event?.location || "Not Specified"})`:`Both (${event?.location || "Not Specified"})`
                          }</div>
                                 <div className="text-xs">{getBankerList?.data?.filter((item)=>item?.id==event?.banker)[0].name || "Not Specified"}</div>
                        </div>
                      );
                    })}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };
  

  const renderMonthCells = () => {
    const startDate = startOfMonth(currentDate);
    const endDate = endOfMonth(currentDate);
    const weeks = eachWeekOfInterval({ start: startDate, end: endDate }, { weekStartsOn: 1 });

    return (
        <div className="grid">
            {weeks.map((weekStart, idx) => {
                const days = eachDayOfInterval({ start: weekStart, end: addDays(weekStart, 6) });

                return (
                    <div key={idx} className="grid grid-cols-7 text-start">
                        {days.map((day, idx) => {
                            const dayEvents = events.filter((event) => isSameDay(parseISO(event.event_date), day));
                            const hasEvents = dayEvents.length > 0;
                            return (
                                <div
                                    key={idx}
                                    className={`p-2 h-32 border overflow-hidden ${!isSameMonth(day, currentDate) ? 'bg-gray-200' : ''}`}
                                >
                                    {format(day, 'd')}
                                    <div 
                                        className="overflow-auto custom-scrollbar w-full h-[80%]" 
                                        role="button" 
                                     
                                    >
                                        {dayEvents?.filter((item)=>item?.deleted_at === null)?.map((event, idx) => (
                                            <div
                                                key={idx}
                                                className={`flex w-[15rem] items-center gap-1 ${event.priority === 1 ? 'text-[#0AA350]' : event.priority === 2 ? 'text-[#FDD33E]' : event.priority === 3 ? 'text-[#FF6B00]' : 'text-[#FD3E3E]'} rounded text-[12px] break-words`}
                                                onClick={() => {
                                                  if (hasEvents) {
                                                      setEventInfo({...event,edit:true}); // Store the events in state
                                                      setisEventViewModalOpen(true);  // Open the modal
                                                  }
                                              }}
                                            >
                                      
                                               <div className={`h-2 w-2 rounded-full ${event.priority === 1 ? 'bg-[#0AA350]' : event.priority === 2 ? 'bg-[#FDD33E]' : event.priority === 3 ? 'bg-[#FF6B00]' : 'bg-[#FD3E3E]'}`}></div>  {event.title}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

const renderMonthCellsForYearView = (month) => {
  const startDate = startOfMonth(month);
  const endDate = endOfMonth(month);
  const weeks = eachWeekOfInterval({ start: startDate, end: endDate }, { weekStartsOn: 1 });
  const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];

  return (
      <div className="grid gap-1 w-full">
          <div className="grid grid-cols-7 text-xs mb-2">
              {daysOfWeek.map((day, idx) => (
                  <div key={idx} className="text-center pl-1">
                      {day}
                  </div>
              ))}
          </div>
          {weeks.map((weekStart, idx) => {
              const days = eachDayOfInterval({ start: weekStart, end: addDays(weekStart, 6) });

              return (
                  <div key={idx} className="grid grid-cols-7 text-start">
                      {days.map((day, dayIdx) => {
                          const dayEvents = events.filter(event => isSameDay(parseISO(event.event_date), day));
                          const hasEvents = dayEvents.length > 0;

                          return hasEvents ? (
                              <Tooltip
                                  key={dayIdx}
                                  content={dayEvents?.filter((item)=>item?.deleted_at === null)?.map((event, eventIdx) => (
                                      <div 
                                          className=''
                                          key={eventIdx} 
                                          role="button"
                                          onClick={() => {
                                              setEventInfo({ ...event, edit: true }); // Store the event in state
                                              setisEventViewModalOpen(true); // Open the modal or perform the action
                                          }}
                                      >
                                          <div className={`text-xs ${event.color || 'bg-green-500'} text-white p-1`}>
                                              {event.title}
                                          </div>
                                      </div>
                                  ))}
                              >
                                  <div 
                                      className={`bg-[#1EB469] rounded-full text-white flex items-center justify-center w-full h-[4rem] sm:h-[3rem] lg:h-[2rem] xl:h-[2rem]`} 
                                      role="button"
                                  >
                                      {format(day, "d")}
                                  </div>
                              </Tooltip>
                          ) : (
                              <div 
                                  key={dayIdx} 
                                  className={`flex items-center justify-center w-full h-[4rem] sm:h-[3rem] lg:h-[2rem] xl:h-[2rem] ${!isSameMonth(day, month) ? "text-gray-400" : ""}`} 
                              >
                                  {format(day, "d")}
                              </div>
                          );
                      })}
                  </div>
              );
          })}
      </div>
  );
};

  
  

  const renderYearCells = () => {
    const startOfYearDate = startOfYear(currentDate);
    const months = Array.from({ length: 12 }, (_, i) => addMonths(startOfYearDate, i));

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4">

        {months.map((month, index) => (
          <div key={index} className="p-2 flex flex-col items-center" style={{ aspectRatio: '1 / 1' }}>
            <h2 className="text-lg font-bold mb-2">{format(month, "MMM")}</h2>
            {renderMonthCellsForYearView(month)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="w-full">
      {renderHeader()}
      {view === "day" && renderDayCells()}
      {view === "week" && (
        <>
          {renderDays()}
          {renderWeekCells()}
        </>
      )}
      {view === "month" && renderMonthCells()}
      {view === "year" && renderYearCells()}
    </div>
  );
};

export default CompanyCanlenderComp;
