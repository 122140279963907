import React, { useState, useEffect } from 'react';

const NudgeNotificationProject = ({message}) => {
 // Initially hidden
  const [hideNotification, setHideNotification] = useState(false); // To manage the exit animation

  return (
    <>
     
        <div
          className={`fixed z-[1000] top-10 left-0 right-0 flex justify-center  
            ${hideNotification ? 'translate-y-[-100px] opacity-0' : 'translate-y-0 opacity-100'}`}
        >
          <button className="py-2 px-4 w-auto rounded-[4px] transition-transform duration-1000 ease-in-out s3-text !bg-[#000000] text-white">
            {message}
          </button>
        </div>
    </>
  );
};

export default NudgeNotificationProject;
