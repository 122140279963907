import React, { useEffect, useState } from "react";
import Navbar from "../../../../common/Navbar";
import SideNavbar from "../../../../common/SideNavbar";
import Checkbox from "../../../../common/Checkbox";
import ProfileCard from "../../../../common/ProfileCard";
import { useNavigate } from "react-router-dom";
import { DoubleLeftArrowIcon, FilterIcon, LeftArrowIcon, RightArrowIcon } from "../../../../common/Icons";
import {
  DashboardCheckboxTitle,
  DashboardCheckboxTitleValue,
  DashboardClearAllDiv,
  DashboardClearAllpTag,
  DashboardCompanyButtonAddJOb,
  DashboardCompanyMainDiv,
  DashboardCompanyRecentSearch,
  DashboardCompanySearchTag,
  DashboardCompanydiv,
  DashboardNextFilterSec,
  DashboardNextFilterSecDiv,
  DashboardNextFilterTag,
  DashboardNextMainDiv,
  DashboardRoundedBoxMainDiv,
  FlexMT3,
  MarginLeftTenStart,
} from "../dashboard/DashboardStyle";
import { CommenHRLine } from "../../../../common/StyleCommon";
import SelectTagComponent from "../../../../common/SelectTagComponent";
import useGetData from "../../../../Hook/useGetData";
import DataNotFound from "../../../../common/DataNotFound";
import usePost from "../../../../Hook/usePost";
import { ToastMessage } from "../../../../common/Config";
import Pagination from "../../../../common/Pagination";
import FilterCard from "../../../../common/FilterCard";
import LoaderAPI from "../../../../common/LoaderAPI";

const SavedBankers = () => {
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: getActiveData, loading: loadingActive, error: errorActive } = useGetData('master-data/active/get', token);
  const { data: getSavedData, loading: loadingSaved, error: errorSaved, refetch: fetchSavedData } = useGetData('job/get-saved-banker', token);
  const { data: filterData, loading: filterLoading, error: filterError, postData: postFilterData } = usePost(`job/filter-saved-banker`);
  const { data: filterActiveData, loading: filterActiveLoading, error: filterActiveError, postData: postFilterActiveData } = usePost(`job/filter-active-banker`);
  const navigate = useNavigate();
  const [ActiveFilter , setActiveFilter] = useState(false)

  const [checkedItems, setCheckedItems] = useState({
    shortTerm: false,
    mediumTerm: false,
    longTerm: false,
    virtual: false,
    'In-person': false,
    both: false,
    active: 1,
  });
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleChange = (event) => {
    const { id, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const handleSelectTimePeriod = (val) => {
    const obj = {
      active: val.id
    };
    postFilterData(obj, token);
    setCheckedItems({ ...checkedItems, active: obj?.active });
  };

  const handlePageSizeChange = (options) => {
    setItemsPerPage(options?.name);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = data?.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(data?.length / itemsPerPage) || 1;

  useEffect(() => {
    const consultancy_mode_values = [];
    if (checkedItems?.virtual) consultancy_mode_values.push(1);
    if (checkedItems['In-person']) consultancy_mode_values.push(2);
    if (checkedItems?.both) consultancy_mode_values.push(3);

    const tenure_values = [];
    if (checkedItems?.shortTerm) tenure_values.push(1);
    if (checkedItems?.mediumTerm) tenure_values.push(2);
    if (checkedItems?.longTerm) tenure_values.push(3);

    const obj = {
      consultancy_mode: consultancy_mode_values,
      tenure: tenure_values,
      active: checkedItems?.active,
    };
    
    if (consultancy_mode_values.length > 0 || tenure_values.length > 0) {
      postFilterData(obj, token);
    } else {
      fetchSavedData();
    }
  }, [checkedItems]);

  useEffect(() => {
    if (filterData?.data?.length > 0) {
      const newArray = filterData?.data?.map((item) => {
        const profile = item?.full_profile;
        return {
          ...item,
          ...profile,
          bkUser: item?.user,
        };
      });
      setData(newArray);
    } else {
      setData([]);
    }
    if (filterError) {
      ToastMessage('error', filterError?.response?.data?.message);
    }
  }, [filterData, filterError,filterData?.data?.length]);

  useEffect(() => {
    if (filterActiveData?.data?.length > 0) {
      const newArray = filterActiveData?.data?.map((item) => {
        const profile = item?.full_profile;
        return {
          ...item,
          ...profile,
          bkUser: item?.user,
        };
      });
      setData(newArray);
    } else {
      setData([]);
    }
    if (filterActiveError) {
      ToastMessage('error', filterActiveError?.response?.data?.message || "Filter failed");
    }
  }, [filterActiveData, filterActiveError]);

  useEffect(() => {
    if (getSavedData?.data?.length > 0 || getSavedData?.advance_data?.length > 0) {
      const newArray = getSavedData?.data?.map((item) => {
        const profile = item?.full_profile;
        return {
          ...item,
          ...profile,
          bkUser: item?.user,
        };
      });
      const newArray1 = getSavedData?.advance_data?.map((item) => {
        const profile = item?.advance_full_profile;
        return {
          ...item,
          ...profile,
          bkUser: item?.advance_user,
        };
      });
      setData([...newArray, ...newArray1]);
    }else{
      setData([]);
    }
  }, [getSavedData?.advance_data,getSavedData?.data, loadingSaved, errorSaved]);

  return (
    <div>
      {/* {filterLoading && <LoaderAPI />} */}
      <Navbar hemBurgerIcon={true} CompanyNavbar={true}  ProfileAndNotification={true} />
      <div className="flex">
      <div className="lg:w-[7%] md:-[7%] w-0">

        {/* <div className="w-[9%]"> */}
          <SideNavbar />
        </div>
        {/* <div className="w-[91%]"> */}
        <div className= "w-[100%] px-[1rem] md:px-[2rem] lg:px-[2rem] md:w-[93%] lg:w-[93%]  relative">
          
          <div className={DashboardCompanyMainDiv}>
            <h4 className="h4-text text-[#2F3340]">Saved Bankers</h4>
          </div>
          <div className={DashboardNextMainDiv}>
            <div className="w-full md:w-[70%] ">
              <div className="mt-4 gap-2 md:flex justify-between">
                <div className="md:flex gap-2 w-full my-4 sm:my-0 md:w-[70%] items-center">
                  <p className="s3-text mb-1 sm:mb-0 text-start">Active in</p>
                  <div className="md:w-36 w-full">
                    <SelectTagComponent
                      defaultVal="1 Year"
                      options={getActiveData?.data?.map((item) => ({ ...item, name: item?.value })) || []}
                      onChange={(val) => handleSelectTimePeriod(val)}
                    />
                  </div>
                </div>
                <div className="md:flex w-full my-4 sm:my-0 justify-between items-center">
                  <div className="md:flex gap-2 items-center">
                    <p className="s3-text mb-1 sm:mb-0 text-start">Show</p>
                    <div className="md:w-24 w-full" >
                      <SelectTagComponent
                        defaultVal="10"
                        options={[{ id: 1, name: 5 }, { id: 2, name: 10 }, { id: 3, name: 20 }, { id: 4, name: 30 }, { id: 5, name: 40 }, { id: 6, name: 50 }]}
                        onChange={handlePageSizeChange}
                      />
                    </div>
                  </div>
                  <div className="my-8 sm:my-0 flex items-center sm:justify-end justify-between w-full">
                    <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                  <div onClick={()=>setActiveFilter(!ActiveFilter)} className={`block lg:hidden ${ActiveFilter?'bg-gray-200':''} px-1 py-1 rounded-lg`}>
                  <FilterIcon/>
                  
                  </div>
                  </div>
                </div>

                { ActiveFilter && <div className="w-full block md:hideen lg:hidden ">
                    <FilterCard setCheckedItems={setCheckedItems} checkedItems={checkedItems} fetch={fetchSavedData} />

                    </div>}
                
              </div>
              {currentItems?.length > 0 ? (
                currentItems?.map((item) => (
                  <ProfileCard
                  saveIconUI={true}
                    SaveIconShow={true}
                    hireBtn="Hire Now"
                    data={item}
                    typeOf="SavedBanker"
                    refetch={fetchSavedData}
                  />
                ))
              ) : (
                <DataNotFound title="No Saved Bankers" />
              )}
             </div>
               {/* <div className="block h-[10rem]">

            </div> */}
            <div className="w-full hidden md:block lg:block md:w-[30%] lg:w-[30%]">

<FilterCard setCheckedItems={setCheckedItems} checkedItems={checkedItems} fetch={fetchSavedData} />
</div>
            </div>
          
          </div>

        </div>
      </div>
  );
};

export default SavedBankers;
