import React, { useState } from 'react';
import { CloseEyePasswordIcon, EyeIcon2, IButtonIcon } from '../../../../common/Icons';
import ToolTip from '../../../../common/ToolTip' // Import the Tooltip component
import CompanyUpdateMilestoneModel from './CompanyUpdateMilestoneModel';
import { CP_Comp_div, CP_Comp_div1, CP_Comp_div10, CP_Comp_div11, CP_Comp_div12, CP_Comp_div13, CP_Comp_div14, CP_Comp_div15, CP_Comp_div2, CP_Comp_div3, CP_Comp_div4, CP_Comp_div5, CP_Comp_div6, CP_Comp_div7, CP_Comp_div8, CP_Comp_div9, CP_CompNo, CPToolTipDiv, CPToolTipDiv2, FlexJustCenter } from './CompanyProjectStyle';
import { useNavigate } from 'react-router-dom';
import { apiHost } from '../../../../common/Config';
import Button from '../../../../common/Button';

const CompanyProjectComp = ({ project }) => {
  const imageData = "https://img.freepik.com/free-photo/portrait-young-man-talking-phone-walking-street_231208-2764.jpg";
  const navigate=useNavigate()
  const [UpdateModelOpen , setUpdateModelOpen] = useState(false)
  const handleCloseModal = () => {
    // SetMileStoneCompleteModel(false);
    setUpdateModelOpen(!UpdateModelOpen)

  };

  const ToolTipColer =[
    {
      id:1,
      color:"bg-[#1EB469]",
      status:"Completed"
    },
    {
      id:2,
      color:"bg-[#EA9515]",
      status:"In Approval"
    },
    {
      id:3,
      color:"bg-[#FDD33E]",
      status:"Ongoing"
    },
    
    {
      id:4,
      color:"bg-[#FF0059]",
      status:"Declined"
    } ,
    {
      id:5,
      color:"bg-[#D8D9E0]",
      status:"Not Start"
    },
    {
      id:6,
      color:"bg-[#FFCACE]",
      status:"Overdue"
    }
  ]

  const StatusToolTip = () => (
    <div className='w-[150px]  '>
        {ToolTipColer?.map((item , index)=>{
          return <div className={CPToolTipDiv}>
          <div className={`${CPToolTipDiv2} ${item?.color}`}></div>
          <div className='body-text'>{item?.status}</div>
          </div>
        })}
      
    </div>
  )
  const getStatusColor = (milestone) => {
    switch (milestone.status) {
      case 4:
        return 'bg-[#1EB469]';
      case 2:
        return 'bg-[#EA9515]';
      case 1:
        return 'bg-[#FDD33E]';
      case 0:
        return 'bg-[#FF0059]';
        case 5:
        return 'bg-[#D8D9E0]';
      default:
        return '';
    }
  };
  const handleDetails=()=>{
    navigate(`/dashboard/company/project-detail/${project.id}`)
    }

    const handlePayment=()=>{
      navigate(`/dashboard/hirenow/proceedtopay/${project?.job}/${project?.banker}/${project?.id}`)
    }
    // const dateString = "2024-10-25";
const options = { year: 'numeric', month: 'short', day: 'numeric' };
const formattedDateofDueDate = new Date(project.end_date).toLocaleDateString('en-US', options);
  return (
    <>
      <div className={CP_Comp_div}></div>
      <div className={CP_Comp_div1}>
        <div className={CP_Comp_div2}>
          <div className={CP_Comp_div3}>
            <p className='s2-text mb-1 text-[#8B8D98]  md:hidden block'>Project Name</p>
           {project?.payment_success ?  <p role='button' onClick={handleDetails} className={CP_Comp_div4}>{project.project_name}</p> :
           <p role='button' className={CP_Comp_div4}>{project.project_name}</p>
           }
          </div>
          <div className={CP_Comp_div5}>
            <p className='s2-text text-[#8B8D98] flex mt-5 md:mt-0 mb-2 md:mb-0 items-center md:hidden gap-2 block'>Milestones Status <ToolTip message={<StatusToolTip/>}>
                          <IButtonIcon />
                        </ToolTip> </p>
            <p className={CP_Comp_div6}>
              {project.milestones.map((milestone, index) => (
                 <ToolTip key={index} message={milestone.status==0 ? 'Declined' : milestone?.status==1 ?'Ongoing':milestone?.status==2 ? 'In Approval':milestone?.status==3 ?'Overdue':milestone?.status==4? 'Completed':'Not Started' }>
                  <div
                    className={`${CP_Comp_div7} ${getStatusColor(milestone)} `}
                  ></div>
                </ToolTip>
              ))}
            </p>
          </div>
        </div>
        <div className={CP_Comp_div8}>
          <div> 
            <div className='text-start mt-5 md:hidden block'>
              <p className='s2-text mb-2 text-[#8B8D98]  md:hidden block'>
              Banker
              </p>
            </div>
          <div className={CP_Comp_div9}>
            <div className={CP_Comp_div10}>
              <img src={`${apiHost}${project?.profile?.profile_image}`} alt="" />
            </div>
            <div>
              <p className={CP_Comp_div11}>{project?.profile?.user?.name || 'Not Available'}</p>
            </div>
          </div>
          </div>
          <div className='md:flex md:justify-center mt-5 md:mt-0 mb-6 md:mb-0'>
            <p className='s2-text text-[#8B8D98] text-start mb-1 mt-2  md:hidden block'>Project Due Date</p>
            <p className='notification text-[#2C2C2C] flex items-center'>{formattedDateofDueDate}</p>
          </div>
          {project?.payment_success  ? <div className={CP_Comp_div13} role='button' onClick={handleDetails}>

<p className='flex items-center gap-2'><EyeIcon2 /> <span className='block md:hidden'>View Details</span></p>
</div> :
<div className='flex gap-2 md:justify-center justify-start my-2 items-center' role='button'>
<ToolTip message="Payment is not done yet">
<p className='flex items-center gap-2'><CloseEyePasswordIcon /> <span className='block md:hidden'>View Details</span></p>
</ToolTip>
          
            <Button className='bg-green-500 text-white rounded-[9px] p-2' onClick={handlePayment}>Pay</Button>
          </div>
}
          
        </div>
      </div>
      {project.DueDateOver && (
        <div className={CP_Comp_div14}>
          <p className={CP_Comp_div15}>
            Do you want to update the milestones? 
            <span onClick={()=>setUpdateModelOpen(!UpdateModelOpen)} className='ml-6 text-[#1EB469] cursor-pointer'>Yes</span>
            <span className={CP_CompNo}>No</span>
          </p>
        </div>
      )}

<CompanyUpdateMilestoneModel isOpen={UpdateModelOpen} onClose={handleCloseModal} />


    </>
  );
};

export default CompanyProjectComp;
