import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "./Button";
import { NavbarStyle1, NavbarStyle2, NavbarStyle3, NavbarStyle4, NavbarStyle5, NavbarStyleBuyBtn, NavbarStyleGetHired, NavbarStyleskipData, navbarStyleContainer, navbarStyleDiv, navbarStyleDivTwo, navbarStyleDivTwoBackBtn, navbarStyleLogo, navbarStyleVLine } from "./StyleCommon";
import { BackButtonIcon, LogoIconSVG, MenuIcon, MobileMenuIcon } from "./Icons";
import { LogOut } from "../Hook/validation";
import Notification from "./Notification";
import BnakerProfileOption from "../modules/profile/banker/BnakerProfileOption";
import CompanyProfileOption from "../modules/profile/company/CompanyProfileOption";
import useGetData from "../Hook/useGetData";
import { useSidebar } from "./SidebarProvider";
import { useBankerSidebar } from "./BankerSidebarProvider";
import BankerNotification from "../modules/profile/banker/BankerNotification";
import BankerNotificationModel from "../modules/profile/banker/subscription/BankerNotificationModel";
import CompanyNotificationModel from "../modules/profile/company/CompanyNotificationModel";

const Navbar = (props) => {
  const { skipdata, buybtn, getHired , BackBtn , ProfileAndNotification , BankerNavbar ,CompanyNavbar , hemBurgerIcon} = props;
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const { data, error, loading, refetch } = useGetData("profile/banker/basic-details", token);
  const { data: dataCompany, error: errorCompany, loading: loadingCompany, refetch: refetchCompanyDetails } = useGetData("profile/company/basic-details", token);
  const { data: getPaymentData } = useGetData("onboarding/get-payment-status", token);
  const { data: getBankerData, loading: loadingServices, error: errorServices } = useGetData('onboarding/banker/get-bankers', token);
  const navigate = useNavigate();
  const location = useLocation();
  const handleDashboard = (text) => {
    const userType=sessionStorage.getItem('user_type') || 1

    if(userType==0){
      
      navigate("/dashboard/company");
    }else{
      if(text==="skip"){
        navigate("/dashboard/banker");
      }
      if(data?.data?.form_step < 4 || location.pathname === '/profile/banker/plan-detail' || location.pathname === '/profile/banker/plan' || location.pathname ==='/profile/banker/payment/status') {
      
        return;
      } else {
        navigate("/dashboard/banker");
      }
      
    }

  };
  const [profileData, setProfileData] = useState({});
  const [profileDataCompany, setProfileDataCompany] = useState({});

  const { toggleSidebar } = useSidebar(); // Use the toggleSidebar function from context
  const {toggleBankerSidebar} = useBankerSidebar();
  useEffect(() => {
    setProfileData(data?.data);
  }, [data,error]);

  const toggleHandle = () => {
    toggleSidebar()

     toggleBankerSidebar();

  }

  useEffect(() => {
    if (dataCompany?.data?.company) {
      setProfileDataCompany(dataCompany?.data?.company);
    }
     if(dataCompany?.data?.individual) {
      setProfileDataCompany(dataCompany?.data?.individual);
    }
  }, [dataCompany,errorCompany]);
  return (
    <div className={navbarStyleContainer}>
      <div className={navbarStyleDiv}>
        <div className={navbarStyleDivTwo}>
         {BackBtn && <><div onClick={() => {
          if(location?.pathname === '/profile/banker/complete-your-profile'){
            LogOut();
            navigate()
          }
          navigate(-1)
         }} className={navbarStyleDivTwoBackBtn}>
          <BackButtonIcon/>
          <p className={NavbarStyle1}>Back</p>

          </div>
          <div className={navbarStyleVLine}>

          </div></>}

            <div className={NavbarStyle2}>
                  {hemBurgerIcon && <MobileMenuIcon onClick={toggleHandle}/> }
            </div>

          {/* <h3 className={`${navbarStyleLogo} cursor-pointer`} onClick={handleDashboard}>BankersKlub</h3> */}
          <p onClick={handleDashboard} className={NavbarStyle3}>
            <LogoIconSVG/>
          </p>
        </div>
        <div className={NavbarStyleskipData}>
          {skipdata ? <p className={NavbarStyle4} role="button" onClick={()=>handleDashboard("skip")}>{skipdata}</p> : ""}{" "}
          {buybtn ? (
            <div className="flex">
              <div>
                <p className={NavbarStyleGetHired}>{getHired}</p>
              </div>
            {!getPaymentData?.payment_status && <div className="">
                {" "}
                <Button
                  onClick={() => navigate("/profile/banker/plan")}
                  type="button"
                  className={NavbarStyleBuyBtn}
                >
                  {buybtn}
                </Button>
              </div>}
              {/* <Button
            onClick={LogOut}
            type="button"
            className={`${NavbarStyleBuyBtn} m-4 flex items-center`}
          >
            Log Out
          </Button> */}
            </div>
          ) : (
            !ProfileAndNotification && token &&
            <></>
          //   <Button
          //   onClick={LogOut}
          //   type="button"
          //   className={`${NavbarStyleBuyBtn} flex items-center`}
          // >
          //   Log Out
          // </Button>
           
          )}

        {ProfileAndNotification ? <div className={NavbarStyle5}>
          {/* <div className="text-sm " role="button" onClick={LogOut}>Logout</div> */}
            <div>
              {/* <NotificationBellIcon />  */}

              {/* <Notification/> */}
             { BankerNavbar &&  <BankerNotificationModel/>}
             { CompanyNavbar &&  <CompanyNotificationModel />}

            </div>
            { BankerNavbar && <BnakerProfileOption onClick={LogOut} data={profileData}/> } 
            { CompanyNavbar && <CompanyProfileOption getBankerData={getBankerData} onClick={LogOut} data={profileDataCompany}/> } 

        </div> : ""}

        </div>

        
        

        
      </div>
      
      
    </div>
  );
};
 
export default Navbar;
