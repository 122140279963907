// Company Project Style  'CP - Company profile'
export const FlexW_Full = "flex w-full"
export const CPMainDiv = "md:px-6 py-6 w-full md:w-[91%]"
export const CPMainDiv2 = 'rounded-[8px] pb-4'
export const CPMainDiv3 = "flex justify-between md:px-6" ;
export const CPMainDiv5 = "flex gap-8 py-6";
export const CP_HR_Line = 'md:w-[70vh] w-full md:-mt-6 border';
export const CPDiv = 'flex justify-between my-3 w-full'
export const CPDiv2 ='flex text-start w-[70%]';
export const CPDivP_tag = 'notification-span pl-4 text-[#8B8D98]';
export const CPDivP_tag2 = 'flex items-center gap-2 notification-span text-[#8B8D98]';
export const CPMainDivCont ='grid grid-cols-3 w-[50%] justify-between';
export const CPMainDivCont2 = "w-[96%] m-auto border mb-4";
export const CPMainDivCont3 = 'flex justify-center lg:justify-end lg:px-10';
export const CPMainDivCont4 = 'flex items-center gap-4'
export const CPToolTipDiv = 'flex items-center ml-2 my-3 gap-2';
export const CPToolTipDiv2 = 'h-[8px] rounded-[2px] w-[36px]'



export const CP_Comp_div ='w-[98%] mt-4 md:mt-0 md:border  pr-6'
export const CP_Comp_div1 ='md:flex border rounded-[8px]  py-4 md:py-0 md:border-none pl-3 md:pl-0 md:justify-between w-full';
export const CP_Comp_div2 ='md:flex text-start w-full md:w-[73%]';
export const CP_Comp_div3 ='w-full md:w-[35%] md:flex items-center';
export const CP_Comp_div4 ='notification-span text-[#2C2C2C] mb-2';
export const CP_Comp_div5 ='w-[65%] md:flex items-center';
export const CP_Comp_div6 ='body-text gap-2 py-2 flex flex-wrap items-center';
export const CP_Comp_div7 ='h-[8px] w-[36px] border rounded-[2px]';
export const CP_Comp_div8 ='md:grid md:grid-cols-3 my-2 w-full md:w-[50%] md:items-center md:justify-between';
export const CP_Comp_div9 ='flex justify-start items-center gap-2';
export const CP_Comp_div10 ='w-[36px] h-[36px] flex items-center justify-center border rounded-full overflow-hidden';
export const CP_Comp_div11 ='body-text text-[#2C2C2C]';
export const CP_Comp_div12 ='notification text-[#2C2C2C] flex items-center'
export const CP_Comp_div13 ='flex md:justify-center justify-start my-2 items-center'
export const CP_Comp_div14 = 'w-[99%] mt-3 -ml-2'
export const CP_Comp_div15 = 'checkBox-label text-[#183D27] text-start px-2 py-2 bg-[#D5F2DD] rounded-[4px]'
export const CP_CompYes = 'ml-6 text-[#1EB469] cursor-pointer'
export const CP_CompNo = 'ml-6 text-[#FF0059] cursor-pointer'

//  Company project aproved model 
export const CP_AM_MainDiv = 'fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center'
export const CP_AM_MainDiv1 = "bg-white rounded-lg border shadow-lg w-[90%] lg:w-[43.5rem]"
export const CP_AM_MainDiv2 = "flex justify-between mx-4 pt-4 items-center mb-1";
export const CP_AM_MainDiv4H4 = "h4-text text-[#2C2C2C]"
export const CP_AM_MainDiv5 = 'flex justify-between px-4 my-5 items-center'
export const CP_AM_MainDiv6 = 'w-full resize-none border h-[30vh] px-5 py-3 rounded-lg'
export const CP_AM_MainDiv7 = 'flex justify-between p-4'
export const CP_AM_MainDiv8 = 'px-6 py-3 ml-auto greenbg text-white rounded-lg'


// Company Project Detail
export const CP_Detail_MainDiv = 'w-full rounded-tr-lg text-start p-8 bg-[#E6F7EB]'
export const CP_Detail_MainDiv1 = 'body-text text-[#183D27]'
export const CP_Detail_MainDiv2 = "flex flex-wrap items-center gap-4 mt-4"
export const CP_Detail_MainDiv3 = "flex items-center px-5 py-2 redfolicbg1 text-white rounded-lg"
export const CP_Detail_MainDiv4 ="flex items-center gap-2 px-5 py-2 greenbg text-white rounded-lg"
export const CP_Detail_MainDiv5 ='mt-4 cursor-pointer gap-2 flex justify-center text-[#1EB469]'
export const CP_Detail_MainDiv6 ='m-8 mt-6 text-start'
export const CP_Detail_MainDiv7 ='notification-span text-[#8B8D98] my-4'
export const CP_Detail_MainDiv8 ='m-8 mt-4 border rounded-lg'
export const CP_Detail_MainDiv9 ='flex items-center justify-between p-4'
export const CP_Detail_MainDiv10 ='w-full border mb-6'
export const CP_Detail_MainDiv11 ='border body-text rounded-lg mx-4 p-4 bg-[#F9F9FB] text-start'
export const CP_Detail_MainDiv12 ='body-text text-start pl-4 mt-2 mb-5 text-[#80828D]'
export const CP_Detail_MainDiv13 ='grid grid-cols-1 lg:grid-cols-2 gap-2'
export const CP_Detail_MainDiv14 ='rounded-lg  border p-4'
export const CP_Detail_MainDiv15 ='flex justify-between items-center'
export const CP_Detail_MainDiv16 ='body-text text-[#80828D] text-start'
export const CP_Detail_MainDiv17 ="flex w-[5%] justir ml-3 items-center"
export const CP_Detail_MainDiv18 ="body-text text-[#2C2C2C] text-start"
export const CP_Detail_MainDivPTag ='flex cursor-pointer items-center text-[#1EB469] remove-text'
export const CP_Detail_MainDiv19 ='rounded flex justify-between items-center border p-6'
export const CP_Detail_MainDiv20 ='h6-text text-[#2C2C2C]'





export const FlexJustCenter = "flex justify-cente"


 // Company Update Milestone Model
export const CUM_Model_MainDiv = "fixed z-50 inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
export const CUM_Model_MainDiv1 = "bg-white rounded-lg border shadow-lg w-[43.5rem]"
export const CUM_Model_MainDiv2 = "flex justify-between mx-6 pt-4 items-center mb-1"
export const CUM_Model_MainDiv3 = 'flex justify-between px-6 my-5 items-center'
export const CUM_Model_MainDiv4 = 'w-full resize-none border h-[30vh] px-5 py-3 rounded-lg'
export const CUM_Model_MainDiv5 = "flex justify-between p-4"
export const CUM_Model_MainDiv6 = "px-8 py-2 ml-auto greenbg text-white rounded-lg"


// Comapny Update Due Date Model

export const CUD_Model_MainDiv = "fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center";
export const CUD_Model_MainDiv1 = "bg-white rounded-lg border shadow-lg w-[95%] lg:w-[24rem]";
export const CUD_Model_MainDiv2 = "flex justify-between mx-4 pt-4 items-center mb-1";
export const CUD_Model_MainDiv3 = "h5-text text-[#2C2C2C]";
export const CUD_Model_MainDiv4 = 'w-full border';
export const CUD_Model_MainDiv5 = 'px-4 my-5 items-center'
export const CUD_Model_MainDiv6 = 's2-text my-6 text-[#2C2C2C]'
export const CUD_Model_MainDiv7 = "px-10 py-2 ml-auto greenbg text-white rounded-lg"


























