import React from "react";
import {
  StepfourIcon,
  StepOneDoneIcon,
  StepOneIcon,
  StepRandon1,
  StepThreeDoneIcon,
  StepThreeIcon,
  StepTwoDoneIcon,
} from "../../../common/Icons";

const Timeline = (props) => {
  const {
    stepOneStatus,
    stepTwoStatus,
    stepThreeStatus,
    stepFourStatus,
    heading,
  } = props;

  return (
    <div className="m-auto z-[1000]  sticky-timeline overflow-hidden">
      <div className="sticky">
        <h3 className="mt-10 h4-text lg:h3-text secondary-text-gray">{heading}</h3>
      </div>
      <div className="md:w-[34.625rem] flex items-center w-full px-4 m-auto mt-10">
        {/* First Circle and Label */}
        <div className="flex flex-col items-center">
          <div
            className={`h-12 w-12 flex ${
              stepOneStatus ? "greenbg" : "bg-white"
            } justify-center items-center greenborder rounded-full primary-text-green border relative`}
          >
            {stepOneStatus ? (
              <StepOneDoneIcon stepTwoStatus={stepTwoStatus} />
            ) : (
              <StepOneIcon />
            )}
          </div>
          <div className="mt-2">Step 1</div>
        </div>

        {/* First Line */}
        <div
          className={`h-[2px] -mt-8 ${
            stepOneStatus || stepTwoStatus || stepThreeStatus
              ? "greenbg"
              : "bg-[#E7E8EC]"
          } flex-1`}
        ></div>

        {/* Second Circle and Label */}
        <div className="flex flex-col items-center">
          <div
            className={`h-12 w-12 flex justify-center items-center ${
              stepOneStatus && stepTwoStatus
                ? "greenbg"
                : stepOneStatus && !stepTwoStatus && !stepThreeStatus && !stepFourStatus
                ? "greenborder primary-text-green"
                : "bg-[#E7E8EC]"
            } rounded-full border-gray-200 border relative`}
          >
            {stepOneStatus && stepTwoStatus ? (
              <StepTwoDoneIcon />
            ) : (
              <StepRandon1
                stroke={
                  stepTwoStatus
                    ? "green"
                    : stepOneStatus && !stepTwoStatus && !stepThreeStatus && !stepFourStatus
                    ? "#1EB469"
                    : "#B9BBC6"
                }
                fill={
                  stepTwoStatus
                    ? "green"
                    : stepOneStatus && !stepTwoStatus && !stepThreeStatus && !stepFourStatus
                    ? "#1EB469"
                    : "#B9BBC6"
                }
              />
            )}
          </div>
          <div className="mt-2">Step 2</div>
        </div>

        {/* Second Line */}
        <div
          className={`h-[2px] -mt-8 ${
            stepOneStatus && stepTwoStatus ? "greenbg" : "bg-[#E7E8EC]"
          } flex-1`}
        ></div>

        {/* Third Circle and Label */}
        <div className="flex flex-col items-center">
          <div
            className={`h-12 w-12 flex justify-center items-center ${
              stepOneStatus && stepTwoStatus && stepThreeStatus
                ? "greenbg"
                : stepOneStatus && stepTwoStatus && !stepThreeStatus && !stepFourStatus
                ? "greenborder primary-text-green"
                : "bg-[#E7E8EC]"
            } rounded-full border-gray-200 border relative`}
          >
            {stepOneStatus && stepTwoStatus && stepThreeStatus ? (
              <StepThreeDoneIcon />
            ) : (
              <StepThreeIcon stepTwoStatus={stepTwoStatus} />
            )}
          </div>
          <div className="mt-2">Step 3</div>
        </div>

        {/* Third Line */}
        <div
          className={`h-[2px] -mt-8 ${
            stepOneStatus && stepTwoStatus && stepThreeStatus
              ? "greenbg"
              : "bg-[#E7E8EC]"
          } flex-1`}
        ></div>

        {/* Fourth Circle and Label */}
        <div className="flex flex-col items-center">
          <div
            className={`h-12 w-12 flex justify-center items-center ${
              stepOneStatus && stepTwoStatus && stepThreeStatus
                ? "bg-white greenborder border primary-text-green"
                : stepOneStatus &&
                  stepTwoStatus &&
                  stepThreeStatus &&
                  stepFourStatus
                ? "greenborder primary-text-green"
                : "bg-gray-200"
            } rounded-full border-gray-200 border relative`}
          >
            <StepfourIcon
              stepTwoStatus={stepTwoStatus}
              stepOneStatus={stepOneStatus}
              stepThreeStatus={stepThreeStatus}
              stepFourStatus={stepFourStatus}
            />
          </div>
          <div className="mt-2">Step 4</div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
