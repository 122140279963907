import { CrossIcon1 } from '../../../../common/Icons';
import Button from '../../../../common/Button';
import InputField from '../../../../common/InputField';
import { CUD_Model_MainDiv, CUD_Model_MainDiv1, CUD_Model_MainDiv2, CUD_Model_MainDiv3, CUD_Model_MainDiv4, CUD_Model_MainDiv5, CUD_Model_MainDiv6, CUD_Model_MainDiv7, CUM_Model_MainDiv5 } from './CompanyProjectStyle';
import { useParams } from 'react-router-dom';
import usePost from '../../../../Hook/usePost';
import { useEffect, useState } from 'react';
import { ToastMessage } from '../../../../common/Config';

const UpdateDueDateModel = ({setMessage,setShowNotification, isOpen, onClose, milestone, dataSet,fetchData }) => {
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { id } = useParams();
  const [date,setDate]=useState(dataSet?.dueDate)
  const { data: DueData, loading: DueDataLoading, 
    error: DueDataError, postData: postDueData } = usePost(`projects/company-milestone-due-date-change/${id}`);

  useEffect(() => {
    if (DueData) {
      // ToastMessage('success', 'Due Date Updated Successfully');
      fetchData()
      onClose();
      setMessage('Due Date Updated Successfully')
      setShowNotification(true)
      setTimeout(() =>{
        setShowNotification(false)
      },7000)
    }
    if (DueDataError) {
      ToastMessage('error', 'Something went wrong');
    }
  }, [DueData, DueDataError]);
useEffect(()=>{
  if(dataSet?.due_date){
    setDate(dataSet?.due_date)
  }
  
},[dataSet?.dueDate])
  if (!isOpen) return null;

  const handleUpdate = () => {
    postDueData({ due_date: date || dataSet?.dueDate, milestone_id: dataSet?.id }, token);
  }
const handleChange = (e) => setDate(e.target.value)
  return (
    <div className={CUD_Model_MainDiv}>
      <div className={CUD_Model_MainDiv1}>
        <div className={CUD_Model_MainDiv2}>
          <h5 className={CUD_Model_MainDiv3}>{milestone?.name || dataSet?.title}</h5>
          <button onClick={onClose} className="text-gray-500">
            <CrossIcon1 />
          </button>
        </div>
        <div className={CUD_Model_MainDiv4}></div>
        <div className={CUD_Model_MainDiv5}>
          <div>
            <p className={CUD_Model_MainDiv6}>
              Change Due Date
            </p>
          </div>
          <div>
          <InputField
  type="date"
  value={date || dataSet?.dueDate} // Use valid date format: YYYY-MM-DD
  onChange={handleChange}
  name="dueDate"
  className={CUD_Model_MainDiv7}
  mindates={dataSet?.dueDate ? new Date(dataSet.dueDate).toISOString().split('T')[0] : "2022-09-09"} // Default to a valid date if dueDate is undefined
/>

          </div>
        </div>
        <div className={CUM_Model_MainDiv5}>
          {/* <Button
            onClick={onClose}
            className="px-10 py-2 redfolicbg1 rounded-lg mr-2"
          >
            Cancel
          </Button> */}
          <Button onClick={handleUpdate} className={CUD_Model_MainDiv7}>
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpdateDueDateModel;
