import React, { useState } from 'react';
import { CrossIcon1, DeleteIcon } from '../../../../common/Icons';
import Button from '../../../../common/Button';
import { BCMM_MainDiv, BCMM_MainDiv1, BCMM_MainDiv2, BCMM_MainDiv3, BCMM_MainDiv4, BCMM_MainDiv5, BCMM_MainDiv6, BCMM_MainDiv7, BCMM_MainDiv8, BCMM_MainDiv9 } from './BankerProjectStyle';
import { useParams } from 'react-router-dom';
import { ToastMessage, apiHost } from '../../../../common/Config';

const BankerCompleteMileStoneModel = ({setMessage,setShowNotification, isOpen, onClose, milestone, fetchAgain, setFeedbackModal }) => {
  const { id } = useParams();
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const [files, setFiles] = useState([]);
  const MAX_FILES = 5; // Set your file upload limit here
  const MAX_FILE_SIZE = 15 * 1024 * 1024; // 15 MB
  const SUPPORTED_EXTENSIONS = ['pdf', 'doc', 'docx', 'csv', 'xlsx'];

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = [];

    for (let file of selectedFiles) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!SUPPORTED_EXTENSIONS.includes(fileExtension)) {
        ToastMessage('error', `File type not supported: ${file.name}`);
        continue;
      }
      if (file.size > MAX_FILE_SIZE) {
        ToastMessage('error', `File size exceeds 15 MB: ${file.name}`);
        continue;
      }
      validFiles.push(file);
    }

    if (files.length + validFiles.length > MAX_FILES) {
      ToastMessage('error', `You can only upload up to ${MAX_FILES} files.`);
      return;
    }
    
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formData = new FormData();
    formData.append("milestone_id", milestone.id);
    files.forEach((file, i) => {
      formData.append("documents", file);
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow"
    };

    fetch(`${apiHost}/projects/banker-milestone-doc-add/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(!result?.error){
          setFeedbackModal(true);
          // ToastMessage('success', "Milestone Completed Successfully");
          // fetchAgain();
          onClose();
          // setMessage('Milestone Completed Successfully')
          // setShowNotification(true)
          setTimeout(() =>{
            setShowNotification(false)
          },7000)
        }
     
      })
      .catch((error) => {
        ToastMessage('error', "Something went wrong");
      });
  };

  if (!isOpen) return null;

  return (
    <div className={BCMM_MainDiv}>
      <div className={BCMM_MainDiv1}>
        <div className={BCMM_MainDiv2}>
          <h2 className={BCMM_MainDiv3}>{milestone.name || milestone?.title || "Not Available"}</h2>
          <button onClick={onClose} className="text-gray-500">
            <CrossIcon1 />
          </button>
        </div>
        <div className='w-full border'></div>
        <div className={BCMM_MainDiv4}>
          <div>
            <p className={BCMM_MainDiv5}>Upload document(s) (Optional)</p>
            <p className='text-[13px] text-gray-500'>Only pdf, doc, docx, csv, xlsx, accepted. Max file size: 15 MB each</p>
          </div>
          <div>
            <label htmlFor="file-upload" className='text-[#1EB469] cursor-pointer'>
              + Add Document
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".pdf,.doc,.docx,.csv,.xlsx"
              className="hidden"
              multiple
              onChange={handleFileChange}
            />
          </div>
        </div>
        {files.length > 0 && (
          <div className='flex flex-col'>
            {files.map((file, index) => (
              <div key={index} className={`${BCMM_MainDiv6} flex !my-2`}>
                <span>{file.name}</span>
                <button onClick={() => handleRemoveFile(index)} className='text-gray-500'>
                  <DeleteIcon />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className='w-full border'></div>
        <div className={BCMM_MainDiv7}>
          <Button
            onClick={onClose}
            className={BCMM_MainDiv8}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpload}
            className={BCMM_MainDiv9}
          >
            Complete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BankerCompleteMileStoneModel;
