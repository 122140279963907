import React from 'react'
import Register from './Register'
import Side from "../../common/Side"
import { RegisterMainStyle } from './RegisterStyle'
 
const RegisterMain = () => {
  
  return (
    <div>
      <div className={RegisterMainStyle}>
        <div>
          <Register />
        </div>
        <div className='hidden lg:block h-full bg-[#F5F5F5]'>
          <Side />
        </div>
      </div>
    </div>
  )
}
 
export default RegisterMain