export const formStyle = "md:flex lg:flex flex-col lg-flex-col lg:space-y-6 space-y-10 lg:py-5 lg:px-[4rem] px-5 mx-auto";
export const logoFontStyle = "font-urbanist text-[23.56px] font-semibold text-[#FF0059]"
export const forgetPasswordStyle = "text-sm cursor-pointer lg:mt-0 mt-4 my-5 font-medium text-green"
export const commenInputDivSpace = "mt-6 lg:mt-4 relative lg:mb-5 w-full"
export const termAndConditionStyle = "flex mt-6  items-center justify-start"


export const ForgotmainContainerOTP = "flex justify-center items-center";
export const FlexCenter ="flex justify-center"
export const ForgotmainContainer = "mx-auto my-[4rem]";

export const ForgotContainer = "bg-green-100 flex justify-center items-center mx-auto my-auto rounded-full w-[86px] h-[86px]";
export const ForgotHeadingTwo = "mb-2 mt-[2rem] h3-text text-gray-900 ";
export const ForgotInputField = "relative mb-2  lg:w-[560px] w-full mt-10 m-auto";
export const  FogotButtonStyle="rounded-lg greenbg w-full lg:w-[560px] py-3 mt-6 px-auto font-bold text-white"
 
export const ForgotOTPmainContainer = "mx-4 my-[4rem]"
export const ForgotOTPmainContainerLogin = "m-auto my-[4rem] w-[70%]"

export const ForgotOTPmainContainerh3 = "my-6 h3-text"
export const ForgotOTPmainInputDiv ="flex justify-center items-center mt-4";
export const ForgotOTPResetPtag="primary-text-green w-[12rem] gap-2 mt-4 flex m-auto item-center cursor-pointer"
 
 
export const ForgotSetPasswordmainContainer = "mx-4  my-[4rem]";
export const ForgotSetPasswordmainContainerDiv = "mx-auto my-auto rounded-full w-[70px] h-[70px] bg-green-100 flex items-center justify-center";
export const ForgotSetPasswordmainContainerheading="mb-2 h3-text text-gray-900";


// Set Password style
export const SetPasswordStyleInputDiv ="relative mb-2 w-full lg:w-[560px] mt-10 m-auto";

export const SetPasswordStyleSVG="absolute top-2.5 hover:primary-text-green right-4"
export const SetPasswordStyleSVG2="absolute top-2.5 right-4"
export const SetPasswordStyleUL = "w-full lg:w-[560px] space-y-2 my-5 mx-auto primary-text-gray list-inside ";
export const SetPasswordStyleListSVG = "w-3.5 h-3.5 me-2";
export const SetPasswordStyleResetDiv = "relative mb-2 w-full lg:w-[560px] mt-4 m-auto";
export const SetPasswordStyleResetButton = "rounded-lg w-full lg:w-[560px] py-3 mt-6 px-auto text-white";

export const RegisterTermAndConditionStyle = "ms-2 text-sm text-start secondary-text-gray "

// Register style
export const RegisterMainStyle = "w-full lg:grid lg:grid-cols-2 ";
export const RegisterStyleMainContainer  = "w-full";
export const RegisterStyleContainer = "flex items-center justify-end w-full p-3";
export const RegisterStyleContainerDiv = "primary-text-red font-semibold";
export const RegisterStyleContainerDiv2 = "w-full lg:flex mt-5 lg:-mt-0";
export const RegisterStyleContainerDivSpan = "primary-text-green pl-2 cursor-pointer flex justify-end";
export const RegisterStyleContainerClientDiv = "grid grid-cols-2 w-[90%] lg:w-[80%] py-[8px] mx-auto";
export const RegisterStyleContainerHanldle = "border  cursor-pointer rounded-l-[8px] py-[8px]";
export const RegisterStyleContainerHanldleBanker = "border  cursor-pointer rounded-r-[8px] py-[8px]";

// Register Client Style
export const ClientStyleMainContainer = "text-start lg:pl-16 w-[90%] px-5 lg:py-5";
export const ClientStyleMainContainerDiv = "grid m-auto lg:m-auto lg:w-[80%] mt-4 mx-5  grid-cols-2";

// Register Individual Stylel




// Congratulation style
export const CongratulationStyleMainContainer = "my-[8rem]";
export const CongratulationStyleMainContainerDivImg = "w-full m-auto";
export const CongratulationStyleMainContainerDivHeading = "h4-text my-5";
export const CongratulationStyleLoginButton = "rounded-lg greenbg py-2 px-8 my-4 text-lg text-white"








