import React, { useEffect, useState } from "react";
import { CongratulationIcon, DeclineIcon, LeftArrowIcon, RetryIcon } from "./Icons";
import Navbar from "./Navbar";
import Button from "./Button";
import { 
  BankerPaymentStyeleBRLine, 
  BankerPaymentStyeleMainDiv, 
  BankerPaymentStyeleMainDiv2, 
  BankerPaymentStyeleMainDiv2Heading, 
  BankerPaymentStyeleMainDiv2Ptag, 
  BankerPaymentStyeleMainDivButton, 
  BankerPaymentStyeleMainDivHeadin, 
  BankerPaymentStyeleSubTotal, 
  BankerPaymentStyeleSubTotalDiv, 
  BankerPaymentStyeleTaxDiv, 
  BankerPaymentStyeleTransactionDate, 
  BankerPaymentStyeleTransactionID, 
  FlexJustCenter 
} from "../modules/profile/banker/BankerStyle";
import { useLocation, useNavigate } from "react-router-dom";
import { getMonthDifference } from "../Hook/validation";
import { formatPriceInput } from "./Config";

const PaymentStatus = ({ ProceedToPayCLick }) => {
  const [paymentStatus, setPaymentStatus] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  
  const HeadingIcon = paymentStatus ? <CongratulationIcon /> : <DeclineIcon />;
  const Heading = paymentStatus ? "Congratulations" : "Payment declined by bank";
  const SubHeading = location?.state?.PaymentData?.status === 'Payment verified' ? "Your payment was successful" : "Payment failed due to a technical issue with your bank.";
  const ButtonText = paymentStatus ? "Continue" : (
    <p className="flex gap-2">
      {isHovered ? <LeftArrowIcon className="mt-1" stroke="#FFFFFF" /> : <RetryIcon />} Retry Payment
    </p>
  );

  const MonthCount =1 || getMonthDifference(location?.state?.predata?.start_date, location?.state?.predata?.end_date);
  const TotalPayOut=(location?.state?.predata?.banker?.payout || 0)*MonthCount;
  const TotalPayOutGST=TotalPayOut*0.18;
  const SubTotal=TotalPayOut+TotalPayOutGST;
  const TotalTDS=TotalPayOut*0.1;
  const Total=SubTotal-TotalTDS;

  const GST = (location?.state?.predata?.banker?.payout || 0) * MonthCount * 0.18;

  const currentDate = new Date().toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' });

  useEffect(() => {
    if (location?.state?.PaymentData?.status === 'Payment verified') {
      setPaymentStatus(true);
    } else {
      setPaymentStatus(false);
    }
  }, [location]);

  // Prevent back navigation
  useEffect(() => {
    if(location?.state?.PaymentData?.status === 'Payment verified'){
 // Push a new state into the history stack
 window.history.pushState(null, document.title, window.location.href);

 const handlePopState = () => {
   window.history.pushState(null, document.title, window.location.href);
 };

 // Add an event listener to prevent back navigation
 window.addEventListener('popstate', handlePopState);

 // Clean up the event listener on component unmount
 return () => {
   window.removeEventListener('popstate', handlePopState);
 };
    }
   
  }, []);

  return (
    <div>
      <div className={BankerPaymentStyeleMainDiv}>
        <div className={FlexJustCenter}>{HeadingIcon}</div>
        <div>
          <h5 className={BankerPaymentStyeleMainDivHeadin}>{Heading}</h5>
          <p className="s3-text">{SubHeading}</p>
        </div>
        <div>
          <Button
            onClick={paymentStatus ? () => navigate("/dashboard/hirenow/congratulations", { state: location?.state}) : () => navigate(-1)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={BankerPaymentStyeleMainDivButton}
          >
            {ButtonText}
          </Button>
        </div>
      </div>

      <div className={`${BankerPaymentStyeleMainDiv2} ${paymentStatus ? "bg-[#E6F7EB]" : "bg-[#FFE8EA]"}`}>
        <div className='md:flex justify-between items-center'>
          <h6 className={BankerPaymentStyeleMainDiv2Heading}>Transaction ID</h6>
          <h6 className={BankerPaymentStyeleTransactionID}>{location?.state?.data?.razorpay_payment_id || "N/A"}</h6>
          
          
        </div>
        <div className='md:flex justify-between items-center'>
        <p className={BankerPaymentStyeleMainDiv2Ptag}>
            You can check the status of this payment with your bank (XYZ Bank) using this transaction ID
          </p>
          <p className={BankerPaymentStyeleTransactionDate}>{currentDate}</p>
         
        </div>
      </div>

      <div className={BankerPaymentStyeleSubTotal}>
        {/* <div className={BankerPaymentStyeleBRLine}></div> */}
        <div className={BankerPaymentStyeleTaxDiv}>
          <p className="s2-text">Total</p>
          <h5 className="h5-text">{`₹ ${formatPriceInput(Total.toFixed(2))}`}</h5>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;
