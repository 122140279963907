import React, { useEffect, useState } from "react";
import { PlusIcon, CloseIcon } from "./Icons";
import Button from "./Button";
import InputField from "./InputField";
import {
  modalContainer,
  modalContent,
  modalBackground,
  modalHeader,
  modalTitleText,
  modalInput,
  tagContainer,
  suggestText,
  modalFooter,
  expertiseModelStyleButton,
  expertiseModelStyleInputMainDiv,
  expertiseModelStyleInputDiv,
  ExpertiseTagStyle,
  expertiseModelStyleSaveBtn,
  AuthButtonDisabledClass,
  expertiseModelStyleSaveBtnDisable,
} from "./StyleCommon";
import { selectedStyle, unselectedStyle } from "./StyleCommon";

const ExpertiseModal = ({
  ShowAllAbove,
  data,
  storageKey,
  modalTitle,
  btnHandle,
  setRefresh,
  isAddJob = false,
  hidePlus
}) => {
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    let storedItemIds = [];
    try {
      storedItemIds = JSON.parse(sessionStorage.getItem(storageKey));
    } catch {
      storedItemIds = [];
    }
    const storedItems = data.filter((item) => storedItemIds?.includes(item.id));
    setSelectedItems(storedItems);
    setSelectedIndices(storedItems.map((item) => data.indexOf(item)));
    setInputValue(storedItems.map((item) => item.name).join(", "));

    // Check if all items are selected
    if (storedItems.length === data.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [storageKey, data]);
  useEffect(() => {
    // Update isDisabled based on the number of selectedItems
    setIsDisabled(selectedItems.length === 0);
  }, [selectedItems]);

  const handleColorStatus = (index) => {
    if (selectAll) {
      setSelectAll(false);
    }

    const newSelectedIndices = selectedIndices.includes(index)
      ? selectedIndices.filter((i) => i !== index)
      : selectedIndices.length < 50
      ? [...selectedIndices, index]
      : selectedIndices;

    const newSelectedItems = newSelectedIndices.map((i) => data[i]);

    setSelectedIndices(newSelectedIndices);
    setSelectedItems(newSelectedItems);
    sessionStorage.setItem(
      storageKey,
      JSON.stringify(newSelectedItems.map((item) => item.id))
    );
    setInputValue(newSelectedItems.map((item) => item.name).join(", "));

    if (newSelectedItems.length === data.length) {
      setSelectAll(true);
    }
  };

  const handleInputTag = () => {
    if (inputValue.trim()) {
      const newSelectedItem = { id: Date.now(), name: inputValue.trim() }; // Assuming new items need an id
      const updatedSelectedItems = [...selectedItems, newSelectedItem];
      setSelectedItems(updatedSelectedItems);
      setSelectedIndices([...selectedIndices, data.length]); // Adding a new index for the new item
      setInputValue("");
      sessionStorage.setItem(
        storageKey,
        JSON.stringify(updatedSelectedItems.map((item) => item.id))
      );
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIndices([]);
      setSelectedItems([]);
      setSelectAll(false);
    } else {
      const allIndices = data.map((_, index) => index);
      setSelectedIndices(allIndices);
      setSelectedItems(data);
      setSelectAll(true);
    }
    sessionStorage.setItem(
      storageKey,
      JSON.stringify(selectAll ? [] : data.map((item) => item.id))
    );
    setInputValue(selectAll ? "" : data.map((item) => item.name).join(", "));
  };

  const tags = data.map((item, index) => (
    <div
      key={index}
      onClick={() => handleColorStatus(index)}
      className={`${ExpertiseTagStyle} ${
        selectedIndices.includes(index) ? selectedStyle : unselectedStyle
      }`}
    >
      {item.name}
    </div>
  ));

  return (
    <>
     {!hidePlus && <PlusIcon onClick={() => setIsOpen(!isOpen)} />}
      {isOpen && (
        <div className={modalContainer}>
          <div className={modalContent}>
            <div className={modalBackground}>
              <div className={modalHeader}>
                <h3 className={modalTitleText}>{modalTitle}</h3>
                <Button
                  onClick={() => setIsOpen(false)}
                  type="button"
                  className={expertiseModelStyleButton}
                >
                  <CloseIcon />
                </Button>
              </div>
              <div className={expertiseModelStyleInputMainDiv}>
                <div className={expertiseModelStyleInputDiv}>
                  <InputField
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === ",") handleInputTag();
                    }}
                    type="text"
                    id="expertiseInput"
                    className={modalInput}
                    placeholder=" "
                    label={modalTitle}
                  />
                </div>
                <div className={tagContainer}>
                  <div>
                    <p className={suggestText}>Suggested based on your Profile</p>
                  </div>
                  <div className={ExpertiseTagStyle}>
                    {tags}
                    {ShowAllAbove && (
                      <div
                        onClick={handleSelectAll}
                        className={`${ExpertiseTagStyle} ${
                          selectAll ? selectedStyle : unselectedStyle
                        }`}
                      >
                        All of the above
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={modalFooter}>
                <Button
                  type="button"
                  disabled={isDisabled}
                  className={`${isDisabled ? expertiseModelStyleSaveBtnDisable : expertiseModelStyleSaveBtn}`}
                  onClick={() => {
                    setRefresh(Math.random());
                    !isAddJob && btnHandle(selectedItems);
                    setIsOpen(false);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExpertiseModal;
