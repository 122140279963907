import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../common/Navbar";
import Timeline from "./Timeline";
import BottomBar from "../../../common/BottomBar";
import Checkbox from "../../../common/Checkbox";
import {
  BankerPlanStyleContainerKeyAndCore,
  BankerPlanStyleContainerTitle,
  BankerPlanStyleContainerTitleGap,
  BankerPlanStyleEditText,
  BankerPlanStyleReviewDetail,
  BankerStepfourContainer,
  BankerStepfourContainerDiv,
  FlexTextJustend,
  flexmy10,
} from "./BankerStyle";
import { termAndConditionStyle } from "../../register/RegisterStyle";
import RegisterTermsConditions from "../../register/RegisterTermsConditions";
import { GreenPencilIcon } from "../../../common/Icons";
import { uploadProfileData } from "../../../Hook/formDataBanker";
import useGetData from "../../../Hook/useGetData";
import { formatPriceInput, formatDateToddmmyr } from "../../../common/Config";

const StepFourbanker = () => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState("");
  const checkboxRef = useRef(null);
  const { data: getBankerData, loading: loadingServices, error: errorServices } = useGetData('onboarding/banker/get-bankers',token);
  const BankersData = JSON.parse(sessionStorage.getItem("bankerData"));
  const BankersSalaryData = JSON.parse(sessionStorage.getItem("bankerLastDrawnSalary"));
  // const BankersLocationData = JSON.parse(sessionStorage.getItem("bankerPreferredLocation"));
  const BankersSelectedData = JSON.parse(sessionStorage.getItem("selectedItems"));
  const CoreExpertise = JSON.parse(sessionStorage.getItem("CoreExpertise"));
  // const bankerConsultancyMode = JSON.parse(sessionStorage.getItem("bankerConsultancyMode"));
  const stateCity=JSON.parse(sessionStorage.getItem("stateCity"));
  // const hireMode = JSON.parse(sessionStorage.getItem("bankerhireMode"));
  var hireMode = {};
  var bankerConsultancyMode = {};
  const { data } = useGetData('master-data/service/index');
  const { data: coreData } = useGetData('master-data/core-expertise/index');
 
  const { data: getStateData, loading: loadingState, error: errorState } = useGetData('master-data/state/get',"token");
  const { data: getCityData, loading: loadingCity, error: errorCity } = useGetData(`master-data/city/get`,"token");
  const { data: getDesignationData } = useGetData(`master-data/designation/get`,"token");
  const { data: getPaymentData,loading: loadingPayment, error: errorPayment } = useGetData('onboarding/get-payment-status', token);
  const bankersSelectedDataIds = BankersSelectedData?.map((item) => item.id || item) || [];
  const coreExpertiseIds = CoreExpertise?.map((item) => item.id || item) || [];
  const BankerState=getStateData?.data?.filter((item) => item?.id==stateCity?.state)[0]?.name || "";
  const BankerCity=getCityData?.data?.filter((item) => item?.id===stateCity?.city)[0]?.city || "";
  const BankerState1=getStateData?.data?.filter((item) => item?.id==getBankerData?.data?.profile?.state)[0]?.name || "";
  const BankerCity1=getCityData?.data?.filter((item) => item?.id===getBankerData?.data?.profile?.city)[0]?.city || "";
  const keyOfServices = data?.data?.filter((item) => bankersSelectedDataIds?.includes(item.id))?.map((item) => item.name) || [];
  const coreExpertise = coreData?.data?.filter((item) => coreExpertiseIds?.includes(item.id))?.map((item) => item.name) || [];

  try {
    hireMode = JSON.parse(sessionStorage.getItem("bankerhireMode"))
  }

  catch {
    hireMode = {};
  }

  try {
    bankerConsultancyMode = JSON.parse(sessionStorage.getItem("consultancyMode"))
  }

  catch {
    bankerConsultancyMode = {};
  }
  const Designation= BankersData?.designation || getBankerData?.data?.profile?.designation || "Not Available";
  const finalDesignation=getDesignationData?.data?.filter((item) => item?.id==Designation)[0]?.name || "";
  const company= BankersData?.company || getBankerData?.data?.profile?.company || "Not Available";
  const dateOfRetirement= BankersData?.retirementDate || getBankerData?.data?.profile?.retirementDate || "Not Available";
  const bankingExperience= BankersData?.bankingExperience || getBankerData?.data?.profile?.banking_experience || "Not Available";
  const ReviewDetails = [
    { id: 1, name: "Name", value: BankersData?.name || getBankerData?.data?.user?.name || "Not Available", subValue: [] },
    { id: 2, name: "DOB", value: formatDateToddmmyr(BankersData?.dob) || formatDateToddmmyr(getBankerData?.data?.profile?.dob) ||"Not Available", subValue: [] },
    { id: 3, name: "Email ID", 
    value: BankersData?.email || getBankerData?.data?.user?.email || "Not Available", subValue: [] },
    { id: 4, name: "Phone Number",
     value: `+91-${BankersData?.mobile}` ||  `+91-${getBankerData?.data?.user?.phone_number}` || "Not Available",
     subValue: [] },
    // { id: 5, name: "Current City",
    //  value: BankersData?.city || getBankerData?.data?.profile?.current_city || "Not Available", subValue: [] },
     { id: 17, name: "Current Address",
     value: BankersData?.current_address || getBankerData?.data?.profile?.current_address || "Not Available", subValue: [] },
     { id: 18, name: "Permanent Address",
     value: BankersData?.permanent_address || getBankerData?.data?.profile?.permanent_address || "Not Available", subValue: [] },
    { id: 6, name: "Experience",
     value:company || "Not Available",
     subValue: [`${finalDesignation==='Others' ? BankersData?.custom_designation :finalDesignation}`,dateOfRetirement,`${bankingExperience} years`]
    },
  
     
    { id: 7, name: "Education", value: BankersData?.education==1 ? "Graduation" : BankersData?.education==2 ? "Post Graduation":"Custom", subValue: [] },
    { id: 8, name: "Key Area of service", value: "", subValue: keyOfServices || ["Debt fund raise"] },
    { id: 9, name: "Core Expertise", value: "", subValue: coreExpertise || ["MSME", "Stress asset", "International banking and Forex"] },
    { id: 10, name: "Last drawn gross consolidated salary", value: `INR ${formatPriceInput(BankersSalaryData.toString()) || formatPriceInput(getBankerData?.data?.profile?.last_salary?.toString()) || "Not Available"}`, subValue: [] },
    { id: 11, name: "Salary slip", value: getBankerData?.data?.profile?.salary_slip || "Not Available", subValue: [] },
    { id: 12, name: "Availability for hire", value: hireMode?.name || "Not Available", subValue: [] },
    { id: 13, name: "Mode of Consultancy", value: bankerConsultancyMode?.name || "Not Available", subValue: [] },
    // { id: 14, name: "Preferred Locations", value: BankersLocationData || getBankerData?.data?.profile?.preferred_location || "Not Available", subValue: [] },
    { id: 15, name: "State", value: BankerState || BankerState1 || "Not Available", subValue: [] },
    { id: 16, name: "City", value: BankerCity || BankerCity1 || "Not Available", subValue: [] },
  ];

  const handleCheckboxChange = (e) => {
    setTermsAccepted(e.target.checked);
    setError(""); // Clear error when checkbox state changes
  };

  const handleNextClick = () => {
    if (!termsAccepted) {
      setError("You must accept the terms to continue.");
      checkboxRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    uploadProfileData(4);
    sessionStorage.removeItem('bankerLastDrawnSalary')
    sessionStorage.removeItem('bankerhireMode')
    sessionStorage.removeItem('userEmail')
    sessionStorage.removeItem('consultancyMode')
    sessionStorage.removeItem('selectedItems')
    sessionStorage.removeItem('bankerConsultancyMode')
    sessionStorage.removeItem('bankerPreferredLocation')
    sessionStorage.removeItem('bankerData')
    sessionStorage.removeItem('userLoginInfo')
    sessionStorage.removeItem('CoreExpertise')
    // navigate("/profile/banker/plan");
    if(getPaymentData?.payment_status===false || getPaymentData?.payment_status===null || getPaymentData?.payment_status===undefined || getPaymentData?.payment_status===""){
      navigate('/profile/banker/plan')
    }else{
      navigate("/dashboard/banker");
    }
  };
  const handleSubmit = () => {
    if (!termsAccepted) {
      setError("You must accept the terms to continue.");
      return;
    }
    // uploadProfileData(4);
  };


  return (
    <div>
      
        <Navbar BackBtn={true} hemBurgerIcon={false} buybtn={getPaymentData?.payment_status ? null : "Buy Package"} />
      
      
      {/* <div className="lg:w-[34.625rem] md:w-[34.625rem] w-full m-auto"> */}

        <Timeline
          stepOneStatus={true}
          stepTwoStatus={true}
          stepThreeStatus={true}
          stepFourStatus={false}
          heading="Complete your profile"
        />
      {/* </div> */}


      <div className={BankerStepfourContainer}>
        <div className={BankerStepfourContainerDiv}>
          <div className="">
            <h5 className={BankerPlanStyleReviewDetail}>Review Details</h5>
          </div>
          <div className="primary-text-green cursor-pointer">
            <div className={FlexTextJustend} onClick={()=>{
              sessionStorage.setItem("back",1)
              navigate("/profile/banker/complete-your-profile")}}>
              <GreenPencilIcon className="mt-0.4 mr-2"/>
              <p className={BankerPlanStyleEditText}>Edit</p>
            </div>
          </div>
        </div>

        {ReviewDetails.map((item, index) => (
          <div className={flexmy10} key={index}>
            <div className="w-[30%]">
              <p className={BankerPlanStyleContainerTitle}>{item?.name}</p>
            </div>
            <div className={BankerPlanStyleContainerTitleGap}>
              <p className="text-[#2C2C2C]">-</p>
            </div>
            <div className={`w-[70%] ${item?.id=== 11 ? "w-[10rem] lg:w-1/2 overflow-hidden text-wrap" : "" }  ${item?.id === 8 || item?.id === 9 ? "gap-3 flex flex-wrap " : ""} text-start`}>
              {item?.id === 8 || item?.id === 9 ? item?.subValue.map((item, index) => (
                <div key={index} className={`${BankerPlanStyleContainerKeyAndCore} `}>
                  {item}
                </div>
              )) : (
                <>
                  <div>
                    <h6 className="h6-text">{item?.value}</h6>
                  </div>
                  {item.subValue?.map((subItem, subIndex) => (
                    <div key={subIndex}>
                      <p className="s2-text text-[#2C2C2C]">{subItem}</p>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        ))}

        <hr />

        <div className={`${termAndConditionStyle}` } ref={checkboxRef}>
          <Checkbox
            name="termsAccepted"
            type="checkbox"
            checked={termsAccepted}
            onChange={handleCheckboxChange}
          />
          <RegisterTermsConditions />
        </div>

        <div className="mb-[5rem] text-start ml-8">
        {error && <span className="primary-text-red text-sm">{error}</span>}

        </div>
      </div>

      <div className="h-[5rem] lg:hidden md:hidden block ">

      </div>

      <div>
        <BottomBar
          OnCLickBackbtn={() => navigate("/profile/banker/add-profile-picture")}
          OnCLickNextBtn={handleNextClick}
          btnName="Submit"
          BackBtn="Back"
          SaveBtn="Save"
          handleSubmit={handleSubmit}
          step="4"
        />
      </div>
    </div>
  );
};

export default StepFourbanker;
