// Banker Plan Style

export const myfiverem = "my-[5rem]";
export const BankerPlanStyleH3 ="tracking-wider font-urbanist text-3xl font-extrabold";
export const BankerPlanStyleContainer =" lg:w-[680px] sm:w-[680px] px-[1rem] md:lg:w-[680px] w-full px-4 md:grid grid-cols-2 lg:grid grid-cols-2 gap-4 bg-gray-100 relative rounded-3xl  m-auto mb-[2rem]";
export const BankerPlanStyleContainerActiveDiv ="relative rounded-3xl mt-5 transition-transform duration-300";
export const customContainerStyle = "mt-6 pl-2";
export const customPriceStyle ="h3-text flex item-center secondary-text-gray text-start";
export const customMandateStyle = "text-start secondary-text-gray font-lato ";
export const containerStyle = "pl-2";
export const paragraphStyle ="text-start secondary-text-gray font-lato text-[14px] mt-10 leading-4";
export const listContainerStyle = "my-4 ml-2";
export const listItemContainerStyle = "flex mt-3";
export const iconContainerStyle ="bg-[#e3f2ec] mt-1 w-6 h-6 rounded-full flex justify-center items-center";
export const listItemTextStyle = "ml-2 secondary-text-gray font-lato text-start";
export const listItemParagraphStyle = "mt-1";
export const BanerPlanbuttonStyles = `
  redfolicbg
  text-[18px]
  w-[80%] 
  rounded-lg 
  px-5 
  py-2.5 
  mt-5
  

`;

export const mostPopularBadge = `
  rounded-3xl 
  text-[15px] 
  text-white 
  w-[60%] 
  py-1 
  font-lato 
  px-2 
  bg-[#FF0059]
`;

export const priceHeading = `
  font-bold 
  secondary-text-gray 
  text-start 
  text-[32px] 
  font-urbanist
`;

export const featuresList = `
  text-start 
  secondary-text-gray 
  font-lato 
  mt-10 
  text-[14px] 
  leading-4
`;

export const featureItem = "flex items-center mt-4 "

export const featureIcon = `
  bg-[#e3f2ec] 
   
  w-6 
  h-6 
  rounded-full 
  flex 
  justify-center 
  items-center
`;

export const featureText = `
  ml-2 
  secondary-text-gray 
  font-lato 
  text-start
`;


export const BankerPlanChooseBtn = "greenbg text-white text-[18px] w-[80%] rounded-lg px-5 py-2.5 me-2 mb-2 "
export const BankerPlanChooseBtn1 = "redfolicbg text-white text-[18px] w-[80%] rounded-lg px-5 py-2.5 me-2 mb-2 "




export const BankerPlanStyleContainerDiv = "relative rounded-3xl overflow-hidden pb-4  transition-transform duration-300";

export const BankerPlanStyleContainerActive  = "translate-y-[-70px] shadow-2xl bg-white"
export const BankerPlanStyleContainerSVG1 = "absolute top-0 left-44 opacity-10";
export const BankerStepfourContainer = "lg:w-[34.625rem] md:w-[34.625rem] px-4 w-full m-auto";

export const BankerTagStyle = "border border-[#2C2C2C] secondary-text-gray font-lato font-normal flex cursor-pointer rounded-full py-2 my-2 px-4 text-[15px] mr-2 mb-2"
export const BankerStepfourContainerDiv  = "my-10 grid grid-cols-2";
export const BankerPlanStyleReviewDetail = "h5-text text-start";
export const FlexTextJustend ="flex text-end justify-end";
export const BankerPlanStyleEditText = "font-lato primary-text-green";

export const flexmy10 = "flex my-10"

export const BankerPlanStyleContainerTitle = "s2-text text-start text-[#2C2C2C]"
export const BankerPlanStyleContainerTitleGap = "flex w-[5%] justify-center items-start"
export const BankerPlanStyleContainerKeyAndCore = "border font-lato text-[#333333] border-black h-auto py-2 text-sm px-5 rounded-3xl";

export const BankerPlanStyleCheckboxDiv = "flex items-center mb-[6rem] my-[2rem]";



 export const BankerstepOneMainDiv = "lg:w-[34.625rem] md:w-[34.625rem] w-full m-auto px-4 lg:px-0"
 export const BankerstepOneMainDivTop = "mt-[4rem] my-4";
 export const marginTopBottom6 = "my-6"
 export const s1TextGray = "s1-text secondary-text-gray"
 export const BankerStepOneAddMore = "ml-auto primary-text-green";
 export const BankerStepOneEducationDiv = "my-4 mb-[15rem]";
 export const BankerStepOneEducationSubDiv = "text-start mt-[1rem]"
 export const BankerStepOneEducationPTag = "s1-text mt-4";
 export const BankerStepOneSelectTag ="w-full mt-[1rem] m-auto";
 export const BankerStepOneCustomBox = "mb-[8rem] mt-4"
 export const BankerStepTwoCustomBox = "mb- m-auto mb-[10rem]"



 export const BankerStep2MainDiv = "lg:m-auto md:m-auto lg:w-[34.625rem] md:w-[34.625rem]  mx-2";
 export const BankerStep2MainDiHRLine = "hr-bgColor w-full lg:w-[34.625rem] md:w-[34.625rem] mt-10 m-auto";
 export const BankerStep2div ="grid grid-cols-2 mt-6 mb-2 mx-auto"
 export const BankerStep2divSaveIem ="text-5xl flex justify-end primary-text-gray";
 export const FlexJustEnd = 'flex justify-end'
 export const BankerStep2divKeyServ="flex m-auto h-auto py-2 px-2 flex-wrap"
 export const BankerStep2divCoreError="flex m-auto h-auto pt-1 px-2 flex-wrap"
 export const BankerStep2MainDiHRLine2 = "hr-bgColor my-4 m-auto"
 export const RelMT6mAuto = "relative mt-6 m-auto"
 export const BankerStep2MainDivMode ="lg:grid md:grid md:grid-cols-2  lg:grid-cols-2 py-6 mx-auto"

export const BankerPaymentStyeleMainDiv = "md:w-[40.625rem] px-3  lg:w-[40.625rem] w-full mt-[2rem] h-[19.875rem] m-auto"
export const BankerPaymentStyeleMainDiv1 = "w-full md:w-[40.625rem] md:h-[19.875rem] pt-3 m-auto"

export const FlexJustCenter = "flex justify-center"
export const BankerPaymentStyeleMainDivHeadin = "h5-text secondary-text-gray my-4"
export const BankerPaymentStyeleMainDivButton = "redfolicbg1 px-6 py-3 my-6 rounded-lg"
export const BankerPaymentStyeleMainDiv2 = "md:w-[40.625rem] lg:w-[40.625rem] w-full p-4 rounded-[8px] m-auto justify-between";
export const BankerPaymentStyeleMainDiv2Heading ="h6-text text-start secondary-text-gray";
export const BankerPaymentStyeleMainDiv2Ptag ="body-text text-start w-[70%] mt-2 text-[#80828D]";
export const BankerPaymentStyeleTransactionID = "h6-text text-end secondary-text-gray";
export const BankerPaymentStyeleTransactionDate = "body-text mt-1 md:text-end text-start lg:text-end text-[#80828D]";
export const BankerPaymentStyeleSubTotal ="md:w-[40.625rem] lg:w-[40.625rem] w-full p-4 mt-4 mb-[5rem] rounded-[8px] bg-[#F9F9FB] m-auto";
export const BankerPaymentStyeleSubTotal1 ="w-full p-4 mt-4 mb-[5rem] rounded-[8px] bg-[#E6F7EB] m-auto";

export const BankerPaymentStyeleSubTotalDiv= "flex w-full justify-between";
export const BankerPaymentStyeleTaxDiv= "flex w-full my-4 justify-between";
export const BankerPaymentStyeleBRLine= "bg-[#E0E1E6] h-[1px] w-full"
//  export const BankerPlanStyleCheckboxDiv = "flex items-center mb-[6rem] my-[2rem]"
//  export const BankerPlanStyleCheckboxDiv = "flex items-center mb-[6rem] my-[2rem]"
//  export const BankerPlanStyleCheckboxDiv = "flex items-center mb-[6rem] my-[2rem]"
//  export const BankerPlanStyleCheckboxDiv = "flex items-center mb-[6rem] my-[2rem]"
