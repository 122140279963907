import React from 'react'
import Navbar from '../../../../common/Navbar'
import BankerSideNavbar from '../../../../common/BankerSideNavbar'
import Checkbox from '../../../../common/Checkbox'
import Button from '../../../../common/Button'
import { useNavigate } from 'react-router-dom'

const CancelSubscriptionOne = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar hemBurgerIcon={true} BackBtn={true} ProfileAndNotification={true} BankerNavbar={true}/>

      <div className='flex'>
        <div className='w-0 md:w-[7%]'>
            <BankerSideNavbar />
        </div>
        <div className='px-4 md:px-8 py-4 w-full md:w-[93%]'>
           <h4 className='h4-text text-start px-4 text-[#2C2C2C]'>Cancel Subscription</h4>
            <div className='w-full bg-[#F9F9FB] mt-8 rounded-lg border px-8 py-6'>
                <h6 className='h6-text text-[#2C2C2C]'>
                What is your reason for cancelling Intercom? 
                </h6>

                <div className=''>
                {[1,2,3,4,5,6,7,8,9].map((item , index) => {
                  return <div className='my-5 text-start'>
                     <Checkbox type="checkbox" label={index===0?"Select all that apply" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce efficitur."}/>
                  </div>
                })}
                </div>

                <div className='text-start flex gap-4' >
                  <Button className="px-4 py-1 greenbg text-white rounded-lg">
                  Return to billing
                  </Button>

                  <Button onClick={()=>navigate("/dashboard/banker/cancel-subscription-switch")}  className="px-4 py-1 redfolicbg text-white rounded-lg">
                  Continue
                  </Button>
                </div>

            </div>
        </div>

      </div>
    </div>
  )
}

export default CancelSubscriptionOne
