export const JobListingMainContainer = "flex mt-[3rem]"
export const WFullFlex = "flex w-full ";
export const WFullFlexAdd = "flex w-full h-auto";

export const JobListingContainer = "sm:w-[30%] hidden lg:block p-5 light-gray-background";
export const JobListingContainerDiv = "flex px-3 mt-10 mb-5";
export const JobListingContainerHeader = "secondary-text-gray ml-2 h5-text";
export const JobListingContainerClearAll = "primary-text-green text-[16px]";
export const HRLineJobListing ="h-[1px] w-[95%] m-auto bg-[#E0E1E6]";
export const JobListingInputDiv = "px-3 my-6";
export const JobListingDropDownFilter1 = "grid grid-cols-1 gap-6 text-[#80828D] my-4"
export const JobListingRightDiv = "md:px-10 mt-10 flex justify-between";
export const JobListingRightDivPostButton = "font-urbanist greenbg rounded-lg text-white w-[9.75rem] h-[3rem] px-4 gap-1 border-t border-l border-opacity-0";
export const JobListingMenuBar = "md:px-10 md:grid md:grid-cols-2"
export const JobListingMenuBarDiv = "flex gap-8 mt-8";
export const JobListingMenuBarDivAvticeTab = "flex justify-between  text-[14px] gap-1 cursor-pointer";
export const JobListingCountStyle = "w-[1.5rem] h-[1.5rem] rounded-full bg-[#D8D9E0]";
export const FlexJustBtItemCenter = "flex justify-between items-center";
export const FlexJustitemCenter = "flex justify-center items-center cursor-pointer"
export const JobListingSelectDiv = "flex items-center space-x-2";
export const JobListingHRLine1 = "[bg-#E0E1E6] border mx-10";
export const JobListingBannerDiv = "mx-10 px-3 rounded-[8px] border grid grid-cols-2 mt-10 py-5"
export const JobListingBannerDivChild = "flex items-center space-x-8";
export const JobListingBannerDivIcon = "flex items-center space-x-1 cursor-pointer";
export const JobListingCardDiv = "flex primary-text-gray items-center w-[15rem]";

export const JobListingViewMainDiv = "flex "
export const JobListingViewDiv = " flex justify-between md:mx-10 lg:mx-10 mx-3 mt-10";
export const FlexGap4 = "flex gap-4";
export const JobListingViewEditJobButton = "flex items-center rounded-lg px-4 gap-2 border redfolicbg";
export const JobListingViewCloseJobButton="redfolicbg1 flex  items-center rounded-lg px-4 gap-2 "
export const JobListingViewDivMainContainer= "text-start mx-3 md:mx-8 lg:mx-10 gap-4 mt-10 flex"
export const JobListingViewDivMainHeading="border rounded-[24.5px] bg-[#D5F2DD] px-4 py-1"

export const JobListingViewDiscriptionDiv = "mx-3 md:mx-10 lg:mx-10 mt-10 text-start gap-4";
export const JobListingViewKeyDiv = "mx-3 md:mx-8 lg:mx-10 mt-10 text-start"
export const JobListingViewKeyDivHeading ="secondary-text-gray h6-text mb-4"
export const JobListingViewToggleDiv = "flex mx-8 gap-8 mt-8"
export const JobListingViewToggleDiv1 = "flex justify-between md:justify-start lg:justify-start gap-2 md:gap-6 lg:gap-8"

export const JobListingViewToggleDivCounter ="px-1.5 rounded-full bg-[#D8D9E0] flex items-center justify-center"

export const HireNowStyleMainDiv = "lg:w-[40.75rem] md:w-[40.75rem] w-full mb-[5rem] lg:px-0 md:px-0 px-4 m-auto";
export const HireNowStyleMainTitle = "s2-text mt-6";
export const HireNowStyleCheckbox = "grid grid-cols-1 md:grid md:grid-cols-3 lg:grid lg:grid-cols-3"
export const FlexJustItemGap4 = "flex justify-center items-center mt-2 gap-4";
export const HireNowStyleMileStone = "flex justify-between mt-12";
export const HireNowStyleMileStoneDiv = " lg:flex  relative justify-between mt-6 lg:mt-2";
export const FlexGap3 = "flex gap-3";
export const HireNowStyleMileButton = "body-text w-full primary-text-green cursor-pointer";
export const HireNowStyleCrossBtn = " md:absolute mt-4 lg:mt-0 md:top-7 -right-0 md:-right-7 md:absolute lg:top-7 lg:-right-7";
export const HireNowStyleUploadDoc = "flex w-full justify-between my-10";

export const HireCongStleDiv = "w-full md:w-[40.625rem] m-auto bg-green-100 p-4 rounded-[12px] flex justify-between";
export const HireCongStleDivPTag = "body-text text-[#80828D] my-1";
export const HireCongStleDivErrorDiv = "w-full md:w-[40.625rem] m-auto bg-[#FFE8EA] p-4 my-6 rounded-[12px]";
export const HireCongStleDivErrorDivPTag = "body-text text-[#FF0059] text-start";
export const HireCongStleDivExperienceOpted = "border  p-4 my-6 rounded-[12px] mt-4 w-full  m-auto";

export const ProceedPayStyleMainDiv = "grid grid-cols-1 md:grid md:grid-cols-2 gap-5 px-3 mt-4 md:px-10";
export const ProceedPayStyleMainDiv1 = "grid grid-cols-1 md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 gap-5 my-10 px-4 lg:px-10";

export const S2TextGreen ="s2-text primary-text-green";
export const ProceedPayStyleReviewDiv = "border border-[#E0E1E6] px-4 my-6 rounded-[10px]";
export const ProceedPayStyleReviewDivPTag = "s3-text text-start text-[#80828D]";
export const ProceedPayStyleReview = "border border-[#E0E1E6] px-4 my-6 rounded-[10px]";

export const ProceedPayStyleReviewDiv2 = "border py-4 border-[#E0E1E6] px-4 my-4 flex justify-between rounded-[10px]";
export const ProceedPayStyleReviewButton = "w-full md:w-[240px] greenbg text-white h-[48px] rounded-lg";