import React, { useEffect, useState } from "react";
import Navbar from "../../../../common/Navbar";
import SideNavbar from "../../../../common/SideNavbar";
import Button from "../../../../common/Button";
import {
  BankerPaymentStyeleMainDiv1,
  BankerPaymentStyeleMainDivButton,
  BankerPaymentStyeleMainDivHeadin,
  FlexJustCenter,
} from "../../../profile/banker/BankerStyle";
import {
  CongratulationIcon,
  DownloadIcon,
  LeftArrowIcon,
  RetryIcon,
} from "../../../../common/Icons";
import ProfileCard from "../../../../common/ProfileCard";
import RoundedBox from "../../../../common/RoundedBox";
import { HireCongStleDiv, HireCongStleDivErrorDiv, HireCongStleDivErrorDivPTag, HireCongStleDivExperienceOpted, HireCongStleDivPTag } from "./DashboardJobStyle";
import { useLocation, useParams } from "react-router-dom";
import usePost from "../../../../Hook/usePost";
import { ToastMessage, apiHost, formatPriceInput } from "../../../../common/Config";
import { getMonthDifference, handleDownloadAll } from "../../../../Hook/validation";
import EditScheduledMeeting from "./EditScheduledMeeting";
import useGetData from "../../../../Hook/useGetData";

const HireNowCongratulation = () => {
  const {id}=useParams()
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [data,setData]=useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const { data: MeetingData,loading:MeetingDataLoading, error: MeetingDataError, postData: postMeetingData } = usePost('hiring/company-get-banker-hire');
  const { data: DocData,loading:DocDataLoading, error: DocDataError, postData: postDocData } = usePost('hiring/get-document');
  const { data: getData,loading: loadingPayment, error: errorData,refetch:fetchData } = useGetData(`hiring/meetings-company/${id}`, token);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const location=useLocation();

  const ButtonText = paymentStatus ? (
    "Continue"
  ) : (
    <p className="flex gap-2">
      {isHovered ? (
        <LeftArrowIcon className="mt-1" stroke="#FFFFFF" />
      ) : (
        <DownloadIcon />
      )}{" "}
      Onboarding Documents
    </p>
  );

  const ProfileDetails = [
    {
        id:1,
        title:"Phone Number",
        titleValue:data[0]?.bkUser?.phone_number || "Not available"
    },
    {
        id:2,
        title:"Email ID",
        titleValue:data[0]?.bkUser?.email || "Not available"
    },
    {
        id:3,
        title:"Period of engagement",
        titleValue:data[0]?.master_tenure==1 ? "short term" : "long term" || "Short term, 20/05/2024 to 19/08/2024"
    }
  ]

  const ServiceOpted = data[0]?.key_service?.map((item)=>item?.name) || [
  ]
  useEffect(() => {
    postMeetingData({     
      job_id : location?.state?.predata?.banker?.id || getData?.data?.banker?.id,
      banker_id : location?.state?.predata?.banker?.bkUser || getData?.data?.banker?.bkUser,
  },token);
  }, [getData])
  useEffect(()=>{
if(MeetingData?.data){
  let obj={
    ...MeetingData?.data?.banker,
    job_hire_id:MeetingData?.data?.job_hire_id,
    bkUser:MeetingData?.data?.banker?.user
  }
  setData([obj])
}
  },[MeetingData])
  const MonthCount =1 || getMonthDifference(location?.state?.predata?.start_date, location?.state?.predata?.end_date);
  const TotalPayOut=(location?.state?.predata?.banker?.payout || 0)*MonthCount;
  const TotalPayOutGST=TotalPayOut*0.18;
  const SubTotal=TotalPayOut+TotalPayOutGST;
  const TotalTDS=TotalPayOut*0.1;
  const Total=SubTotal-TotalTDS;
  useEffect(()=>{
    if(DocData){
      ToastMessage("success","Documents fetched successfully")
      handleDownloadAll(DocData?.data?.map((item)=>`${apiHost}/${item?.document}`))
    }
    if(DocDataError){
      ToastMessage("error","No Documents found")
    }
  },[DocData,DocDataError])
  return (
    <div>
      <Navbar hemBurgerIcon={true} CompanyNavbar={true} ProfileAndNotification={true} />
      <div className="flex">
        <div className="w-0 md:w-[9%]">
          <SideNavbar />
        </div>
        <div className="m-auto px-3 w-full md:w-[91%]">
          <div className={BankerPaymentStyeleMainDiv1}>
            <div className={FlexJustCenter}>
              <CongratulationIcon />
            </div>
            <div>
              <h5 className={BankerPaymentStyeleMainDivHeadin}>
                Congratulations
              </h5>
              <p className="s3-text">
                {`You have successfully engaged with ${location.state?.predata?.banker?.user?.name || "Not Available"}.`}
              </p>
            </div>
            <div>
              <Button
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={BankerPaymentStyeleMainDivButton}
                onClick={()=>{
                  postDocData({job_hire_id:location?.state?.predata?.id || getData?.data?.job_hire},token)
                }}
              >
                {ButtonText}
              </Button>
            </div>
          </div>

          <div className={HireCongStleDiv}>
            <div>
                <h6 className="h6-text">You Paid</h6>
            </div>
            <div>
                <h6 className="h6-text">
                {`₹ ${formatPriceInput((Total|| 0).toFixed(2))}`}
                </h6>
                <p className={HireCongStleDivPTag}> (incl. of taxes)</p>

            </div>
        </div>

        <div className={HireCongStleDivErrorDiv}>
            <p className={HireCongStleDivErrorDivPTag}>Banker will acknowledge the meeting in next 24 hours once the meeting is scheduled</p>
        </div>

        <div className="mt-4 w-full md:w-[40.625rem] m-auto">
          {data?.length>0 ? data?.map((item,index)=>(
 <ProfileCard  ProfileDetails={ProfileDetails} ExperinceDataUI={false} saveIconUI={false} CoreUI={false} nameTitle="Sumit Mathur" subTitle="View Profile" ScheduleBtn="Schedule a Meeting"    data={item}/>
          )) :<></>}
       

        </div>

        <div className='border md:w-[40.625rem] p-4 my-6 rounded-[12px] mt-4 w-full  m-auto'>
          <h6 className="h6-text mb-4 ">
          Services opted for
          </h6>
          <RoundedBox data = {ServiceOpted}/>
        </div>

        </div>
        

        
      </div>
      <div className="block md:h-[3rem] h-[8rem]">

</div>
{id && <EditScheduledMeeting  isOpen={isOpen} setIsOpen={setIsOpen}/> }
    </div>
  );
};

export default HireNowCongratulation;
