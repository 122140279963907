import React from 'react'
import Navbar from '../../../../common/Navbar'
import PaymentStatus from '../../../../common/PaymentStatus'
import SideNavbar from '../../../../common/SideNavbar'

const HireNowPaymentStatus = () => {
  return (
    <div className=''>


        <Navbar hemBurgerIcon={true} CompanyNavbar={true} ProfileAndNotification={true}/>

        <div className='flex'>
          <div className='w-0 md:w-[9%]'>
          <SideNavbar/>

          </div>
          <div className='md:m-auto px-3 w-full md:w-[91%] '>
          <PaymentStatus />

          </div>

        </div>
      
    </div>
  )
}

export default HireNowPaymentStatus
