import React, { useCallback, useEffect, useRef, useState } from "react";
import Navbar from "../../../../common/Navbar";
import BankerSideNavbar from "../../../../common/BankerSideNavbar";
import Button from "../../../../common/Button";
import {
  JobListingViewToggleDiv1,
  JobListingViewToggleDivCounter,
} from "../../company/job/DashboardJobStyle";
import InputField from "../../../../common/InputField";
import { DashboardRoundedBoxMainDiv } from "../../company/dashboard/DashboardStyle";
import RoundedBox from "../../../../common/RoundedBox";
import { RoundedBoxItemStyle2 } from "../../../../common/StyleCommon";
import BankerJobCard from "./BankerJobCard";
import FilterCard from "../../../../common/FilterCard";
import { useNavigate } from "react-router-dom";
import useGetData from "../../../../Hook/useGetData";
import { FilterIcon, LoaderIcon } from "../../../../common/Icons";
import usePost from "../../../../Hook/usePost";
import BottomPagination from "../../../../common/BottomPagination";
import NudgeNotification from "../../../../common/NudgeNotification";
import { toPascalCase } from "../../../../Hook/validation";
import { BankerDashboardStyle1, BankerDashboardStyle10, BankerDashboardStyle11, BankerDashboardStyle12, BankerDashboardStyle13, BankerDashboardStyle14, BankerDashboardStyle15, BankerDashboardStyle16, BankerDashboardStyle17, BankerDashboardStyle18, BankerDashboardStyle19, BankerDashboardStyle2, BankerDashboardStyle20, BankerDashboardStyle21, BankerDashboardStyle22, BankerDashboardStyle23, BankerDashboardStyle24, BankerDashboardStyle25, BankerDashboardStyle3, BankerDashboardStyle4, BankerDashboardStyle5, BankerDashboardStyle6, BankerDashboardStyle7, BankerDashboardStyle8, BankerDashboardStyle9 } from "./BankersDasboardStyle";
const BankersDashboard = () => {
  const [activeTab, setActiveTab] = useState("Search Jobs");
  const [BankAdd, setBankAdd] = useState(false);
  const [checkedItems, setCheckedItems] = useState({
    shortTerm: false,
    mediumTerm: false,
    longTerm: false,
    virtual: false,
    "In-person": false,
    both: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSaved, setCurrentPageSaved] = useState(1);
  const [currentPageInterested, setCurrentPageInterested] = useState(1);
  const [ActiveFilter, setActiveFilter] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [jobList, setJobList] = useState([]);
  const [savedjobList, setSavedjobList] = useState([]);
  const [interestedjobList, setInterestedjobList] = useState([]);
  const [searchedProfiles, setSearchedProfiles] = useState(0);
  const [shortlisedCount, setShortlistedCount] = useState(0);
  const [interestedCount, setInterestedCount] = useState(0);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [recentSearch, setRecentSearch] = useState([]);
  const [componentMounted, setComponentMounted] = useState(false);
  let itemsPerPage = 5;
  const navigate = useNavigate();
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";

  const {
    data: dataBanker,
    error: errorBanker,
    loading: loadingBanker,
    refetch: refetchBankerDetails,
  } = useGetData("profile/banker/basic-details", token);

  const { data, loading, error, refetch } = useGetData(
    "banker/job-search",
    token
  );
  const { data: getPaymentData } = useGetData("onboarding/get-payment-status", token);
  const {
    data: filteredData,
    error: errorFilter,
    loading: filterSearchLoading,
    postData: applyFilterSearch,
  } = usePost("banker/job-filter", token);

  const totalPages = Math.ceil(jobList?.length / itemsPerPage);
  const totalPagesSavedJobs = Math.ceil(savedjobList?.length / itemsPerPage);
  const totalPagesInterestedJobs = Math.ceil(
    interestedjobList?.length / itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageChangeSavedJobs = (page) => {
    setCurrentPageSaved(page);
  };

  const handlePageChangeInterestedJobs = (page) => {
    setCurrentPageInterested(page);
  };

  const displayData = jobList?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const displayDataSavedJobs = savedjobList?.slice(
    (currentPageSaved - 1) * itemsPerPage,
    currentPageSaved * itemsPerPage
  );

  const displayDataInterestedJobs = interestedjobList?.slice(
    (currentPageInterested - 1) * itemsPerPage,
    currentPageInterested * itemsPerPage
  );
  const timeoutRef = useRef(null);

  const debounceSearch = useCallback((value) => {
    // Create a reference to store the timeout ID
  
    // Clear any previous timeout before setting a new one
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  
    // Set a new timeout to call postSearchData after the user stops typing
    timeoutRef.current = setTimeout(() => {
      let payload = { search_query: value };
      setIsFilterApplied(true);
      applyFilterSearch(payload, token);
    }, 2000); // Adjust the delay as needed
  
    // Clear timeout on unmount
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [applyFilterSearch, token]);
  const handleSearch = (e) => {
    // setSearchQuery(e.target.value);
    const value = e.target.value;
  
    // Call debounce function with the new value
    debounceSearch(value);
  };

  const handleSearchKey = (e) => {
    if (e.key === "Enter") {
      // Perform the search or filtering action here
      let payload = { search_query: searchQuery };
      setIsFilterApplied(true);
      applyFilterSearch(payload, token);
    }
  };

  useEffect(() => {
    // Trigger search/filter when searchQuery or checkedItems change
    let tenure = [];
    let consultancy_mode = [];

    if (checkedItems?.shortTerm) tenure.push(1);
    if (checkedItems?.mediumTerm) tenure.push(2);
    if (checkedItems?.longTerm) tenure.push(3);
    if (checkedItems?.virtual) consultancy_mode.push(1);
    if (checkedItems?.["In-person"]) consultancy_mode.push(2);
    if (checkedItems?.both) consultancy_mode.push(3);

    let payload = {
      consultancy_mode: consultancy_mode,
      tenure: tenure,
      search_query: searchQuery,
    };

    if (
      searchQuery === "" &&
      tenure.length === 0 &&
      consultancy_mode.length === 0
    ) {
      setIsFilterApplied(false);
    } else {
      setIsFilterApplied(true);
      applyFilterSearch(payload, token);
    }

    // Reset pagination on new search/filter
    setCurrentPage(1);
    setCurrentPageSaved(1);
    setCurrentPageInterested(1);
  }, [searchQuery, checkedItems]);

  useEffect(() => {
    let jobdata = isFilterApplied ? filteredData?.data : data?.data;
    let dataa = [],
      dataSavedJobs = [],
      dataInterestedJobs = [];

    setSearchedProfiles(jobdata?.length || 0);

    let savedCount = 0,
      intrestedCount = 0;

    for (let i = 0; i < jobdata?.length; i++) {
      let obj = { jobId: jobdata[i]["id"] };

      if ("BkJobTitleAndCustom" in jobdata[i]) {
        let bkjobtitleobj = jobdata[i]["BkJobTitleAndCustom"];
        obj["jobTitle"] = bkjobtitleobj["custom_title"] ?? "Not Available";
      }

      if ("consultancy_mode" in jobdata[i]) {
        let tenureobj = jobdata[i]["consultancy_mode"];
        obj["consultancy_mode"] = tenureobj["name"] ?? "Not Available";
      }

      if ("tenure" in jobdata[i]) {
        let tenureobj = jobdata[i]["tenure"];
        obj["tenure"] = tenureobj["name"] ?? "Not Available";
      }

      let date = jobdata[i]["created_at"]?.split("T")[0] ?? "";
      obj["postOn"] = date;
      obj["postedBy"] = "Not Available";
      obj["location"] =
        jobdata[i]["city"] && jobdata[i]["state"]
          ? `${jobdata[i]["city"].city},${toPascalCase(jobdata[i]["state"].name)}`
          : "Not Available";

      obj["job_id"] = jobdata[i]["job_id"] ?? "";
      obj["job_description"] = jobdata[i]["job_description"] ?? "";
      obj["save_job"] = jobdata[i]["save_job"] ?? false;
      obj["share_interest"] = jobdata[i]["share_interest"] ?? false;

      obj["interested_job"] = jobdata[i]["share_interest"] ?? false;
      dataa.push(obj);

      if (jobdata[i]["save_job"]) {
        savedCount += 1;
        dataSavedJobs.push(obj);
      }

      if (jobdata[i]["share_interest"]) {
        intrestedCount += 1;
        dataInterestedJobs.push(obj);
      }
    }

    setJobList(dataa);
    if (!isFilterApplied) {
      setSavedjobList(dataSavedJobs);
      setInterestedjobList(dataInterestedJobs);
      setShortlistedCount(savedCount);
      setInterestedCount(intrestedCount);
    }

    setRecentSearch(filteredData?.recent_searches || []);

    sessionStorage.setItem("jobList", JSON.stringify(dataa));
  }, [data, filteredData, isFilterApplied]);

  useEffect(() => {
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (
      dataBanker?.data?.bank &&
      Object.keys(dataBanker?.data?.bank).length >= 4
    ) {
      setBankAdd(true);
    } else {
      setBankAdd(false);
    }
  }, [dataBanker]);
const ReloadCount=sessionStorage.getItem("reloadCount");
  return (
    <div className="custom-scrollbar">
      <Navbar
        hemBurgerIcon={true}
        BankerNavbar={true}
        ProfileAndNotification={true}
      />

      <div className={BankerDashboardStyle1}>
        <div className={BankerDashboardStyle2}>
          <BankerSideNavbar />
        </div>
        <div className={BankerDashboardStyle3}>
          <div className="sticky-welcomeBK">
            <h4 className={BankerDashboardStyle4}>
              Welcome to <span className="text-[#FF0059]">BankersKlub</span>
            </h4>
          </div>
         {ReloadCount<5 && <NudgeNotification />}

          {
            // !BankAdd && <div className="bg-green-100 w-full my-10 py-3 px-5 rounded-[8px] flex flex-col lg:flex lg:flex text-start lg:justify-between lg:justify-between items-center">
            !BankAdd && (
              <div className={BankerDashboardStyle5}>
                <div className={BankerDashboardStyle6}>
                  <p className={BankerDashboardStyle7}>
                    Add your bank details to receive payments.
                  </p>
                </div>
                <div className="">
                  <Button
                    onClick={() => navigate("/dashboard/banker/bank-detail")}
                    className={BankerDashboardStyle8}
                  >
                    +Add Bank Details
                  </Button>
                </div>
              </div>
            )
          }

          <div className="">
           <div className="sticky-tabs">
           <div className={`  ${JobListingViewToggleDiv1}`}>
               <div
                onClick={() => setActiveTab("Search Jobs")}
                className="cursor-pointer"
              >
                <div className={BankerDashboardStyle9}>
                  <div>Search Jobs</div>
                  <div className={JobListingViewToggleDivCounter}>
                    {searchedProfiles || 0}
                  </div>
                </div>
                <div
                  className={`${BankerDashboardStyle10} ${
                    activeTab === "Search Jobs" ? "greenbg" : "bg-transparent"
                  }`}
                ></div>
              </div>
              <div
                onClick={() => setActiveTab("Saved Jobs")}
                className="cursor-pointer"
              >
                <div className={BankerDashboardStyle11}>
                  <div>Saved Jobs</div>
                  <div className={JobListingViewToggleDivCounter}>
                    {shortlisedCount || 0}
                  </div>
                </div>
                <div
                  className={`${BankerDashboardStyle10} ${
                    activeTab === "Saved Jobs" ? "greenbg" : "bg-transparent"
                  }`}
                ></div>
              </div>
              <div
                onClick={() => setActiveTab("Interested Jobs")}
                className="cursor-pointer"
              >
                <div className={BankerDashboardStyle12}>
                  <div>Interested Jobs</div>
                  <div className={JobListingViewToggleDivCounter}>
                    {interestedCount || 0}
                  </div>
                </div>
                <div
                  className={`${BankerDashboardStyle10} ${
                    activeTab === "Interested Jobs"
                      ? "greenbg"
                      : "bg-transparent"
                  }`}
                ></div>
              </div>
            </div>

            <div className={BankerDashboardStyle13}></div>
           </div>

            <div className=" ">
              {activeTab === "Search Jobs" && (
                <div className="w-full">
                 <div className={BankerDashboardStyle14}>
                 <InputField
                    SearchIcon={true}
                    id="search"
                    className='truncate pr-8'
                    onChange={(e) => handleSearch(e)}
                    onkeyDown={(e) => handleSearchKey(e)}
                    placeholder="Search Job By Title, Expertise, Designation, Location"
                  />

                  <div className={BankerDashboardStyle15}>
                    <p className={BankerDashboardStyle16}>
                      Recent Searches :
                    </p>

                    <RoundedBox
                      mainClassName={DashboardRoundedBoxMainDiv}
                      className={RoundedBoxItemStyle2}
                      data={recentSearch}
                    />
                  </div>
                  <h6 className={BankerDashboardStyle17}>Suggested Jobs</h6>

                 </div>

                  <div className={BankerDashboardStyle18}>
                    <div>
                    </div>
                    <div
                      onClick={() => setActiveFilter(!ActiveFilter)}
                      className={BankerDashboardStyle19}
                    >
                      <FilterIcon />
                    </div>
                  </div>

                  {ActiveFilter && (
                    <div className={BankerDashboardStyle20}>
                      <FilterCard
                        setCheckedItems={setCheckedItems}
                        checkedItems={checkedItems}
                        fetch={refetch}
                        setFilterState={setIsFilterApplied}
                      />
                    </div>
                  )}

                  <div className={BankerDashboardStyle21}>
                    <div className={BankerDashboardStyle22}>
                      {loading || filterSearchLoading ? (
                        <LoaderIcon />
                      ) : (
                        displayData?.map((item, index) => {
                          if (item?.jobTitle !== "Not Available") {
                            return (
                              <BankerJobCard
                                jobId={item.jobId}
                                jobTitle={item.jobTitle}
                                postOn={item.postOn}
                                postedBy={item.postedBy}
                                location={item.location}
                                custJobId={item.job_id}
                                consultancy_mode={item.consultancy_mode}
                                tenure={item.tenure}
                                jobDescription={item.job_description}
                                savedJob={item.save_job}
                                interestedJob={item.interested_job}
                                refetchListing={refetch}
                                setCheckedItems={setCheckedItems}
                                setFilterApplied={setIsFilterApplied}
                                setComponentMounted={setComponentMounted}
                                handlePageChange={handlePageChange}
                                item={item}
                                currentPage={currentPage}
                              />
                            );
                          }
                        })
                      )}
                      <BottomPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>

                    <div className={BankerDashboardStyle23}>
                      <FilterCard
                        setCheckedItems={setCheckedItems}
                        checkedItems={checkedItems}
                        fetch={refetch}
                        setFilterState={setIsFilterApplied}
                      />
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "Saved Jobs" && (
                <div className={BankerDashboardStyle24}>
                  {loading || filterSearchLoading ? (
                    <LoaderIcon />
                  ) : (
                    displayDataSavedJobs?.map((item, index) => {
                      if (item?.save_job) {
                        return (
                          <BankerJobCard
                            jobId={item.jobId}
                            jobTitle={item.jobTitle}
                            postOn={item.postOn}
                            postedBy={item.postedBy}
                            location={item.location}
                            custJobId={item.job_id}
                            consultancy_mode={item.consultancy_mode}
                            tenure={item.tenure}
                            jobDescription={item.job_description}
                            savedJob={item.save_job}
                            interestedJob={item.interested_job}
                            refetchListing={refetch}
                            setCheckedItems={setCheckedItems}
                            setFilterApplied={setIsFilterApplied}
                            setComponentMounted={setComponentMounted}
                            handlePageChange={handlePageChangeSavedJobs}
                          />
                        );
                      }
                    })
                  )}
                  <BottomPagination
                    currentPage={currentPageSaved}
                    totalPages={totalPagesSavedJobs}
                    onPageChange={handlePageChangeSavedJobs}
                  />
                </div>
              )}

              {activeTab === "Interested Jobs" && (
                <div className={BankerDashboardStyle24}>
                  {loading || filterSearchLoading ? (
                    <LoaderIcon />
                  ) : (
                    displayDataInterestedJobs?.map((item, index) => {
                      if (item?.share_interest) {
                        return (
                          <BankerJobCard
                            jobId={item.jobId}
                            jobTitle={item.jobTitle}
                            postOn={item.postOn}
                            postedBy={item.postedBy}
                            location={item.location}
                            custJobId={item.job_id}
                            consultancy_mode={item.consultancy_mode}
                            tenure={item.tenure}
                            jobDescription={item.job_description}
                            savedJob={item.save_job}
                            interestedJob={item.interested_job}
                            refetchListing={refetch}
                            setCheckedItems={setCheckedItems}
                            setFilterApplied={setIsFilterApplied}
                            setComponentMounted={setComponentMounted}
                            handlePageChange={handlePageChangeInterestedJobs}
                          />
                        );
                      }
                    })
                  )}
                  <BottomPagination
                    currentPage={currentPageInterested}
                    totalPages={totalPagesInterestedJobs}
                    onPageChange={handlePageChangeInterestedJobs}
                  />
                </div>
              )}
            </div>

            <div className={BankerDashboardStyle25}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankersDashboard;
