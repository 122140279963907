import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import RegisterMain from "../modules/register/RegisterMain";
import SetPassword from "../modules/register/SetPassword";
import Congratulations from "../modules/register/Congratulations";
import Login from "../modules/login/Login";
import Forgot from "../modules/login/Forgot";
import Otp from "../modules/login/LoginOTP";
import Steponebanker from "../modules/profile/banker/StepOneBanker";
import StepTwobanker from "../modules/profile/banker/StepTwoBanker";
import StepThreebanker from "../modules/profile/banker/StepThreeBanker";
import StepFourbanker from "../modules/profile/banker/StepFourBanker";
import BankerPlan from "../modules/profile/banker/BankerPlan";
import Done from "../modules/login/Done";
import CompanyDashboard from "../modules/dashboard/company/dashboard/CompanyDashboard";
import AddJobStepOne from "../modules/dashboard/company/dashboard/addjob/AddJobStepOne";
import AddJobStepTwo from "../modules/dashboard/company/dashboard/addjob/AddJobStepTwo";
import DashboardSearch from "../modules/dashboard/company/search/DashboardSearch";
import DashboardFilter from "../modules/dashboard/company/search/DashboardFilter";
import ProfileView from "../modules/dashboard/company/search/ProfileView";
import JobListing from "../modules/dashboard/company/job/JobListing";
import JobListingView from "../modules/dashboard/company/job/JobListingView";
import LoginOTP from "../modules/login/LoginOTP";
import ForgotOTP from "../modules/login/ForgotOTP";
import SavedBankers from "../modules/dashboard/company/savedbankers/SavedBankers";
import PaymentStatus from "../common/PaymentStatus";
import HireNow from "../modules/dashboard/company/job/HireNow";
import ProceedToPay from "../modules/dashboard/company/job/ProceedToPay";
import HireNowPaymentStatus from "../modules/dashboard/company/job/HireNowPaymentStatus";
import BankerPaymentStatus from "../modules/profile/banker/BankerPaymentStatus";
import HireNowCongratulation from "../modules/dashboard/company/job/HireNowCongratulation";
import BankersDashboard from "../modules/dashboard/banker/dashboard/BankersDashboard";
import BankerJobView from "../modules/dashboard/banker/dashboard/BankerJobView";
import BankerBankDetails from "../modules/dashboard/banker/dashboard/BankerBankDetails";
import BankerLogOut from "../modules/profile/banker/BankerLogOut";
import Congrat from "../modules/dashboard/banker/dashboard/MeetingConfirmation";
import BankerPlanDetail from "../common/BankerPlanDetail";
import MeetingConfirmation from "../modules/dashboard/banker/dashboard/MeetingConfirmation";
import MyProfile from "../modules/profile/company/CompanyMyProfile";
import BankerMyProfile from "../modules/profile/banker/BankerMyProfile";
import CompanyMyProfile from "../modules/profile/company/CompanyMyProfile";
import BankerProject from "../modules/dashboard/banker/project/BankerProject";
import BankerManageMandate from "../modules/dashboard/banker/project/BankerManageMandate";
import BankerProjectDetails from "../modules/dashboard/banker/project/BankerProjectDetails";
import CompanyProject from "../modules/dashboard/company/project/CompanyProject";
import CompanyProjectDetail from "../modules/dashboard/company/project/CompanyProjectDetail";
import BankerReferrals from "../modules/dashboard/banker/referrals/BankerReferrals";
import CompanyReferrals from "../modules/dashboard/company/referrals/CompanyReferrals";
import BankerManagePlan from "../modules/profile/banker/BankerManagePlan";
// import CompanyCalendar from "../modules/dashboard/company/calender/CompanyCalender";
import BankerCalendar from "../modules/dashboard/banker/calendar/BankerCalender";
import CompanyCalendar from "../modules/dashboard/company/calendar/CompanyCalender";
import CancelSubscriptionOne from "../modules/profile/banker/subscription/CancelSubscriptionOne";
import CancelSubscriptionFour from "../modules/profile/banker/subscription/CancelSubscriptionFour";
import CancelSubscriptionTwo from "../modules/profile/banker/subscription/CancelSubscriptionTwo";
import CancelSubscriptionThree from "../modules/profile/banker/subscription/CancelSubscriptionThree";
import CompanyNotification from "../modules/profile/company/CompanyNotification";
import BankerProfileView from "../modules/dashboard/company/project/BankerProfileView";
import BankerNotification from "../modules/profile/banker/BankerNotification";
import ResetPassword from "../modules/register/ResetPassword";
import BankerResetPassword from "../modules/profile/banker/BankerResetPassword";
import CompanyResetPassword from "../modules/profile/company/CompanyResetPassword";
import useGetData from "../Hook/useGetData";
import { LogOut } from "../Hook/validation";

const AllRoutes = () => {
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const navigate=useNavigate()
  const userType = JSON.parse(sessionStorage.getItem("user_type"));
  const { data, error, loading, refetch } = useGetData("profile/banker/basic-details", token);
  const { data: dataCompany, error: errorCompany, loading: loadingCompany, refetch: refetchCompanyDetails } = useGetData("profile/company/basic-details", token);
  const companyType = JSON.parse(sessionStorage.getItem("company"));
  const otp_verified = JSON.parse(sessionStorage.getItem("otp_verified"));
  const bankerUser = userType == 1 && companyType == 0;
  const companyUser = userType == 0 && companyType == 1;
  const individualUser = userType == 0 && companyType == 0;
  const [state, setState] = useState(false);
  useEffect(()=>{
    setState(true)
    setTimeout(()=>{
      setState(!true)
    },2000)
  },[])
  useEffect(() => {
    if((error?.response?.status===401 || errorCompany?.response?.status===401) && token){
      LogOut()
    }
  },[error,errorCompany])
  return (
    <>
 

    <Routes>
      {!token ? (
        <>
        
          <Route path="/" element={<Navigate to="/login" replace={true} />} />
          <Route path="/register" element={<RegisterMain />} />
          <Route
            path="/set-password"
            element={<SetPassword isBankerSignup={false} />}
          />
          <Route
            path="/banker-set-password"
            element={<SetPassword isBankerSignup={true} />}
          />

          <Route path="/congratulation" element={<Congratulations />} />
          <Route default path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/forgot-otp" element={<ForgotOTP />} />
          <Route path="/login-otp" element={<LoginOTP />} />
          <Route path="/done" element={<Done />} />
          <Route path="*" element={<Navigate to="/login" replace={true} />} />
        </>
      ) : (
        <>
          <Route path="/dashboard/banker/reset-password" element={<BankerResetPassword />} />
          <Route path="/dashboard/company/reset-password" element={<CompanyResetPassword />} />

          
          <Route path="/profile/banker/complete-your-profile" element={<Steponebanker />} />
          <Route path="/profile/banker/additional-hiring-details" element={<StepTwobanker />} />
          <Route
            path="/profile/banker/add-profile-picture"
            element={<StepThreebanker />}
          />
          <Route path="/profile/banker/profile-preview" element={<StepFourbanker />} />
          <Route path="/profile/banker/plan" element={<BankerPlan />} />
          <Route
            path="/profile/banker/payment/status"
            element={<BankerPaymentStatus />}
          />
          <Route
            path="/profile/banker/plan-detail"
            element={<BankerPlanDetail />}
          />
          <Route path="/dashboard/banker" element={<BankersDashboard />} />
          <Route path="/dashboard/banker/manage-plan" element={<BankerManagePlan />} />

          <Route path="/dashboard/banker/project" element={<BankerProject />} />
          <Route path="/dashboard/company/project" element={<CompanyProject />} />

          <Route path="/dashboard/banker/calendar" element={<BankerCalendar />} />
          <Route path="/dashboard/company/calendar" element={<CompanyCalendar />} />



          <Route path="/dashboard/banker/project-detail/:id" element={<BankerProjectDetails />} />
          <Route path="/dashboard/company/project-detail/:id" element={<CompanyProjectDetail />} />
          <Route path="/dashboard/company/notification" element={<CompanyNotification />} />
          <Route path="/dashboard/banker/notification" element={<BankerNotification />} />

          

          <Route path="/dashboard/company/banker-profile" element={<BankerProfileView />} />


          <Route path="/dashboard/banker/project/manage-mandate" element={<BankerManageMandate />} />
          <Route path="/dashboard/banker/referrals" element={<BankerReferrals/>} />
          <Route path="/dashboard/company/referrals" element={<CompanyReferrals/>} />




          <Route
            path="/dashboard/banker/job/detail/:id"
            element={<BankerJobView />}
          />
          <Route
            path="/dashboard/banker/bank-detail"
            element={<BankerBankDetails />}
          />
          <Route path="/logout" element={<BankerLogOut />} />
          <Route path="/dashboard/metting-confirmation/:id" element={<MeetingConfirmation />} />
          <Route path="/dashboard/company/my-profile" element={<CompanyMyProfile />} />
          <Route path="/dashboard/banker/my-profile" element={<BankerMyProfile />} />
          <Route path="/dashboard/banker/cancel-subscription-reason" element={<CancelSubscriptionOne />} />
          <Route path="/dashboard/banker/cancel-subscription-switch" element={<CancelSubscriptionTwo />} />
          <Route path="/dashboard/banker/cancel-subscription-about" element={<CancelSubscriptionThree />} />
          <Route path="/dashboard/banker/cancel-subscription" element={<CancelSubscriptionFour />} />



          <Route path="/dashboard/company" element={<CompanyDashboard />} />
          <Route
            path="/dashboard/company/addjob/stepone"
            element={<AddJobStepOne />}
          />
              <Route
            path="/dashboard/company/addjob/stepone/:id"
            element={<AddJobStepOne />}
          />
          <Route
            path="/dashboard/company/addjob/steptwo"
            element={<AddJobStepTwo />}
          />
          <Route
            path="/dashboard/company/search"
            element={<DashboardSearch />}
          />
          <Route
            path="/dashboard/company/filter"
            element={<DashboardFilter />}
          />
          <Route path="/dashboard/company/profile/:profileId/:JobId" element={<ProfileView />} />
          <Route path="/dashboard/company/profile/:profileId" element={<ProfileView />} />
          <Route path="/dashboard/joblisting" element={<JobListing />} />
          <Route
            path="/dashboard/joblisting/view/:id"
            element={<JobListingView />}
          />
          <Route path="/dashboard/savedbankers" element={<SavedBankers />} />
          <Route path="/dashboard/hirenow/:id/:bkId" element={<HireNow />} />
          <Route
            path="/dashboard/hirenow/proceedtopay/:id/:bkId"
            element={<ProceedToPay />}
          />
           <Route
            path="/dashboard/hirenow/proceedtopay/:id/:bkId/:hireId"
            element={<ProceedToPay />}
          />
          <Route
            path="/dashboard/hirenow/paymentstatus"
            element={<HireNowPaymentStatus />}
          />
          <Route
            path="/dashboard/hirenow/congratulations"
            element={<HireNowCongratulation />}
          />
             <Route
            path="/dashboard/hirenow/congratulations/:id"
            element={<HireNowCongratulation />}
          />
          {bankerUser && (
            <Route
              path="*"
              element={<Navigate to="/profile/banker/complete-your-profile" replace={true} />}
            />
          )}

          {(companyUser || individualUser) && (
            <Route
              path="*"
              element={<Navigate to="/dashboard/company" replace={true} />}
            />
          )}
        </>
      )}
    </Routes>
    </>
  );
};

export default AllRoutes;
