import React, { useEffect, useRef, useState } from 'react';
import { AvilableStatuIcon, ChangePasswordIcon, FeedBackIcon, HelpAndSupportIcon, LogOutIcon1, ManagePlanIcon, ProfileIcon1 } from '../../../common/Icons';
import { useNavigate } from 'react-router-dom';
import { apiHost } from '../../../common/Config';
import useGetData from '../../../Hook/useGetData';
import FeedbackModal from '../../../common/FeedbackModal';

const BnakerProfileOption = ({ onClick, data }) => {
    const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
    const [isOpen, setIsOpen] = useState(false);
    const [Notification, setNotification] = useState(true);
    const [hideShow, setHideShow] = useState(false);
    const [imageData, setImageData] = useState("https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg");
    const [initials, setInitials] = useState("AK");
    const [feedBackModal,setFeedbackModal]=useState(false);
    const navigate = useNavigate();
    const { data: getPaymentData,loading: loadingPayment, error: errorPayment } = useGetData('onboarding/get-payment-status', token);

    const popoverRef = useRef(null);
    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        if (data?.user?.name) {
            try {
                const [firstname, lastname] = data.user.name.split(" ");
                const newInitials = `${firstname?.charAt(0) || 'T'}${lastname?.charAt(0) || 'A'}`;
                setInitials(newInitials);
            } catch (error) {
                setInitials('TA'); // Fallback initials
            }
        } else {
            setInitials('TA'); // Fallback initials when name is not available
        }
        
        if(data?.profile_image){
            setImageData(`${apiHost}/${data?.profile_image}`);
        }
    }, [data])

    return (
        <div className='relative' ref={popoverRef}>
            <div
                onClick={toggleModal}
                className={`w-[40px] h-[40px] ${isOpen ? 'border-[#1EB469]' : ''} border-2 cursor-pointer rounded-full overflow-hidden ${!imageData ? "bg-[#1EB469]" : ""}`}>
                {imageData && <img className='w-full imgClassHeight' src={imageData} alt='' />}
            </div>
            {isOpen && (
                <div className=' md:w-[360px]  absolute right-0 top-14 rounded-lg border border-1 bg-white'>
                    <div className='flex p-6 '>
                        {/* <div className='w-[65px] h-[65px] rounded-full bg-green-600 text-white flex items-center justify-center'>{initials}</div>
                         */}
                         <div className={`w-[65px] relative h-[65px] flex justify-center items-center overflow-hidden rounded-full ${!imageData?"bg-[#1EB469]" :""}`}>
                  {!imageData?<h2 className='h2-text text-white'>{initials}</h2>: <img className='w-full imgClassHeight' src={imageData} alt=''/>}
                </div>
                        <div className='ml-4 mt-3'>
                            <h6 className='h6-text'>{data?.user?.name || "Not Available"}</h6>
                            <p className='ml-1 body-text'>{data?.user?.email || data?.company?.user?.email || "Not Available"}</p>
                        </div>
                    </div>

                    {/* <div className='flex items-center hover:bg-gray-100 px-6 py-4 cursor-pointer'>
                        <div>
                            <AvilableStatuIcon />
                        </div>
                        <div className='flex justify-between w-full items-center'>
                            <p className='body-text ml-2'>Availability Status</p>
                            <div onClick={() => setHideShow(!hideShow)} className={`${hideShow ? "bg-gray-300" : "bg-[#1EB469]"} w-[80px] h-[32px] flex items-center rounded-[16px] relative`}>
                                <p className={`body-text ${hideShow ? "text-black ml-9 py-2" : "text-white ml-2 py-2"}`}>{hideShow ? "Hide" : "Show"}</p>
                                <div className={`absolute  ${!hideShow ? "top-1 right-1.5" : "top-1 left-1.5"} w-[24px] h-[24px] rounded-full bg-white`}>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div onClick={() => navigate("/dashboard/banker/my-profile")} className='flex items-center hover:bg-gray-100 px-6 py-4 cursor-pointer'>
                        <div>
                            <ProfileIcon1 />
                        </div>
                        <div>
                            <p className='body-text ml-2'>My Profile</p>
                        </div>
                    </div>
                   {/* {getPaymentData?.payment_status && <div onClick={()=>navigate("/dashboard/banker/manage-plan")} className='flex items-center hover:bg-gray-100 px-6 py-4 cursor-pointer'>
                        <div>
                            <ManagePlanIcon />
                        </div>
                        <div>
                            <p className='body-text ml-2'>Manage Plan</p>
                        </div>
                    </div>} */}

                    <div className='flex items-center hover:bg-gray-100 px-6 py-4 cursor-pointer' onClick={()=>navigate("/dashboard/banker/reset-password")}>
                        <div>
                            <ChangePasswordIcon />
                        </div>
                        <div onClick={()=>navigate("/dashboard/banker/reset-password")}>
                            <p className='body-text ml-2'>Change Password</p>
                        </div>
                    </div>

                    {/* <div className='flex items-center hover:bg-gray-100 px-6 py-4 cursor-pointer'>
                        <div>
                            <HelpAndSupportIcon />
                        </div>
                        <div>
                            <p className='body-text ml-2'>Help & Support</p>
                        </div>
                    </div> */}

                    <div className='flex items-center hover:bg-gray-100 px-6 py-4 cursor-pointer' onClick={()=>setFeedbackModal(!feedBackModal)}>
                        <div>
                            <FeedBackIcon />
                        </div>
                        <div>
                            <p className='body-text ml-2'>Feedback</p>
                        </div>
                    </div>

                    <div className=" bg-[#E0E1E6] border mt-2 mx-6"></div>

                    <div onClick={onClick} className='flex hover:bg-gray-100 cursor-pointer items-center px-6 py-4 my-2'>
                        <div>
                            <LogOutIcon1 />
                        </div>
                        <div>
                            <p className='body-text ml-2'>Log Out</p>
                        </div>
                    </div>
                </div>
            )}
            {feedBackModal && <FeedbackModal type="Banker" onClose={()=>{setFeedbackModal(!feedBackModal);}} 
            //  User={getProjectData?.data?.company_user}
             />}
        </div>
    );
};

export default BnakerProfileOption;
