import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../common/Navbar";
import {
  ForgotSetPasswordmainContainer,
  ForgotSetPasswordmainContainerDiv,
  ForgotSetPasswordmainContainerheading,
  SetPasswordStyleInputDiv,
  SetPasswordStyleListSVG,
  SetPasswordStyleResetButton,
  SetPasswordStyleResetDiv,
  SetPasswordStyleSVG,
  SetPasswordStyleSVG2,
  SetPasswordStyleUL,
} from "../register/RegisterStyle";
import { InputFieldClass, flexitemCentre, setPassButtonClass } from "../../common/StyleCommon";
import InputField from "../../common/InputField";
import Button from "../../common/Button";
import {
  CloseEyePasswordIcon,
  LockIcon,
  OpenEyePasswordIcon,
  ResetPasswordRightTick,
} from "../../common/Icons";
import usePost from "../../Hook/usePost";
import {
  ToastMessage,
  hasCapitalLetter,
  hasLetter,
  hasNumberOrSpecialChar,
} from "../../common/Config";
import { Loader } from "../../common/Loader";

const SetPassword = ({ isBankerSignup = false }) => {
  const navigate = useNavigate();
  const ForgotData=sessionStorage.getItem("forgotData");
  const ForgotDataObject=JSON.parse(ForgotData);
  const postUrl=ForgotDataObject ? "onboarding/forgot-password" :"onboarding/sign-up"
  const postUrlCompany = "onboarding/company/save";
  const postUrlIndividual = "onboarding/individual/save";
  const { data, error, loading, postData } = usePost(postUrl);
  const { data: data2, error: error2, loading: loading2, postData: postData2 } = usePost(postUrlCompany);
  const { data: data3, error: error3, loading: loading3, postData: postData3 } = usePost(postUrlIndividual);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [passwordLength, setPasswordLength] = useState(0);
  const [showPassword, setShowPassword] = useState(true);
  const [showRePassword, setShowRePassword] = useState(true);
  const [errors, setErrors] = useState({});
  
  const handleInputChange = (e) => {
    const newPassword = e.target.value;
    let errs = {...errors};

    if ("password" in errs)  delete errs["password"]
    
    setErrors(errs);
    setPassword(newPassword);
    setPasswordLength(newPassword.length);
  };

  const handleRePasswordChange = (e) => {
    let errs = {...errors};

    if ("rePassword" in errs)  delete errs["rePassword"];

    setErrors(errs);
    setRePassword(e.target.value);
  };

  // const hasLetter = /[a-zA-Z]/.test(password);
  // const hasNumberOrSpecialChar = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
  const isContinueDisabled =
    password === "" ||
    rePassword === "" ||
    password !== rePassword ||
    !hasCapitalLetter(password) ||
    !hasLetter(password) ||
    !hasNumberOrSpecialChar(password) ||
    passwordLength < 8;
    const handleConfirm = () => {
      const bankerData = JSON.parse(sessionStorage.getItem("bankerData"));
      const companyData = JSON.parse(sessionStorage.getItem("companyData"));
      const individualsData = JSON.parse(sessionStorage.getItem("IndivisualsData"));
    
      let userObject;
      let errs = {};
    
      // Validate password
      if (!password) {
        errs['password'] = "This field is required";
      } else if (password.length > 20) {  // Check for max length
        errs['password'] = "Password should not exceed 20 characters";
      }
    
      if (!rePassword) {
        errs['rePassword'] = "This field is required";
      } else if (password && rePassword && isContinueDisabled && password !== rePassword) {
        errs['rePassword'] = "Entered password should be same";
      }
    
      if (Object.keys(errs).length > 0) {
        setErrors(errs);
        return;
      }
    
      if (isContinueDisabled) {
        return;
      }
    
      if (ForgotDataObject || ForgotData) {
        userObject = {
          phone_number: ForgotDataObject?.phone_number || 0,
          email: ForgotDataObject?.email || "",
          new_password: password,
          confirm_new_password: rePassword
        };
      } else {
        if (companyData) {
          userObject = {
            name: companyData?.fullName || "",
            phone_number: companyData?.mobileNumber || 0,
            email: companyData?.companyEmail || "",
            status: "active",
            user_type: 0,
            company: 1,
            state: companyData?.state || 0,
            city: companyData?.city || 0,
            company_name: companyData?.companyName,
            password,
            turnover: companyData?.turnoverGrid || 0,
            vintage: companyData?.vintageGrid || 0,
          };
          postData2(userObject);
          return;
        } else if (individualsData) {
          userObject = {
            email: individualsData?.email || "",
            phone_number: individualsData?.mobileNumber || 0,
            password,
            user_type: 0,
            usertype:0,
            state: individualsData?.state || 0,
            city: individualsData?.city || 0,
            status: "active",
            company: 0,
            masterIndivisualService: individualsData?.service || 0,
            name: individualsData?.fullName || ""
          };
          postData3(userObject);
          return;
        } else {
          userObject = {
            name: bankerData?.fullName || "",
            phone_number: bankerData?.mobileNumber || 0,
            email: bankerData?.email || "",
            status: "active",
            user_type: 1,
            company: 0,
            password,
          };
        }
      }
    
      postData(userObject);
    };
    

  useEffect(() => {
    if (data?.status === "success") {
      // ToastMessage("success", "Password set successfully");
      sessionStorage.removeItem("bankerData");
      sessionStorage.removeItem("companyData");
      sessionStorage.removeItem("individualsData");
      navigate("/congratulation");
    } else if (
      error?.response?.data?.email &&
      error?.response?.data?.phone_number
    ) {
      ToastMessage(
        "error",
        "Given email and mobile number are already registered"
      );
    }
    if(data?.status==200 || data?.status==201 ){
      // ToastMessage("success", "Password set successfully");
      sessionStorage.removeItem("forgotData");
      navigate(ForgotDataObject ? "/done" : "/congratulation");
      // navigate("/congratulation");
    }
    if(error){
      ToastMessage("error", error?.response?.data?.error);
    }
  }, [data, error]);

  useEffect(() => {
    if (data2?.status === "success" || data2?.status === "Success") {
      // ToastMessage("success", "Password set successfully");
      sessionStorage.removeItem("bankerData");
      // sessionStorage.removeItem("companyData");
      sessionStorage.removeItem("individualsData");
      navigate("/congratulation");
    } else if (
      error2?.response?.data?.email &&
      error2?.response?.data?.phone_number
    ) {
      ToastMessage(
        "error",
        "Given email and mobile number are already registered"
      );
    }
    if(data2?.status==200 || data2?.status==201 ){
      // ToastMessage("success", "Password set successfully");
      sessionStorage.removeItem("forgotData");
      navigate(ForgotDataObject ? "/done" : "/congratulation");
      // navigate("/congratulation");
    }
    if(error2){
      ToastMessage("error", error2?.response?.data?.error);
    }
  }, [data2, error2]);

  useEffect(() => {
    if (data3?.status === "success" || data3?.status === "Success") {
      // ToastMessage("success", "Password set successfully");
      sessionStorage.removeItem("bankerData");
      sessionStorage.removeItem("companyData");
      // sessionStorage.removeItem("individualsData");
      navigate("/congratulation");
    } else if (
      error3?.response?.data?.email &&
      error3?.response?.data?.phone_number
    ) {
      ToastMessage(
        "error",
        "Given email and mobile number are already registered"
      );
    }
    if(data3?.status==200 || data3?.status==201 ){
      // ToastMessage("success", "Password set successfully");
      sessionStorage.removeItem("forgotData");
      navigate(ForgotDataObject ? "/done" : "/congratulation");
      // navigate("/congratulation");
    }
    if(error3){
      ToastMessage("error", error3?.response?.data?.error);
    }
  }, [data3, error3]);

  return (
    <div>
      <Navbar hemBurgerIcon={false}/>

      <div>
        <div>
          {loading ? (
            <Loader />
          ) : (
            <div className={ForgotSetPasswordmainContainer}>
              <div className={ForgotSetPasswordmainContainerDiv}>
                <LockIcon />
              </div>
              <h3 className={ForgotSetPasswordmainContainerheading}>
                Please set your password
              </h3>
              <div>
                <div className={SetPasswordStyleInputDiv}>
                  <InputField
                    label="New password"
                    astrix={true}
                    id="newPassword"
                    disabled={false}
                    required={true}
                    type={!showPassword ? "text" : "password"}
                    value={password}
                    name=""
                    maxLength={16}
                    onChange={handleInputChange}
                    className={InputFieldClass}
                    error={errors.password}
                  />
                  {showPassword ? (
                    <CloseEyePasswordIcon
                      onClick={() => setShowPassword(!showPassword)}
                      className={SetPasswordStyleSVG}
                    />
                  ) : (
                    <OpenEyePasswordIcon
                      onClick={() => setShowPassword(!showPassword)}
                      className={SetPasswordStyleSVG}
                    />
                  )}
                </div>
              </div>
              <ul className={SetPasswordStyleUL}>
                <li className={flexitemCentre}>
                  <ResetPasswordRightTick
                    className={`${SetPasswordStyleListSVG} ${passwordLength >= 8 ? "primary-text-green" : "text-[#D3D3D3]" } flex-shrink-0`}
                  />
                  At least 8 characters
                </li>
                <li className={flexitemCentre}>
                  <ResetPasswordRightTick
                    className={`${SetPasswordStyleListSVG} ${hasCapitalLetter(password) === true ? "primary-text-green" : "text-[#D3D3D3]" } flex-shrink-0`}
                  />
                  At least 1 capital letter
                </li>
                <li className={flexitemCentre}>
                  <ResetPasswordRightTick
                    className={`${SetPasswordStyleListSVG} ${
                      hasNumberOrSpecialChar(password) === true? "primary-text-green" : "text-[#D3D3D3]" } flex-shrink-0`}
                  />
                  At least 1 number or special character
                </li>
              </ul>

              <div className={SetPasswordStyleResetDiv}>
                <InputField
                  label="Re-enter password"
                  astrix={true}
                  id="rePassword"
                  disabled={false}
                  required={true}
                  type={!showRePassword ? "text" : "password"}
                  name=""
                  maxLength={16}
                  onChange={handleRePasswordChange}
                  value={rePassword}
                  error={errors.rePassword}
                  className={InputFieldClass}
                />

                {showRePassword ? (
                  <CloseEyePasswordIcon
                    onClick={() => setShowRePassword(!showRePassword)}
                    className={SetPasswordStyleSVG}
                  />
                ) : (
                  <OpenEyePasswordIcon
                    onClick={() => setShowRePassword(!showRePassword)}
                    className={SetPasswordStyleSVG}
                  />
                )}
              </div>

              <Button
                onClick={handleConfirm}
                disabled={!password}
                className={` w-full lg:w-[560px] rounded-lg py-3 mt-6 px-auto text-white ${
                  password !== rePassword || password === '' || rePassword ===''  ? "bg-[#8ED9B4]" : "greenbg"
                }`}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
