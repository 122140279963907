import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ForgotOTPResetPtag, ForgotOTPmainInputDiv } from "../modules/register/RegisterStyle";
import usePost from "../Hook/usePost";
import { ToastMessage, apiHost } from "./Config";
import { ResendOTPReLoadIcon, ResendOTPReLoadIconDisabled } from "./Icons";

const OtpBox = ({ forgotPassword, ForgotData }) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [errors, setErrors] = useState(new Array(6).fill(false));
  const [verifyError, setVerifyError] = useState("");
  const inputRefs = useRef([]);
  const [seconds, setSeconds] = useState(60);
  const [isDisabled, setIsDisabled] = useState(false);
  const postUrl = forgotPassword ? "onboarding/forgot-otp" : "onboarding/verify-otp";
  const { data, error, loading, postData: verifyOtp } = usePost(postUrl);
  const { data: sendOtp, error: sendError, postData: postSendOtp } = usePost('onboarding/sign-in');
  const { data:DataResendOTP, error:DataResendOTPError, postData:PostResendOTP } = usePost('onboarding/send-otp'); 
  // return false

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    if (error?.response?.data?.error) {
      ToastMessage("error", error?.response?.data?.error);
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[5] = true;
        return newErrors;
      });
    }
  }, [error]);

  useEffect(() => {
    if (data && !error) {
      if (data?.status === "success" || data?.status==200) {
        // ToastMessage("success", "Your otp has been verified");
        if(!forgotPassword){
          sessionStorage.setItem("userToken", JSON.stringify(data?.token));
          sessionStorage.setItem("user_type", JSON.stringify(data?.user_type));
          sessionStorage.setItem("company", JSON.stringify(data?.company));
        }
  
        if (data?.user_type === 1 && data?.token) {
          sessionStorage.setItem("userToken", JSON.stringify(data?.token));
          navigate("/profile/banker/complete-your-profile");
        } else if (data?.user_type ===0 && data?.company===1 && !forgotPassword) {
          navigate("/dashboard/company"); // need to be discussed
        } else if (data?.user_type ===0 && data?.company===0 && !forgotPassword) {
          navigate("/dashboard/company"); // need to be discussed
        } else if (forgotPassword ) {
          navigate("/set-password");
        }
        window.location.reload();
      } else {
        setVerifyError("Invalid OTP. Please try again.");
        setErrors((prevErrors) => {
          const newErrors = [...prevErrors];
          newErrors[5] = true;
          return newErrors;
        });
      }
    }

    if((data?.status==200 || data?.status=='success') && !data?.token){ 
      ToastMessage("success", "OTP verify successfully");
      navigate("/set-password");
    }
  }, [data, error]);

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = false;
      return newErrors;
    });
    if (value && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
    setVerifyError(""); // Clear verify error message on input change
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleBlur = (index) => {
    if (!otp[index]) {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = true;
        return newErrors;
      });
    }
  };

  useEffect(() => {
    if (sendError?.error) {
      ToastMessage("error", sendError?.error);
    }
    if (sendOtp?.status === 200) {
      ToastMessage("success", "OTP sent successfully");
    }
  }, [sendOtp, sendError]);

  const handleResendOtp = () => {
    setOtp(["", "", "", "", "", ""]);
    // const userLoginInfo = JSON.parse(sessionStorage.getItem("userLoginInfo"))
    // postSendOtp({ email: userLoginInfo?.email, password: userLoginInfo?.password });
    const ForgotMail=JSON.parse(sessionStorage.getItem("forgotData"))?.email || ""
    PostResendOTP({ identifier: ForgotMail })
    setSeconds(59);
  };

  const handleSubmit = () => {
    const enteredOTP = otp.join("");
    if (enteredOTP.length === 6) {
      if(seconds === 0) {
        ToastMessage("error", "OTP expired. Please request new OTP.");
        return;
      }
      const userPayload = JSON.parse(sessionStorage.getItem("userLoginInfo")) || {};
      const email = forgotPassword ? ForgotData?.email : userPayload?.email;

      verifyOtp({ email, otp: enteredOTP });
    } else {
      setErrors(otp.map((digit) => !digit));
      setVerifyError("Please enter a 6-digit OTP.");
    }
  };

  useEffect(() => {
    if (otp.every((digit) => digit !== '')) {
      handleSubmit();
    }
  }, [otp]);
  useEffect(()=>{
    if(DataResendOTP){
      ToastMessage("success", "OTP sent successfully");
    }
    if(DataResendOTPError){
      ToastMessage("error", "OTP not sent. Please try again.");
    }
  },[DataResendOTP,DataResendOTPError])
  return (
    <div>
      <div className={ForgotOTPmainInputDiv}>
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="tel"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onBlur={() => handleBlur(index)}
            className={`w-12 h-12 border rounded-lg mx-1 lg:mx-3 text-center focus:outline-none ${errors[5] ? "border-red-500" : "border-gray-300"
              }`}
          />
        ))}
      </div>
      {verifyError && (
        <div className="w-[43%] m-auto">
          <p className="primary-text-red mt-2 text-right">{verifyError}</p>
        </div>
      )}
      <div className="flex">
        <p role="button" className={`${ForgotOTPResetPtag} ${isDisabled ? "text-disabled cursor-original" : "cursor-pointer"}`} onClick={() =>  isDisabled ? null : handleResendOtp() }>
        {isDisabled ? <ResendOTPReLoadIconDisabled /> :<ResendOTPReLoadIcon/>}  Resend OTP <span className="primary-text-green">{isDisabled && `00:${seconds > 9 ? seconds : "0" + seconds}`}</span>
        </p>
      </div>
     
    </div>
  );
};

export default OtpBox;
