import { CrossIcon1 } from '../../../../common/Icons';
import Button from '../../../../common/Button';
import { CUD_Model_MainDiv } from '../../company/project/CompanyProjectStyle';
import { BP_AUM_MainDiv, BP_AUM_MainDiv1, BP_AUM_MainDiv2, BP_AUM_MainDiv3, BP_AUM_MainDiv4, BP_AUM_MainDiv5, BP_AUM_MainDiv6, BP_AUM_MainDiv7, BP_AUM_MainDiv8, w_full_border } from '../../banker/project/BankerProjectStyle';
import { useEffect, useState } from 'react';
import usePost from '../../../../Hook/usePost';
import { convertDate } from '../../../../Hook/validation';
import { ToastMessage } from '../../../../common/Config';
import { useParams } from 'react-router-dom';
const BankerAddUpdateModel = ({setMessage,setShowNotification, isOpen, onClose, milestone,fetchAgain }) => {
  const {id}=useParams()
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: updateMilestoneData,loading:updateMilestoneLoading, 
    error: updateMilestoneError, postData: postUpdateMilestoneData } = usePost(`projects/banker-milestone-update/${id}`);
  const [Text,setText]=useState("");
  const [errors,setErrors]=useState("")
  const [btn,setBtn]=useState(false)
  const handleSubmit=()=>{
    if(Text===""){
      setErrors("Please enter some text")
    }else{
      setErrors('')
      postUpdateMilestoneData({milestone_id:milestone.id,update_description:Text},token)
      setBtn(true)
      // onClose()
    }
  }
useEffect(()=>{
  if(updateMilestoneData){
  // ToastMessage("success","Milestone updated successfully")
  fetchAgain()
  setText("")
  onClose()
  setMessage('Update added successfully')
  setShowNotification(true)
  setTimeout(() =>{
    setShowNotification(false)
  },7000)
  }
  if(updateMilestoneError){
    ToastMessage("error","Something went wrong")
  }
  setBtn(false)
  
},[updateMilestoneData,updateMilestoneError])
  if (!isOpen) return null;
  return (
    <div className={CUD_Model_MainDiv}>
      <div className={BP_AUM_MainDiv}>
        <div className={BP_AUM_MainDiv1}>
          <h6 className={BP_AUM_MainDiv2}>{milestone?.project || "Not Available"}</h6>
          
          <button onClick={onClose} className="text-gray-500">
            <CrossIcon1 />
          </button>
        </div>
        <p className={BP_AUM_MainDiv3}>
        {milestone?.name || milestone?.title || "Not Available"}<div className={BP_AUM_MainDiv4}></div> {convertDate(new Date())}
                </p>
        <div className={w_full_border}></div>
        <div className={BP_AUM_MainDiv5}>
          
        <textarea className={BP_AUM_MainDiv6} placeholder='What do you want to post' name="" id="" onChange={(e)=>setText(e.target.value)}>

          </textarea>
        </div>
    {errors && <p className='text-[red] ml-0 max-w-max pl-4'>{errors}</p>}
        <div className={w_full_border}></div>
        <div className={BP_AUM_MainDiv7}>
          {/* <Button
            onClick={onClose}
            className="px-10 py-2 redfolicbg1 rounded-lg mr-2"
          >
            Cancel
          </Button> */}
          <Button disabled={btn} onClick={handleSubmit} className={BP_AUM_MainDiv8}>
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BankerAddUpdateModel;
