export const BankerProjectDetailMainDiv = "gap-2 w-full md:w-[97%]"
export const BPDetailMaindiv2 = 'md:flex justify-between items-center p-8 w-full bg-[#F9F9FB]';
export const BPDetailMaindiv3 = "md:flex gap-4 items-center"
export const BPDetailImagediv1 = 'm-auto w-[96px] overflow-hidden h-[96px] rounded-full bg-green-400'
export const BPDetailPtag1 = 'md:flex my-1 b2-text text-[#80828D] items-center gap-4'
export const BPDetailPtag2 = 'w-[8px] h-[8px] bg-green-500 rounded';
export const BPScheduleMeeting = 'px-4 mt-4 md:mt-0 rounded-lg py-3 redfolicbg1'
export const BProMainTag = "flex gap-8 px-8 py-6"
export const BProBreaLine1 = "w-full border -mt-6"
export const BProActiveTag = 'md:flex border-[1px] rounded-lg'
export const BProActiveTag1 = 'w-full lg:w-1/3 bg-[#F9F9FB] rounded-lg'
export const BProMileStoneHeading = "h5-text pl-4 text-start py-4";
export const BProMileStoneMainDiv = "p-4 flex justify-between w-full cursor-pointer "
export const BProDiv2 = "w-full md:w-2/3 p-2  md:p-8"
export const GridCol4 = "grid grid-cols-4"
export const BProStatus = "border max-w-max mt-2 md:mt-3 px-4 py-1 rounded-[24.5px]";
export const TextGrayNotification = 'text-[#8B8D98] notification';



// Banker Project Add Update Model Style
export const BP_AUM_MainDiv = "bg-white rounded-lg border shadow-lg w-[90%] lg:w-[37.5rem]";
export const BP_AUM_MainDiv1 = "flex justify-between mx-4 pt-4 items-center mb-1"
export const BP_AUM_MainDiv2 ="h6-text text-[#2C2C2C]"
export const BP_AUM_MainDiv3 ="flex mb-3 px-4 b2-text text-[#2C2C2C] items-center gap-2"
export const BP_AUM_MainDiv4 ='w-[4px] h-[4px] bg-gray-500 rounded '
export const BP_AUM_MainDiv5 ='flex justify-between px-4 my-5 items-center'
export const BP_AUM_MainDiv6 ='w-full resize-none border h-[30vh] p-4 rounded-lg'
export const BP_AUM_MainDiv7 ="flex justify-between p-4"
export const BP_AUM_MainDiv8 ="px-10 py-2 ml-auto greenbg text-white rounded-lg"

export const w_full_border = "w-full border"



// Banker Complete MileStone Model

export const BCMM_MainDiv = "fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center";
export const BCMM_MainDiv1 = "bg-white rounded-lg border shadow-lg w-[90%] md:w-[33.5rem]"
export const BCMM_MainDiv2 = "flex justify-between mx-4 pt-5 items-center mb-4";
export const BCMM_MainDiv3 = "text-xl font-semibold";
export const BCMM_MainDiv4 = 'flex justify-between px-4 my-5 items-center'
export const BCMM_MainDiv5 = 's2-text text-[#2C2C2C]'
export const BCMM_MainDiv6 = 'flex justify-between items-center mx-4 my-8 px-4 py-2 border-dashed border-2 rounded'
export const BCMM_MainDiv7 = "flex justify-between p-4"

export const BCMM_MainDiv8 = "px-10 py-2 redfolicbg1 rounded-lg mr-2"
export const BCMM_MainDiv9 = "px-10 py-2 greenbg text-white rounded-lg"



// Banker Manage Mandate

export const BMM_MainDiv = "flex w-full flex-wrap justify-between";
export const BMM_MainDiv1 = "h4-text text-[#2C2C2C]";
export const BMM_MainDiv2 = "redfolicbg1 flex items-center gap-2 px-4 py-1.5 rounded-md";
export const BMM_MainDiv3 = 'flex border px-4 py-4 my-4 rounded-[8px] w-full gap-10';
export const BMM_MainDiv4 = 'h6-text text-[#2C2C2C]'
export const BMM_MainDiv5 = "border w-full my-8 p-4 rounded-lg"
export const BMM_MainDiv6 = 'grid grid-cols-6 gap-8 mt-4'
export const BMM_MainDiv7 = 'notification-span text-[#8B8D98]'
export const BMM_MainDiv8 = 'w-full border my-5 border-[#EFF0F3]'
export const BMM_MainDiv9 ='grid grid-cols-6 gap-8 mt-4'


// Banker Project Style 

export const BP_MainDiv = 'flex items-center ml-2 my-3 gap-2'
export const BP_MainDiv1 = 'h-[8px] rounded-[2px] w-[36px]'
export const BP_MainDiv2 = 'lg:flex gap-2 px-4 lg:px-6 py-6 w-full lg:w-[97%]'
export const BP_MainDiv3 = 'w-full lg:w-[75%] rounded-[8px] pb-4 border border-[#DADCE0]'
export const BP_MainDiv4 = "grid grid-cols-2 px-6"
export const BP_MainDiv5 = "flex w-full justify-between lg:justify-start lg:gap-8 py-6"
export const BP_MainDiv6 = 'h-1 mt-4 border-1'
export const BP_MainDiv7 = 'w-full -mt-6 border';
export const BP_MainDiv8 = 'flex justify-between my-3 w-full'
export const BP_MainDiv9 = 'flex text-start w-[73%]';
export const BP_MainDiv10 = 'flex text-start w-[73%]';
export const BP_MainDiv11 = 'flex items-center gap-2 notification-span text-[#8B8D98]'
export const BP_MainDiv12 = 'grid grid-cols-2 w-[30%] justify-between'
export const BP_MainDiv13 = 'border rounded-[8px]  px-6 py-6 border-[#DADCE0] mb-4'
export const BP_MainDiv14 = 'border rounded-[8px] border-[#DADCE0]'
export const BP_MainDiv15 = 'h6-text leading-[21.6px]'
export const BP_MainDiv16 = 'text-[#1EB469] remove-text'



