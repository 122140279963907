import React, { useEffect, useRef, useState } from "react";
import Button from "../../common/Button";
import {
  ProfilePhotoStyleBodyDiv,
  ProfilePhotoStyleDiv4,
  ProfilePhotoStyleBodyDiv2,
  ProfilePhotoStyleBodyDiv3,
  ProfilePhotoStyleBodyDiv4,
  ProfilePhotoStyleDiv2,
  ProfilePhotoStyleDivButton,
  ProfilePhotoStyleDivModel,
  ProfilePhotoStyleDivModelDiv,
  ProfilePhotoStyleDivModelModalBody,
  ProfilePhotoStyleDivModelModalContent,
  ProfilePhotoStyleDivModelModalHeader,
  ProfilePhotoStyleDivModelModalHeaderH3,
  ProfilePhotoStyleMainContainer,
  ProfilePhotoStyleMainImg,
  ProfilePhotoStyleDiv3
} from "./ProfileStyle";
import ImageInputField from "../../common/ImageInputField";
import { uploadPhotoInputHide, uploadPhotoInputHide1 } from "../../common/StyleCommon";
import { CameraImageIcon, CrossIcon1, ProfileEditIcon, ProfilePhotoDumyIcon, UploadImageIcon } from "../../common/Icons";
import useUploadProfilePicture from "../../Hook/useUploadProfilePicture";
import { ToastMessage, apiHost } from "../../common/Config";
import useGetData from "../../Hook/useGetData";
import Webcam from "react-webcam";
import usePost from "../../Hook/usePost";

const ProfilePhotoUploadModel = (props) => {
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: getBankerData, loading: loadingServices, error: errorServices } = useGetData('onboarding/banker/get-bankers', token);
  const { uploading, error, success, uploadProfilePicture } = useUploadProfilePicture('onboarding/banker/profile-picture');
  const { data: imgRemoveData,loading:imgRemoveLoading, 
    error: imgRemoveError, postData: postImgRemoveData } = usePost(`onboarding/banker/remove-profile-picture`);
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [useWebcam, setUseWebcam] = useState(false);
  const webcamRef = useRef(null);
  const { profilePhoto, setProfilePhoto } = props;

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setUseWebcam(false);
  };
  const handleRemoveImage=()=>{
    postImgRemoveData({}, token);
     
  }

  const inputElement = useRef();
  const bankerData = JSON.parse(sessionStorage.getItem("bankerData"));

  const handleChange = (e) => {
    if (e.target.files[0]) {
      uploadProfilePicture("profile_image", e.target.files[0]);
      setProfilePhoto(true);
      setFile(URL.createObjectURL(e.target.files[0]));
      toggleModal();
      e.target.value = '';
    }
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    fetch(imageSrc)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], "captured_image.jpg", { type: "image/jpeg" });
        uploadProfilePicture("profile_image", file);
        setProfilePhoto(true);
        setFile(imageSrc);
        uploadProfilePicture("profile_image", file);
        toggleModal();
      });
  };

  useEffect(() => {
    if (success) {
      ToastMessage('success', "Image uploaded successfully");
      setProfilePhoto(true);
    }
    if (error) {
      ToastMessage('error', "Image upload failed");
    }
  }, [success, error]);

  useEffect(() => {
    if (getBankerData?.data?.profile?.profile_image) {
      setProfilePhoto(true);
      setFile(`${apiHost}${getBankerData?.data?.profile?.profile_image}`);
    }
  }, [getBankerData?.data]);

  useEffect(()=>{
    if(imgRemoveData){
      ToastMessage('success', "Image removed successfully");
      setProfilePhoto(false);
      setFile(null);
    }
    if(imgRemoveError){
      ToastMessage('error', "Image remove failed");

    }

  },[imgRemoveData,imgRemoveError])
  return (
    <>
      <div className={ProfilePhotoStyleMainContainer}>
        {profilePhoto === true ? (
          <img className={ProfilePhotoStyleMainImg} src={file} alt="" />
        ) : (
          <ProfilePhotoDumyIcon />
        )}
        {profilePhoto === true ? (
          <div onClick={toggleModal} className={ProfilePhotoStyleDiv3}>
            <ProfileEditIcon />
          </div>
        ) : (
          <div onClick={toggleModal} className={ProfilePhotoStyleDiv4}>
            +
          </div>
        )}
      </div>

      {/* Main modal */}
      <div
        id="default-modal"
        className={`${ProfilePhotoStyleDivModel} ${isOpen ? "block" : "hidden"}`}
      >
        <div className={ProfilePhotoStyleDivModelDiv}>
          {/* Modal content */}
          <div className={ProfilePhotoStyleDivModelModalContent}>
            {/* Modal header */}
            <div className={ProfilePhotoStyleDivModelModalHeader}>
              <h3 className={ProfilePhotoStyleDivModelModalHeaderH3}>Upload Profile</h3>
              <Button onClick={toggleModal} type="button" className={ProfilePhotoStyleDivButton}>
                <CrossIcon1 />
                <span className="sr-only">Close modal</span>
              </Button>
            </div>
            <div className={ProfilePhotoStyleDivModelModalBody}>
              <div className={ProfilePhotoStyleBodyDiv}>
                <div className={ProfilePhotoStyleBodyDiv2} onClick={() => setUseWebcam(true)}>
                  <CameraImageIcon />
                </div>
                <div className={ProfilePhotoStyleBodyDiv3}>
                  <div className="absolute cursor-pointer flex flex-col items-center justify-center w-full h-full">
                    <ImageInputField accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff,.webp" type="file" className={uploadPhotoInputHide} ref={inputElement} onChange={handleChange} />
                  </div>
                  <UploadImageIcon />
                </div>
              </div>
              <div className={ProfilePhotoStyleBodyDiv4}>
                <div className="m-auto">Camera</div>
                <div className="m-auto">Gallery</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Webcam modal */}
      {useWebcam && (
        <div
          id="webcam-modal"
          className={`${ProfilePhotoStyleDivModel} ${isOpen ? "block" : "hidden"}`}
        >
          <div className={ProfilePhotoStyleDivModelDiv}>
            <div className={ProfilePhotoStyleDivModelModalContent}>
              <div className={ProfilePhotoStyleDivModelModalHeader}>
                <h3 className={ProfilePhotoStyleDivModelModalHeaderH3}>Capture Photo</h3>
                <Button onClick={toggleModal} type="button" className={ProfilePhotoStyleDivButton}>
                  <CrossIcon1 />
                  <span className="sr-only">Close modal</span>
                </Button>
              </div>
              <div className={ProfilePhotoStyleDivModelModalBody}>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  className="w-full h-full"
                />
                <Button onClick={captureImage} className="mt-4">Capture</Button>
              </div>
            </div>
          </div>
        </div>
      )}
     {profilePhoto && <p className='-mt-5 primary-text-green' role='button' onClick={handleRemoveImage}>Remove</p>}

    </>
  );
};

export default ProfilePhotoUploadModel;
