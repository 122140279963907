import React, { useEffect, useState } from "react";
import Navbar from "../../../../common/Navbar";
import InputField from "../../../../common/InputField";
import Checkbox from "../../../../common/Checkbox";
import {
  CheckboxLabelTextStart,
} from "../../../../common/StyleCommon";

import SelectTagComponent from "../../../../common/SelectTagComponent";
import { DeleteIcon, GreenCrossICon, IButtonIcon } from "../../../../common/Icons";
import BottomBar from "../../../../common/BottomBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FlexGap3, FlexJustItemGap4, FlexJustitemCenter, HireNowStyleCheckbox, HireNowStyleCrossBtn, HireNowStyleMainDiv, HireNowStyleMainTitle, HireNowStyleMileButton, HireNowStyleMileStone, HireNowStyleMileStoneDiv, HireNowStyleUploadDoc } from "./DashboardJobStyle";
import { HiringFormValidation, calculateNextDay, getMonthDifference } from "../../../../Hook/validation";
import usePost from "../../../../Hook/usePost";
import { ToastMessage, apiHost } from "../../../../common/Config";
import SideNavbar from "../../../../common/SideNavbar";
import useGetData from "../../../../Hook/useGetData";
import ToolTip from "../../../../common/ToolTip";

const HireNow = () => {
    const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
    const {id,bkId}=useParams()
    const location=useLocation()
 
    const { data: hiringData,loading:hiringLoading, 
        error: hiringError, postData: postHiringData } = usePost(`hiring/hire`);
        
    const { data: documentData, loading: documentLoading, error: documentError,
        postData: postDocumentData } = usePost('hiring/document');
    const [formData, setFormData] = useState({
        projectName: '',
        modeOfConsultancy: '',
        modeofconsultancy:1,
        availabilityForHire: '',
        availabilityforhire:1,
        selectMonth: '',
        startDate: '',
        endDate: '',
        milestones: [{id: 1, description: '', due_date:null}],
        uploadDocument: []
    });
    const [errors, setErrors] = useState({});
    const [state, setState] = useState({
        isMax:false,
        id:0
    })
    const { data: hiringUpdateData, error: hiringUpdateError,postData: postHiringUpdateData } = usePost(`hiring/update-job-hire/${formData?.id}`);
    const { data: getHireData, loading: loadingHire, error: errorHire } = useGetData(`hiring/get-job-hire/${bkId}`,token);
    const IsEdit=(location?.state?.edit || getHireData?.data?.documents?.length>0 )? true :false;
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
         
        if (name === 'startDate' && formData.selectMonth) {
           const enddate= calculateEndDate(value, formData.selectMonth);
        }
        if(errors[name])HiringFormValidation({...formData, [name]: value }, setErrors);
    };

    const handleSelectChange = (name,e) => {
      setFormData({ ...formData, selectMonth: e?.name });
      if (formData.startDate) {
          calculateEndDate(formData.startDate, e?.name );
      }
      if(errors[name])HiringFormValidation({...formData,selectMonth:e?.name }, setErrors);
    //   HiringFormValidation({...formData,selectMonth:e?.name }, setErrors);
    };

    const calculateEndDate = (startDate, months) => {
    const start = new Date(startDate);
    const end = new Date(start.setMonth(start.getMonth() + parseInt(months)));
    setFormData(prevState => ({ ...prevState, endDate: end.toISOString().split('T')[0] }));
    // HiringFormValidation({...formData, endDate: end.toISOString().split('T')[0] }, setErrors);
    };
    const handleAddMilestone = () => {
        setFormData({
            ...formData,
            milestones: [
                ...formData.milestones,
                { id: formData.milestones.length + 1, description: '', due_date: null } // Initialize due_date as null
            ]
        });
    };
    

    const handleDeleteMilestone = (id) => {
        setFormData({
            ...formData,
            milestones: formData.milestones.filter(milestone => milestone.id !== id)
        });
    };

    const handleMilestoneChange = (e, id, field) => {
        const newMilestones = formData.milestones.map(milestone => {
            if (milestone.id === id) {
                return { ...milestone, [field]: e.target.value };
            }
            return milestone;
        });
        setFormData({ ...formData, milestones: newMilestones });
    };

    const handleAddDocument = (event) => {
        const files = Array.from(event.target.files);
        const newDocuments = files.map((file, index) => ({
            id: formData.uploadDocument.length + index + 1,
            name:file.name,
            file: file, // Store the actual file object
        }));
        const payload = new FormData();
        payload.append("banker_id",bkId);
        payload.append("document",event.target.files);
        payload.append("job",id);
        // postDocumentData(payload, token);

        setFormData(prevState => ({
            ...prevState,
            uploadDocument: [...prevState.uploadDocument, ...newDocuments]
        }));
        event.target.value = ''; 

    };
    
    const handleDeleteUploadDocument = (id) => {
        setFormData({
            ...formData,
            uploadDocument: formData.uploadDocument.filter(doc => doc.id !== id)
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const obj={
            job: parseInt(id),
            banker_id:parseInt(bkId),
            project_name: formData?.projectName || "",
            consultancy_mode: formData?.modeofconsultancy || 1,
            tenure:formData?.availabilityforhire || 1,
            start_date: formData?.startDate ||"",
            end_date:formData?.endDate || "",
            is_payment: false,
            payment_order_id: "PO-12345",
            is_meeting_scheduled: true,
            milestones: formData?.milestones || []
          }
        if (HiringFormValidation(formData,setErrors) && !IsEdit) {
            // Submit form data
          
            postHiringData(obj,token)
         
            // Navigate to the next step
        
        }else if(HiringFormValidation(formData,setErrors) && IsEdit){
            const obj1={
                // job: parseInt(id),
                banker_id:parseInt(bkId),
                project_name: formData?.projectName || "",
                consultancy_mode: formData?.modeofconsultancy || 1,
                tenure:formData?.availabilityforhire || 1,
                start_date: formData?.startDate ||"",
                end_date:formData?.endDate || "",
                is_payment: false,
                payment_order_id: "PO-12345",
                is_meeting_scheduled: true,
                milestones: formData?.milestones || []
              }
            postHiringUpdateData(obj1,token)
        }
    };

    const checkboxTitle = [
        {
            id: 7,
            title: "Mode of Consultancy",
            checkbox: [
                { id: 1, label: "Virtual", description: "", value: "virtual" },
                { id: 2, label: "In-person", description: "", value: "In-person" },
                { id: 3, label: "Both", description: "", value: "both" },
            ],
        },
        {
            id: 8,
            title: 'Availability for hire',
            checkbox: [
                { id: 1, label: "Short Term", description: "(Up to 3 months)", value: "shortTerm" },
                { id: 2, label: "Medium Term", description: "(Up to 6 months)", value: "mediumTerm" },
                { id: 3, label: "Long Term", description: "(6 months and above)", value: "longTerm" },
            ],
        },
    ];

    const navigate = useNavigate();

    const handleMultipleUpload=()=>{
        const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${token}`);
const formdata = new FormData();
formdata.append("banker_id", bkId);
for(let i=0;i<formData?.uploadDocument?.length;i++){
    formdata.append("documents", formData?.uploadDocument[i]?.file);

}
formdata.append("job", id);

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: formdata,
  redirect: "follow"
};

fetch(`${apiHost}/hiring/document`, requestOptions)
  .then((response) => response.json())
  .then((result) =>{
    if(result?.data){
        ToastMessage('success','Document Uploaded Succesfully')
    }
  })
  .catch((error) =>{
    if(error){
        ToastMessage('error',"Document Uploaded Failed")
    }
  });
    }
    useEffect(()=>{
        if(hiringData){
            if(formData?.uploadDocument?.length>0){
                handleMultipleUpload()
            }
ToastMessage('success','Hiring Process Created Succesfully')
navigate(`/dashboard/hirenow/proceedtopay/${id}/${bkId}`,{state:{hire_id:hiringData?.id}});
        }
        if(hiringError){
            ToastMessage('error',`${hiringError?.response?.data?.message || "Hiring process failed" }`)
        }

    },[hiringData,hiringError])

    useEffect(() => {
        if (documentError?.response?.data?.message) ToastMessage("error",documentError?.response?.data?.message);
    }, [documentError]);
    const handleTwoDigit = (e) => {
        const { key, target: { value } } = e;
    
        // Allow control keys: backspace, delete, arrow keys, etc.
        const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
        if (controlKeys.includes(key)) {
            return;
        }
    
        // Prevent non-numeric keys
        if (!/^\d$/.test(key)) {
            e.preventDefault();
            return;
        }
    
        // Check the new value after key press
        const newValue = value + key;
    
        // Prevent input if already two digits or if the new value is less than or equal to 5
        if (value.length >= 2 || parseInt(newValue, 10) <= 0) {
            e.preventDefault();
        }
    };
    
    
    useEffect(()=>{
if(location?.state?.data){
    const data=location?.state?.data?.data
    const monthCount=getMonthDifference(data?.start_date,data?.end_date)
    const obj={
        projectName: data?.project_name || '',
        modeOfConsultancy:data?.consultancy_mode?.id || '',
        modeofconsultancy:data?.consultancy_mode?.id || '',
        availabilityForHire: '',
        availabilityforhire:data?.tenure?.id || 1,
        selectMonth: monthCount || '',
        startDate: data?.start_date|| '',
        endDate: data?.end_date || '',
        milestones: data?.milestones?.map((item)=>{
            return{
                description:item?.description,
                due_date:item?.due_date
            }
        }) || [],
        id:data?.id,
        uploadDocument: data?.documents?.map((item)=>item?.document) || [1,2,3]
    }
    setFormData(obj)

}
if(getHireData?.data?.documents?.length>0){
    const data=getHireData?.data
    const monthCount=getMonthDifference(data?.start_date,data?.end_date)
    const obj={
        projectName: data?.project_name || '',
        modeOfConsultancy:data?.consultancy_mode?.id || '',
        modeofconsultancy:data?.consultancy_mode?.id || '',
        availabilityForHire: '',
        availabilityforhire:data?.tenure?.id || 1,
        selectMonth: monthCount || '',
        startDate: data?.start_date|| '',
        endDate: data?.end_date || '',
        milestones: data?.milestones?.map((item)=>{
            return{
                description:item?.description,
                due_date:item?.due_date
            }
        }) || [],
        id:data?.id,
        uploadDocument: data?.documents?.map((item)=>item?.document) || [1,2,3]
    }
    setFormData(obj)
}
    },[IsEdit])
    useEffect(()=>{
        if(hiringUpdateData){
            if(formData?.uploadDocument?.length>0){
                handleMultipleUpload()
            }
            ToastMessage('success','Hiring Process Updated Succesfully')
            navigate(`/dashboard/hirenow/proceedtopay/${id}/${bkId}`);
        }

        if(hiringUpdateError){
            ToastMessage('error',`${hiringUpdateError?.response?.data?.error || "Hiring process failed" }`)
        }
    },[hiringUpdateData, hiringUpdateError])
    useEffect(()=>{
        if(formData?.uploadDocument?.length>0){
            HiringFormValidation(formData,setErrors)
        }
    },[formData?.uploadDocument,errors])
    
    const handleClickState=(id)=>{
        setState({
            isMax:true,
            id:id
        })
    }
    return (
        <div>
            <Navbar CompanyNavbar={true} ProfileAndNotification={true} />
            <div className="block sm:hidden">
            <SideNavbar/>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={HireNowStyleMainDiv}>
                    <h3 className="h3-text mt-10">Hiring Process</h3>
                    <div className="my-10">
                        <InputField
                            label="Project name"
                            name="projectName"
                            value={formData.projectName}
                            onChange={handleInputChange}
                            astrix={false}
                            disabled={false}
                            type="text"
                            id="projectName"
                            required={true}
                            error={errors.projectName}
                        />
                    </div>

                    {checkboxTitle?.map((item, index) => (
                        <div className="w-full" key={item.id}>
                            <div className="">
                                <h6 className={HireNowStyleMainTitle}>{item?.title}</h6>
                            </div>

                            <div className={HireNowStyleCheckbox}>
                                {item.checkbox.map((checkboxItem) => (
                                    <div className="my-3" key={checkboxItem.id}>
                           
                                        <Checkbox
                                            type={
                                                (item?.id==8 || item?.id==7)
                                                    ? "radio"
                                                    : "checkbox"
                                            }
                                            id={checkboxItem?.id}
                                            label={checkboxItem?.label}
                                            description={checkboxItem?.description}
                                            value={checkboxItem?.value}
                                            labelClassName={CheckboxLabelTextStart}
                                            checked={true ? formData[item.title.toLowerCase().replace(/\s+/g, '')] === checkboxItem.id : null}
                                            onChange={() => {
                                         
                                                setFormData({ ...formData, [item.title.toLowerCase().replace(/\s+/g, '')]: checkboxItem.id })
                                                if(item.title.toLowerCase().replace(/\s+/g, '')=='availabilityforhire'){setFormData({...formData,selectMonth:'',[item.title.toLowerCase().replace(/\s+/g, '')]: checkboxItem.id})

                                            }
                                            
                                            }
                                        }
          
                                                
                                            
                                            
                                        />
                                    </div>
                                ))}
                    

                            </div>
                            <p className="text-[red] r-0 w-[100%] text-end">{errors[item.title.toLowerCase().replace(/\s+/g, '')]}</p>
                        </div>
                    ))}

<div className='flex flex-col md:flex-row lg:flex-row justify-center items-center mt-2 gap-4'>
  {(formData?.availabilityforhire==1 || formData?.availabilityforhire==2 ) ?
    <div className="w-full md:w-[198px] mt-2">
      <SelectTagComponent
        defaultVal={formData?.selectMonth || "Select Month"}
        options={formData?.availabilityforhire==1 ? [
          { id: 1, name: "1" },
          { id: 2, name: "2" },
          { id: 3, name: "3" },
        ]:[
          { id: 1, name: "1" },
          { id: 2, name: "2" },
          { id: 3, name: "3" },
          { id: 4, name: "4" },
          { id: 5, name: "5" },
          { id: 6, name: "6" }
        ]}
        name="selectMonth"
        value={formData.selectMonth}
        onChange={(val)=>handleSelectChange("selectMonth",val)}
        error={errors.selectMonth}
      />
    </div> :
    <div className="w-full md:w-[198px]">
      <InputField
        label="Select Month"
        name="selectMonth"
        value={formData.selectMonth}
        onChange={handleInputChange}
        astrix={false}
        disabled={false}
        maxLength={2}
        type="number"
        onkeyDown={handleTwoDigit}
        id="selectMonth"
        shouldAllowPastDates={false}
        required={true}
        error={errors.selectMonth}
      />
    </div>
  }
  <div className="w-full md:w-[198px]">
    <InputField
      label="Start Date"
      name="startDate"
      value={formData.startDate}
      onChange={handleInputChange}
      astrix={false}
      disabled={false}
      type="date"
      id="startDate"
      shouldAllowPastDates={false}
      required={true}
      error={errors.startDate}
    />
  </div>
  <div className="w-full text-center md:w-auto">
    to
  </div>
  <div className="w-full md:w-[198px]">
    <InputField
      label="End Date"
      name="endDate"
      value={formData.endDate}
      onChange={handleInputChange}
      astrix={false}
      disabled={true}
      type="date"
      id="endDate"
      required={true}
      error={errors.endDate}
    />
  </div>
</div>


                    <div>
                        <div className={HireNowStyleMileStone}>
                            <div className={FlexGap3}>
                                <p className="s2-text">Milestone <span className="text-[#80828D]">(Optional)</span></p>
                                <ToolTip message='Here you can create project milestones with their due dates'>
                          <IButtonIcon />
                        </ToolTip>
                            </div>
                            <div>
                                <p className={HireNowStyleMileButton} onClick={handleAddMilestone}>
                                    + Add Milestone
                                </p>
                            </div>
                        </div>

                        {formData.milestones.map((milestone, index) => (
                            <div className={HireNowStyleMileStoneDiv} key={milestone.id}>
                                <div className='flex flex-col md:flex justify-center md:justify-center md:items-center cursor-pointer'>
                                    <p className="s2-text">{index + 1}.</p>
                                </div>
                                <div className="w-full md:w-[400px]">
                                    <InputField
                                        label="Description"
                                        name={`description-${milestone.id}`}
                                        value={milestone.description}
                                        onChange={(e) => handleMilestoneChange(e, milestone.id, 'description')}
                                        astrix={false}
                                        disabled={false}
                                        type="text"
                                        id={`description-${milestone.id}`}
                                        required={true}
                                        error={errors[`milestoneDescription${index}`]}
                                    />
                                </div>
                                <div className="w-full md:w-[195px]">
                                    <InputField
                                        label="Due date"
                                        name={`dueDate-${milestone.id}`}
                                        value={milestone.due_date}
                                        onChange={(e) => handleMilestoneChange(e, milestone.id, 'due_date')}
                                        astrix={false}
                                        disabled={false}
                                        type="date"
                                        id={`dueDate-${milestone.id}`}
                                        shouldAllowPastDates={false}
                                        // DobInput={false}
                                        onClick={()=>handleClickState(milestone.id)}
                                        required={true}
                                        mindates={formData.startDate || new Date()}
                                        MaxDate={(state?.isMax && milestone?.id==state?.id) ? formData?.endDate : null}
                                        error={errors[`milestoneDueDate${index}`]}
                                    />
                                </div>
                                {milestone.id !== 1 && <div className={HireNowStyleCrossBtn} onClick={() => handleDeleteMilestone(milestone.id)}>
                                    <GreenCrossICon />
                                </div>}
                            </div>
                        ))}
                    </div>

                    <div>
                        <div className={HireNowStyleUploadDoc}>
                            <div className="gap-3">
                                <p className="s2-text text-[#2C2C2C]">Upload document</p>
                                <p className="body-text text-start text-[#80828D]">Only pdf, doc, docx, csv, xlsx, accepted. Max file size: 15 MB each</p>
                            </div>
                            <div>
                                <input
                                    type="file"
                                    id="file-upload"
                                    multiple
                                    accept=".pdf, .doc, .docx, .csv, .xlsx"
                                    onChange={handleAddDocument}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="file-upload" className={HireNowStyleMileButton}>
                                    + Add Document
                                </label>
                            </div>
                        </div>

                        {formData?.uploadDocument?.map((doc) => (
                            <div className={HireNowStyleMileStoneDiv} key={doc.id}>
                                <div className="w-full">
                                    <InputField
                                        label=""
                                        UploadClass='true'
                                        name={`file-${doc.id}`}
                                        value={doc.name || doc}
                                        astrix={false}
                                        disabled={true}
                                        remove={<DeleteIcon onClick={() => handleDeleteUploadDocument(doc.id)} />}
                                        type="text"
                                        id={`file-${doc.id}`}
                                        required={false}
                                        isHireDocument={true}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className="text-[red] r-0 w-[100%] text-end">{errors?.uploadDocument}</p>
                </div>
                <div className="block md:hidden lg:hidden h-[2rem]">

</div>
            </form>

            <BottomBar type="Hire" OnCLickBackbtn={() => navigate(-1)} OnCLickNextBtn={handleSubmit} btnName="Next" BackBtn="Cancel"  />
        </div>
    );
};

export default HireNow;
