import React, { useEffect, useState } from 'react'
import Navbar from '../../../common/Navbar'
import PaymentStatus from '../../../common/PaymentStatus'
import { useLocation, useNavigate } from 'react-router-dom';
import { CongratulationIcon, DeclineIcon, LeftArrowIcon, RetryIcon } from '../../../common/Icons';
import { BankerPaymentStyeleBRLine, BankerPaymentStyeleMainDiv, BankerPaymentStyeleMainDiv2, BankerPaymentStyeleMainDiv2Heading, BankerPaymentStyeleMainDiv2Ptag, BankerPaymentStyeleMainDivButton, BankerPaymentStyeleMainDivHeadin, BankerPaymentStyeleSubTotal, BankerPaymentStyeleSubTotalDiv, BankerPaymentStyeleTaxDiv, BankerPaymentStyeleTransactionDate, BankerPaymentStyeleTransactionID, FlexJustCenter } from './BankerStyle';
import Button from '../../../common/Button';
import { formatPriceInput } from '../../../common/Config';
import useGetData from '../../../Hook/useGetData';

const BankerPaymentStatus = () => {
  const [paymentStatus, setPaymentStatus] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";

  const { data: getBankerData, loading: loadingServices, error: errorServices } = useGetData('onboarding/banker/get-bankers',token);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const navigate= useNavigate();
  const location = useLocation();
  const { data,predata,formattedDate,PaymentData } = location.state;
  const HeadingIcon = paymentStatus ? <CongratulationIcon /> : <DeclineIcon />;
  const Heading = paymentStatus ? "Congratulations" : "Payment declined by bank";
  const SubHeading = paymentStatus ? "Your payment was successful" : "Payment failed due to a technical issue with your bank.";
  const ButtonText = paymentStatus ? "Continue" : (
    <p className="flex gap-2">
      {isHovered ? <LeftArrowIcon className="mt-1" stroke="#FFFFFF" /> : <RetryIcon />} Retry Payment
    </p>
  );
const GST=predata?.price*0.18;
const TAXvalue=parseInt(predata.price)-parseInt(predata.cost)
useEffect(()=>{
  if(PaymentData?.status==='Payment verified' || PaymentData?.status===true){
    setPaymentStatus(true)
  }
  else{
    setPaymentStatus(false)
  }

},[predata])
const handleNavigate=()=>{
  if(getBankerData?.data?.profile?.form_step==4){
    navigate("/dashboard/banker")
  }
  if(getBankerData?.data?.profile?.form_step==2){
    navigate("/profile/banker/add-profile-picture")
  }
  if(getBankerData?.data?.profile?.form_step==1){
    navigate("/profile/banker/additional-hiring-details")
}
if(getBankerData?.data?.profile?.form_step==3){
  navigate("/profile/banker/profile-preview")
}
if(!getBankerData?.data?.profile?.form_step){
  navigate("/profile/banker/complete-your-profile")
}
}
  return (
    <div>
        <Navbar hemBurgerIcon={false} BankerNavbar={true} ProfileAndNotification={true}/>
        <div className='px-3'>
      
      <div className={BankerPaymentStyeleMainDiv}>
        <div className={FlexJustCenter}>{HeadingIcon}</div>
        <div>
          <h5 className={BankerPaymentStyeleMainDivHeadin}>{Heading}</h5>
          <p className="s3-text">{SubHeading}</p>
        </div>
        <div>
          <Button
            onClick = {paymentStatus ? handleNavigate : ()=>navigate(-1)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={BankerPaymentStyeleMainDivButton}
          >
            {ButtonText}
          </Button>
        </div>
      </div>

      <div className={`${BankerPaymentStyeleMainDiv2} ${paymentStatus === true ? "bg-[#E6F7EB]" : "bg-[#FFE8EA]"}`}>
        <div>
          <h6 className={BankerPaymentStyeleMainDiv2Heading}>Transaction ID</h6>
          <p className={BankerPaymentStyeleMainDiv2Ptag}>
            You can check the status of this payment with your bank (XYZ Bank) using this transaction ID
          </p>
        </div>
        <div>
          <h6 className={BankerPaymentStyeleTransactionID}>{data?.razorpay_payment_id || 4522781676453899}</h6>
          <p className={BankerPaymentStyeleTransactionDate}>{formattedDate}</p>
        </div>
      </div>

      <div className={BankerPaymentStyeleSubTotal}>
        <div className={BankerPaymentStyeleSubTotalDiv}>
          <p className="s2-text">Subtotal</p>
          <p className="s2-text">{`₹ ${formatPriceInput(Math.floor(predata?.cost).toString())}`}</p>
        </div>
        <div className={BankerPaymentStyeleTaxDiv}>
          <p className="s2-text">
            Tax <span className="text-[#80828D]">(GST 18%)</span>
          </p>
          <p className="s2-text">{`₹ ${formatPriceInput(TAXvalue.toString())}`}</p>
        </div>
        <div className={BankerPaymentStyeleBRLine}></div>
        <div className={BankerPaymentStyeleTaxDiv}>
          <p className="s2-text">Total</p>
          <h5 className="h5-text">{`₹ ${formatPriceInput(predata?.price.toString())}`}</h5>
        </div>
      </div>
    </div>
      
    </div>
  )
}

export default BankerPaymentStatus
