import React from 'react';
import Navbar from '../../../common/Navbar';
import BankerSideNavbar from '../../../common/BankerSideNavbar';
import Button from '../../../common/Button';
import { DownloadIconInProfile, GetHelpIcon } from '../../../common/Icons';
import { BMM_MainDiv5, BMM_MainDiv7, BMM_MainDiv8 } from '../../dashboard/banker/project/BankerProjectStyle';
import { useNavigate } from 'react-router-dom';
import useGetData from '../../../Hook/useGetData';
import { formatDateBanker } from '../../../Hook/validation';

const BankerManagePlan = () => {
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const { data: getData, loading: loadingPayment, error: errorPayment, refetch: fetchData } = useGetData(`onboarding/get-payment-manadate`, token);
  const navigate = useNavigate();

  const CurrentPlan = [
    {
      id: 1,
      title: "Plan you have",
      value: `₹ ${getData?.amount} (${getData?.current_plan_manadate} mandates)`
    },
    {
      id: 2,
      title: "Subscription Renewal date",
      value: formatDateBanker(getData?.renewal_date)
    },
    {
      id: 3,
      title: "Mandates left",
      value: getData?.current_plan_manadate - getData?.consume_plan_manadate || 0
    },
    {
      id: 4,
      title: "What you’ll be charged",
      value: `₹${getData?.amount} for ${getData?.current_plan_manadate} mandates`
    }
  ];

  return (
    <div>
      <Navbar hemBurgerIcon={false} BankerNavbar={true} BackBtn={true} ProfileAndNotification={true} />
      <div className='md:flex custom-scrollbar'>
        <div className="lg:w-[7%] md:w-[7%] w-0">
          <BankerSideNavbar />
        </div>
        <div className='w-[100%] md:w-[93%] lg:w-[93%] px-3 md:px-8 lg:px-10 relative'>
          <div className='md:flex justify-between py-6 items-center'>
            <div>
              <h4 className='h4-text text-start'>
                Manage Plan
              </h4>
            </div>
            <div className='flex mt-4 md:mt-0 gap-4'>
             {getData?.current_plan_manadate !==8  && <Button className="flex items-center rounded-lg px-4 py-2 greenbg text-white"
                onClick={() => navigate("/profile/banker/plan")}
              >
                Upgrade Plan
              </Button>}
              <Button className="flex gap-2 redfolicbg rounded-lg px-4 py-2 items-center text-white">
                <GetHelpIcon />
                Get Help
              </Button>
            </div>
          </div>

          <div className='w-full border bg-[#F9F9FB] rounded-lg px-4 py-4'>
            <h6 className='h6-text'>
              Current plan (yearly)
            </h6>
            <div className='grid grid-cols-1 md:grid-cols-2 mt-4 gap-6 w-full'>
              {CurrentPlan?.map((item, index) => (
                <div key={index} className='text-start'>
                  <p className='s3-text text-[#80828D] mb-1'>{item?.title}</p>
                  <p className='s3-text text-[#2C2C2C] '>{item?.value}</p>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className={`${BMM_MainDiv5} overflow-x-auto`}>
              <div>
                <h6 className='h6-text'>Transaction history</h6>
              </div>
              <div className='min-w-full overflow-x-auto'>
                <div className='grid grid-cols-5 gap-4 mt-4 min-w-[600px]'>
                  {["Date", "Invoice Number", "Total Amount", "Mandates Used", "Invoice"].map((item, index) => (
                    <div key={index} className={`${item === "Invoice" ? "text-end" : "text-start"} ${index === 2 || index === 3 ? "flex justify-center" : "text-start"}`}>
                      <p className={BMM_MainDiv7}>{item}</p>
                    </div>
                  ))}
                </div>

                {getData?.mandate_history?.map((mandate, rowIndex) => (
                  <div key={rowIndex} className='mt-4'>
                    <div className={BMM_MainDiv8}></div>
                    <div className='grid grid-cols-5 gap-4 mt-4 min-w-[600px]'>
                      <div className='text-start notification-span text-[#2C2C2C]'>{formatDateBanker(mandate.created_at)}</div>
                      <div className='text-start body-text text-[#2C2C2C]'>{mandate.order_id}</div>
                      <div className='flex justify-center body-text text-[#2C2C2C]'>₹{mandate.amount}</div>
                      <div className='flex justify-center body-text text-[#2C2C2C]'>{mandate.project_name}</div>
                      <div className='flex justify-end'>
                        {mandate.invoice ? <DownloadIconInProfile /> : "Not available"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='mb-[3rem]'>
            <Button onClick={() => navigate("/dashboard/banker/cancel-subscription-reason")} className="greenbg text-white px-4 py-2 rounded-lg">
              Cancel Subscription
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankerManagePlan;
