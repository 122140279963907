import React, { useState, useEffect } from 'react';
import Navbar from '../../../../common/Navbar';
import { FlexJustitemCenter, JobListingCountStyle, JobListingMenuBarDivAvticeTab, JobListingSelectDiv } from '../../company/job/DashboardJobStyle';
import { IButtonIcon } from '../../../../common/Icons';
import InputField from '../../../../common/InputField';
import { useNavigate } from 'react-router-dom';
import SideNavbar from '../../../../common/SideNavbar';
import CompanyProjectComp from './CompanyProjectComp';
import SelectTagComponent from '../../../../common/SelectTagComponent';
import Pagination from '../../../../common/Pagination';
import ToolTip from '../../../../common/ToolTip';
import { CP_HR_Line, CPDiv2, CPDivP_tag, CPDivP_tag2, CPMainDiv, CPMainDiv2, CPMainDivCont, CPMainDivCont2, CPMainDivCont3, CPMainDivCont4, CPToolTipDiv, CPToolTipDiv2, FlexW_Full } from './CompanyProjectStyle';
import useGetData from '../../../../Hook/useGetData';
import CompanyAddEvent from '../calendar/CompanyAddEvent';

const CompanyProject = () => {
  const [activeTab, setActiveTab] = useState("All projects");
  const [list, setList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateInfo, setDateInfo] = useState(null);
  const [events, setEvents] = useState([]);

  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: getProjectData } = useGetData('projects/company', token);

  useEffect(() => {
    setList(getProjectData?.data || []);
  }, [getProjectData]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (option) => {
    setItemsPerPage(option.name);
    setCurrentPage(1);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab.name);
    if (tab.id === 1) {
      const filteredData = getProjectData?.data?.filter(item =>
        item.milestones.some(milestone => milestone.status === 1)
      ) || [];
      setList(filteredData);
    } else if (tab.id === 2) {
      const filteredData = getProjectData?.data?.filter(item =>
        item.milestones.some(milestone => milestone.status === 4)
      ) || [];
      setList(filteredData);
    } else {
      setList(getProjectData?.data || []);
    }
  };

  const handleSaveEvent = ({ title, eventType, color }) => {
    if (title) {
      const newEvent = {
        title,
        start: dateInfo ? dateInfo.date : null,
        end: dateInfo ? dateInfo.date : null,
        extendedProps: { eventType },
        color,
      };
      setEvents([...events, newEvent]);
    }
    setIsModalOpen(false);
  };

  const ToolTipColor = [
    { id: 1, color: "bg-[#1EB469]", status: "Completed" },
    { id: 2, color: "bg-[#EA9515]", status: "In Approval" },
    { id: 3, color: "bg-[#FDD33E]", status: "Ongoing" },
    { id: 4, color: "bg-[#FF0059]", status: "Declined" },
    { id: 5, color: "bg-[#D8D9E0]", status: "Not Started" },
    { id: 6, color: "bg-[#FFCACE]", status: "Overdue" }
  ];

  const mainTag = [
    {
      id: 1,
      name: "Ongoing",
      length: getProjectData?.data?.filter(item =>
        item.milestones.some(milestone => milestone.status === 1)
      ).length || 0,
    },
    {
      id: 2,
      name: "Completed",
      length: getProjectData?.data?.filter(item =>
        item.milestones.some(milestone => milestone.status === 4)
      ).length || 0,
    },
    {
      id: 3,
      name: "All projects",
      length: getProjectData?.data?.length || 0,
    },
  ];

  const filteredList = list?.filter((project) => {
    const projectNameMatch = project.project_name?.toLowerCase().includes(searchQuery.toLowerCase());
    const projectSubtitleMatch = project.subTitle?.toLowerCase().includes(searchQuery.toLowerCase());
    const projectMilestonesMatch = project.milestones?.some((milestone) =>
      milestone.title?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    // Return true if any of the fields match the search query
    return projectNameMatch || projectSubtitleMatch || projectMilestonesMatch;
  });
  

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredList.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(filteredList.length / itemsPerPage);

  const StatusToolTip = () => (
    <div className='w-[150px]'>
      {ToolTipColor.map((item) => (
        <div key={item.id} className={CPToolTipDiv}>
          <div className={`${CPToolTipDiv2} ${item.color}`}></div>
          <div className='body-text'>{item.status}</div>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <Navbar hemBurgerIcon={true} CompanyNavbar={true} ProfileAndNotification={true} />
      <div className={FlexW_Full}>
        <div className='w-0 md:w-[9%]'>
          <SideNavbar />
        </div>
        <div className={CPMainDiv}>
          <div className={CPMainDiv2}>
            <div className='md:flex md:justify-between px-4 lg:px-2'>
              <div className='w-full justify-between flex md:justify-start gap-8 py-6'>
                {mainTag.map((item) => (
                  <div key={item.id}>
                    <div
                      className={`${JobListingMenuBarDivAvticeTab} ${activeTab === item.name ? "font-bold" : ""}`}
                      onClick={() => handleTabClick(item)}
                    >
                      <div>{item.name}</div>
                      <div className={JobListingCountStyle}>{item.length}</div>
                    </div>
                    <div
                      className={`h-1 mt-4 border-1 ${activeTab === item.name ? "greenbg" : "bg-transparent"}`}
                    ></div>
                  </div>
                ))}
              </div>
              <div className={`block -mt-6 sm:hidden ${CP_HR_Line}`}></div>
              <div className='md:w-1/3 mt-4 w-full'>
                <InputField
                  SearchIcon={true}
                  placeholder='Search'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className={`md:block hidden ${CP_HR_Line}`}></div>
            <div className='px-4 md:px-0'>
              <div className='hidden md:block md:flex justify-between my-3 w-full'>
                <div className={CPDiv2}>
                  <div className='w-[35%]'>
                    <p className={CPDivP_tag}>Project Name</p>
                  </div>
                  <div className='w-[65%]'>
                    <p className={CPDivP_tag2}>
                      Milestones Status
                      <ToolTip message={<StatusToolTip />}>
                        <IButtonIcon />
                      </ToolTip>
                    </p>
                  </div>
                </div>
                <div className={CPMainDivCont}>
                  <div className='text-start'>
                    <p className={CPDivP_tag}>Banker</p>
                  </div>
                  <div>
                    <p className={CPDivP_tag}>Project Due Date</p>
                  </div>
                  <div className={FlexJustitemCenter}>
                    <p className={CPDivP_tag}>View Details</p>
                  </div>
                </div>
              </div>
              {currentItems.map((project) => (
                <CompanyProjectComp key={project.id} project={project} />
              ))}
            </div>
          </div>
          <div className={CPMainDivCont2}></div>
          <div className={CPMainDivCont3}>
            <div className={CPMainDivCont4}>
              <div className={JobListingSelectDiv}>
                <div>
                  <p className="s3-text">Show</p>
                </div>
                <div className="w-24">
                  <SelectTagComponent
                    defaultVal={itemsPerPage.toString()}
                    onChange={handleItemsPerPageChange}
                    options={[
                      { id: 1, name: 5 },
                      { id: 2, name: 10 },
                      { id: 3, name: 20 },
                      { id: 4, name: 30 },
                      { id: 5, name: 40 },
                      { id: 6, name: 50 },
                    ]}
                  />
                </div>
              </div>
              <div>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProject;
